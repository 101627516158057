import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames/bind';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import { enUS, cs, ro, hu, bg, hr, uk, sk, el, sl, de, ru, pl, mk, fr, sr, lv, lt, et } from 'date-fns/locale';
import Icon from '../Icon/Icon';
import Button from '../Button/Button';
import { getLocalization } from '../../../common/UserManager/UserManager';
import { LOGIN_INFO } from '../../../common/Constants';
import { albanianLocale } from './CustomLocale';
import * as styles from './Datepicker.css';
import './react-datepicker.css';

const cx = classNames.bind(styles);

const defaultFormat = 'dd/MM/yyyy';

const Datepicker = props => {
    const {
        calendarTitle,
        calendarNote,
        calendarMessage,
        theme,
        buttonOptions,
        footerText,
        onChange,
        selected,
        dateFormat,
        showTimeSelect,
        inline,
        minDate,
        maxDate,
        selectedDate = new Date(),
        onCloseClick,
        popoverAttachment,
        popoverTargetAttachment,
        customCalWrapper,
        includeDates
    } = props;
    const selectedFormat = defaultFormat;

    const lang = getLocalization()[LOGIN_INFO.LANG];

    const getLocale = () => {
        switch (lang) {
            case 'cs':
                return cs;
            case 'ro':
                return ro;
            case 'hu':
                return hu;
            case 'bg':
                return bg;
            case 'hr':
                return hr;
            case 'uk':
                return uk;
            case 'sk':
                return sk;
            case 'el':
                return el;
            case 'sl':
                return sl;
            case 'de':
            case 'de_AT':
                return de;
            case 'ru':
            case 'ru_BY':
                return ru;
            case 'pl':
                return pl;
            case 'mk':
                return mk;
            case 'sh':
                return sr;
            case 'sq':
                return albanianLocale;
            case 'fr':
                return fr;
            case 'lv':
                return lv;
            case 'lt':
                return lt;
            case 'et':
                return et;
            default:
                return enUS;
        }
    };

    const OpenWithButton = ({ value, onClick }) => {
        const lable = buttonOptions.buttonText ? buttonOptions.buttonText : value;
        return (
            <Button className={`${styles.datepickerButton}`} onClick={onClick} size='Md'>
                {' '}
                {lable}{' '}
            </Button>
        );
    };

    const datePicketContainer = ({ className, children }) => {
        let listItems = '';
        if (footerText) {
            listItems = calendarMessage && (
                <div className={cx('footerText')}>
                    <span>{calendarNote} </span>
                    {calendarMessage}
                </div>
            );
        }
        return (
            <div className={cx('calWrapper', customCalWrapper)}>
                <CalendarContainer className={className}>
                    <div className={cx('topContent')}>
                        <div className={cx('dateText')}>
                            <Icon
                                className={cx('leftCalIcon')}
                                iconType='svg'
                                width='24px'
                                height='24px'
                                viewBox='0 0 24 24'
                                name='cal-inner'
                            />
                            <h1>{calendarTitle}</h1>
                            <Button type='button' className={cx('closeIcon')} lable='button' onClick={onCloseClick} />
                        </div>
                        {children}
                    </div>
                    <div className={cx('bottomText')}>{listItems}</div>
                </CalendarContainer>
            </div>
        );
    };

    const handleChange = selectedDate => {
        if (onChange) onChange(selectedDate);
    };

    return (
        <div className={`${cx('datepickerContainer')} ${theme}`}>
            <DatePicker
                includeDates={includeDates}
                locale={getLocale()}
                customInput={buttonOptions ? <OpenWithButton /> : null}
                calendarContainer={!buttonOptions ? datePicketContainer : null}
                className={`${styles.datepickerInput}`}
                selected={selected || selectedDate}
                dateFormat={dateFormat || selectedFormat}
                onChange={handleChange}
                minDate={minDate}
                maxDate={maxDate}
                showTimeSelect={showTimeSelect}
                placeholderText
                inline={inline}
                popoverAttachment={popoverAttachment}
                popoverTargetAttachment={popoverTargetAttachment}
            />
        </div>
    );
};

Datepicker.propTypes = {
    calendarTitle: PropTypes.string,
    calendarNote: PropTypes.string,
    calendarMessage: PropTypes.string,
    theme: PropTypes.string,
    buttonOptions: PropTypes.oneOfType([PropTypes.object]),
    footerText: PropTypes.bool,
    onChange: PropTypes.func,
    showTimeSelect: PropTypes.string,
    inline: PropTypes.bool,
    maxDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onCloseClick: PropTypes.func.isRequired,
    popoverAttachment: PropTypes.string,
    popoverTargetAttachment: PropTypes.string,
    customCalWrapper: PropTypes.string,
    includeDates: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array])
};

Datepicker.defaultProps = {
    calendarTitle: '',
    calendarNote: '',
    calendarMessage: '',
    theme: '',
    buttonOptions: null,
    footerText: null,
    onChange: null,
    showTimeSelect: '',
    inline: '',
    maxDate: '',
    minDate: '',
    popoverAttachment: '',
    popoverTargetAttachment: '',
    customCalWrapper: '',
    includeDates: ''
};

export default Datepicker;
