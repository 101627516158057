import { all, put, call, takeLatest, select, takeLeading } from 'redux-saga/effects';
import _get from 'lodash/get';
import CartApi from '../interfaces/Cart/CartApi';
import HomeApi from '../interfaces/Home/HomeApi';
import UserApi from '../interfaces/UserMaintenance/UserMaintenanceApi';
import FactorySaga from './FactorySaga';
import { getFavoriteProductSaga } from './HomeSaga';
import {
    getActiveCartId,
    setActiveCartId,
    setReturnEmpties,
    setMarketingMaterials,
    mainPartnerId,
    getUserId
} from '../common/UserManager/UserManager';
import { notifySuccess, notifyError, clearAllNotification, showOrderNotifications, pushNotifications } from '../actions/NotificationAction';
import {
    GET_MINI_CART,
    GET_MINI_CART_SUCCESS,
    GET_CART,
    GET_CART_SUCCESS,
    GET_MULTI_CART,
    GET_MULTI_CART_SUCCESS,
    ADD_TO_CART,
    UPDATE_SAP_CART,
    ADD_PRODUCTS_TO_CART_FAILED,
    DELETE_FROM_CART,
    DELETE_SAVED_CART_ENTRY,
    UPDATE_CART,
    CART_ERROR,
    CLEAR_ALL_CART,
    CLEAR_ALL_CART_SUCCESS,
    CLEAR_SAVED_CART,
    CLEAR_SAVED_CART_SUCCESS,
    UPDATE_DELIVERY_DATE,
    UPDATE_RETURN_EMPTIES,
    OPEN_MINI_CART,
    RESTORE_CART,
    CART_PAGE_LOAD, 
    REMOVE_MANY_FROM_CART
} from '../actionTypes/CartActionTypes';
import { createTempMiniCartItems, updateMiniCartCount, clearMiniCart, updateTempMiniCart, updateMiniCartList, updateSavedMiniCartTotalPrice, restoreCart } from '../actions/CartAction';
import { SET_DELIVERY_DATE } from '../actionTypes/DeliveryActionTypes';
import { CLEAR_FAVOURITE_IN_HOME, SET_MINICART_MESSAGE_SHOWN } from '../actionTypes/HomeActionTypes';
import { RESET_PRODUCT_LIST } from '../actionTypes/ProductListActionTypes';
import { RESET_PROMO_CALENDER } from '../actionTypes/PromotionsActionTypes';
import Storage, { SessionStorage } from '../common/Storage/Storage';
import { getErrorInfo } from '../common/Utils/Utils';
import { ERROR_CODES } from '../common/MessageCodes';
import { addToCartEventGA4, removeFromCartEventGA4 } from '../common/GoogleTagManager/GoogleTagManager';
import { getProductPrice } from '../service/DataLayerFilter';
import { MESSAGE_TYPES, PAGE_ID, URL_PATHS, CART_L10N } from '../common/Constants';


const getDeliveryDate = state => _get(state, 'cartDetails.deliveryDate');
const getAddedToCartItems = state => _get(state, 'cartDetails.miniCartItems');

export function* getMiniCartSaga(action = {}) {
    try {
        if (getActiveCartId()) {
            if (!action.payload) {
                action.payload = {};
            }
            action.payload.params = { ...action.payload.params, totalCountOnly: true };

            const result = yield call(FactorySaga, CartApi.getMultiCart, action);

            if (result.isSuccess) {
                yield put({ type: GET_MINI_CART_SUCCESS, data: result.response.data });
            } else {
                yield put({ type: CART_ERROR });
            }
        }
    } catch (err) {
        yield put({ type: CART_ERROR });
    }
}

function* setMiniCartDataSaga(action) {
    try {
        //TODO: This API call should receive
        // all items and not just push what is send to previous ones
        // or should be called each time an item is added
        action.isSkipLoader = false;  
        action.data.addCartList = action.data.addCartList.reduce((a, e) => [
            ...a,
            {
                ...e,
                // entryNumber: 10 * (i+1)
                entryNumber: e.entryNumber || null
            }
        ], []);

        // const result = yield call(FactorySaga, CartApi.updateManyInCart, action);
        const result = yield call(FactorySaga, CartApi.addToMultiCart, action);
        if (result.isSuccess) {
            const cartId = _get(result, 'response.data.cartId');
            const message = _get(result, 'response.data.message');

            if (message || cartId) {   
                if (message && !cartId) {
                    yield put(notifySuccess({ message }));
                } else {
                    const isReturnEmpty = _get(result, 'response.data.isReturnEmptiesMode');
                    const isMarketingMaterials = _get(result, 'response.data.isMarketingMaterialsMode');
    
                    setActiveCartId(cartId);
                    setReturnEmpties(isReturnEmpty);
                    setMarketingMaterials(isMarketingMaterials);
    
                    if (!action.data.ignoreMiniCartQty) {
                        const { cartItems = [] } = result.response.data;
                        const qtyAdded = cartItems.reduce((total, { quantity }) => total + quantity, 0)
    
                        yield all([
                            // put(clearMiniCart()),
                            put(updateMiniCartCount({
                                override: true,
                                qtyAdded
                            })),
                        ]);
                    } else {
                        yield call(getMultiCartSaga)
                    }
                    yield put({ type: UPDATE_RETURN_EMPTIES, data: { isReturnEmpties: isReturnEmpty } })
                }
            }
        }
    } catch (err) {
        yield put({ type: CART_ERROR });
    }
}

export function* getCartDetailsSaga(action) {
    try {
        const result = yield call(FactorySaga, CartApi.getCart, action);

        if (result.isSuccess) {
            yield put({ type: GET_CART_SUCCESS, data: result.response.data });
        }
    } catch (err) {
        yield put({ type: CART_ERROR });
    }
}

export function* addCartEntriesSaga() {
    try {
        const items = yield select(getAddedToCartItems);

        if (items.length !== 0) {
            //Create fake action
            const action = {
                type: 'ADD_ALL_TO_CART',
                data: {
                    addCartList: items,
                    isAddProductsFromStateFlow: false
                }
            };

            //For some reason when adding the fake action to the saga directly it saves the state before cart opens...
            //But when dispatching a real action: the asynchronous call stucks and data is added to the state after the cart is opened!

            // yield put(clearMiniCart());
            yield* setMiniCartDataSaga(action);
        }
    } catch (err) {
        yield put({
            type: ADD_PRODUCTS_TO_CART_FAILED
        });
    }
}

export function* getMultiCartSaga(action = {}) {
    try {
        if (getActiveCartId()) {
            action.isSkipNotifyError = true;
            action.isSkipLoader = false;
            const result = yield call(FactorySaga, CartApi.getMultiCart, action);
            if (result.isSuccess) {
                const data = _get(result, 'response.data');
                yield put({ type: GET_MULTI_CART_SUCCESS, data });

                yield put(showOrderNotifications({
                    ...result.response.data,
                    saveLocal: true,
                    checkPage: true
                }));
            } else {
                const errorInfo = getErrorInfo(result);

                // Reset when cart is invalid.
                if (errorInfo.type === ERROR_CODES.CART_EMPTY_ERROR) {
                    const isDeliveryDateUpdated = yield select(getDeliveryDate);

                    setActiveCartId();
                    setReturnEmpties();
                    setMarketingMaterials();
                    yield all([
                        put(clearMiniCart()),
                        put({ type: SET_DELIVERY_DATE }),
                        put({ type: CLEAR_ALL_CART_SUCCESS })
                    ]);

                    // If delivery date got changed then reset the Home favorite products.
                    if (isDeliveryDateUpdated) {
                        yield all([
                            put({ type: CLEAR_FAVOURITE_IN_HOME }),
                            // put({ type: RESET_PRODUCT_LIST }),
                            put({ type: RESET_PROMO_CALENDER })
                        ]);

                        // To reset home favorites product.
                        if (window.location.pathname === URL_PATHS.HOME) {
                            yield call(getFavoriteProductSaga);
                        }
                    }
                } else if (errorInfo.type !== ERROR_CODES.UNKNOWN_ERROR) {
                    yield put(notifyError(errorInfo));
                }
            }
        }
    } catch (err) {
        yield put({ type: CART_ERROR });
    }
}

function* addToCartSaga(action) {
    const {
        addToCartMessage: message,
        code,
        substitute,
        quantity,
        quantityType,
        conversionFactors,
        isAdd,
        ignoreMiniCartQty,
        analyticsProductInfo,
        freeItem,
        promotionId,
        baseUnit,
        isSavedCartUpdateFlow
    } = action.payload.data;
    const { ignoreSavedCart } = action.payload;

    let uom = quantityType

    if (quantityType === 'PAL') {
        const conversionFactor = conversionFactors.find(cf => cf.code === quantityType);

        uom = baseUnit || uom
        try {
            const updatedQuantity = conversionFactor.value * quantity;
            action.payload.data.quantity = updatedQuantity;
        } catch (err) {
            yield put({ type: CART_ERROR });
        }
    }

    if (quantityType === 'LAY') {
        uom = baseUnit || uom
        const result = yield call(FactorySaga, CartApi.getConvFactor, action);
        if (result.isSuccess) {
            const conversionFactor = _get(result, 'response.data.conversionFactor');

            if (conversionFactor === 0) {
                const conversionFactorMessage = _get(result, 'response.data.conversionFactorMessage');
                yield put(notifyError({ message: conversionFactorMessage }));
                return;
            }

            action.payload.data.quantity = quantity * conversionFactor;
        }
    }

    try {
        const item = {
            code,
            freeItem,
            promotionId,
            quantityType: uom,
            quantity: isAdd || substitute
                ? action.payload.data.quantity
                : action.payload.data.quantity * -1,
        }

        if (ignoreSavedCart) {
            yield all([
                put(updateMiniCartList({ entry: { ...action.payload.data, quantityType: uom } })),
                put(updateTempMiniCart({ ...action.payload.data, quantity })),
                put(updateMiniCartCount({ qtyAdded: item.quantity })),
            ]);
        } else {
            const result = yield call(FactorySaga, CartApi.addToCart, {
                isSkipLoader: true,
                data: {
                    partnerId: mainPartnerId(),
                    userId: getUserId(),
                    substitute,
                    addCartList: item
                }
            });
    
            if (result.isSuccess && !ignoreMiniCartQty) {
                const { cartItems } = result.response.data;
    
                // GTM addToCartEvent
                if (analyticsProductInfo) {
                    try {
                        const foundIndex = cartItems.findIndex(({ entry: { product } }) => product.code.replace(/\b0+/g, '') === analyticsProductInfo.id);
                        const newQty = cartItems[foundIndex]?.entry?.quantity;

                        let price = getProductPrice(analyticsProductInfo.price) || 0;
                        if (analyticsProductInfo.dividePrice && price && newQty && analyticsProductInfo.oldQuantity) {
                            price = +(price / analyticsProductInfo.oldQuantity).toFixed(2);
                        }

                        const ga4Product = {
                            item_id: analyticsProductInfo.id,
                            item_name: analyticsProductInfo.name,
                            index: foundIndex,
                            item_brand: analyticsProductInfo.brand,
                            item_category: analyticsProductInfo.category,
                            item_list_id: analyticsProductInfo.list,
                            item_list_name: analyticsProductInfo.list,
                            item_variant: analyticsProductInfo.uom,
                            price,
                            quantity: newQty
                        };

                        if (!isNaN(newQty)) {
                            if (newQty > analyticsProductInfo.oldQuantity) {
                                ga4Product.quantity = newQty - analyticsProductInfo.oldQuantity;
                                addToCartEventGA4([ga4Product], analyticsProductInfo.origin_element);
                            } else {
                                ga4Product.quantity = analyticsProductInfo.oldQuantity - newQty;
                                removeFromCartEventGA4([ga4Product], analyticsProductInfo.origin_element);
                            }
                        }
                    } catch (ex) {
                        console.error(ex);
                    }
                }
                yield all([
                    put(updateMiniCartList({ entry: { ...action.payload.data, quantityType: uom } })),
                    put(updateTempMiniCart({ ...action.payload.data, quantity })),
                    put(updateMiniCartCount({
                        override: true,
                        qtyAdded: cartItems
                            ? cartItems.reduce((a, { entry }) => a + entry.quantity, 0)
                            : 0
                    }))
                ]);
                if(isSavedCartUpdateFlow) {
                    action.payload.isOptmizedMiniCartFlow = true
                    yield call(restoreCartSaga, action)
                }
            } else {
                yield put(notifyError(getErrorInfo(result)));
            }
        }
    } catch (err) {
        yield put({ type: CART_ERROR });
    }
}

export function* updateSAPCart(action) {
    yield* addToCartSaga({
        ...action,
        payload: {
            ...action.payload,
            data: {
                ...action.payload.data,
                ignoreMiniCartQty: true

            }
        }
    });
    
    const {
        quantity,
        quantityType,
        conversionFactors,
    } = action.payload.data;

    if (quantityType === 'PAL') {
        const conversionFactor = conversionFactors.find(cf => cf.code === quantityType);
        try {
            const updatedQuantity = conversionFactor.value * quantity;
            action.payload.data.quantity = updatedQuantity;
        } catch (err) {
            yield put({ type: CART_ERROR });
        }
    }

    if (quantityType === 'LAY') {
        const result = yield call(FactorySaga, CartApi.getConvFactor, action);
        if (result.isSuccess) {
            const conversionFactor = _get(result, 'response.data.conversionFactor');

            if (conversionFactor === 0) {
                const conversionFactorMessage = _get(result, 'response.data.conversionFactorMessage');

                yield put(notifyError({ message: conversionFactorMessage }));
                return;
            }

            action.payload.data.quantity = quantity * conversionFactor;
        }
    }

    const items = yield select(getAddedToCartItems);

    const newItem = action.payload.data;
    let found = false;
    const addCartList = items.map((e) => {
        if (newItem.code === e.code) {
            found = true;
            return {
                ...newItem,
                quantity: newItem.quantity + e.quantity
            }
        }

        return e;
    });

    if (!found) {
        addCartList.push(newItem);
    }

    yield* setMiniCartDataSaga({
        type: 'ADD_ALL_TO_CART',
        data: {
            addCartList,
            isAddProductsFromStateFlow: false,
            ignoreMiniCartQty: true
        }
    });
}

export function* openMiniCartSaga(action = {}) {
    try {
        yield* addCartEntriesSaga();

        const items = yield select(getAddedToCartItems);

        if (items.length > 0) {
            action.isSkipLoader = false;
        }
       
        yield* getMultiCartSaga(action);

    } catch (err) {
        yield put({ type: CART_ERROR });
    }
}

export function* cartPageLoad(action = {}) {
    try {
        SessionStorage.setData(CART_L10N.IS_SKIP_LOAD_CART_DATA, true, true);

        yield* restoreCartSaga();

        yield* addCartEntriesSaga();

        const items = yield select(getAddedToCartItems);

        if (items.length > 0) {
            action.isSkipLoader = false;
        }

        yield* getMultiCartSaga(action);
       
        SessionStorage.removeData(CART_L10N.IS_SKIP_LOAD_CART_DATA, true);
    } catch (err) {
        yield put({ type: CART_ERROR });
    }
}


function* updateCartSaga(action) {
    try {
        const result = yield call(FactorySaga, CartApi.updateToCart, action);
        if (result.isSuccess) {
            const data = _get(result, 'response.data.multiCartSummary');
            const message = _get(result, 'response.data.message');
            const loyaltyDiscountAddedMessage = _get(result, 'response.data.loyaltyDiscountAddedMessage');
           
            yield all([
                put({ type: GET_MULTI_CART_SUCCESS, data }),
                put(showOrderNotifications({
                    ...result.response.data,
                    checkPage: true
                }))
            ]);
        }
    } catch (err) {
        yield put({ type: CART_ERROR });
    }
}

function* updateDeliveryDateSaga(action) {
    try {
        const result = yield call(FactorySaga, CartApi.updateDeliveryDate, action);

        if (result.isSuccess) {
            const payload = _get(action, 'payload.data', {});
            // Clear the PLP URL Session.
            Storage.removeData(URL_PATHS.PLP_REFERRER_URL);
            // Reset when cart deleivery date is updated.
            yield all([
                put({ type: CLEAR_FAVOURITE_IN_HOME }),
                put({ type: RESET_PRODUCT_LIST }),
                put({ type: RESET_PROMO_CALENDER }),
                put({ type: GET_MULTI_CART_SUCCESS, data: _get(result, 'response.data.multiCartSummary') }),
                put({ type: SET_DELIVERY_DATE, data: `${payload.basketId}${payload.deliveryDate}` })
            ]);
            // To reset home favorites product.
            if (window.location.pathname === URL_PATHS.HOME) {
                yield call(getFavoriteProductSaga);
            }
        }
    } catch (err) {
        yield put({ type: CART_ERROR });
    }
}

export function* resetDeliveryDateSaga() {
    try {
        const action = {
            isSkipLoader: true,
            isSkipNotifyError: true
        };

        yield call(FactorySaga, CartApi.resetDeliveryDate, action);
    } catch (err) {
        yield put({ type: CART_ERROR });
    }
}

function* deleteFromCartSaga(action) {
    try {
        action.isSkipNotifyError = true;
        const result = yield call(FactorySaga, CartApi.deleteFromCart, action);

        if (result.isSuccess) {
            yield call(getMultiCartSaga)
            yield put(notifySuccess(result.response.data));
        } else {
            const errorInfo = getErrorInfo(result);

            // Reset when cart is invalid.
            if (errorInfo.type === ERROR_CODES.CART_DELETE_EMPTY_ERROR) {
                const isDeliveryDateUpdated = yield select(getDeliveryDate);

                setActiveCartId();
                setReturnEmpties();
                setMarketingMaterials();

                yield all([
                    put({ type: SET_DELIVERY_DATE }),
                    put({ type: CLEAR_ALL_CART_SUCCESS }),
                    put(clearAllNotification())
                ]);
                // Reset delivery date in the background.
                yield call(resetDeliveryDateSaga);

                // If delivery date got changed then reset the Home favorite products.
                if (isDeliveryDateUpdated) {
                    yield all([
                        put({ type: CLEAR_FAVOURITE_IN_HOME }),
                        put({ type: RESET_PRODUCT_LIST }),
                        put({ type: RESET_PROMO_CALENDER })
                    ]);

                    // To reset home favorites product.
                    if (window.location.pathname === URL_PATHS.HOME) {
                        yield call(getFavoriteProductSaga);
                    }
                }
            } else {
                yield put(notifyError(errorInfo));
            }
        }
    } catch (err) {
        yield put({ type: CART_ERROR });
    }
}

function* removeManyFromCartSaga(action) {
    try {
        action.isSkipNotifyError = true;
        const result = yield call(FactorySaga, CartApi.removeManyFromCartSaga, action);

        if (result.isSuccess) {
            action.isRemoveOosFlow = true;  
            yield* getMultiCartSaga(action);
            yield put(notifySuccess(result.response.data));
        } else {
            const errorInfo = getErrorInfo(result);

            // Reset when cart is invalid.
            if (errorInfo.type === ERROR_CODES.CART_DELETE_EMPTY_ERROR) {
                const isDeliveryDateUpdated = yield select(getDeliveryDate);

                setActiveCartId();
                setReturnEmpties();
                setMarketingMaterials();

                yield all([
                    put({ type: SET_DELIVERY_DATE }),
                    put({ type: CLEAR_ALL_CART_SUCCESS }),
                    put(clearAllNotification())
                ]);
                // Reset delivery date in the background.
                yield call(resetDeliveryDateSaga);

                // If delivery date got changed then reset the Home favorite products.
                if (isDeliveryDateUpdated) {
                    yield all([
                        put({ type: CLEAR_FAVOURITE_IN_HOME }),
                        put({ type: RESET_PRODUCT_LIST }),
                        put({ type: RESET_PROMO_CALENDER })
                    ]);

                    // To reset home favorites product.
                    if (window.location.pathname === URL_PATHS.HOME) {
                        yield call(getFavoriteProductSaga);
                    }
                }
            } else {
                yield put(notifyError(errorInfo));
            }
        }
    } catch (err) {
        yield put({ type: CART_ERROR });
    }
}


export function* clearAllCartSaga(action = {}) {
    try {
        const activeCartId = getActiveCartId()
        if (activeCartId) {
            action.isSkipLoader = false;
            const isDeliveryDateUpdated = yield select(getDeliveryDate);
            // Invoking Clear All Cart API in the background to improve the performance.
            const result = yield call(FactorySaga, CartApi.clearAllCart, action);

            if (result.isSuccess) {
                // Reset when cart is cleared.
                setActiveCartId();
                setReturnEmpties();
                setMarketingMaterials();

                yield all([
                    put({ type: SET_DELIVERY_DATE }),
                    put({ type: CLEAR_ALL_CART_SUCCESS, data: { isClearCart: action.isClearCart } }),
                    put(clearAllNotification()),
                    put(clearMiniCart()),
                ]);

                // If delivery date got changed then reset the Home favorite products.
                if (isDeliveryDateUpdated) {
                    yield all([
                        put({ type: CLEAR_FAVOURITE_IN_HOME }),
                        put({ type: RESET_PRODUCT_LIST }),
                        put({ type: RESET_PROMO_CALENDER })
                    ]);

                    // To reset home favorites product.
                    if (window.location.pathname === URL_PATHS.HOME) {
                        yield call(getFavoriteProductSaga);
                    }
                }
            }

        }
    } catch (err) {
        yield put({ type: CART_ERROR });
    }
}

export function* clearSavedCartSaga(action = {}) {
    try {

            const result = yield call(FactorySaga, CartApi.clearSavedCart, {
                type: 'action',
                isSkipLoader: true,
                payload: {
                    params: {
                        b2bUnitId: mainPartnerId().toString(),
                    }
                }
        
            });

            if (result.isSuccess) {
                // Reset when cart is cleared.
                yield all([
                    put({ type: CLEAR_SAVED_CART_SUCCESS, data: { isClearCart: action.isClearCart } }),
                    put(clearAllNotification()),
                    put(clearMiniCart()),
                ]);

            }

    } catch (err) {
        yield put({ type: CART_ERROR });
    }
}

function* deleteSavedCartEntrySaga(action) {
    try {
        action.isSkipNotifyError = true;

        const result = yield call(FactorySaga, CartApi.deleteSavedCartEntry, {
            type: 'action',
            isSkipLoader: true,
            payload: {
                params: {
                    b2bUnitId: mainPartnerId().toString(),
                    productCode: action.payload.code
                }
            }
    
        });

        if (result.isSuccess) {
            action.payload.isOptmizedMiniCartFlow = true
            yield call(restoreCartSaga, action)
            yield put(notifySuccess(result.response.data));
        } else {
               yield all([
                put(clearMiniCart()),
                put(clearAllNotification())
            ]);
        }
        
    } catch (err) {
        yield put({ type: CART_ERROR });
    }
}

export function* setMiniCartMessageShownSaga(action) {
    try {
        if (action.data !== false) {
            yield call(FactorySaga, UserApi.setMinicartMessageShown, {
                type: 'action',
                payload: {}
            });
        }
    } catch (err) {
        // eslint-disable-next-line no-console
    }
}

export function* restoreCartSaga(action = {}) {
    const savedCart = yield call(FactorySaga, HomeApi.getSavedCart, {
        type: 'action',
        isSkipLoader: action?.payload?.isOptmizedMiniCartFlow,
        payload: {
            params: {
                b2bUnitId: mainPartnerId().toString(),
                isOptmizedMiniCartFlow: action?.payload?.isOptmizedMiniCartFlow,
                isIgnoreFreeItemIteration: action?.isIgnoreFreeItemIteration
            }
        }

    });

    if (savedCart.isSuccess) {
        const { entries, message, savedCartTotalPrice, showGrossPrice } = savedCart.response.data;

        if (entries) {
            const miniCartItems = entries.reduce((all, element) => [
                ...all,
                {
                    quantity: element.quantity,
                    code: element.product.code,
                    codeShort: element.product.codeShort,
                    name: element.product.name,
                    quantityType: element.qtyType || 'CS',
                    quantityTypeName: element.qtyTypeName || 'CS',
                    stockFlag: element.product.stockFlag,
                    price: element.product.price,
                    promotionId: null,
                },
            ], []);
    
            const plpItems = miniCartItems.reduce((all, item) => ({
                ...all,
                [item.quantityType]: {
                    ...(all[item.quantityType] || {}),
                    [item.code]: {
                        quantityType: item.quantityType,
                        quantity: item.quantity,
                    }
                }
            }), {});
    
            yield all([
                put(createTempMiniCartItems(plpItems)),
                put(updateMiniCartList({ entry: miniCartItems })),
                put(updateMiniCartCount({
                    override: true,
                    qtyAdded: miniCartItems.reduce((a, e) => a + e.quantity, 0),
                    
                })),
                put(updateSavedMiniCartTotalPrice({
                    savedCartTotalPrice,
                    showGrossPrice
                })),
            ]);
    
            const { pageId } = action;
            if (pageId === PAGE_ID.HOME)
                yield put(pushNotifications({ 
                    messages: [{
                        content: message || '',
                        type: MESSAGE_TYPES.BLUE_NOTIFICATION
                    }]
                }));
        }
    }
}

export default function* watchCartRequest() {
    yield takeLatest(GET_MINI_CART, getMiniCartSaga);
    yield takeLatest(SET_MINICART_MESSAGE_SHOWN, setMiniCartMessageShownSaga);
    yield takeLatest(GET_CART, getCartDetailsSaga);
    yield takeLatest(GET_MULTI_CART, getMultiCartSaga);
    yield takeLatest(OPEN_MINI_CART, openMiniCartSaga);
    yield takeLatest(CART_PAGE_LOAD, cartPageLoad);
    yield takeLeading(ADD_TO_CART, addToCartSaga);
    yield takeLatest(REMOVE_MANY_FROM_CART, removeManyFromCartSaga)
    yield takeLatest(DELETE_FROM_CART, deleteFromCartSaga);
    yield takeLatest(DELETE_SAVED_CART_ENTRY, deleteSavedCartEntrySaga);
    yield takeLatest(UPDATE_CART, updateCartSaga);
    yield takeLatest(CLEAR_ALL_CART, clearAllCartSaga);
    yield takeLatest(CLEAR_SAVED_CART, clearSavedCartSaga);
    yield takeLatest(UPDATE_DELIVERY_DATE, updateDeliveryDateSaga);
    yield takeLatest(UPDATE_SAP_CART, updateSAPCart);
    yield takeLeading(RESTORE_CART, restoreCartSaga);
}
