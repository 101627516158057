export const SET_CUTOFF_NOTIFICATION = 'SET_CUTOFF_NOTIFICATION';
export const SET_SUCCESS_NOTIFICATION = 'SET_SUCCESS_NOTIFICATION';
export const SET_BLUE_NOTIFICATION = 'SET_BLUE_NOTIFICATION';
export const SET_WARN_NOTIFICATION = 'SET_WARN_NOTIFICATION';
export const SET_ERROR_NOTIFICATION = 'SET_ERROR_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const CLEAR_ALL_NOTIFICATION = 'CLEAR_ALL_NOTIFICATION';
export const SET_MILTIPLE = 'SET_MILTIPLE';
export const PUSH_NOTIFICATIONS = 'PUSH_NOTIFICATIONS';
export const SHOW_ORDER_NOTIFICATIONS = 'SHOW_ORDER_NOTIFICATIONS';

