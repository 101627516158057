/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _forOwn from 'lodash/forOwn';
import _cloneDeep from 'lodash/cloneDeep';
import classNames from 'classnames/bind';
import Slider from 'react-slick';
import Button from '../../../components/core/Button/Button';
import Checkbox from '../../../components/core/Checkbox/Checkbox';
import Layout from '../../../components/site/Layout/Layout';
import Loader from '../../../components/site/Loader/Loader';
import Accordion from '../../../components/core/Accordion/Accordion';
import BreadCrumbs from '../../../components/core/BreadCrumbs/BreadCrumbs';
import Icon from '../../../components/core/Icon/Icon';
import SelectBox from '../../../components/core/SelectBox/SelectBox';
import DeliveryType from '../../../components/site/DeliveryType/DeliveryType';
import { restoreCart, clearMiniCart, changeOrderType } from '../../../actions/CartAction';
import {
    getProductList,
    resetProductList,
    updateProductsSort,
    getBdaaSuggestion,
    setPLPViewChoiceFunc
} from '../../../actions/ProductListAction';
import {
    getElement,
    getUpdatedURL,
    parseSavedFilters,
    productListGetURL,
    redirectTo
} from '../../../common/Utils/Utils';
import Modal from '../../../components/core/Modal/Modal';
import {
    UNIQUE_ID,
    PAGE_ID,
    PLP_L10N,
    PLP_TYPES,
    PLP_VIEW_TYPES,
    PLP_FILTERS,
    URL_PATHS,
    GENERIC_L10N,
    DATA_LAYER_LISTS,
    PLP_SORTS_VALUES,
    PLP_SORTS,
    PLP_MOBILE_FILTERS_VIEWS,
    ROUND_LOGO_COUNTRIES,
    MESSAGE_LEVELS,
    MESSAGE_TYPES
} from '../../../common/Constants';
import { getPageContent, setPageTitlePrefix } from '../../../actions/PageContentAction';

import ProductCard from '../../../components/site/ProductCards/ProductCards';
import ShowPriceTemplate from '../../../components/site/ShowPriceTemplate/ShowPriceTemplate';
import Image from '../../../components/core/Image/Image';
import Storage from '../../../common/Storage/Storage';
import {
    isReturnEmpties,
    isUseMarketingMaterials,
    isBdaaActive,
    mainPartnerId,
    mainPartnerCode,
    getSiteId,
    isStockAllowed
} from '../../../common/UserManager/UserManager';
import * as styles from './ProductList.css';
import SmartEdit from '../../../components/site/SmartEdit/SmartEdit';
import {
    viewItemListEventGA4,
    promotionImpressionEventGA4,
    promotionClickEventGA4,
    buttonClickEvent,
    buttonClickEventConfigs
} from '../../../common/GoogleTagManager/GoogleTagManager';
import {
    homePromotionBannerImpressionsDataLayerFilterGA4,
    productImpressionsProductListDataLayerFillterGA4,
    productImpressionsDataLayerFillterGA4
} from '../../../service/DataLayerFilter';
import { getMarketingConsent, hideMarketingConsent } from '../../../actions/MarketingAction';

import { DEVICE_TYPES, getDevice } from '../../../../__runtime/redux/ContextLoader';
import { pubSubManager, PUB_SUB_EVENTS } from '../../../common/UIPubSub';
import NotificationSubscription from '../../../components/site/NotificationSubscription/NotificationSubscription';
import { openSubscriptionPopup } from '../../../actions/NotifyMeAction';
import RangeInput from '../../../components/core/RangeInput/RangeInput';
import Switcher from '../../../components/core/Switcher/Switcher';
import MessageBox from '../../../components/core/MessageBox/MessageBox';

const isRoundLogo = ROUND_LOGO_COUNTRIES.includes(getSiteId());
const cx = classNames.bind(styles);

export class ProductList extends Component {
    constructor(props) {
        super(props);
        // TODO: Temporarily keeping this variable to enable/disable PLP Sticky.
        this.props.actions.hideMarketingConsent();
        this.isStickyEnabled = true;
        // Create a ref to prevent blurring the saved filters card when an option is clicked within it
        this.savedFiltersCardRef = React.createRef();
        // Create a ref to prevent blurring the saved filters card when an option is clicked within it on mobile
        this.mobileFooterRef = React.createRef();
        // Variable to ensure we don't rerender componentDidUpdate when manual saved filters are applied using the applySavedFilters function
        this.initialFiltersLoaded = false;
        this.state = {
            appliedFacets: {
                data: {}
            },
            isShowFilter: true,
            isShowPrice: true,
            isShowMobileFiltersToggle: false,
            isShowMobileFilter: false,
            isShowMobileSort: false,
            isSavedFilters: false,
            updateSavedFiltersPossible: false,
            currentFilterBanner: '',
            showSavedFiltersCard: false,
            mobileFilterView: PLP_MOBILE_FILTERS_VIEWS.MAIN,
            savedMobileFilters: {},
            mobileFiltersOnOpenCopy: {},
            selectedView: '',
            selectedMobileView: '',
            appliedMobileView: '',
            showPricePopUp: false,
            orientationAngle: 0,
            isViewValueUpdated: false,
            showMarketing: false,
            products: [],
            showNotifyPopup: false,
            subscribedProduct: '',
            isBdaaImpressionSent: false,
            seeMoreBrands: false,
            isPricePerPiece: undefined,
            filterPriceRange: [0, 1]
        };

        this.isStockAllowed = isStockAllowed();
        this.isBdaaActive = isBdaaActive();
        this.mainPartnerId = mainPartnerId();
        this.mainPartnerCode = mainPartnerCode();

        this.lastItem = React.createRef();

        this.toggleAccordianPromo = false;

        const handleIntersect = entities => {
            entities.forEach(entity => {
                if (
                    entity.isIntersecting &&
                    entity.intersectionRatio > 0 &&
                    this.props.productList.currentPage + 1 < this.props.productList.totalPages &&
                    !entity.target.once
                ) {
                    const pageToLoad = this.props.productList.currentPage + 2;
                    this.loadMore(PLP_FILTERS.PAGE, pageToLoad, true);
                    entity.target.once = true;
                }
            });
        };

        function redirectPath(path, char) {
            return `${path.split(char).slice(0, -1).join(char)}${char}1`;
        }

        if (
            !Array.isArray(this.props?.productList?.products) &&
            window.location.pathname !== '/plp/order' &&
            !window.location.pathname.includes('/search') &&
            window.location.pathname !== '/plp/zpop' &&
            window.location.pathname !== '/plp/return' &&
            !window.location.pathname.endsWith(1)
        ) {
            window.location = window.location.pathname.includes(',')
                ? redirectPath(window.location.pathname, ',')
                : redirectPath(window.location.pathname, '/');
        }

        this.observer = new IntersectionObserver(handleIntersect, {
            root: this.lastItem.current
        });

        if (this.isBdaaActive) {
            this.props.actions.getBdaaSuggestion({
                data: { countryCode: this.mainPartnerCode, customerId: this.mainPartnerId }
            });
        }
    }

    componentDidMount() {
        this.isFirstViewItemListEvent = true;
        this.handleMobileFiltersToggleScroll();
        this.getProductData(isReturnEmpties(), isUseMarketingMaterials(), false);
        if (window.location.pathname.includes('/plp/order')) {
            this.props.actions.getMarketingConsent();
        }

        window.addEventListener('orientationchange', event => {
            try {
                if (event.target.screen.orientation.angle === 0) {
                    this.setState({
                        isShowFilter: false,
                        orientationAngle: event.target.screen.orientation.angle
                    });
                } else {
                    this.setState({
                        isShowMobileFilter: false,
                        orientationAngle: event.target.screen.orientation.angle
                    });
                }
            } catch (e) {
                return e;
            }
        });

        if (this.isStickyEnabled) {
            window.addEventListener('scroll', this.handleScroll);
        }

        this.setState(prevState => ({
            filterPriceRange: [
                +prevState.appliedFacets?.data?.minPriceFilterValue?.[0],
                +prevState.appliedFacets?.data?.maxPriceFilterValue?.[0]
            ]
        }));
    }

    // productList in redux is set to local state here, be careful
    static getDerivedStateFromProps(props, state) {
        return {
            ...state,
            showMarketing: props.marketing.visible,
            productList: props.productList
        };
    }

    componentDidUpdate(prevProps) {
        const {
            orderType,
            params,
            location,
            localization,
            productList,
            simpleBanner,
            notification = {},
            viewChoice,
            isSavedFilterDefault,
            savedFilters
        } = this.props;
        const { products } = productList;
        const { plpType, filterTypes } = params;

        const hasViewChanged = prevProps.viewChoice !== viewChoice;
        const hasPageChanged = prevProps.localization.pageId !== localization.pageId;

        // Parse the filterTypes params to make sure it doesn't contain anything except PLP_FILTERS.MAKE_DEFAULT_SAVED_FILTERS
        const filterTypesArray = filterTypes && filterTypes.length > 0 ? filterTypes.split(',') : [];

        // Make sure we have access to parsed saved filters if they exist in customer's backend. We also do this if localization changes, cause that means they're coming from another page. We can't use location because it doesn't work properly due to a bad routing setup.
        let parsedFilters = {};
        if (
            (prevProps.savedFilters !== savedFilters && savedFilters) ||
            (!_isEqual(prevProps.localization, localization) && savedFilters)
        ) {
            parsedFilters = parseSavedFilters(savedFilters);
        }

        // On first mount, when saved filters and isSavedFilterDefault are true, we need to set the appliedFacets in the state to the parsedFilters from the saved filters prop, and then fetch the product list with those filters applied. We only want this logic to happen once, because then it can cause problems since isSavedFilterDefault will always be true after the first time. Therefore, we initialize a variable to keep track of whether this logic has already happened or not in the constructor. We ignore this if the url contains any params, because that means the user will be coming from some sort of promotion or saved bookmark.

        if (
            !this.initialFiltersLoaded &&
            (!filterTypes || filterTypesArray.includes(PLP_FILTERS.MAKE_DEFAULT_SAVED_FILTERS)) &&
            isSavedFilterDefault &&
            savedFilters
        ) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(
                {
                    isSavedFilters: true,
                    appliedFacets: {
                        data: parsedFilters
                    },
                    updateSavedFiltersPossible: false
                },
                () => {
                    this.getProductData(false, false, true);
                }
            );
            // initialize this variable to true so this logic doesn't happen again
            this.initialFiltersLoaded = true;
        }

        if (filterTypes && !filterTypesArray.includes(PLP_FILTERS.MAKE_DEFAULT_SAVED_FILTERS)) {
            this.initialFiltersLoaded = true;
        }

        // Prevent user from updating their saved filters if the appliedFacets in the state are equal to those coming in from savedFilters prop and parsed above.
        if (Object.keys(parsedFilters).length > 0) {
            const { data } = this.state.appliedFacets;
            // Remove the page filter, saved filter, default filter, or delete filter keys from the applied facets in the state so we can compare it to the parsed filters from the saved filters prop
            const appliedFacetsFiltered = this.removeSavedFilterKeysFromAppliedFacets(data);
            // Sort both arrays before comparing them, since the order of the keys in the applied facets in the state may be different than the order of the keys in the parsed filters from the saved filters prop which can cause the comparison to fail even if they are the same for isEqual
            const appliedFacetsObjClean = this.sortNestedArrays(appliedFacetsFiltered);
            const parsedFiltersClean = this.sortNestedArrays(parsedFilters);
            const areAppliedFacetsDifferentThanParsed = !_isEqual(parsedFiltersClean, appliedFacetsObjClean);
            if (areAppliedFacetsDifferentThanParsed) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({ updateSavedFiltersPossible: true, isSavedFilters: false });
            } else if (!areAppliedFacetsDifferentThanParsed) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({ updateSavedFiltersPossible: false, isSavedFilters: true });
            }
        }

        if (
            (Object.keys(this.state.appliedFacets.data).length > 0 && prevProps.savedFilters !== savedFilters) ||
            (Object.keys(this.state.appliedFacets.data).length > 0 &&
                this.state?.appliedFacets?.data?.isPricePerPieceFilterActive &&
                this.state?.isPricePerPiece === undefined)
        ) {
            // check if isPricePerPieceFilterActive exists in appliedFacets
            const isPricePerPieceInAppliedFacets =
                this.state?.appliedFacets?.data?.isPricePerPieceFilterActive?.[0] === 'true' || false;

            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(prevState => {
                return {
                    isPricePerPiece: isPricePerPieceInAppliedFacets
                };
            });
        }

        //Logic for sending promo impressions to GTag when we change pages or views
        if (_isEmpty(prevProps.simpleBanner) && !_isEmpty(simpleBanner)) {
            this.handleGAPromotionImpressions(simpleBanner);
        }

        if (window.location.pathname === `/plp/${PLP_TYPES.ZPOP}` && orderType !== PLP_TYPES.ZPOP) {
            this.props.actions.changeOrderType({ type: PLP_TYPES.ZPOP });
        }

        if (window.location.pathname === `/plp/${PLP_TYPES.RETURN}` && orderType !== PLP_TYPES.RETURN) {
            this.props.actions.changeOrderType({ type: PLP_TYPES.RETURN });
        }

        if (window.location.pathname === `/plp/${PLP_TYPES.ORDER}` && orderType !== PLP_TYPES.ORDER) {
            this.props.actions.changeOrderType({ type: PLP_TYPES.ORDER });
            this.props.actions.restoreCart({ pageId: null });
        }

        //Logic for sending product impressions to GTag when we change pages or views
        if ((!_isEqual(products, prevProps.productList.products) || this.isFirstViewItemListEvent) && products) {
            if (products.length > 0) {
                try {
                    const isFavorite = filterTypes ? filterTypes.indexOf(PLP_FILTERS.FAVORITE) !== -1 : false;
                    let listType = DATA_LAYER_LISTS.PRODUCT_LIST;
                    const queryText = location.query.text;

                    if (plpType === PLP_TYPES.ORDER && isFavorite) {
                        listType = DATA_LAYER_LISTS.FAVORITE_LIST;
                    } else if (plpType === PLP_TYPES.SEARCH && queryText) {
                        listType = DATA_LAYER_LISTS.SEARCH_LIST;
                    }

                    viewItemListEventGA4(
                        productImpressionsProductListDataLayerFillterGA4(_cloneDeep(products), listType),
                        queryText
                    );
                } catch (ex) {
                    console.error(ex);
                }

                this.isFirstViewItemListEvent = false;
            }
        }

        // Setting default value for first time when the PLP Localization is available.
        if (hasViewChanged || hasPageChanged) {
            this.setViewDefaultState();
        }

        if (prevProps.params.plpType !== params.plpType) {
            if (
                prevProps.params.plpType !== PLP_TYPES.RETURN &&
                prevProps.params.plpType !== PLP_TYPES.ZPOP &&
                prevProps.params.plpType !== PLP_TYPES.SEARCH
            ) {
                Storage.removeData(URL_PATHS.PLP_REFERRER_URL);
                Storage.setData(URL_PATHS.PLP_REFERRER_URL, prevProps.location.pathname);
            }
            const isEmpties = prevProps.params.plpType === PLP_TYPES.RETURN;
            const isMarketingMaterials = prevProps.params.plpType === PLP_TYPES.ZPOP;
            // getProductData is used to create a query string which is then used by the getPageContent Saga to fetch the page content from the API. That saga also yield calls to getProductList Saga which fetches the products from the API.
            this.resetData(this.getProductData, isEmpties, isMarketingMaterials);
        } else if (
            prevProps.params.plpType === params.plpType &&
            prevProps.location.pathname !== location.pathname &&
            location.pathname === URL_PATHS.PLP_ORDER
        ) {
            Storage.removeData(URL_PATHS.PLP_REFERRER_URL);
            Storage.setData(URL_PATHS.PLP_REFERRER_URL, location.pathname);
            this.resetData(this.getProductData, false, false);
        }
        if (
            _get(prevProps, 'location.query.text', '') !== _get(location, 'query.text', '') &&
            _get(prevProps, 'location.query.text', '') !== undefined &&
            _get(location, 'query.text', '') !== undefined
        ) {
            this.resetData();
        }

        if (prevProps.location.pathname !== location.pathname && location.hash === '#force') {
            this.resetData(this.getProductData, false, false);
        }

        // Hiding show price & getting updated promotion when the cart delivery date is changed.
        if (productList.isReset && !prevProps.productList.isReset) {
            this.handleHidePrice();
        }

        // Setting page title prefix.
        let pageTitlePrefix = localization[PLP_L10N.ALL_PRODUCTS];

        if (params.plpType === PLP_TYPES.RETURN) {
            pageTitlePrefix = localization[PLP_L10N.RETURN_TITLE];
        } else if (params.plpType === PLP_TYPES.SEARCH) {
            pageTitlePrefix = localization[PLP_L10N.SEARCH_TITLE];
        } else if (params.plpType === PLP_TYPES.ZPOP) {
            pageTitlePrefix = localization[PLP_L10N.MARKETING_MATERIALS_TITLE];
        }
        this.props.actions.setPageTitlePrefix(pageTitlePrefix);

        if (this.isStickyEnabled) {
            const messageCount = _get(notification, 'messageList.length', 0);

            // Check if any new message or existing message got updated to adjust the height of #stickyButton.
            if (this.messageCount !== messageCount) {
                this.messageCount = messageCount;
                // Just a scroll will reset the height appropriately after the notification update.
                window.scrollTo(0, window.pageYOffset - 1);
            }

            const cutOffCount = _get(notification, 'cutOffNotifications.length', 0);

            // Check if any new cutoff message or existing cutoff message got updated to adjust the height of #stickyButton.
            if (this.cutOffCount !== cutOffCount) {
                this.cutOffCount = cutOffCount;
                // Just a scroll will reset the height appropriately after the notification update.
                window.scrollTo(0, window.pageYOffset - 1);
            }
        }

        if (this.lastItem.current) {
            this.observer.observe(this.lastItem.current);
        }
        // logic to determine if brand filters should be collapsed
        if ((!_isEqual(products, prevProps.productList.products) || this.isFirstViewItemListEvent) && products) {
            this.calculateSeeMoreBrands(productList);
        }
    }

    componentWillUnmount() {
        this.initialFiltersLoaded = false;
        window.removeEventListener('orientationchange', event => {
            if (event.target.screen.orientation.angle === 0) {
                this.setState({
                    isShowFilter: false
                });
            }
        });

        if (this.props.params.plpType === PLP_TYPES.ORDER) {
            const { location } = this.props;
            Storage.removeData(URL_PATHS.PLP_REFERRER_URL);
            Storage.setData(URL_PATHS.PLP_REFERRER_URL, location.pathname);
        }
        this.props.actions.setPageTitlePrefix();

        if (this.isStickyEnabled) {
            window.removeEventListener('scroll', this.handleScroll);
        }

        if (this.props.deviceType.isMobile) {
            this.toggleLockScroll(false);
        }
        clearTimeout(this.blurTimeout);
    }

    handleGAPromotionImpressions = items => {
        try {
            promotionImpressionEventGA4(homePromotionBannerImpressionsDataLayerFilterGA4(items));
        } catch (ex) {
            console.error(ex);
        }
    };

    handleGAPromotionClick = item => {
        try {
            promotionClickEventGA4(homePromotionBannerImpressionsDataLayerFilterGA4(item));
        } catch (ex) {
            console.error(ex);
        }
    };

    // Toggle between price per piece and price per order in the filters
    togglePricePerPiece = () => {
        this.setState(prevState => {
            return {
                isPricePerPiece: !prevState?.isPricePerPiece
            };
        });
    };

    handleScroll = () => {
        // The property pageYOffset is compatable with all browsers.
        const currentScrollTop = window.pageYOffset;
        const headerEle = getElement('#stickyHeader');
        const notificationEle = getElement('#notificationContainer');
        const stickyHeight = headerEle.height + notificationEle.height;
        // Use #stickyWrapper to get top value instead of stickyButton.
        // As wrapper won't get moved from the current position and gives the exact original top value.
        const stickyWrapper = getElement('#stickyWrapper');
        const stickyWrapperTop = Number(currentScrollTop + stickyWrapper.top);
        let isSticky = false;

        /**
         * Make button sticky after considering both scroll top & total height of the top sticky header (Current Scroll + Header + Notification)
         * to compare with sticky button top value.
         */
        if (currentScrollTop + stickyHeight >= stickyWrapperTop) {
            isSticky = true;
        }

        // Setting sticky state for freezing the sticky buttons just below the top sticky header.
        this.setState({
            isSticky,
            stickyHeight
        });
    };

    calculateMobileFiltersToggleNewState = () => {
        const productListWrapper = document.querySelector(
            '#mainContainer > div > section.ProductList-productGridWrapper'
        );
        const footer = document.querySelector('#root > main > div > div.Layout-stickyContainer > footer');

        if (window.scrollY + window.innerHeight * 0.4 >= document.body.offsetHeight - footer.offsetHeight) {
            return false;
        }

        if (window.scrollY >= productListWrapper?.offsetTop) {
            return true;
        }

        return false;
    };

    handleMobileFiltersToggleScroll = () => {
        // debouncer needed if any performance issues
        if (!this.props.deviceType.isMobile) {
            return;
        }
        this.setState({ isShowMobileFiltersToggle: true });
    };

    setViewDefaultState = () => {
        const { deviceType, viewChoice } = this.props;

        let plpView = viewChoice || PLP_VIEW_TYPES.GRID_VIEW;

        // If prefered view is LIST then for mobile fallback to default GRID view.
        if (deviceType.isMobile) {
            plpView = PLP_VIEW_TYPES.GRID_VIEW;
        }

        this.setState({
            isViewValueUpdated: true,
            selectedMobileView: plpView,
            appliedMobileView: plpView,
            selectedView: plpView
        });
    };

     getProductData = async (isReturn, isMarketing, savedFiltersActive = false) => {
        const { appliedFacets } = this.state;
        const { params, location } = this.props;
        if (!params?.filterTypes) {
            Storage.removeData(URL_PATHS.PLP_REFERRER_URL);
        }

        const sessionData = Storage.getData(URL_PATHS.PLP_REFERRER_URL);
        let queryString = '';
        let isSearch = false;

        if (!_isEmpty(location.query) && location.query.re) {
            isReturn = true;
        }

        if (!_isEmpty(location.query) && location.query.ma) {
            isMarketing = true;
        }
        /* condition to check applied filters */
        if (!_isEmpty(location.query) && location.query.text) {
            const freeTextQuery = encodeURIComponent(location.query.text);
            if (isReturn) {
                queryString = `&freeTextQuery=${freeTextQuery}&useEmpties=true`;
            } else if (isMarketing) {
                queryString = `&freeTextQuery=${freeTextQuery}&useMarketingMaterials=true`;
            } else {
                queryString = `&freeTextQuery=${freeTextQuery}`;
            }
            isSearch = true;
        }

        if (savedFiltersActive) {
            // If saved filters are on, either by default prop from API or active through the saved filters checkbox, then we need to use the saved filters appliedFacets which have been set either in componentDidMount or in the applySavedFilters method. The appliedFacets will never include saveUpdateSavedFilter or isSavedFilterDefault or removeSavedFilter if coming from applySavedFilters function or componentDidMount therefore no need to worry about that at all when creating the query string in productListGetURL().
            queryString = productListGetURL(
                appliedFacets,
                location.query.text,
                params.plpType === PLP_TYPES.RETURN,
                params.plpType === PLP_TYPES.ZPOP
            );
        } else if (params.plpType === PLP_TYPES.RETURN && !params.filterTypes) {
            /* condition to check page type is return empties */
            queryString = `&useEmpties=${true}`;
        } else if (params.plpType === PLP_TYPES.ZPOP && !params.filterTypes) {
            queryString = `&useMarketingMaterials=${true}`;
        } else if (params.filterTypes) {
            /* Condition to check page has filters or not */
            const paramsSplit = location.pathname.split('/');
            let filterValues = paramsSplit[paramsSplit.length - 1].split(',');
            filterValues = filterValues.map(value => decodeURIComponent(value));
            
            params.filterTypes.split(',').map((filterType, index) => {
                if (!appliedFacets.data[filterType]) {
                    appliedFacets.data[filterType] = [];
                }
                const splitVal = filterValues[index].split('|');
                splitVal.map(item => {
                    appliedFacets.data[filterType].push(item);
                    return true;
                });
                return true;
            });
            await new Promise((resolve) => {
                this.setState({ appliedFacets }, () => {
                        queryString = productListGetURL(
                            appliedFacets,
                            location.query.text,
                            params.plpType === PLP_TYPES.RETURN,
                            params.plpType === PLP_TYPES.ZPOP
                        );
                    resolve();
                });
            });

        } else if (!_isEmpty(sessionData) && !isReturn && !isMarketing && !isSearch) {
            const decodedData = decodeURIComponent(sessionData);

            if (decodedData !== '' && decodedData !== URL_PATHS.PLP_ORDER) {
                const appFilters = decodedData.split('/').slice(-2);
                const filterTypes = appFilters[0] || '';
                [
                    PLP_FILTERS.FAVORITE,
                    PLP_FILTERS.PROMOTION,
                    PLP_FILTERS.SHOW_PRICE,
                    PLP_FILTERS.RECOMMENDATIONS
                ].forEach(filter => {
                    if (filterTypes.indexOf(filter) !== -1) {
                        appliedFacets.data[filter] = ['true'];
                    }
                });
                await new Promise((resolve) => {
                    this.setState({ appliedFacets }, () => {
                            queryString = productListGetURL(
                                appliedFacets,
                                location.query.text,
                                isReturn,
                                isMarketing
                            );
                        resolve();
                    });
                });
            }
        }
        const isReturnEmpties = queryString.indexOf('useEmpties=true') !== -1;
        const isUseMarketingMaterials = queryString.indexOf('useMarketingMaterials=true') !== -1;
        this.props.actions.getPageContent({
            pageId: PAGE_ID.PLP,
            queryString,
            isReturnEmpties,
            isUseMarketingMaterials,
            isPageSpinner: isSearch
        });
    };

    getSavedFiltersBannerMessage = () => {
        const { localization } = this.props;
        const { currentFilterBanner } = this.state;

        switch (currentFilterBanner) {
            case 'saved':
                return <p>{localization[PLP_L10N.SAVE_ACTION_MESSAGE]}</p>;
            case 'updated':
                return <p>{localization[PLP_L10N.UPDATED_ACTION_MESSAGE]}</p>;
            case 'default':
                return <p>{localization[PLP_L10N.MAKE_DEFAULT_ACTION_MESSAGE]}</p>;
            case 'deleted':
            default:
                return <p>{localization[PLP_L10N.DELETED_ACTION_MESSAGE]}</p>;
        }
    };

    renderSavedFiltersDotMenu = () => {
        return (
            <Icon
                iconType='svg'
                width='16px'
                height='16px'
                viewBox='0 0 16 16'
                name='dotmenu'
                className={cx('dotMenuSVG')}
            />
        );
    };

    renderSavedFiltersCard = () => {
        const { isSavedFilterDefault, localization } = this.props;
        const { updateSavedFiltersPossible } = this.state;
        return (
            <div tabIndex={-1} className={cx('savedFiltersCard')} ref={this.savedFiltersCardRef}>
                <div
                    onClick={() => {
                        if (isSavedFilterDefault) return;
                        this.setState({
                            currentFilterBanner: 'default'
                        });
                        this.makeDefaultFiltersFunc();
                    }}
                    className={cx(!isSavedFilterDefault ? 'savedFiltersCardOption' : 'savedFiltersCardBlockedOption')}
                >
                    <Icon
                        className={cx('flex-icon')}
                        iconType='svg'
                        width='18px'
                        height='18px'
                        viewBox='0 0 18 18'
                        name='home'
                    />
                    {localization[PLP_L10N.MAKE_DEFAULT]}
                </div>
                <div
                    onClick={() => {
                        if (!updateSavedFiltersPossible || !this.arePLPFiltersApplied()) return;
                        this.updateSavedFiltersFunc();
                    }}
                    className={cx(
                        updateSavedFiltersPossible && this.arePLPFiltersApplied()
                            ? 'savedFiltersCardOption'
                            : 'savedFiltersCardBlockedOption'
                    )}
                >
                    <Icon
                        className={cx('flex-icon')}
                        iconType='svg'
                        width='18px'
                        height='18px'
                        viewBox='0 0 18 18'
                        name='refresh'
                    />
                    {localization[PLP_L10N.UPDATE_SAVED]}
                </div>
                <div
                    onClick={() => {
                        this.setState({
                            currentFilterBanner: 'deleted'
                        });
                        this.deleteSavedFiltersFunc();
                    }}
                    style={{ color: '#e90000' }}
                    className={cx('savedFiltersCardOption')}
                >
                    <Icon
                        className={cx('flex-icon')}
                        iconType='svg'
                        width='18px'
                        height='18px'
                        viewBox='0 0 18 18'
                        name='bin'
                    />
                    {localization[PLP_L10N.DELETE_SAVED]}
                </div>
            </div>
        );
    };

    handleSavedFiltersCardBlur = event => {
        this.blurTimeout = setTimeout(() => {
            if (
                this.savedFiltersCardRef.current &&
                !this.savedFiltersCardRef?.current.contains(document.activeElement)
            ) {
                this.setState({
                    showSavedFiltersCard: false
                });
            }
        }, 0);
    };

    handleSavedFiltersCardMouseDown = event => {
        clearTimeout(this.blurTimeout);
    };

    // Filter menu options for brands, capacity, packaging, etc
    renderFilterOptions = (item, filterType, key) => {
        return (
            <li key={key}>
                <div className={cx('formGroup')}>
                    <Checkbox
                        theme={cx('theme')}
                        themecheckboxCheckmark={cx('themecheckboxCheckmark')}
                        id={`${item.name}-checkbox`}
                        name={item.code}
                        labelText={item.name}
                        isFilter={true}
                        dataFilter={filterType}
                        counts={item.count}
                        visibleCount
                        checked={_get(this.state.appliedFacets, `data.${filterType}`, []).includes(item.code)}
                        onChange={e => this.handleChange(e, filterType)}
                    />
                </div>
            </li>
        );
    };

    // Filters inside appliedFacets state are used to create the query string which is then used to fetch a new product list from the backend
    updateFilters = (filterType, val, skipAddPreCart, viewSinglePromo) => {
        const { appliedFacets } = this.state;
        const newFacets = { ...appliedFacets };
        const { params, location } = this.props;
        const filtersToRemove = [
            PLP_FILTERS.SAVED_FILTERS,
            PLP_FILTERS.MAKE_DEFAULT_SAVED_FILTERS,
            PLP_FILTERS.REMOVE_SAVED_FILTERS
        ];

        // Remove saved filters keys to avoid having problems once they've saved, updated, or deleted something in save filters, then try to filter something right after again.
        if (!filtersToRemove.includes(filterType)) {
            filtersToRemove.forEach(filter => {
                if (Object.prototype.hasOwnProperty.call(newFacets.data, filter)) {
                    delete newFacets.data[filter];
                }
            });
        }
        if (
            filterType === PLP_FILTERS.PROMOTION ||
            filterType === PLP_FILTERS.FAVORITE ||
            filterType === PLP_FILTERS.IN_STOCK ||
            filterType === PLP_FILTERS.MIN_PRICE ||
            filterType === PLP_FILTERS.MAX_PRICE ||
            filterType === PLP_FILTERS.IS_PRICE_PER_PIECE ||
            filterType === PLP_FILTERS.RECOMMENDATIONS ||
            filterType === PLP_FILTERS.SAVED_FILTERS ||
            filterType === PLP_FILTERS.MAKE_DEFAULT_SAVED_FILTERS ||
            filterType === PLP_FILTERS.REMOVE_SAVED_FILTERS
        ) {
            this.props.actions.resetProductList({ sortType: this.props.productList.orderBy });
        }
        if (
            !appliedFacets.data[filterType] ||
            filterType === PLP_FILTERS.MIN_PRICE ||
            filterType === PLP_FILTERS.MAX_PRICE ||
            filterType === PLP_FILTERS.IS_PRICE_PER_PIECE
        ) {
            appliedFacets.data[filterType] = [];
            appliedFacets.data[filterType].push(val.toString());
        } else if (filterType === PLP_FILTERS.PAGE || filterType === PLP_FILTERS.PAGE_ITEM_SIZE) {
            appliedFacets.data[filterType][0] = val;
        } else if (viewSinglePromo) {
            const filterIndex = appliedFacets.data[filterType].indexOf(val);

            if (filterIndex > -1) {
                appliedFacets.data[filterType] = [appliedFacets.data[filterType][filterIndex]];
                if (!appliedFacets.data[filterType].length) {
                    delete appliedFacets.data[filterType];
                }
            }
        } else {
            const filterIndex = appliedFacets.data[filterType].indexOf(val);
            if (filterIndex > -1) {
                appliedFacets.data[filterType].splice(filterIndex, 1);
                if (!appliedFacets.data[filterType].length) {
                    delete appliedFacets.data[filterType];
                }
            } else {
                appliedFacets.data[filterType].push(val);
            }
        }

        if (
            filterType === PLP_FILTERS.PAGE_ITEM_SIZE ||
            filterType === PLP_FILTERS.FAVORITE ||
            filterType === PLP_FILTERS.IN_STOCK ||
            filterType === PLP_FILTERS.PROMOTION ||
            filterType === PLP_FILTERS.MIN_PRICE ||
            filterType === PLP_FILTERS.MAX_PRICE ||
            filterType === PLP_FILTERS.RECOMMENDATIONS
        ) {
            appliedFacets.data[PLP_FILTERS.PAGE] = [];
            appliedFacets.data[PLP_FILTERS.PAGE][0] = 1;
        }
        const text = location.query.text ? location.query.text : '';
        getUpdatedURL(params.plpType, location.search, appliedFacets);

        const queryString = productListGetURL(
            appliedFacets,
            text,
            params.plpType === PLP_TYPES.RETURN,
            params.plpType === PLP_TYPES.ZPOP
        );
        this.props.actions.getProductList({ queryString, skipAddPreCart });
    };

    /* This method will on change of checkbox. selectedAll is an array coming from the promo filters */
    handleChange = (e, filterType, selectedAll) => {
        const { params, location, deviceType } = this.props;
        /* condition to check for Desktop */
        if (window.innerWidth > 767 || deviceType.isDesktop || deviceType.isTablet) {
            this.props.actions.resetProductList({ sortType: this.props.productList.orderBy });
        }
        let item = e.target.name;
        const isChecked = e.target.checked;
        const { appliedFacets } = this.state;

        // Remove saved filters keys to avoid having problems once they've saved, updated, or deleted something in save filters, then try to filter something right after again.
        const filterAppliedFacets = this.removeSavedFilterKeysFromAppliedFacets(appliedFacets.data);
        const newAppliedFacets = { ...appliedFacets, data: filterAppliedFacets };
        if (!newAppliedFacets.data[filterType]) {
            newAppliedFacets.data[filterType] = [];
        }

        if (
            item === PLP_FILTERS.FAVORITE ||
            item === PLP_FILTERS.IN_STOCK ||
            item === PLP_FILTERS.PROMOTION ||
            item === PLP_FILTERS.SHOW_PRICE ||
            item === PLP_FILTERS.RECOMMENDATIONS
        ) {
            item = isChecked;
        }

        if (isChecked && selectedAll) {
            newAppliedFacets.data[filterType] = selectedAll;
        } else if (!isChecked && selectedAll) {
            delete newAppliedFacets?.data?.[PLP_FILTERS.PROMOTION];
        } else if (isChecked) {
            newAppliedFacets.data[filterType].push(item.toString());
        } else if (
            filterType === PLP_FILTERS.FAVORITE ||
            filterType === PLP_FILTERS.IN_STOCK ||
            filterType === PLP_FILTERS.SHOW_PRICE ||
            filterType === PLP_FILTERS.RECOMMENDATIONS
        ) {
            delete newAppliedFacets.data[filterType];
        } else {
            const index = newAppliedFacets.data[filterType].indexOf(item);
            if (index > -1) {
                newAppliedFacets.data[filterType].splice(index, 1);
            }
            if (newAppliedFacets.data[filterType].length === 0) {
                delete newAppliedFacets.data[filterType];
            }
        }
        if (_isEmpty(newAppliedFacets.data[PLP_FILTERS.PAGE])) {
            newAppliedFacets.data[PLP_FILTERS.PAGE] = [];
        }
        newAppliedFacets.data[PLP_FILTERS.PAGE][0] = 1;
        this.setState({ appliedFacets: newAppliedFacets }, () => {
            /* condition to check for Desktop */
            if (window.innerWidth > 767 || deviceType.isDesktop || deviceType.isTablet) {
                getUpdatedURL(params.plpType, location.search, this.state.appliedFacets);
                const queryString = productListGetURL(
                    this.state.appliedFacets,
                    location.query.text,
                    params.plpType === PLP_TYPES.RETURN,
                    params.plpType === PLP_TYPES.ZPOP
                );
                this.props.actions.getProductList({ queryString });
            } else {
                const { savedFilters } = this.props;
                const { data } = this.state.appliedFacets;
                const parsedFilters = savedFilters ? parseSavedFilters(savedFilters) : {};
                if (Object.keys(parsedFilters).length > 0 && Object.keys(data).length > 0) {
                    // Remove the page filter, saved filter, default filter, or delete filter keys from the applied facets in the state so we can compare it to the parsed filters from the saved filters prop
                    const appliedFacetsFiltered = this.removeSavedFilterKeysFromAppliedFacets(data);
                    const appliedFacetsObjClean = this.sortNestedArrays(appliedFacetsFiltered);
                    const parsedFiltersClean = this.sortNestedArrays(parsedFilters);
                    const areAppliedFacetsDifferentThanParsed = !_isEqual(parsedFiltersClean, appliedFacetsObjClean);
                    if (areAppliedFacetsDifferentThanParsed) {
                        this.setState({ updateSavedFiltersPossible: true, isSavedFilters: false });
                    } else if (Object.keys(appliedFacetsObjClean).length > 0 && !areAppliedFacetsDifferentThanParsed) {
                        this.setState({ updateSavedFiltersPossible: false, isSavedFilters: true });
                    }
                }
            }
        });
    };

    /** Apply Filters for Mobile */
    applyFiltersForMobile = () => {
        this.props.actions.resetProductList({ sortType: this.props.productList.orderBy });
        const { appliedFacets, selectedMobileView } = this.state;
        const { params, location } = this.props;

        this.onChangeHandlerView(selectedMobileView);

        if (_isEmpty(appliedFacets.data.showPrice)) {
            getUpdatedURL(params.plpType, location.search, appliedFacets);
            const queryString = productListGetURL(
                appliedFacets,
                location.query.text,
                params.plpType === PLP_TYPES.RETURN,
                params.plpType === PLP_TYPES.ZPOP
            );
            this.props.actions.getProductList({ queryString });

            this.setState({
                isShowMobileFilter: false
            });
        } else {
            this.setState({
                isShowMobileFilter: false,
                showPricePopUp: true
            });
        }

        this.toggleLockScroll(false);
    };

    clearAllFilters = () => {
        this.props.actions.resetProductList({ sortType: this.props.productList.orderBy });
        const { appliedFacets } = this.state;
        const {
            params,
            location,
            productList: { appliedFilters }
        } = this.props;
        appliedFilters.map(facet => delete appliedFacets.data[facet.facetCode]);
        const updateAppliedFacets = { ...appliedFacets };
        delete updateAppliedFacets?.data?.[PLP_FILTERS.FAVORITE];
        delete updateAppliedFacets?.data?.[PLP_FILTERS.PROMOTION];
        delete updateAppliedFacets?.data?.[PLP_FILTERS.MIN_PRICE];
        delete updateAppliedFacets?.data?.[PLP_FILTERS.MAX_PRICE];
        delete updateAppliedFacets?.data?.[PLP_FILTERS.IS_PRICE_PER_PIECE];
        delete updateAppliedFacets?.data?.[PLP_FILTERS.IN_STOCK];
        delete updateAppliedFacets?.data?.[PLP_FILTERS.SAVED_FILTERS];
        delete updateAppliedFacets?.data?.[PLP_FILTERS.MAKE_DEFAULT_SAVED_FILTERS];
        delete updateAppliedFacets?.data?.[PLP_FILTERS.REMOVE_SAVED_FILTERS];
        this.setState(
            {
                appliedFacets: updateAppliedFacets,
                isPricePerPiece: false,
                isSavedFilters: false,
                updateSavedFiltersPossible: false
            },
            () => {
                const newFilters = this.state.appliedFacets;
                getUpdatedURL(params.plpType, location.search, newFilters);
                const queryString = productListGetURL(
                    newFilters,
                    location.query.text,
                    params.plpType === PLP_TYPES.RETURN,
                    params.plpType === PLP_TYPES.ZPOP
                );
                this.props.actions.getProductList({ queryString });
            }
        );
    };

    removeFilter = item => {
        this.props.actions.resetProductList({ sortType: this.props.productList.orderBy });
        const { appliedFacets } = this.state;
        const { params, location, deviceType } = this.props;
        const filteredAppliedFacets = this.removeSavedFilterKeysFromAppliedFacets(appliedFacets.data);
        if (!_isEmpty(filteredAppliedFacets[item.facetCode])) {
            const index = filteredAppliedFacets[item.facetCode].indexOf(item.facetValueCode);
            if (index > -1) {
                filteredAppliedFacets[item.facetCode].splice(index, 1);
            }
            if (filteredAppliedFacets[item.facetCode].length === 0) {
                delete filteredAppliedFacets[item.facetCode];
            }
        }
        this.setState(
            {
                appliedFacets: {
                    ...appliedFacets,
                    data: filteredAppliedFacets
                }
            },
            () => {
                const updatedAppliedFacets = this.state.appliedFacets; // Get the updated state
                getUpdatedURL(params.plpType, location.search, updatedAppliedFacets);
                const queryString = productListGetURL(
                    updatedAppliedFacets,
                    location.query.text,
                    params.plpType === PLP_TYPES.RETURN,
                    params.plpType === PLP_TYPES.ZPOP
                );
                this.props.actions.getProductList({ queryString });

                if (deviceType.isMobile) {
                    this.setState({ isShowMobileFilter: false });
                }
            }
        );
    };

    removePriceFilter = () => {
        this.removeFilterFullFacet(PLP_FILTERS.IS_PRICE_PER_PIECE);
    };

    removeFilterFullFacet = facetCode => {
        const { params, location, deviceType } = this.props;
        this.props.actions.resetProductList({ sortType: this.props.productList.orderBy });
        const { appliedFacets } = this.state;
        const filteredAppliedFacets = this.removeSavedFilterKeysFromAppliedFacets(appliedFacets.data);
        if (!_isEmpty(filteredAppliedFacets[facetCode])) {
            // If we're removing the price filters from the chips, we have to make sure we remove all three related to that at once
            if (facetCode === PLP_FILTERS.IS_PRICE_PER_PIECE) {
                delete filteredAppliedFacets[PLP_FILTERS.IS_PRICE_PER_PIECE];
                delete filteredAppliedFacets[PLP_FILTERS.MIN_PRICE];
                delete filteredAppliedFacets[PLP_FILTERS.MAX_PRICE];
            } else {
                delete filteredAppliedFacets[facetCode];
            }
        }
        this.setState(
            {
                appliedFacets: {
                    ...appliedFacets,
                    data: filteredAppliedFacets
                }
            },
            () => {
                const { appliedFacets } = this.state;
                getUpdatedURL(params.plpType, location.search, appliedFacets);
                const queryString = productListGetURL(
                    appliedFacets,
                    location.query.text,
                    params.plpType === PLP_TYPES.RETURN,
                    params.plpType === PLP_TYPES.ZPOP
                );
                this.props.actions.getProductList({ queryString });

                if (deviceType.isMobile) {
                    this.setState({ isShowMobileFilter: false });
                }
            }
        );
    };

    renderFilterWrapper = () => {
        const { productList, localization } = this.props;
        const { totalItems } = productList;
        const { selectedView, isShowFilter } = this.state;
        return (
            <div
                className={cx(
                    'filterWrapper',
                    selectedView && 'listViewFilter',
                    isShowFilter && selectedView === PLP_VIEW_TYPES.LIST_VIEW && 'showListViewFilter'
                )}
            >
                <div>
                    {/* /** display on on Mobile only */}
                    {productList.totalItems > 0 && (
                        <h4 automation-id='at-number-of-results-text'>
                            {localization[PLP_L10N.RESULTS]}
                            <span className={cx('counts')}>({totalItems})</span>
                        </h4>
                    )}
                    <p>
                        <span className={cx('filterByText')}>{localization[PLP_L10N.FILTER_BY]}:</span>
                    </p>
                    {/* /** display on  Desktop */}
                    {!_isEmpty(productList) &&
                        (_get(productList, 'appliedFilters', []).length > 0 ||
                            this.state.appliedFacets?.data?.[PLP_FILTERS.FAVORITE] ||
                            this.state.appliedFacets?.data?.[PLP_FILTERS.IN_STOCK] ||
                            this.state.appliedFacets?.data?.[PLP_FILTERS.MIN_PRICE] ||
                            this.state.appliedFacets?.data?.[PLP_FILTERS.MAX_PRICE] ||
                            this.state.appliedFacets?.data?.[PLP_FILTERS.PROMOTION]) && (
                            <ul className={cx('filterResult')}>
                                {productList.appliedFilters
                                    .reduce((acc, item) => {
                                        const findCode = acc.find(p => p.facetCode === item.facetCode);
                                        if (findCode) {
                                            return acc;
                                        }
                                        acc.push(item);
                                        return acc;
                                    }, [])
                                    .map(item => (
                                        <li
                                            key={item.facetName}
                                            role='presentation'
                                            onKeyDown={() => {
                                                this.removeFilterFullFacet(item.facetCode);
                                            }}
                                            onClick={() => {
                                                this.removeFilterFullFacet(item.facetCode);
                                            }}
                                        >
                                            <div className={cx('filterTags')}>
                                                <span className={cx('textLower')}>{item.facetName}</span>
                                                <span className={cx('filterClose')} />
                                            </div>
                                        </li>
                                    ))}
                                {this.state.appliedFacets?.data?.[PLP_FILTERS.RECOMMENDATIONS] && (
                                    <li
                                        role='presentation'
                                        onKeyDown={() => {
                                            this.removeFilterFullFacet(PLP_FILTERS.RECOMMENDATIONS);
                                        }}
                                        onClick={() => {
                                            this.removeFilterFullFacet(PLP_FILTERS.RECOMMENDATIONS);
                                        }}
                                    >
                                        <div className={cx('filterTags')}>
                                            <span className={cx('textLower')}>
                                                {localization[PLP_L10N.RECOMMENDATIONS]}
                                            </span>
                                            <span className={cx('filterClose')} />
                                        </div>
                                    </li>
                                )}
                                {this.state.appliedFacets?.data?.[PLP_FILTERS.FAVORITE] && (
                                    <li
                                        role='presentation'
                                        onKeyDown={() => {
                                            this.removeFilterFullFacet(PLP_FILTERS.FAVORITE);
                                        }}
                                        onClick={() => {
                                            this.removeFilterFullFacet(PLP_FILTERS.FAVORITE);
                                        }}
                                    >
                                        <div className={cx('filterTags')}>
                                            <span className={cx('textLower')}>{localization[PLP_L10N.FAVORITES]}</span>
                                            <span className={cx('filterClose')} />
                                        </div>
                                    </li>
                                )}
                                {this.state.appliedFacets?.data?.[PLP_FILTERS.IN_STOCK] && (
                                    <li
                                        role='presentation'
                                        onKeyDown={() => {
                                            this.removeFilterFullFacet(PLP_FILTERS.IN_STOCK);
                                        }}
                                        onClick={() => {
                                            this.removeFilterFullFacet(PLP_FILTERS.IN_STOCK);
                                        }}
                                    >
                                        <div className={cx('filterTags')}>
                                            <span className={cx('textLower')}>{localization[PLP_L10N.IN_STOCK]}</span>
                                            <span className={cx('filterClose')} />
                                        </div>
                                    </li>
                                )}
                                {this.state.appliedFacets?.data?.promotion?.filter(p => p).length > 0 && (
                                    <li
                                        role='presentation'
                                        onKeyDown={() => {
                                            this.removeFilterFullFacet(PLP_FILTERS.PROMOTION);
                                        }}
                                        onClick={() => {
                                            this.removeFilterFullFacet(PLP_FILTERS.PROMOTION);
                                        }}
                                    >
                                        <div className={cx('filterTags')}>
                                            <span className={cx('textLower')}>{localization[PLP_L10N.PROMOTIONS]}</span>
                                            <span className={cx('filterClose')} />
                                        </div>
                                    </li>
                                )}

                                {this.state.appliedFacets?.data?.[PLP_FILTERS.MAX_PRICE] && (
                                    <li
                                        role='presentation'
                                        onKeyDown={() => {
                                            this.removePriceFilter();
                                        }}
                                        onClick={() => {
                                            this.removePriceFilter();
                                        }}
                                    >
                                        <div className={cx('filterTags')}>
                                            <span className={cx('textLower')}>
                                                {
                                                    // eslint-disable-next-line prefer-template
                                                    localization[PLP_FILTERS.PRICE_FILTER_FROM] +
                                                    ' ' +
                                                    this.state.appliedFacets?.data?.minPriceFilterValue?.[0] +
                                                    localization[PLP_L10N.CURRENCY_SYMBOL] +
                                                    ' ' +
                                                    localization[PLP_FILTERS.PRICE_FILTER_TO] +
                                                    ' ' +
                                                    this.state.appliedFacets?.data?.maxPriceFilterValue?.[0] +
                                                    localization[PLP_L10N.CURRENCY_SYMBOL]
                                                }
                                            </span>
                                            <span className={cx('filterClose')} />
                                        </div>
                                    </li>
                                )}

                                {productList.appliedFilters.map(item => (
                                    <li
                                        key={item.facetValueCode}
                                        role='presentation'
                                        onKeyDown={() => {
                                            this.removeFilter(item);
                                        }}
                                        onClick={() => {
                                            this.removeFilter(item);
                                        }}
                                    >
                                        <div className={cx('filterTags')}>
                                            <span className={cx('textLower')}>{item.facetValueName}</span>
                                            <span className={cx('filterClose')} />
                                        </div>
                                    </li>
                                ))}

                                <li
                                    role='presentation'
                                    id='clearAll'
                                    onClick={this.clearAllFilters}
                                    className={cx('filterClearAll')}
                                >
                                    <div className={cx('filterTags')}>
                                        <span className={cx('textUpper')}>
                                            {localization[PLP_L10N.CLEAR_ALL] || 'Clear All'}
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        )}
                </div>
            </div>
        );
    };

    toggleFilters = val => {
        this.setState({
            isShowFilter: val
        });
    };

    mobileToggleFilters = () => {
        const { mobileToggleFilters } = this.state;
        this.setState({
            isShowMobileFilter: !mobileToggleFilters
        });
    };

    onChangeHandlerView = val => {
        let plpView = '';
        if (val === PLP_VIEW_TYPES.GRID_VIEW) {
            plpView = PLP_VIEW_TYPES.GRID_VIEW;
        } else if (val === PLP_VIEW_TYPES.LIST_VIEW) {
            plpView = PLP_VIEW_TYPES.LIST_VIEW;
        } else {
            plpView = PLP_VIEW_TYPES.COMPACT_VIEW;
        }

        this.setState({
            selectedView: plpView,
            selectedMobileView: plpView,
            appliedMobileView: plpView
        });

        this.props.actions.setPLPViewChoiceFunc(plpView);
    };

    renderView = () => {
        const {
            localization,
            deviceType,
            productList: { showShortListView }
        } = this.props;
        let viewOptions;
        if (deviceType.isDesktop || deviceType.isTablet) {
            viewOptions = [
                {
                    id: '1',
                    value: PLP_VIEW_TYPES.GRID_VIEW,
                    label: localization[PLP_L10N.GRID_VIEW]
                },
                {
                    id: '2',
                    value: PLP_VIEW_TYPES.LIST_VIEW,
                    label: localization[PLP_L10N.LIST_VIEW]
                }
            ];
            if (showShortListView === true) {
                viewOptions.push({
                    id: '3',
                    value: PLP_VIEW_TYPES.COMPACT_VIEW,
                    label: localization[PLP_L10N.COMPACT_VIEW]
                });
            }
        } else {
            viewOptions = [
                {
                    id: '1',
                    value: PLP_VIEW_TYPES.GRID_VIEW,
                    label: localization[PLP_L10N.GRID_VIEW]
                }
            ];
        }

        return (
            <span className={cx('gridSelectBox')}>
                {this.state.selectedView === PLP_VIEW_TYPES.GRID_VIEW ? (
                    <span className={cx('iconTheme')}>
                        <Icon iconType='svg' width='16px' height='16px' viewBox='0 0 16 16' name='grid-view' />
                    </span>
                ) : (
                    <span className={cx('iconTheme')}>
                        {' '}
                        <Icon iconType='svg' width='16px' height='16px' viewBox='0 0 16 16' name='compact-view' />
                    </span>
                )}
                <SelectBox
                    onChange={this.onChangeHandlerView}
                    id='subject'
                    theme={cx('gridSelect')}
                    themeSelectBox={cx('selectBox')}
                    arrowTheme={cx('arrow', 'down')}
                    options={viewOptions}
                    defaultValue={this.state.selectedView}
                    automationId='gridButton'
                />
            </span>
        );
    };

    renderDeliveryType = () => {
        const { localization, isPickUpAllowed } = this.props;

        return (
            isPickUpAllowed && (
                <DeliveryType
                    localization={{
                        deliveryType: localization[PLP_L10N.DELIVERY],
                        pickUpType: localization[PLP_L10N.PICKUP]
                    }}
                />
            )
        );
    };

    modalOnClose = () => {
        const { appliedFacets, appliedMobileView } = this.state;
        const { params, location } = this.props;
        this.setState({ isShowMobileFilter: false, selectedMobileView: appliedMobileView });

        if (params.filterTypes === undefined) {
            appliedFacets.data = {};
            this.setState({ appliedFacets });
            getUpdatedURL(params.plpType, location.search, appliedFacets);
        }
    };

    handlePagination = (type, val, isBottom) => {
        this.updateFilters(type, val);
        // For bottom pagination action need to scroll the page to top.
        isBottom && window.scrollTo(0, 0);
    };

    loadMore = (type, val) => {
        this.updateFilters(type, val, true);
    };

    handleChangeMobileView = e => {
        this.setState({
            selectedMobileView:
                e.target.name === 'compactView' && e.target.checked === true
                    ? PLP_VIEW_TYPES.COMPACT_VIEW
                    : PLP_VIEW_TYPES.GRID_VIEW
        });
    };

    handleSortChange = (value = PLP_SORTS_VALUES.NAME_ASC) => {
        if (value === this.props.productList.orderBy) {
            return;
        }

        this.props.actions.resetProductList({ sortType: this.props.productList.orderBy });

        const { appliedFacets } = this.state;
        const { params, location, deviceType } = this.props;

        if (Array.isArray(appliedFacets.data?.page)) {
            appliedFacets.data.page[0] = 1;
        }
        if (!Array.isArray(appliedFacets.data?.page)) {
            appliedFacets.data.page = [1];
        }

        getUpdatedURL(params.plpType, location.search, appliedFacets);
        const queryString = productListGetURL(
            appliedFacets,
            location.query.text,
            params.plpType === PLP_TYPES.RETURN,
            params.plpType === PLP_TYPES.ZPOP
        );

        if (deviceType.isMobile) {
            this.setState({ isShowMobileFilter: false });
        }

        this.props.actions.updateProductsSort(value);
        this.props.actions.getProductList({ queryString });

        if (deviceType.isMobile /* || (window.innerHeight + window.scrollY) >= document.body.offsetHeight*/) {
            window.scrollTo(0, 0);
        }
    };

    handleShowPrice = () => {
        this.setState({ showPricePopUp: true });
    };

    handleHidePrice = () => {
        const { appliedFacets } = this.state;
        const { params, location } = this.props;

        if (!_isEmpty(appliedFacets.data[PLP_FILTERS.SHOW_PRICE])) {
            delete appliedFacets.data[PLP_FILTERS.SHOW_PRICE];
        }
        this.setState({ appliedFacets });
        getUpdatedURL(params.plpType, location.search, appliedFacets);
        const queryString = productListGetURL(
            appliedFacets,
            location.query.text,
            params.plpType === PLP_TYPES.RETURN,
            params.plpType === PLP_TYPES.ZPOP
        );
        this.props.actions.getProductList({ queryString });
    };

    handleBackToProduct = () => {
        window.scrollTo(0, 0);
        redirectTo(URL_PATHS.PLP_ORDER);
    };

    onClose = (isShowPrice = '') => {
        if (isShowPrice) {
            this.setState({ isShowPrice: true });
        }
        this.setState({ showPricePopUp: false });
    };

    goToPLPPage = () => {
        const pageURL = _get(this.props.location, 'query.re') ? URL_PATHS.PLP_RETURN : URL_PATHS.PLP_ORDER;
        redirectTo(pageURL);
    };

    resetData = (callback, param, marketingMaterials) => {
        const { deviceType, viewChoice } = this.props;
        let plpView = viewChoice || PLP_VIEW_TYPES.GRID_VIEW;

        // If prefered view is LIST then for mobile fallback to default GRID view.
        if (deviceType.isMobile) {
            plpView = PLP_VIEW_TYPES.GRID_VIEW;
        }

        const initialState = {
            appliedFacets: { data: {} },
            isShowFilter: true,
            isShowPrice: true,
            isShowMobileFilter: false,
            selectedView: plpView,
            selectedMobileView: plpView,
            appliedMobileView: plpView
        };

        if (callback) {
            this.setState(initialState, () => callback(param, marketingMaterials));
        } else {
            this.setState(initialState);
        }
    };

    renderMobileFiltersPartsFavouritesAndPromotions = () => {
        const { appliedFacets } = this.state;
        const { localization } = this.props;
        const faviIcon = (
            <Icon className={cx('leftIcon')} width='20px' height='20px' viewBox='0 0 24 24' name='fav_icon' />
        );
        const faviActiveIcon = (
            <Icon
                className={cx('leftIcon', 'activeIcon')}
                width='20px'
                height='20px'
                viewBox='0 0 22 20'
                name={isRoundLogo ? 'filter-favorite-gold' : 'fav-icon-active'}
            />
        );
        const recommendationsIcon = (
            <Icon
                className={
                    !appliedFacets?.data?.[PLP_FILTERS.RECOMMENDATIONS]
                        ? cx('leftIcon')
                        : cx('leftIcon', 'bdaaActiveIcon')
                }
                iconType='svg'
                width='18px'
                height='18px'
                viewBox='0 0 15 15'
                name='filterRecommendation'
            />
        );
        const recommendationsActiveIcon = (
            <Icon
                className={
                    !appliedFacets?.data?.[PLP_FILTERS.RECOMMENDATIONS]
                        ? cx('leftIcon')
                        : cx('leftIcon', 'bdaaActiveIcon')
                }
                iconType='svg'
                width='18px'
                height='18px'
                viewBox='0 0 15 15'
                name={isRoundLogo ? 'filter-recommendation-gold' : 'filter-recommendation-red'}
            />
        );
        const inStockIcon = (
            <Icon
                className={cx('leftIcon', 'activeIcon')}
                width='18px'
                height='18px'
                viewBox='0 0 15 15'
                name='filter-instock'
            />
        );
        const inStockIconActive = (
            <Icon
                className={cx('leftIcon', 'activeIcon')}
                width='18px'
                height='18px'
                viewBox='0 0 15 15'
                name={isRoundLogo ? 'filter-instock-gold' : 'filter-instock-red'}
            />
        );
        return (
            <>
                {this.isBdaaActive && !!this.props.bdaaSuggestion?.length && (
                    <li>
                        <div className={cx('formGroup')}>
                            {appliedFacets?.data?.[PLP_FILTERS.RECOMMENDATIONS] && (
                                <span className={cx('mobIconfil')} htmlFor={`${'bdaaView'}-checkbox`}>
                                    {recommendationsActiveIcon}
                                </span>
                            )}
                            {!appliedFacets?.data?.[PLP_FILTERS.RECOMMENDATIONS] && (
                                <span className={cx('mobIconfil')} htmlFor={`${'bdaaView'}-checkbox`}>
                                    {recommendationsIcon}
                                </span>
                            )}

                            <Checkbox
                                theme={cx('modalChecktheme', 'mobViewclickicon')}
                                themecheckboxCheckmark={cx('themecheckboxCheckmark')}
                                name='bdaa'
                                labelText={localization[PLP_L10N.RECOMMENDATIONS]}
                                id={`${'bdaaView'}-checkbox`}
                                checked={!!appliedFacets?.data?.bdaa}
                                onChange={e => this.handleChange(e, 'bdaa')}
                            />
                        </div>
                    </li>
                )}
                <li>
                    <div className={cx('formGroup')} onClick={e => e.stopPropagation()}>
                        {appliedFacets?.data?.[PLP_FILTERS.FAVORITE] && (
                            <span className={cx('mobIconfil')} htmlFor={`${'favoriteView'}-checkbox`}>
                                {faviActiveIcon}
                            </span>
                        )}
                        {!appliedFacets?.data?.[PLP_FILTERS.FAVORITE] && (
                            <span className={cx('mobIconfil')} htmlFor={`${'favoriteView'}-checkbox`}>
                                {faviIcon}
                            </span>
                        )}
                        <Checkbox
                            theme={cx('modalChecktheme', 'mobViewclickicon')}
                            themecheckboxCheckmark={cx('themecheckboxCheckmark')}
                            name='favorite'
                            labelText={localization[PLP_L10N.FAVORITES]}
                            id={`${'favoriteView'}-checkbox`}
                            checked={!!appliedFacets?.data?.favorite}
                            onChange={e => {
                                e.stopPropagation();
                                this.handleChange(e, 'favorite');
                            }}
                        />
                    </div>
                </li>
                {this.isStockAllowed && (
                    <li>
                        <div className={cx('formGroup')}>
                            {appliedFacets?.data?.[PLP_FILTERS.IN_STOCK] && (
                                <span className={cx('mobIconfil')} htmlFor={`${'favoriteView'}-checkbox`}>
                                    {inStockIconActive}
                                </span>
                            )}
                            {!appliedFacets?.data?.[PLP_FILTERS.IN_STOCK] && (
                                <span className={cx('mobIconfil')} htmlFor={`${'favoriteView'}-checkbox`}>
                                    {inStockIcon}
                                </span>
                            )}
                            <Checkbox
                                theme={cx('modalChecktheme', 'mobViewclickicon')}
                                themecheckboxCheckmark={cx('themecheckboxCheckmark')}
                                name='inStock'
                                labelText={localization[PLP_L10N.IN_STOCK]}
                                id={`${'inStockView'}-checkbox`}
                                checked={!!appliedFacets?.data?.inStock}
                                onChange={e => this.handleChange(e, 'inStock')}
                            />
                        </div>
                    </li>
                )}
            </>
        );
    };

    renderNoSearchResultsPage = () => {
        const { productList, localization, location } = this.props;
        const breadcrumbValues = _get(productList, 'breadcrumbs', []);
        const { isShowFilter } = this.state;
        return (
            <section className={cx('productTopContainer', isShowFilter && 'productShowTopContainer')}>
                <BreadCrumbs
                    breadcrumbValues={breadcrumbValues}
                    custombreadCrumbWrapper={cx('custombreadCrumbWrapper')}
                />
                <div className={cx('col12', 'sm12', 'md12', 'productTitles', 'searchRs')}>
                    <span className={cx('col12', 'sm12', 'subTitles')}>
                        <div automation-id='at-search-no-result' className={cx('title-search', 'col12', 'text-center')}>
                            {localization[PLP_L10N.NO_RESULTS_FOUND]}{' '}
                            <span className={cx('txt-bold')}>{location.query.text}</span>
                        </div>
                        <div className={cx('col12', 'text-center')}>
                            <Button
                                automationId='at-create-claim-button'
                                id='contiuneShopping'
                                className={cx('continuebtn', 'sm12')}
                                type='button'
                                size='Sm'
                                buttonType='Primary'
                                onClick={this.goToPLPPage}
                            >
                                {localization[PLP_L10N.CONTINUE_SHIPPING]}
                            </Button>
                        </div>
                        <h2 className={cx('col12', 'sm12', 'md12')}>{localization[PLP_L10N.NO_RESULTS_DESC]}</h2>
                        <div className={cx('col12', 'sm12', 'md12', 'mg-tb')}>
                            {localization[PLP_L10N.SUGGESTION_TXT]}
                        </div>
                        <div className={cx('col12', 'sm12', 'md12')}>
                            <ul className={cx('small-sz')}>
                                <li key={`breadcrumbs-${PLP_L10N.SUGGESTION_POINTONE}`}>
                                    {localization[PLP_L10N.SUGGESTION_POINTONE]}
                                </li>
                                <li key={`breadcrumbs-${PLP_L10N.SUGGESTION_POINTTWO}`}>
                                    {localization[PLP_L10N.SUGGESTION_POINTTWO]}
                                </li>
                                <li key={`breadcrumbs-${PLP_L10N.SUGGESTION_POINTTHREE}`}>
                                    {localization[PLP_L10N.SUGGESTION_POINTTHREE]}
                                </li>
                            </ul>
                        </div>
                    </span>
                </div>
            </section>
        );
    };

    renderPageTitle = (isReturnPage, isMarketingMaterialsPage) => {
        const { localization, params } = this.props;
        const filteredArray =
            params.filterTypes &&
            params.filterTypes
                .split(',')
                .filter(
                    item =>
                        item !== PLP_FILTERS.PAGE &&
                        item !== PLP_FILTERS.PAGE_ITEM_SIZE &&
                        item !== PLP_FILTERS.SHOW_PRICE
                );

        if (isReturnPage) {
            return (
                <h3 automation-id='at-return-empties-title' className={cx('title')}>
                    {localization[PLP_L10N.RETURN_TITLE]}
                </h3>
            );
        }

        if (isMarketingMaterialsPage) {
            return (
                <h3 automation-id='at-marketing-materials-title' className={cx('title')}>
                    {localization[PLP_L10N.MARKETING_MATERIALS_TITLE]}
                </h3>
            );
        }
        if (filteredArray && filteredArray.length === 1 && filteredArray[0] === PLP_FILTERS.FAVORITE) {
            return (
                <h3 automation-id='at-all-products' className={cx('title')}>
                    {localization[PLP_L10N.FAVORITE_PRODUCTS]}
                </h3>
            );
        }
        if (filteredArray && filteredArray.length === 1 && filteredArray[0] === PLP_FILTERS.PROMOTION) {
            return (
                <h3 automation-id='at-all-products' className={cx('title')}>
                    {localization[PLP_L10N.PROMOTION_PRODUCTS]}
                </h3>
            );
        }
        if (filteredArray && filteredArray.length === 1 && filteredArray[0] === PLP_FILTERS.RECOMMENDATIONS) {
            return (
                <h3 automation-id='at-all-products' className={cx('title')}>
                    {localization[PLP_L10N.SMART_ORDERS] || 'Smart Orders' || 'check for missing translation before PR'}
                </h3>
            );
        }
        return (
            <h3 automation-id='at-all-products' className={cx('title')}>
                {localization[PLP_L10N.ALL_PRODUCTS]}
            </h3>
        );
    };

    toggleLockScroll = (val = true) => pubSubManager.publish(PUB_SUB_EVENTS.TOGGLE_MAIN_SCROLL, val);

    renderMobileFiltersToggle = () => {
        const { appliedFacets } = this.state;
        const { localization } = this.props;

        const filtersCount = Object.entries(appliedFacets?.data || {})
            .filter(
                ([n]) =>
                    n !== PLP_FILTERS.PAGE &&
                    n !== PLP_FILTERS.SAVED_FILTERS &&
                    n !== PLP_FILTERS.MAKE_DEFAULT_SAVED_FILTERS &&
                    n !== PLP_FILTERS.REMOVE_SAVED_FILTERS
            )
            .reduce((c, [_, items]) => c + items.length, 0);

        const sortIcon = <Icon iconType='svg' width='12px' height='12px' viewBox='0 0 13 13' name='Icons-Arrow-Sort' />;

        const filterIcon = <Icon iconType='svg' width='12px' height='12px' viewBox='0 0 16 16' name='filter' />;

        return (
            <div
                className={cx('MobileFiltersToggles')}
                style={{
                    ...(this.props.isChatPluginLoaded ? { bottom: '100px' } : {}),
                    ...(appliedFacets?.data?.[PLP_FILTERS.RECOMMENDATIONS] ? { gridTemplateColumns: '1fr' } : {})
                }}
            >
                {!appliedFacets?.data?.[PLP_FILTERS.RECOMMENDATIONS] && (
                    <button
                        type='button'
                        onClick={() => {
                            this.setState({ isShowMobileSort: true });
                            this.toggleLockScroll(true);
                        }}
                    >
                        {sortIcon} {localization[PLP_L10N.SORT_SORT]}
                    </button>
                )}
                <button type='button' onClick={this.openMobileFilters}>
                    {filtersCount ? <div>{filtersCount}</div> : filterIcon} {localization[PLP_L10N.FLTR_FILTER]}
                </button>
            </div>
        );
    };

    renderMobileSort = () => {
        const {
            localization,
            productList: { orderBy }
        } = this.props;

        return (
            <>
                <div
                    className={cx('MobileSortGroupHelper')}
                    onClick={() => {
                        this.setState({ isShowMobileSort: false });
                        this.toggleLockScroll(false);
                    }}
                />
                <div className={cx('MobileSortGroupWrapper')}>
                    {PLP_SORTS.map(s => (
                        <div
                            key={`mobile-sort-${s.value}`}
                            onClick={() => {
                                this.handleSortChange(s.value);
                                this.setState({ isShowMobileSort: false });
                                this.toggleLockScroll(false);
                            }}
                        >
                            <input
                                type='radio'
                                id={`sort-${s.value}-rbtn`}
                                name='mobileSort'
                                value={s.value}
                                checked={s.value === orderBy}
                                onChange={() => 1}
                            />
                            <label htmlFor='sort-name-desc-rbtn'>
                                {localization[s.lolcalizationProp] || s.fallbackLabel}
                            </label>
                        </div>
                    ))}
                </div>
            </>
        );
    };

    goBackMobileFilter = filterProp => {
        this.setState({ mobileFilterView: PLP_MOBILE_FILTERS_VIEWS.MAIN });

        if (!filterProp) {
            return this.saveMobileFilters();
        }

        const { appliedFacets, savedMobileFilters } = this.state;
        const {
            productList: { filters }
        } = this.props;
        const appliedFacetsNew = { data: {} };
        const savedMobileFiltersNew = {};

        if (Object.keys(appliedFacets?.data || {}).length) {
            Object.entries(appliedFacets.data).forEach(([prop, value]) => {
                appliedFacetsNew.data[prop] = [...value];
            });
        }

        if (Object.keys(savedMobileFilters || {}.length)) {
            Object.entries(savedMobileFilters).forEach(([prop, value]) => {
                savedMobileFiltersNew[prop] = [...value];
            });
        }

        if (!savedMobileFilters[filterProp]) {
            const foundFilters = filters.find(({ categoryCode }) => categoryCode === filterProp);
            // If there are any saved filters, we must add them to foundFilters coming from redux productList.filters
            //  Create deep copy of the state since you can't mutate it directly
            const newFilter = _cloneDeep(foundFilters);
            if (this.state.isSavedFilters && this.props.savedFilters) {
                const parsedFilters = parseSavedFilters(this.props.savedFilters);
                if (Object.keys(parsedFilters).length > 0) {
                    const key = newFilter.categoryCode.toLowerCase();
                    if (parsedFilters[key]) {
                        parsedFilters[key].forEach(parsedFilterName => {
                            if (
                                !newFilter.items.some(
                                    item => item.name.toUpperCase() === parsedFilterName.toUpperCase()
                                )
                            ) {
                                // Add the item to the filter.items array if it doesn’t already exist
                                newFilter.items.push({
                                    name: parsedFilterName,
                                    code: parsedFilterName,
                                    totalItem: 0,
                                    imageUrl: '',
                                    selected: true,
                                    count: 0
                                });
                            }
                        });
                    }
                }
            }
            if (newFilter?.items && newFilter.items.findIndex(f => f.selected) !== -1) {
                const selectedFilters = newFilter.items.filter(f => f.selected);
                appliedFacetsNew.data[filterProp] = selectedFilters.map(({ code }) => code);
                delete savedMobileFiltersNew[filterProp];
            } else {
                delete appliedFacetsNew.data[filterProp];
            }
        } else {
            appliedFacetsNew.data[filterProp] = savedMobileFilters[filterProp];
        }

        this.setState({ appliedFacets: appliedFacetsNew, savedMobileFilters: savedMobileFiltersNew });
    };

    openMobileFilters = () => {
        this.setState({ isShowMobileFilter: true }, () => {
            this.toggleLockScroll(true);
        });
    };

    closeMobileFilters = () => {
        this.setState({
            isShowMobileFilter: false,
            mobileFilterView: PLP_MOBILE_FILTERS_VIEWS.MAIN
        }, () => {
            this.toggleLockScroll(false);
        });
    };

    resetMobileFilters = filterProp => {
        const newAppliedFacets = { data: {} };
        if (filterProp) {
            Object.entries(this.state.appliedFacets?.data || {}).forEach(([prop, val]) => {
                newAppliedFacets.data[prop] = [...val];
            });
            delete newAppliedFacets.data[filterProp];
        }

        delete newAppliedFacets?.data?.[PLP_FILTERS.MIN_PRICE];
        delete newAppliedFacets?.data?.[PLP_FILTERS.MAX_PRICE];
        delete newAppliedFacets?.data?.[PLP_FILTERS.IS_PRICE_PER_PIECE];
        delete newAppliedFacets?.data?.[PLP_FILTERS.SAVED_FILTERS];
        delete newAppliedFacets?.data?.[PLP_FILTERS.MAKE_DEFAULT_SAVED_FILTERS];
        delete newAppliedFacets?.data?.[PLP_FILTERS.REMOVE_SAVED_FILTERS];

        this.setState({
            appliedFacets: newAppliedFacets,
            isPricePerPiece: false,
            isSavedFilters: false,
            updateSavedFiltersPossible: false
        });

        this.applyFiltersForMobile();
    };

    saveMobileFilters = () => {
        const savedMobileFilters = {};
        if (Object.keys(this.state.appliedFacets?.data || {}).length) {
            Object.entries(this.state.appliedFacets.data).forEach(([prop, value]) => {
                savedMobileFilters[prop] = [...value];
            });
        }

        this.setState({ savedMobileFilters });
    };

    // Saved Filters Icon
    renderSavedFilterIcon = () => {
        const { isSavedFilters } = this.state;
        const savedFiltersIcon = isSavedFilters ? (
            <Icon
                className={cx('saveFiltersIcon', 'flex-icon')}
                iconType='svg'
                width='16px'
                height='16px'
                viewBox='0 0 16 16'
                name='save-red'
            />
        ) : (
            <Icon
                className={cx('saveFiltersIcon', 'flex-icon')}
                iconType='svg'
                width='16px'
                height='16px'
                viewBox='0 0 16 16'
                name='save'
            />
        );
        return savedFiltersIcon;
    };

    // Logic to determine which filter options are available in the mobile menu (Not tablet)
    renderMobileFilter = () => {
        const { localization, productList, customerHasSavedFilter } = this.props;
        const {
            mobileFilterView,
            appliedFacets,
            savedMobileFilters,
            isPricePerPiece,
            updateSavedFiltersPossible,
            isSavedFilters
        } = this.state;
        const { isShowProdPrice } = productList;

        const filterProp = {
            [PLP_MOBILE_FILTERS_VIEWS.BRAND]: 'brand',
            [PLP_MOBILE_FILTERS_VIEWS.CAPACITY]: 'capacity',
            [PLP_MOBILE_FILTERS_VIEWS.PACKAGING]: 'pack',
            [PLP_MOBILE_FILTERS_VIEWS.PROMOTION]: 'promotion'
        }[mobileFilterView];

        const viewByProp = {
            brand: PLP_MOBILE_FILTERS_VIEWS.BRAND,
            capacity: PLP_MOBILE_FILTERS_VIEWS.CAPACITY,
            pack: PLP_MOBILE_FILTERS_VIEWS.PACKAGING,
            promotion: PLP_MOBILE_FILTERS_VIEWS.PROMOTION
        };

        const viewResultsButton = (
            <button
                type='button'
                automationId='at-apply-filter-button'
                className={cx('applyMobileFiltersButton')}
                onClick={this.applyFiltersForMobile}
            >
                {localization[PLP_L10N.FLTR_VIEW_RESULTS]}
            </button>
        );

        let isDisabledApply = false;
        if (!appliedFacets?.data || Object.keys(!appliedFacets?.data || {}).length || !filterProp) {
            isDisabledApply = true;
        } else if (filterProp) {
            const inStore = (productList?.filters || []).find(f => f.categoryCode === filterProp)?.items;
            if (!appliedFacets.data[filterProp] && inStore.findIndex(f => f.selected) === -1) {
                if (!savedMobileFilters[filterProp]) {
                    isDisabledApply = true;
                }
            } else {
                const inState = appliedFacets.data[filterProp] || [];
                if (customerHasSavedFilter && !updateSavedFiltersPossible) {
                    isDisabledApply = true;
                } else if (customerHasSavedFilter && updateSavedFiltersPossible) {
                    isDisabledApply = false;
                } else if (!inStore || !inStore.length) {
                    isDisabledApply = true;
                } else {
                    const hasDiffInState =
                        inState.findIndex(
                            fState => inStore.find(fStore => fState === fStore?.code)?.selected === false
                        ) !== -1;
                    const hasDiffInStore =
                        inStore.findIndex(
                            fStore => fStore?.selected && inState.findIndex(fState => fState === fStore?.code) === -1
                        ) !== -1;
                    if (!hasDiffInState && !hasDiffInStore) {
                        isDisabledApply = true;
                    }
                }
            }
        }

        const applyFiltersButton = (
            <button
                type='button'
                automationId='at-apply-filter-button'
                onClick={() => this.goBackMobileFilter()}
                disabled={isDisabledApply}
                className={cx('applyMobileFiltersButton')}
            >
                {localization[PLP_L10N.APPLY_FILTERS]}
            </button>
        );

        const mobileSaveFiltersButton = (
            <button
                type='button'
                onClick={() => {
                    if (this.arePLPFiltersApplied()) {
                        this.saveFiltersFunc();
                    }
                }}
                className={cx(
                    this.arePLPFiltersApplied() ? 'mobileSaveFiltersButton' : 'mobileSaveFiltersButtonDisabled'
                )}
            >
                {localization[PLP_L10N.SAVE_FILTERS]}
            </button>
        );

        const closeBtnIcon = <Icon iconType='svg' width='25px' height='25px' viewBox='0 0 24 25' name='close-gray' />;

        const backBtnIcon = <Icon iconType='svg' width='25px' height='25px' viewBox='0 0 24 24' name='chevron-left' />;

        let isShowResetBtn = false;
        if (filterProp) {
            if (appliedFacets?.data?.[filterProp]?.length) {
                isShowResetBtn = true;
            }
        } else {
            const _filters = Object.entries(appliedFacets?.data || {}).filter(([n]) => n !== 'page');
            const filterCount = _filters.reduce((count, [_, applied]) => count + applied.length, 0);
            if (filterCount) {
                isShowResetBtn = true;
            }
        }
        const filtersView = () => {
            const foundFilters = productList?.filters?.find(f => f.categoryCode === filterProp);
            if (!foundFilters?.items?.length) {
                return null;
            }
            //  Create deep copy of the state since you can't mutate it directly
            const newFilter = _cloneDeep(foundFilters);
            // Show only the applied facets related to product categories
            const filteredAppliedFacets = this.removeSavedFilterKeysFromAppliedFacets(appliedFacets.data);
            if (this.state.isSavedFilters && this.props.savedFilters) {
                const parsedFilters = parseSavedFilters(this.props.savedFilters);
                if (Object.keys(parsedFilters).length > 0) {
                    const key = newFilter.categoryCode.toLowerCase();
                    if (parsedFilters[key]) {
                        parsedFilters[key].forEach(parsedFilterName => {
                            if (
                                !newFilter.items.some(
                                    item => item.name.toUpperCase() === parsedFilterName.toUpperCase()
                                )
                            ) {
                                // Add the item to the filter.items array if it doesn’t already exist
                                newFilter.items.push({
                                    name: parsedFilterName,
                                    code: parsedFilterName,
                                    totalItem: 0,
                                    imageUrl: '',
                                    selected: true,
                                    count: 0
                                });
                            }
                        });
                    }
                }
            }
            if (Object.keys(filteredAppliedFacets).length > 0) {
                const key = newFilter.categoryCode.toLowerCase();
                if (filteredAppliedFacets[key]) {
                    filteredAppliedFacets[key].forEach(facet => {
                        if (
                            !newFilter.items.some(
                                item => item.name.toUpperCase() === facet.toUpperCase()
                            )
                        ) {
                            // Add the item to the filter.items array if it doesn’t already exist
                            newFilter.items.push({
                                name: facet,
                                code: facet,
                                totalItem: 0,
                                imageUrl: '',
                                selected: true,
                                count: 0
                            });
                        }
                    });
                }
            }
            return (
                <ul
                    style={{
                        paddingTop:
                            this.state.currentFilterBanner !== '' &&
                                !(
                                    this.state.isSavedFilters ||
                                    this.props.customerHasSavedFilter ||
                                    this.state.appliedFacets?.data?.[PLP_FILTERS.SAVED_FILTERS]
                                )
                                ? '15px'
                                : 0
                    }}
                    className={cx('mobileLinks', cx('filtersListMobileBody'))}
                >
                    {filterProp === PLP_FILTERS.PROMOTION && (
                        <li key='mfltr-promotion-all'>
                            <div className={cx('formGroup')}>
                                <Checkbox
                                    theme={cx('theme', 'label-bold')}
                                    themecheckboxCheckmark={cx('themecheckboxCheckmark')}
                                    name='selectAll'
                                    labelText={localization[PLP_L10N.PROMO_SELECT_ALL]}
                                    checked={this.isCheckAll()}
                                    onChange={e =>
                                        this.handleChange(
                                            e,
                                            this.promoFilters.categoryCode,
                                            this.promoFilters?.items.map(pf => pf.name)
                                        )
                                    }
                                />
                            </div>
                        </li>
                    )}
                    {newFilter?.items.map((filter, idx) =>
                        this.renderFilterOptions(filter, filterProp, `mfltr-${filterProp}-item-${idx}`)
                    )}
                </ul>
            );
        };
        // Mobile filter menu
        const views = {
            [PLP_MOBILE_FILTERS_VIEWS.MAIN]: () => (
                <ul
                    style={{
                        paddingTop:
                            this.state.currentFilterBanner !== '' &&
                                !(
                                    this.state.isSavedFilters ||
                                    this.props.customerHasSavedFilter ||
                                    this.state.appliedFacets?.data?.[PLP_FILTERS.SAVED_FILTERS]
                                )
                                ? '5px'
                                : 0
                    }}
                    className={cx('mobileLinks', cx('mainMobileBody'))}
                >
                    {/* Mobile favorites & brands here here */}
                    {this.renderMobileFiltersPartsFavouritesAndPromotions()}
                    {!!this.promoFilters?.items?.length && (
                        <li
                            key='mfltr-promotion'
                            className={cx('mobileFilterMainItem')}
                            style={appliedFacets?.data?.promotion?.length ? { height: '72px' } : {}}
                        >
                            <div
                                className={cx('formGroup')}
                                onClick={() => this.setState({ mobileFilterView: viewByProp.promotion })}
                            >
                                <span className={cx('mobIconfil')}>
                                    <Icon
                                        iconType='svg'
                                        width='16px'
                                        height='16px'
                                        viewBox='0 0 20 20'
                                        name={
                                            appliedFacets?.data?.promotion?.filter(p => p).length > 0
                                                ? isRoundLogo
                                                    ? 'filter-promotion-gold'
                                                    : 'filter-promotion-red'
                                                : 'filter-Promotion'
                                        }
                                    />
                                </span>
                                <label style={{}}>
                                    {localization[PLP_L10N.PROMOTIONS]}
                                    {!!appliedFacets?.data?.promotion?.length && (
                                        <div>{appliedFacets.data.promotion.join(', ')}</div>
                                    )}
                                </label>
                                <button type='button'>
                                    <Icon
                                        iconType='svg'
                                        width='25px'
                                        height='25px'
                                        viewBox='0 0 24 24'
                                        name='chevron-right'
                                    />
                                </button>
                            </div>
                        </li>
                    )}
                    {!!productList?.filters?.length &&
                        productList.filters.map(filter => {
                            if (filter.categoryCode === PLP_FILTERS.PROMOTION) {
                                return null;
                            }

                            const hasSelected = !!appliedFacets?.data?.[filter.categoryCode]?.length;
                            // opening the mobile filter menu options logic here
                            return (
                                <li
                                    key={`mfltr-${filter.categoryCode}`}
                                    className={cx('mobileFilterMainItem')}
                                    style={hasSelected ? { height: '72px' } : {}}
                                >
                                    <div
                                        className={cx('formGroup')}
                                        onClick={() =>
                                            this.setState({ mobileFilterView: viewByProp[filter.categoryCode] })
                                        }
                                    >
                                        <span className={cx('mobIconfil')}>
                                            {filter.categoryCode === PLP_FILTERS.BRAND && (
                                                <Icon
                                                    iconType='svg'
                                                    width='16px'
                                                    height='16px'
                                                    viewBox='0 0 16 16'
                                                    name='brands'
                                                />
                                            )}
                                            {filter.categoryCode !== PLP_FILTERS.BRAND && (
                                                <Icon
                                                    iconType='svg'
                                                    width='16px'
                                                    height='16px'
                                                    viewBox='0 0 16 16'
                                                    name={`filter-${filter.categoryCode}`}
                                                />
                                            )}
                                        </span>
                                        <label>
                                            {filter.type}
                                            {hasSelected && (
                                                <div>{appliedFacets.data[filter.categoryCode].join(', ')}</div>
                                            )}
                                        </label>
                                        <button type='button'>
                                            <Icon
                                                iconType='svg'
                                                width='25px'
                                                height='25px'
                                                viewBox='0 0 24 24'
                                                name='chevron-right'
                                            />
                                        </button>
                                    </div>
                                </li>
                            );
                        })}
                    {isShowProdPrice && typeof productList?.priceFilter?.maxOverallPrice !== 'undefined' && (
                        <li key='mfltr-price-filter' className={cx('mobileFilterMainItem')} style={{}}>
                            <div className={cx('formGroup')}>
                                <span className={cx('mobIconfil')}>
                                    <Icon
                                        iconType='svg'
                                        width='16px'
                                        height='16px'
                                        viewBox='0 0 16 16'
                                        name='filter-price'
                                    />
                                </span>
                                <label>{localization[PLP_L10N.PRICE_FILTER_NAME]}</label>
                                <div className={cx('firstMenu', 'subMenu', 'rangeFilter')}>
                                    <RangeInput
                                        min={
                                            productList?.priceFilter?.[
                                            `min${isPricePerPiece ? 'PerPiece' : 'Overall'}Price`
                                            ] || 0
                                        }
                                        max={
                                            productList?.priceFilter?.[
                                            `max${isPricePerPiece ? 'PerPiece' : 'Overall'}Price`
                                            ] ||
                                            productList?.priceFilter?.maxOverallPrice ||
                                            0
                                        }
                                        initialValue={[
                                            +(appliedFacets.data?.minPriceFilterValue?.[0] || 0),
                                            productList?.priceFilter?.priceToFilterValue ||
                                            +(
                                                appliedFacets.data?.maxPriceFilterValue?.[0] ||
                                                productList?.priceFilter?.[
                                                `max${isPricePerPiece ? 'PerPiece' : 'Overall'}Price`
                                                ] ||
                                                0
                                            )
                                        ]}
                                        fromValue={productList?.priceFilter?.['priceFromFilterValue'] || 0}
                                        toValue={productList?.priceFilter?.['priceToFilterValue'] || 0}
                                        suffix={localization[PLP_L10N.CURRENCY_SYMBOL]}
                                        labelMin={localization[PLP_L10N.LABEL_MIN_PRICE]}
                                        labelMax={localization[PLP_L10N.LABEL_MAX_PRICE]}
                                        step={
                                            productList?.priceFilter?.[
                                            isPricePerPiece ? 'pricePerPieceFilterStep' : 'productPriceFilterStep'
                                            ] || 0.01
                                        }
                                        onChange={v =>
                                            this.setState({
                                                filterPriceRange: v
                                            })
                                        }
                                    />
                                    <div className={cx('filterSwitcher')}>
                                        <div className={cx('filterLabel')}>
                                            {localization[PLP_L10N.PRICE_PER_PIECE]}
                                        </div>
                                        <Switcher
                                            controlledValue={
                                                appliedFacets.data?.isPricePerPiece?.[0] || isPricePerPiece
                                            }
                                            onChange={this.togglePricePerPiece}
                                        />
                                    </div>
                                    <div className={cx('mobilePriceFilterApply')}>
                                        <button
                                            type='button'
                                            automationId='at-apply-price-filter-mobile-button'
                                            onClick={this.applyPriceFilter}
                                        >
                                            {localization[PLP_L10N.APPLY_FILTERS] || 'Apply filters'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    )}
                </ul>
            )
        };

        return (
            <div tabIndex={-1} className={cx('MobileFilterWrapperNew')}>
                <div className={cx('MobileFilterWrapperNew', 'mobileFilterHeader')}>
                    <button
                        type='button'
                        className={cx('mobileFilterHeader', 'closeBtn')}
                        onClick={this.closeMobileFilters}
                    >
                        {closeBtnIcon}
                    </button>
                    {/* Go back button mobile */}
                    {!!filterProp && (
                        <button
                            type='button'
                            className={cx('mobileFilterHeader', 'backBtn')}
                            onClick={() => this.goBackMobileFilter(filterProp)}
                        >
                            {backBtnIcon}
                        </button>
                    )}
                    {isShowResetBtn && (
                        <button
                            type='button'
                            className={cx('mobileFilterHeader', 'resetBtn')}
                            onClick={() => this.resetMobileFilters(filterProp)}
                        >
                            {localization[PLP_L10N.FLTR_RESET_ALL] || 'Reset all'}
                        </button>
                    )}
                    <div className={cx('headerTitle')} style={!filterProp ? { left: '18px' } : {}}>
                        {localization[PLP_L10N.FLTR_FILTER]}
                    </div>
                </div>
                <div
                    style={{ paddingTop: this.state.currentFilterBanner !== '' ? '13px' : 0 }}
                    className={cx('MobileFilterWrapperNew', 'mobileFilterBodyWrapper')}
                >
                    {/* Mobile banner logic here  */}
                    {this.state.currentFilterBanner !== '' && (
                        <MessageBox
                            id='save-fiters-banner'
                            level={MESSAGE_LEVELS.PAGE}
                            type={MESSAGE_TYPES.SUCCESS}
                            closeBtn
                            fullWidth
                            onClose={() => {
                                this.setState({
                                    currentFilterBanner: ''
                                });
                            }}
                            isAutoClose
                            autoCloseTime={5000}
                            theme={cx('saveFiltersBanner')}
                        >
                            {this.getSavedFiltersBannerMessage()}
                        </MessageBox>
                    )}
                    {/* Mobile Saved Filters Option & Checkbox Button Here */}
                    {(this.state.isSavedFilters ||
                        this.props.customerHasSavedFilter ||
                        this.state.appliedFacets?.data?.[PLP_FILTERS.SAVED_FILTERS]) && (
                            <li
                                style={{ paddingTop: this.state.currentFilterBanner !== '' ? '35px' : '18px' }}
                                role='presentation'
                                className={cx('mobileSavedFiltersButton')}
                                onClick={() => {
                                    this.applySavedFilters(!this.state.isSavedFilters);
                                }}
                            >
                                <span>
                                    {isSavedFilters && (
                                        <Icon
                                            className={cx('saveFiltersIcon', 'flex-icon')}
                                            iconType='svg'
                                            width='16px'
                                            height='16px'
                                            viewBox='0 0 16 16'
                                            name='save-red'
                                        />
                                    )}
                                    {!isSavedFilters && (
                                        <Icon
                                            className={cx('saveFiltersIcon', 'flex-icon')}
                                            iconType='svg'
                                            width='16px'
                                            height='16px'
                                            viewBox='0 0 16 16'
                                            name='save'
                                        />
                                    )}
                                    {localization[PLP_L10N.SAVED_FILTERS]}
                                </span>
                                <div onClick={e => {
                                    e.stopPropagation()
                                }}>
                                    <Checkbox
                                        theme={cx('theme', 'label-bold', 'singleFilterCheckbox')}
                                        themecheckboxCheckmark={cx('themecheckboxCheckmark')}
                                        name='mobileSavedFiltersCheckbox'
                                        checked={this.state.isSavedFilters && !this.state.updateSavedFiltersPossible}
                                        onChange={e => {
                                            e.stopPropagation();
                                            this.applySavedFilters(!this.state.isSavedFilters);
                                        }}
                                    />
                                </div>
                            </li>
                        )}
                    {(views[mobileFilterView] || filtersView)()}
                </div>
                <div
                    ref={this.mobileFooterRef}
                    tabIndex={-1}
                    style={{
                        top:
                            this.state.isSavedFilters ||
                                this.props.customerHasSavedFilter ||
                                this.state.appliedFacets?.data?.[PLP_FILTERS.SAVED_FILTERS]
                                ? 'calc(100vh - 68px)'
                                : 'calc(100vh - 118px)',
                        height:
                            this.state.isSavedFilters ||
                                this.props.customerHasSavedFilter ||
                                this.state.appliedFacets?.data?.[PLP_FILTERS.SAVED_FILTERS]
                                ? '68px'
                                : '118px',
                        flexDirection:
                            this.state.isSavedFilters ||
                                this.props.customerHasSavedFilter ||
                                this.state.appliedFacets?.data?.[PLP_FILTERS.SAVED_FILTERS]
                                ? 'row'
                                : 'column'
                    }}
                    className={cx('MobileFilterWrapperNew', 'mobileFilterFooter')}
                >
                    {mobileFilterView === PLP_MOBILE_FILTERS_VIEWS.MAIN ? viewResultsButton : applyFiltersButton}
                    {/* Mobile Save Filters Button OR Three dots menu to display saved filters options */}
                    {this.state.isSavedFilters ||
                        this.props.customerHasSavedFilter ||
                        this.state.appliedFacets?.data?.[PLP_FILTERS.SAVED_FILTERS] ? (
                        <div
                            tabIndex={-1}
                            type='button'
                            onBlur={this.handleSavedFiltersCardBlur}
                            onMouseDown={this.handleSavedFiltersCardMouseDown}
                            onClick={() => {
                                this.setState(prevState => ({
                                    showSavedFiltersCard: !prevState.showSavedFiltersCard
                                }));
                            }}
                            className={cx('mobileDotMenuIcon')}
                            style={{
                                border: this.state.showSavedFiltersCard ? '1px solid #D7D7D7' : '1px solid #F6F7F6'
                            }}
                        >
                            {this.renderSavedFiltersDotMenu()}
                        </div>
                    ) : (
                        mobileSaveFiltersButton
                    )}
                    {/* Saved filter card action buttons here */}
                    {this.state.showSavedFiltersCard && this.renderSavedFiltersCard()}
                </div>
            </div>
        );
    };

    renderMobileStickyFilters = () => {
        const { isShowMobileSort } = this.state;
        if (isShowMobileSort) {
            return this.renderMobileSort();
        }
        return this.renderMobileFiltersToggle();
    };

    openStockNotificationModal = productCode => {
        this.props.actions.openSubscriptionPopup(productCode);
    };

    /**
     * @brief      Extracts promo facets
     *
     * @return     {void}
     */
    getPromoFilters = () => {
        const { productList } = this.state;
        this.promoFilters = (productList?.filters || [])
            .filter((facet, index) => facet.categoryCode === 'promotion')
            .pop();
    };

    // Apply the min and max price filters
    applyPriceFilter = () => {
        // The user's recent interactions.
        const userMinPrice = this.state.filterPriceRange?.[0];
        const userMaxPrice = this.state.filterPriceRange?.[1];
        const userIsPricePerPiece = this.state.isPricePerPiece;

        // The initially fetched filters.
        const savedMinPrice = this.state.appliedFacets?.data?.minPriceFilterValue?.[0];
        const savedMaxPrice = this.state.appliedFacets?.data?.maxPriceFilterValue?.[0];
        const savedIsPricePerPiece = this.state.appliedFacets?.data?.isPricePerPieceFilterActive?.[0];

        // Choose the most recent value or fallback to the initially fetched value.
        const minPriceToApply = userMinPrice !== undefined ? userMinPrice : savedMinPrice;
        const maxPriceToApply = userMaxPrice !== undefined ? userMaxPrice : savedMaxPrice;
        const isPricePerPieceToApply = userIsPricePerPiece || savedIsPricePerPiece || false;

        this.setState(
            prevState => {
                // Clone the appliedFacets.data object
                const newData = { ...prevState.appliedFacets?.data };

                // Delete the saved filters keys to avoid involuntary api calls if the user makes changes right after performing the save filters api calls.
                delete newData[PLP_FILTERS.SAVED_FILTERS];
                delete newData[PLP_FILTERS.MAKE_DEFAULT_SAVED_FILTERS];
                delete newData[PLP_FILTERS.REMOVE_SAVED_FILTERS];

                return {
                    appliedFacets: {
                        data: {
                            ...newData,
                            minPriceFilterValue: [`${minPriceToApply}`],
                            maxPriceFilterValue: [`${maxPriceToApply}`],
                            isPricePerPieceFilterActive: [`${isPricePerPieceToApply}`]
                        }
                    }
                };
            },
            () => {
                // Now, apply the filters.
                this.updateFilters(PLP_FILTERS.MIN_PRICE, minPriceToApply);
                this.updateFilters(PLP_FILTERS.MAX_PRICE, maxPriceToApply);
                this.updateFilters(PLP_FILTERS.IS_PRICE_PER_PIECE, isPricePerPieceToApply);
            }
        );
    };

    // Apply filters from saved filters
    applySavedFilters = selected => {
        const { params, location, savedFilters, deviceType } = this.props;
        if (selected) {
            const parsedFilters = parseSavedFilters(savedFilters);
            if (Object.keys(parsedFilters).length > 0) {
                this.setState(
                    {
                        isSavedFilters: true,
                        updateSavedFiltersPossible: false,
                        appliedFacets: {
                            data: {
                                ...parsedFilters,
                                [PLP_FILTERS.PAGE]: [1]
                            }
                        }
                    },
                    () => {
                        // We only do this for desktop & tablet, because for mobile we're in a modal and we don't want to trigger a rerender which will close the modal and lead to bad UX
                        if (!deviceType.isMobile) {
                            const { appliedFacets } = this.state;
                            this.initialFiltersLoaded = false;
                            this.getProductData(false, false, true);
                            getUpdatedURL(params.plpType, location.search, appliedFacets);
                        }
                    }
                );
            }
        } else {
            this.setState(
                {
                    isSavedFilters: false,
                    updateSavedFiltersPossible: false,
                    appliedFacets: {
                        data: {}
                    }
                },
                () => {
                    // We only do this for desktop & tablet, because for mobile we're in a modal and we don't want to trigger a rerender which will close the modal and lead to bad UX
                    if (!deviceType.isMobile) {
                        this.clearAllFilters();
                        getUpdatedURL(params.plpType, location.search, {});
                    }
                }
            );
        }
    };

    // Save filters function
    saveFiltersFunc = () => {
        this.setState(
            {
                isSavedFilters: true,
                updateSavedFiltersPossible: false,
                currentFilterBanner: 'saved',
                showSavedFiltersCard: false
            },
            () => {
                this.updateFilters(PLP_FILTERS.SAVED_FILTERS, 'true');
            }
        );
    };

    // Update filters function
    updateSavedFiltersFunc = () => {
        this.setState(
            {
                isSavedFilters: true,
                updateSavedFiltersPossible: false,
                currentFilterBanner: 'updated',
                showSavedFiltersCard: false
            },
            () => {
                this.updateFilters(PLP_FILTERS.SAVED_FILTERS, 'true');
            }
        );
    };

    // Delete Saved filters function
    deleteSavedFiltersFunc = () => {
        this.setState(
            {
                isSavedFilters: false,
                updateSavedFiltersPossible: false,
                currentFilterBanner: 'deleted',
                showSavedFiltersCard: false,
                appliedFacets: {
                    data: {}
                }
            },
            () => {
                this.updateFilters(PLP_FILTERS.REMOVE_SAVED_FILTERS, 'true');
            }
        );
    };

    sortNestedArrays = obj => {
        if (Object.keys(obj).length === 0) return obj;
        const newSortedObject = _forOwn(obj, value => {
            if (Array.isArray(value)) {
                value.sort();
            }
        });
        return newSortedObject;
    };

    removeSavedFilterKeysFromAppliedFacets = obj => {
        const newObj = Object.keys(obj).reduce((item, key) => {
            if (
                key !== PLP_FILTERS.PAGE &&
                key !== PLP_FILTERS.SAVED_FILTERS &&
                key !== PLP_FILTERS.MAKE_DEFAULT_SAVED_FILTERS &&
                key !== PLP_FILTERS.REMOVE_SAVED_FILTERS
            ) {
                item[key] = obj[key];
            }
            return item;
        }, {});
        return newObj;
    };

    // Make Default filters function
    makeDefaultFiltersFunc = () => {
        this.setState(
            {
                isSavedFilters: true,
                updateSavedFiltersPossible: false,
                currentFilterBanner: 'default',
                showSavedFiltersCard: false
            },
            () => {
                // Reset initial filters to false when we make default, because we want our componentDidUpdate to run which will allow us to see our default filters on mount immediately after setting them
                this.initialFiltersLoaded = false;
                this.updateFilters(PLP_FILTERS.MAKE_DEFAULT_SAVED_FILTERS, 'true');
            }
        );
    };

    // Check if there are any filters active, except for page & saved filters (save || update || delete)
    arePLPFiltersApplied() {
        const { appliedFacets } = this.state;
        const appliedFacetsFiltered = this.removeSavedFilterKeysFromAppliedFacets(appliedFacets.data);
        // Check if the object has any keys
        return Object.keys(appliedFacetsFiltered).length > 0;
    }

    calculateSeeMoreBrands(productList) {
        const filter = productList?.filters?.find(filterItem => filterItem.categoryCode === PLP_FILTERS.BRAND);
        if (filter) {
            let lengthOfList = filter.items.length;

            if (!this.state.seeMoreBrands && filter.categoryCode === PLP_FILTERS.BRAND) {
                lengthOfList = 5;
            }

            if (filter.categoryCode === PLP_FILTERS.BRAND && this.state.appliedFacets?.data?.[PLP_FILTERS.BRAND]) {
                const findBrandInsideCollapseIndex = filter.items
                    .slice(5)
                    .findIndex(item => this.state.appliedFacets?.data?.[PLP_FILTERS.BRAND].includes(item.code));

                if (findBrandInsideCollapseIndex !== -1) {
                    this.setState({
                        seeMoreBrands: true
                    });
                }
            }

            return lengthOfList;
        }
    }

    isCheckAll() {
        const filterFacets = (this.promoFilters?.items || []).filter(pf => {
            return !!this.state?.appliedFacets?.data?.promotion?.find(af => af === pf.name);
        });
        if (filterFacets?.length === 0) return false;
        return this.promoFilters?.items?.length === filterFacets?.length;
    }

    isSetActive(filter) {
        const filterType = filter.categoryCode;

        if (filterType === PLP_FILTERS.BRAND) return true;
        const findfilter = Object.keys(this.state.appliedFacets?.data || {}).find(
            key => key.toLowerCase() === filterType.toLowerCase()
        );
        if (findfilter) return true;
        return false;
    }

    render() {
        const {
            isShowFilter,
            isSticky,
            stickyHeight,
            showMarketing,
            productList,
            isPricePerPiece,
            isShowMobileFiltersToggle,
            isShowMobileFilter,
            selectedView,
            showPricePopUp,
            appliedFacets,
            isShowPrice,
            isSavedFilters,
            updateSavedFiltersPossible,
            currentFilterBanner,
            showSavedFiltersCard
        } = this.state;
        const { localization, params, simpleBanner, location, customerHasSavedFilter, savedFilters } = this.props;
        const { products, currentPage = 0, isShowProdPrice, spinner, blockAddToCartEnabled } = productList;
        const breadcrumbValues = _get(productList, 'breadcrumbs', []);
        const isReturnEmpties = _get(productList, 'returnEmpties', false);
        const isMarketingMaterials = _get(productList, 'marketingMaterials', false);
        const isReturnPage = params.plpType === PLP_TYPES.RETURN;
        const isMarketingMaterialsPage = params.plpType === PLP_TYPES.ZPOP;
        const isSearch = params.plpType === PLP_TYPES.SEARCH;
        const isReturnSearch = !!_get(location, 'query.re');
        const topBanner = _find(this.props.components, [UNIQUE_ID, PLP_L10N.PLP_BANNER_IMAGE_UID]);
        const isGrid = !!(this.state.selectedView === PLP_VIEW_TYPES.GRID_VIEW || !this.state.selectedView);
        const iconFilter = (
            <Icon
                className={cx('mobileFilterIcon')}
                iconType='svg'
                width='16px'
                height='16px'
                viewBox='0 0 16 16'
                name='filter'
            />
        );
        const faviIcon = (
            <Icon
                className={cx('leftIcon')}
                iconType='svg'
                width='20px'
                height='20px'
                viewBox='0 0 24 24'
                name='fav_icon'
            />
        );
        const faviActiveIcon = (
            <Icon
                className={cx('leftIcon', 'activeIcon')}
                width='20px'
                height='20px'
                viewBox='0 0 24 24'
                name={isRoundLogo ? 'filter-favorite-gold' : 'fav-icon-active'}
            />
        );
        const inStockIcon = (
            <Icon
                className={cx('leftIcon', 'inStockActiveIcon')}
                width='20px'
                height='15px'
                viewBox='0 0 20 15'
                name='filter-instock'
            />
        );
        const inStockIconActive = (
            <Icon
                className={cx('leftIcon', 'inStockActiveIcon')}
                width='20px'
                height='15px'
                viewBox='0 0 20 15'
                name={isRoundLogo ? 'filter-instock-gold' : 'filter-instock-red'}
            />
        );
        const recommendationsIcon = (
            <Icon
                className={
                    !appliedFacets?.data?.[PLP_FILTERS.RECOMMENDATIONS]
                        ? cx('leftIcon')
                        : cx('leftIcon', 'bdaaActiveIcon')
                }
                iconType='svg'
                width='16px'
                height='16px'
                viewBox='0 0 16 16'
                name='filterRecommendation'
            />
        );
        const recommendationsActiveIcon = (
            <Icon
                className={
                    !appliedFacets?.data?.[PLP_FILTERS.RECOMMENDATIONS]
                        ? cx('leftIcon')
                        : cx('leftIcon', 'bdaaActiveIcon')
                }
                iconType='svg'
                width='16px'
                height='16px'
                viewBox='0 0 16 16'
                name={isRoundLogo ? 'filter-recommendation-gold' : 'filter-recommendation-red'}
            />
        );

        const plpListDetail = selectedView === PLP_VIEW_TYPES.LIST_VIEW;

        // Slider BDAA settings
        const bdaaProductsCount = (this.props.bdaaSuggestion || []).length;
        const settings = {
            className: 'center',
            centerMode: false,
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: bdaaProductsCount < 3 ? bdaaProductsCount : 3,
            slidesToScroll: bdaaProductsCount < 3 ? bdaaProductsCount : 3,
            autoplay: false,
            adaptiveHeight: false,
            autoplaySpeed: 5000,
            pauseOnHover: true,
            nextArrow: <CustomNextArrow />,
            prevArrow: <CustomPrevArrow />,
            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: bdaaProductsCount < 2 ? bdaaProductsCount : 2,
                        slidesToScroll: bdaaProductsCount < 2 ? bdaaProductsCount : 2,
                        nextArrow: <CustomNextArrow hideComponent />,
                        prevArrow: <CustomPrevArrow hideComponent />,
                        dots: false
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        nextArrow: <CustomNextArrow hideComponent />,
                        prevArrow: <CustomPrevArrow hideComponent />,
                        dots: false
                    }
                }
            ]
        };

        // product lists
        const sliceCount = this.props.deviceType.isMobile || this.props.deviceType.isTablet ? 4 : 6;
        const productList1 = (products || []).slice(0, sliceCount);
        const productList2 = (products || []).slice(sliceCount);

        const bdaaMsg = {
            bdaaMsgBoldText: localization[PLP_L10N.BDAA_MSG_BOLD_TEXT],
            bdaaMsgRegularText: localization[PLP_L10N.BDAA_MSG_REGULAR_TEXT]
        };

        const filterSortOptions = (sortOptions = []) => {
            if (this.isBdaaActive && !!this.props.bdaaSuggestion?.length) {
                return sortOptions;
            }
            return sortOptions.filter(option => option.fallbackLabel !== 'Recommendations');
        };

        this.getPromoFilters();

        if (
            this.isBdaaActive &&
            !this.state.appliedFacets?.data?.[PLP_FILTERS.RECOMMENDATIONS] &&
            bdaaProductsCount > 0 &&
            !this.state.isBdaaImpressionSent
        ) {
            this.setState({ isBdaaImpressionSent: true }, () => {
                try {
                    viewItemListEventGA4(
                        productImpressionsDataLayerFillterGA4(
                            _cloneDeep(this.props.bdaaSuggestion),
                            DATA_LAYER_LISTS.BDAA_LIST
                        )
                    );
                } catch (err) {
                    console.error(err);
                }
            });
        }

        return (
            <Layout
                showMarketing={showMarketing}
                showDashboardHeader
                showOutlet
                theme={cx('plpContainer')}
                bdaaMsgPlp={bdaaMsg}
                isBdaaActive={this.isBdaaActive && !!this.props.bdaaSuggestion?.length}
            >
                {/* Show backdrop when filters are saved successfully */}
                {currentFilterBanner === 'saved' && <div className={cx('saveFiltersSuccessBackdrop')} />}
                <NotificationSubscription />
                {showPricePopUp && (
                    <Modal
                        automationId='at-create-new-template-popup'
                        showModal={showPricePopUp}
                        onClose={this.onClose}
                        className={cx('themeoverlay')}
                        modalTheme={cx('tabletuserThemeModal')}
                    >
                        <ShowPriceTemplate
                            params={params}
                            location={location}
                            appliedFacets={appliedFacets}
                            onCancel={this.onClose}
                        />
                    </Modal>
                )}
                {!_isEmpty(localization) && (
                    <div
                        automation-id='at-product-listing-page'
                        className={cx('productsContainer', plpListDetail && 'productListViewContainer')}
                    >
                        {!_isEmpty(simpleBanner) && !isSavedFilters && (
                            <div
                                onClick={() => {
                                    this.handleGAPromotionClick(simpleBanner);
                                    this.resetData(this.getProductData, false, false);
                                }}
                                className={cx('productBanner')}
                            >
                                <SmartEdit data={topBanner}>
                                    <Image
                                        automationId='at-static-banner'
                                        theme={cx('bannerImg')}
                                        src={simpleBanner.url}
                                        linkUrl={simpleBanner.urlLink}
                                        alt={simpleBanner.altText}
                                    />
                                </SmartEdit>
                            </div>
                        )}
                        {spinner ? (
                            <div className={cx('plpSpinner')}>
                                <Loader spinner />
                            </div>
                        ) : (
                            productList.products && (
                                <>
                                    {(!_isEmpty(productList.products) || !isSearch) && (
                                        <>
                                            <section className={cx('productTopContainer')}>
                                                {currentFilterBanner !== '' && (
                                                    <MessageBox
                                                        id='save-fiters-banner'
                                                        level={MESSAGE_LEVELS.PAGE}
                                                        type={MESSAGE_TYPES.SUCCESS}
                                                        closeBtn
                                                        fullWidth
                                                        onClose={() => {
                                                            this.setState({
                                                                currentFilterBanner: ''
                                                            });
                                                        }}
                                                        isAutoClose
                                                        autoCloseTime={5000}
                                                        theme={cx('saveFiltersBanner')}
                                                    >
                                                        {this.getSavedFiltersBannerMessage()}
                                                    </MessageBox>
                                                )}
                                                <BreadCrumbs
                                                    breadcrumbValues={breadcrumbValues}
                                                    custombreadCrumbWrapper={cx('custombreadCrumbWrapper')}
                                                />
                                                <div className={cx('col12', 'productTitles')}>
                                                    <span className={cx('col12', 'sm12', 'subTitles')}>
                                                        {isSearch && !_isEmpty(productList.products) ? (
                                                            <h3 automation-id='at-search-term' className={cx('title')}>
                                                                {localization[PLP_L10N.SEARCHED_FOR]} &apos;
                                                                {location.query.text}
                                                                &apos;
                                                            </h3>
                                                        ) : (
                                                            this.renderPageTitle(isReturnPage, isMarketingMaterialsPage)
                                                        )}
                                                        {!isReturnPage &&
                                                            !isMarketingMaterialsPage &&
                                                            this.props.deviceType.isTablet && (
                                                                <div className={cx('mobileFilter')}>
                                                                    <Button
                                                                        className={cx('filtersBtn')}
                                                                        role='presentation'
                                                                        onClick={this.mobileToggleFilters}
                                                                    >
                                                                        {localization[PLP_L10N.FILTERS]} {iconFilter}
                                                                    </Button>
                                                                </div>
                                                            )}

                                                        <div className={cx('deliverySelect')}>
                                                            {!this.props.isIndirectUser &&
                                                                !isReturnPage &&
                                                                !isMarketingMaterialsPage &&
                                                                !isReturnSearch &&
                                                                this.renderDeliveryType()}
                                                        </div>
                                                    </span>
                                                    <div className={cx('resultItems')}>
                                                        <ul
                                                            className={cx(
                                                                'showLeftRow',
                                                                'col5',
                                                                isShowFilter && 'hidePrice'
                                                            )}
                                                        >
                                                            <li
                                                                className={cx('listItems')}
                                                                key={`breadcrumbs-${PLP_L10N.RESULTS}`}
                                                            >
                                                                <h4 automation-id='at-number-of-results-text'>
                                                                    {localization[PLP_L10N.RESULTS]}
                                                                    <span className={cx('counts')}>
                                                                        ({productList.totalItems})
                                                                    </span>
                                                                </h4>
                                                            </li>
                                                            {!isReturnEmpties && !isMarketingMaterials && (
                                                                <>
                                                                    <li
                                                                        className={cx('listItems', 'hiddenSm')}
                                                                        key={`breadcrumbs-${PLP_L10N.HIDE_FILTERS}`}
                                                                    >
                                                                        <div className={cx('filter')}>
                                                                            {this.state.isShowFilter ? (
                                                                                <Button
                                                                                    className={cx('filtersBtn')}
                                                                                    role='presentation'
                                                                                    onClick={() =>
                                                                                        this.toggleFilters(false)
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        localization[
                                                                                        PLP_L10N.HIDE_FILTERS
                                                                                        ]
                                                                                    }{' '}
                                                                                    {iconFilter}
                                                                                </Button>
                                                                            ) : (
                                                                                <Button
                                                                                    className={cx('filtersBtn')}
                                                                                    role='presentation'
                                                                                    onClick={() =>
                                                                                        this.toggleFilters(true)
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        localization[
                                                                                        PLP_L10N.SHOW_FILTERS
                                                                                        ]
                                                                                    }{' '}
                                                                                    {iconFilter}
                                                                                </Button>
                                                                            )}
                                                                        </div>
                                                                    </li>
                                                                </>
                                                            )}
                                                        </ul>
                                                        <ul className={cx('showRightRow', 'col7')}>
                                                            <li
                                                                className={cx('gridList')}
                                                                key='breadcrumbs-gridList-renderview'
                                                            >
                                                                {this.renderView()}
                                                            </li>
                                                            <li
                                                                className={cx('gridList')}
                                                                key='breadcrumbs-gridList-empty'
                                                            />
                                                            <li
                                                                className={cx('gridList')}
                                                                key='breadcrumbs-gridList-pagination'
                                                            >
                                                                <div className={cx('paginations')}>
                                                                    <div className='Pagination-paginationItems'>
                                                                        <div
                                                                            automation-id='at-list-sort-item-dropdown'
                                                                            className='Pagination-selectBoxItems ProductList-customItemsClass'
                                                                        >
                                                                            <div
                                                                                className='Pagination-selectBox'
                                                                                style={{
                                                                                    marginRight: -20,
                                                                                    marginTop: 4,
                                                                                    marginLeft: 8
                                                                                }}
                                                                            >
                                                                                <span className='Pagination-listPage ProductList-customListPage'>
                                                                                    {' '}
                                                                                    {localization[PLP_L10N.SORT_SORT]}
                                                                                </span>
                                                                            </div>
                                                                            <SelectBox
                                                                                onChange={this.handleSortChange}
                                                                                onFocus={() => {
                                                                                    try {
                                                                                        buttonClickEvent(buttonClickEventConfigs.products.actions.sort, buttonClickEventConfigs.products.labels.sortSortClick, buttonClickEventConfigs.products.categories.products);
                                                                                    } catch (ex) {
                                                                                        console.error(ex);
                                                                                    }
                                                                                }}
                                                                                theme={cx(
                                                                                    'Pagination-customBox',
                                                                                    cx('paginationSelect')
                                                                                )}
                                                                                themeSelectBox={cx(
                                                                                    'Pagination-pageThemeselectBox',
                                                                                    cx('themeSelectBox')
                                                                                )}
                                                                                arrowTheme={cx('arrowTheme', 'down')}
                                                                                id='sortSelectBox'
                                                                                options={filterSortOptions(
                                                                                    PLP_SORTS
                                                                                ).map((s, i) => ({
                                                                                    id: i + 1,
                                                                                    value: s.value,
                                                                                    label:
                                                                                        localization[
                                                                                        s.lolcalizationProp
                                                                                        ] || s.fallbackLabel
                                                                                }))}
                                                                                defaultValue={
                                                                                    this.props.productList.orderBy
                                                                                }
                                                                                automationId='gridButton'
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </section>
                                            <section
                                                className={cx(
                                                    'productGridWrapper',
                                                    isShowFilter && 'prodFilterGridView',
                                                    plpListDetail && 'productListWrapper'
                                                )}
                                            >
                                                <div className={cx('productGridDetails')}>
                                                    {this.props.deviceType.isMobile &&
                                                        (isShowMobileFiltersToggle || isShowMobileFilter) &&
                                                        this.renderMobileStickyFilters()}
                                                    {isShowMobileFilter && (
                                                        <div className={cx('mobileshowFilter')}>
                                                            {this.renderMobileFilter()}
                                                        </div>
                                                    )}
                                                    {/* Filters desktop */}
                                                    <div
                                                        className={cx(
                                                            'leftContent',
                                                            `${isShowFilter &&
                                                                !isReturnEmpties &&
                                                                !isMarketingMaterials
                                                                ? 'showFilter'
                                                                : 'hideFilter'
                                                            }`
                                                        )}
                                                    >
                                                        <div className={cx('saveFiltersRow')}>
                                                            <p className={cx('filtersText')}>
                                                                {localization[PLP_L10N.FILTERS_CAPITALIZED]}
                                                            </p>
                                                            {/* Saved filters three dots */}
                                                            {isSavedFilters ||
                                                                customerHasSavedFilter ||
                                                                appliedFacets?.data?.[PLP_FILTERS.SAVED_FILTERS] ? (
                                                                <div
                                                                    tabIndex={-1}
                                                                    type='button'
                                                                    onBlur={this.handleSavedFiltersCardBlur}
                                                                    onClick={() => {
                                                                        this.setState(prevState => ({
                                                                            showSavedFiltersCard: !prevState.showSavedFiltersCard
                                                                        }));
                                                                    }}
                                                                    className={cx('dotMenuIcon')}
                                                                >
                                                                    {this.renderSavedFiltersDotMenu()}
                                                                    <div
                                                                        style={{
                                                                            display:
                                                                                currentFilterBanner === 'saved' &&
                                                                                'flex'
                                                                        }}
                                                                        className={cx('dotMenuIconTooltip')}
                                                                    >
                                                                        {localization[PLP_L10N.THREE_DOTS_MESSAGE]}
                                                                        <Icon
                                                                            iconType='svg'
                                                                            width='16px'
                                                                            height='20px'
                                                                            viewBox='0 0 20 20'
                                                                            name='close-global'
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <button
                                                                    type='button'
                                                                    onClick={() => {
                                                                        if (this.arePLPFiltersApplied()) {
                                                                            this.saveFiltersFunc();
                                                                        }
                                                                    }}
                                                                    className={cx('saveFilters')}
                                                                    style={{
                                                                        opacity: this.arePLPFiltersApplied() ? 1 : 0.25,
                                                                        cursor: this.arePLPFiltersApplied()
                                                                            ? 'pointer'
                                                                            : 'auto'
                                                                    }}
                                                                >
                                                                    {localization[PLP_L10N.SAVE_FILTERS]}
                                                                </button>
                                                            )}
                                                            {showSavedFiltersCard && this.renderSavedFiltersCard()}
                                                        </div>
                                                        <div className={cx('leftNoscroll')}>
                                                            {/* Save filters button */}
                                                            <ul className={cx('links')}>
                                                                {(isSavedFilters ||
                                                                    customerHasSavedFilter ||
                                                                    appliedFacets?.data?.[
                                                                    PLP_FILTERS.SAVED_FILTERS
                                                                    ]) && (
                                                                        <li
                                                                            role='presentation'
                                                                            className={cx('singleFilter', 'pointer')}
                                                                            style={{ userSelect: 'none' }}
                                                                            onClick={() => {
                                                                                this.applySavedFilters(!isSavedFilters);
                                                                            }}
                                                                        >
                                                                            <span className={cx('savedFiltersButton')}>
                                                                                {this.renderSavedFilterIcon()}
                                                                                <p>
                                                                                    {localization[PLP_L10N.SAVED_FILTERS]}
                                                                                </p>
                                                                            </span>
                                                                            <div onClick={e => e.stopPropagation()}>
                                                                                <Checkbox
                                                                                    theme={cx(
                                                                                        'theme',
                                                                                        'label-bold',
                                                                                        'savedFiltersCheckbox'
                                                                                    )}
                                                                                    themecheckboxCheckmark={cx(
                                                                                        'themecheckboxCheckmark'
                                                                                    )}
                                                                                    name='selectAll'
                                                                                    checked={
                                                                                        isSavedFilters &&
                                                                                        !updateSavedFiltersPossible
                                                                                    }
                                                                                    onChange={e => {
                                                                                        e.stopPropagation();
                                                                                        this.applySavedFilters(
                                                                                            !isSavedFilters
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </li>
                                                                    )}
                                                                {this.isBdaaActive &&
                                                                    !!this.props.bdaaSuggestion?.length && (
                                                                        <li
                                                                            className={cx('pointer', 'singleFilter')}
                                                                            role='presentation'
                                                                            onClick={() =>
                                                                                this.updateFilters('bdaa', 'true')
                                                                            }
                                                                        >
                                                                            <span>
                                                                                {appliedFacets?.data?.[
                                                                                    PLP_FILTERS.RECOMMENDATIONS
                                                                                ]
                                                                                    ? recommendationsActiveIcon
                                                                                    : recommendationsIcon}
                                                                                {localization[PLP_L10N.RECOMMENDATIONS]}
                                                                            </span>

                                                                            <Checkbox
                                                                                theme={cx(
                                                                                    'theme',
                                                                                    'label-bold',
                                                                                    'singleFilterCheckbox'
                                                                                )}
                                                                                themecheckboxCheckmark={cx(
                                                                                    'themecheckboxCheckmark'
                                                                                )}
                                                                                name='selectAll'
                                                                                checked={
                                                                                    appliedFacets?.data?.[
                                                                                    PLP_FILTERS.RECOMMENDATIONS
                                                                                    ]
                                                                                }
                                                                            />
                                                                        </li>
                                                                    )}

                                                                <li
                                                                    className={cx('pointer', 'singleFilter')}
                                                                    automation-id='at-favourites-link'
                                                                    role='presentation'
                                                                    onClick={() =>
                                                                        this.updateFilters('favorite', 'true')
                                                                    }
                                                                >
                                                                    {appliedFacets?.data?.[PLP_FILTERS.FAVORITE] && (
                                                                        <span>
                                                                            {faviActiveIcon}
                                                                            {localization[PLP_L10N.FAVORITES]}
                                                                        </span>
                                                                    )}
                                                                    {!appliedFacets?.data?.[PLP_FILTERS.FAVORITE] && (
                                                                        <span>
                                                                            {faviIcon}
                                                                            {localization[PLP_L10N.FAVORITES]}
                                                                        </span>
                                                                    )}
                                                                    <Checkbox
                                                                        theme={cx(
                                                                            'theme',
                                                                            'label-bold',
                                                                            'singleFilterCheckbox'
                                                                        )}
                                                                        themecheckboxCheckmark={cx(
                                                                            'themecheckboxCheckmark'
                                                                        )}
                                                                        name='selectAll'
                                                                        checked={
                                                                            appliedFacets?.data?.[PLP_FILTERS.FAVORITE]
                                                                        }
                                                                    />
                                                                </li>
                                                                {this.isStockAllowed && (
                                                                    <li
                                                                        className={cx('pointer', 'singleFilter')}
                                                                        automation-id='instock-link'
                                                                        role='presentation'
                                                                        onClick={() =>
                                                                            this.updateFilters('inStock', 'true')
                                                                        }
                                                                    >
                                                                        {appliedFacets?.data?.[
                                                                            PLP_FILTERS.IN_STOCK
                                                                        ] && (
                                                                                <span>
                                                                                    {inStockIconActive}
                                                                                    {localization[PLP_L10N.IN_STOCK]}
                                                                                </span>
                                                                            )}
                                                                        {!appliedFacets?.data?.[
                                                                            PLP_FILTERS.IN_STOCK
                                                                        ] && (
                                                                                <span>
                                                                                    {inStockIcon}
                                                                                    {localization[PLP_L10N.IN_STOCK]}
                                                                                </span>
                                                                            )}

                                                                        <Checkbox
                                                                            theme={cx(
                                                                                'theme',
                                                                                'label-bold',
                                                                                'singleFilterCheckbox'
                                                                            )}
                                                                            themecheckboxCheckmark={cx(
                                                                                'themecheckboxCheckmark'
                                                                            )}
                                                                            name='selectAll'
                                                                            checked={
                                                                                appliedFacets?.data?.[
                                                                                PLP_FILTERS.IN_STOCK
                                                                                ]
                                                                            }
                                                                        />
                                                                    </li>
                                                                )}
                                                            </ul>
                                                            {/* promoFacets Desktop */}
                                                            {!!this.promoFilters?.items?.length && (
                                                                <Accordion
                                                                    key={this.promoFilters.categoryCode}
                                                                    title={localization[PLP_L10N.PROMOTIONS]}
                                                                    customTitle={cx('accordionTitle')}
                                                                    className={cx('accordionHeader')}
                                                                    iconTheme={cx('iconClasses')}
                                                                    themesetActive='setActive'
                                                                    titleIcon
                                                                    customIconName={
                                                                        this.state?.appliedFacets?.data?.promotion?.filter(
                                                                            p => p
                                                                        ).length > 0
                                                                            ? isRoundLogo
                                                                                ? 'filter-promotion-gold'
                                                                                : 'filter-promotion-red'
                                                                            : 'filter-Promotion'
                                                                    }
                                                                    customIconDimension={20}
                                                                    themeIcons={cx('themeIcons')}
                                                                    setActive={
                                                                        this.state.toggleAccordianPromo ||
                                                                            this.state?.appliedFacets?.data?.promotion?.filter(
                                                                                p => p
                                                                            ).length > 0
                                                                            ? true
                                                                            : ''
                                                                    }
                                                                    onChange={value => {
                                                                        this.setState({
                                                                            toggleAccordianPromo: value
                                                                        });
                                                                    }}
                                                                >
                                                                    <ul className={cx('firstMenu', 'subMenu')}>
                                                                        <li>
                                                                            <div className={cx('formGroup')}>
                                                                                <Checkbox
                                                                                    theme={cx('theme', 'label-bold')}
                                                                                    themecheckboxCheckmark={cx(
                                                                                        'themecheckboxCheckmark'
                                                                                    )}
                                                                                    name='selectAll'
                                                                                    labelText={
                                                                                        localization[
                                                                                        PLP_L10N.PROMO_SELECT_ALL
                                                                                        ]
                                                                                    }
                                                                                    checked={this.isCheckAll()}
                                                                                    onChange={e =>
                                                                                        this.handleChange(
                                                                                            e,
                                                                                            this.promoFilters
                                                                                                .categoryCode,
                                                                                            this.promoFilters?.items.map(
                                                                                                pf => pf.name
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </li>
                                                                        {this.promoFilters.items.map((item, j) =>
                                                                            this.renderFilterOptions(
                                                                                item,
                                                                                this.promoFilters.categoryCode,
                                                                                `${this.promoFilters.type}-${j}`
                                                                            )
                                                                        )}
                                                                    </ul>
                                                                </Accordion>
                                                            )}
                                                            {/* Rendered brand. capacity, packaging filters on desktop here */}
                                                            {productList.filters &&
                                                                productList.filters.length > 0 &&
                                                                productList.filters?.map((filter, index) => {
                                                                    //  Create deep copy of the state since you can't mutate it directly
                                                                    const newFilter = _cloneDeep(filter);
                                                                    // Show only the applied facets related to product categories
                                                                    const filteredAppliedFacets = this.removeSavedFilterKeysFromAppliedFacets(appliedFacets.data);
                                                                    if (isSavedFilters && savedFilters) {
                                                                        const parsedFilters = parseSavedFilters(
                                                                            savedFilters
                                                                        );
                                                                        if (Object.keys(parsedFilters).length > 0) {
                                                                            const key = newFilter.categoryCode.toLowerCase();
                                                                            if (parsedFilters[key]) {
                                                                                parsedFilters[key].forEach(
                                                                                    parsedFilterName => {
                                                                                        if (
                                                                                            !newFilter.items.some(
                                                                                                item =>
                                                                                                    item.name.toUpperCase() ===
                                                                                                    parsedFilterName.toUpperCase()
                                                                                            )
                                                                                        ) {
                                                                                            // Add the item to the filter.items array if it doesn’t already exist
                                                                                            newFilter.items.push({
                                                                                                name: parsedFilterName,
                                                                                                code: parsedFilterName,
                                                                                                totalItem: 0,
                                                                                                imageUrl: '',
                                                                                                selected: true,
                                                                                                count: 0
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                );
                                                                            }
                                                                        }
                                                                    }
                                                                    if (Object.keys(filteredAppliedFacets).length > 0) {
                                                                        const key = newFilter.categoryCode.toLowerCase();
                                                                        if (filteredAppliedFacets[key]) {
                                                                            filteredAppliedFacets[key].forEach(facet => {
                                                                                if (
                                                                                    !newFilter.items.some(
                                                                                        item => item.name.toUpperCase() === facet.toUpperCase()
                                                                                    )
                                                                                ) {
                                                                                    // Add the item to the filter.items array if it doesn’t already exist
                                                                                    newFilter.items.push({
                                                                                        name: facet,
                                                                                        code: facet,
                                                                                        totalItem: 0,
                                                                                        imageUrl: '',
                                                                                        selected: true,
                                                                                        count: 0
                                                                                    });
                                                                                }
                                                                            });
                                                                        }
                                                                    }
                                                                    if (
                                                                        newFilter.categoryCode === PLP_FILTERS.PROMOTION
                                                                    ) {
                                                                        return null;
                                                                    }

                                                                    return (
                                                                        <Accordion
                                                                            key={index.toString()}
                                                                            title={newFilter.type}
                                                                            customTitle={cx('accordionTitle')}
                                                                            className={cx('accordionHeader')}
                                                                            iconTheme={cx('iconTheme')}
                                                                            themesetActive='setActive'
                                                                            titleIcon
                                                                            customIconName={`filter-${newFilter.categoryCode}`}
                                                                            themeIcons={cx('themeIcons')}
                                                                            setActive={this.isSetActive(newFilter)}
                                                                        >
                                                                            <ul className={cx('firstMenu', 'subMenu')}>
                                                                                {newFilter.items
                                                                                    .map((item, j) =>
                                                                                        this.renderFilterOptions(
                                                                                            item,
                                                                                            newFilter.categoryCode,
                                                                                            `${index}-${j}`
                                                                                        )
                                                                                    )
                                                                                    .slice(
                                                                                        0,
                                                                                        !this.state.seeMoreBrands &&
                                                                                            newFilter.categoryCode ===
                                                                                            PLP_FILTERS.BRAND
                                                                                            ? 5
                                                                                            : newFilter.items.length
                                                                                    )}
                                                                                {newFilter.categoryCode ===
                                                                                    PLP_FILTERS.BRAND &&
                                                                                    newFilter.items.length > 5 && (
                                                                                        <p
                                                                                            role='presentation'
                                                                                            className={cx(
                                                                                                'seeMoreBrands'
                                                                                            )}
                                                                                            onClick={() => {
                                                                                                this.setState(
                                                                                                    prevState => ({
                                                                                                        seeMoreBrands: !prevState.seeMoreBrands
                                                                                                    })
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            {this.state.seeMoreBrands
                                                                                                ? localization[
                                                                                                PLP_L10N.VIEW_LESS
                                                                                                ]
                                                                                                : localization[
                                                                                                PLP_L10N.VIEW_MORE
                                                                                                ]}
                                                                                        </p>
                                                                                    )}
                                                                            </ul>
                                                                        </Accordion>
                                                                    );
                                                                })}
                                                            {isShowProdPrice &&
                                                                typeof productList?.priceFilter?.maxOverallPrice !==
                                                                'undefined' && (
                                                                    <Accordion
                                                                        key='priceFilterComponent'
                                                                        title={localization[PLP_L10N.PRICE_FILTER_NAME]}
                                                                        customTitle={cx('accordionTitle')}
                                                                        className={cx('accordionHeader')}
                                                                        iconTheme={cx('iconTheme')}
                                                                        titleIcon
                                                                        customIconName='filter-price'
                                                                        themeIcons={cx('themeIcons')}
                                                                        setActive={
                                                                            Boolean(
                                                                                appliedFacets.data?.minPriceFilterValue
                                                                            ) &&
                                                                            Boolean(
                                                                                appliedFacets.data?.maxPriceFilterValue
                                                                            )
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={cx(
                                                                                'firstMenu',
                                                                                'subMenu',
                                                                                'rangeFilter'
                                                                            )}
                                                                        >
                                                                            <RangeInput
                                                                                min={
                                                                                    productList?.priceFilter?.[
                                                                                    `min${isPricePerPiece
                                                                                        ? 'PerPiece'
                                                                                        : 'Overall'
                                                                                    }Price`
                                                                                    ] || 0
                                                                                }
                                                                                max={
                                                                                    productList?.priceFilter?.[
                                                                                    `max${isPricePerPiece
                                                                                        ? 'PerPiece'
                                                                                        : 'Overall'
                                                                                    }Price`
                                                                                    ] ||
                                                                                    productList?.priceFilter
                                                                                        ?.maxOverallPrice ||
                                                                                    0
                                                                                }
                                                                                initialValue={[
                                                                                    +(
                                                                                        appliedFacets.data
                                                                                            ?.minPriceFilterValue?.[0] ||
                                                                                        0
                                                                                    ),
                                                                                    productList.priceFilter
                                                                                        ?.priceToFilterValue ||
                                                                                    +(
                                                                                        appliedFacets.data
                                                                                            ?.maxPriceFilterValue?.[0] ||
                                                                                        productList?.priceFilter?.[
                                                                                        `max${isPricePerPiece
                                                                                            ? 'PerPiece'
                                                                                            : 'Overall'
                                                                                        }Price`
                                                                                        ] ||
                                                                                        0
                                                                                    )
                                                                                ]}
                                                                                fromValue={
                                                                                    productList?.priceFilter?.[
                                                                                    'priceFromFilterValue'
                                                                                    ] || 0
                                                                                }
                                                                                toValue={
                                                                                    productList?.priceFilter?.[
                                                                                    'priceToFilterValue'
                                                                                    ] || 0
                                                                                }
                                                                                suffix={
                                                                                    localization[
                                                                                    PLP_L10N.CURRENCY_SYMBOL
                                                                                    ]
                                                                                }
                                                                                labelMin={
                                                                                    localization[
                                                                                    PLP_L10N.LABEL_MIN_PRICE
                                                                                    ]
                                                                                }
                                                                                labelMax={
                                                                                    localization[
                                                                                    PLP_L10N.LABEL_MAX_PRICE
                                                                                    ]
                                                                                }
                                                                                step={
                                                                                    productList?.priceFilter?.[
                                                                                    isPricePerPiece
                                                                                        ? 'pricePerPieceFilterStep'
                                                                                        : 'productPriceFilterStep'
                                                                                    ] || 0.01
                                                                                }
                                                                                onChange={v => {
                                                                                    this.setState(prevState => {
                                                                                        return {
                                                                                            filterPriceRange: v
                                                                                        };
                                                                                    });
                                                                                }}
                                                                            />
                                                                            <div className={cx('filterSwitcher')}>
                                                                                <div className={cx('filterLabel')}>
                                                                                    {
                                                                                        localization[
                                                                                        PLP_L10N.PRICE_PER_PIECE
                                                                                        ]
                                                                                    }
                                                                                </div>
                                                                                <Switcher
                                                                                    controlledValue={
                                                                                        appliedFacets.data
                                                                                            ?.isPricePerPiece?.[0] ||
                                                                                        isPricePerPiece
                                                                                    }
                                                                                    onChange={this.togglePricePerPiece}
                                                                                />
                                                                            </div>
                                                                            <p
                                                                                className={cx('seeMoreBrands')}
                                                                                onClick={this.applyPriceFilter}
                                                                            >
                                                                                {
                                                                                    localization[
                                                                                    PLP_L10N
                                                                                        .PRICE_FILTER_VIEW_RESULT
                                                                                    ]
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </Accordion>
                                                                )}
                                                        </div>
                                                    </div>
                                                    <div className={cx('rightContent')}>
                                                        <div className={cx('productsListCont')}>
                                                            {/* hide it on Mobile using styles  class */}
                                                            {/*(isShowFilter || plpListDetail) && this.renderFilterWrapper()*/}
                                                            {isShowFilter && this.renderFilterWrapper()}
                                                            {isSearch && (
                                                                <div
                                                                    id='stickyWrapper'
                                                                    className={cx(
                                                                        'stickyWrapper',
                                                                        isSearch && 'backToProdBtn'
                                                                    )}
                                                                >
                                                                    <div
                                                                        id='stickyButton'
                                                                        className={cx(
                                                                            'topBtn',
                                                                            isShowFilter && 'showFilterTop',
                                                                            isSticky && 'stickyButton'
                                                                        )}
                                                                        style={{
                                                                            top: isSticky ? `${stickyHeight}px` : ''
                                                                        }}
                                                                    >
                                                                        <div className={cx('stickyInnerClass')}>
                                                                            <span>
                                                                                {isSearch &&
                                                                                    !_isEmpty(productList.products) && (
                                                                                        <Button
                                                                                            automation-id='back-to-products'
                                                                                            className={cx(
                                                                                                'backToProductBtn'
                                                                                            )}
                                                                                            type='button'
                                                                                            size='Sm'
                                                                                            buttonType='Primary'
                                                                                            onClick={
                                                                                                this.handleBackToProduct
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                localization[
                                                                                                PLP_L10N
                                                                                                    .BACK_TO_PRODUCTS
                                                                                                ]
                                                                                            }
                                                                                        </Button>
                                                                                    )}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}

                                                            <div
                                                                automation-id='at-product-list'
                                                                className={cx(
                                                                    'productCards',
                                                                    isShowFilter && 'showFilterCards',
                                                                    plpListDetail && 'plpListCard',
                                                                    isShowFilter &&
                                                                    plpListDetail &&
                                                                    'showFilterplpListCard'
                                                                )}
                                                            >
                                                                <div
                                                                    className={cx(
                                                                        'plpDetails',
                                                                        isShowFilter
                                                                            ? 'showplpDetails'
                                                                            : 'hideplpDetails',
                                                                        plpListDetail && 'showListDetails'
                                                                    )}
                                                                    style={
                                                                        productList2.length
                                                                            ? { borderBottom: 'none' }
                                                                            : {}
                                                                    }
                                                                >
                                                                    {!_isEmpty(productList1) ? (
                                                                        <ul
                                                                            className={cx(
                                                                                isGrid ? 'plpGrid' : 'plpList'
                                                                            )}
                                                                        >
                                                                            {productList1 &&
                                                                                productList1.map((item, index) => (
                                                                                    <ProductCard
                                                                                        {...item}
                                                                                        updateFilters={
                                                                                            this.updateFilters
                                                                                        }
                                                                                        selectedView={
                                                                                            this.state.selectedView
                                                                                        }
                                                                                        forwardRef={
                                                                                            index ===
                                                                                                productList1.length -
                                                                                                1 &&
                                                                                                !productList2.length
                                                                                                ? this.lastItem
                                                                                                : null
                                                                                        }
                                                                                        currentPage={currentPage + 1}
                                                                                        stockFlag={item.stockFlag}
                                                                                        stockNotified={
                                                                                            item.stockNotified
                                                                                        }
                                                                                        key={item.sku.id}
                                                                                        name={item.name}
                                                                                        skuId={item.sku.id}
                                                                                        price={item.listPrice}
                                                                                        currency={item.currency}
                                                                                        unitOptions={item.units}
                                                                                        addToCartLabel={
                                                                                            item.addToCartLabel
                                                                                        }
                                                                                        isRecommended={
                                                                                            !!this.props
                                                                                                .bdaaSuggestion &&
                                                                                            this.props.bdaaSuggestion.findIndex(
                                                                                                ({ sku: { id } }) =>
                                                                                                    id === item.sku.id
                                                                                            ) !== -1
                                                                                        }
                                                                                        isFav={item.isFav}
                                                                                        isPromo={item.isPromoProduct}
                                                                                        promotion={item.promotion}
                                                                                        shipping={item.shippingInfo}
                                                                                        imageUrl={item.imageUrl}
                                                                                        isPlpPage
                                                                                        addToCartMessage={
                                                                                            localization[
                                                                                            GENERIC_L10N
                                                                                                .ADD_TO_CART_MESSAGE
                                                                                            ]
                                                                                        }
                                                                                        addToCartNotifyButton={
                                                                                            localization[
                                                                                            GENERIC_L10N
                                                                                                .STOCK_NOTIFICATION
                                                                                                .NOTIFY_ME
                                                                                            ]
                                                                                        }
                                                                                        addToCartNotifiedButton={
                                                                                            localization[
                                                                                            GENERIC_L10N
                                                                                                .STOCK_NOTIFICATION
                                                                                                .GET_NOTIFIED
                                                                                            ]
                                                                                        }
                                                                                        url={item.url}
                                                                                        isShowFilter={isShowFilter}
                                                                                        isShowPrice={isShowPrice}
                                                                                        isGrid={isGrid}
                                                                                        promoDescription={
                                                                                            item.promoDescription
                                                                                        }
                                                                                        description={item.description}
                                                                                        isReturnEmpties={
                                                                                            isReturnEmpties
                                                                                        }
                                                                                        isMarketingMaterials={
                                                                                            isMarketingMaterials
                                                                                        }
                                                                                        selectedMobileView={
                                                                                            this.state.appliedMobileView
                                                                                        }
                                                                                        productUrl={item.productUrl}
                                                                                        code={item.code}
                                                                                        isFrom='PLP'
                                                                                        plpType={
                                                                                            this.props.routeParams
                                                                                                .plpType
                                                                                        }
                                                                                        minQty={0}
                                                                                        maxQty={
                                                                                            this.props.stockAllocation?.products.find(
                                                                                                ({ product }) =>
                                                                                                    product.codeShort ===
                                                                                                    item.sku.id.toString()
                                                                                            )?.quantity
                                                                                        }
                                                                                        isSearch={isSearch}
                                                                                        openStockNotificationModal={e =>
                                                                                            this.openStockNotificationModal(
                                                                                                item.code
                                                                                            )
                                                                                        }
                                                                                        blockAddToCartEnabled={
                                                                                            blockAddToCartEnabled
                                                                                        }
                                                                                        depositProduct={item?.depositProduct}
                                                                                        depositProductLogoUrl={item?.depositProductLogoUrl}
                                                                                    />
                                                                                ))}
                                                                        </ul>
                                                                    ) : (
                                                                        <span
                                                                            className={cx('col12', 'sm12', 'subTitles')}
                                                                        >
                                                                            <h3
                                                                                automation-id='at-return-empties-title'
                                                                                className={cx('title', 'noProducts')}
                                                                            >
                                                                                <div
                                                                                    className={cx('noProductsFoundRow')}
                                                                                >
                                                                                    {localization[
                                                                                        PLP_L10N
                                                                                            .SAVED_FILTER_NO_PRODUCTS
                                                                                    ]
                                                                                        ?.split('<br/>')
                                                                                        .map((item, index) => {
                                                                                            if (index === 0) {
                                                                                                return (
                                                                                                    <span
                                                                                                        className={cx(
                                                                                                            'noProductsFoundInnerRow'
                                                                                                        )}
                                                                                                        key={item}
                                                                                                    >
                                                                                                        <Icon
                                                                                                            iconType='svg'
                                                                                                            width='24'
                                                                                                            height='24'
                                                                                                            viewBox='0 0 24 24'
                                                                                                            name='no-results'
                                                                                                            className={cx(
                                                                                                                'flex-icon'
                                                                                                            )}
                                                                                                        />
                                                                                                        {item}
                                                                                                    </span>
                                                                                                );
                                                                                            }
                                                                                            return <p>{item}</p>;
                                                                                        })}
                                                                                </div>
                                                                            </h3>
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                {this.isBdaaActive &&
                                                                    !this.state.appliedFacets?.data?.[
                                                                    PLP_FILTERS.RECOMMENDATIONS
                                                                    ] &&
                                                                    !this.state.appliedFacets?.data?.[
                                                                    PLP_FILTERS.FAVORITE
                                                                    ] &&
                                                                    bdaaProductsCount > 0 &&
                                                                    !window.location.pathname.includes('/plp/return') &&
                                                                    !window.location.pathname.includes('/plp/zpop') && (
                                                                        <div
                                                                            className={cx(
                                                                                'plpDetails',
                                                                                'smartRecommendationsContainer',
                                                                                isShowFilter
                                                                                    ? 'showplpDetails'
                                                                                    : 'hideplpDetails',
                                                                                plpListDetail && 'showListDetails'
                                                                            )}
                                                                        >
                                                                            <div className={cx('plpBdaaTitleWrap')}>
                                                                                <div>
                                                                                    {
                                                                                        localization[
                                                                                        PLP_L10N
                                                                                            .BDAA_SMART_RECOMMENDATIONS
                                                                                        ]
                                                                                    }
                                                                                </div>
                                                                                <div
                                                                                    onClick={() => {
                                                                                        this.updateFilters(
                                                                                            'bdaa',
                                                                                            'true'
                                                                                        );
                                                                                        window.scrollTo({
                                                                                            top: 0,
                                                                                            behavior: 'smooth'
                                                                                        });
                                                                                    }}
                                                                                >
                                                                                    {this.props.deviceType.isDesktop
                                                                                        ? localization[
                                                                                        PLP_L10N
                                                                                            .BDAA_VIEW_ALL_PRODUCTS
                                                                                        ]
                                                                                        : localization[
                                                                                        PLP_L10N.BDAA_VIEW_ALL
                                                                                        ]}
                                                                                </div>
                                                                            </div>
                                                                            <Slider {...settings}>
                                                                                {this.props.bdaaSuggestion.map(item => (
                                                                                    <ProductCard
                                                                                        {...item}
                                                                                        updateFilters={
                                                                                            this.updateFilters
                                                                                        }
                                                                                        isRecommended={
                                                                                            !!this.props
                                                                                                .bdaaSuggestion &&
                                                                                            this.props.bdaaSuggestion.findIndex(
                                                                                                ({ sku: { id } }) =>
                                                                                                    id === item.sku.id
                                                                                            ) !== -1
                                                                                        }
                                                                                        isPromo={item.isPromoProduct}
                                                                                        price={item.listPrice}
                                                                                        unitOptions={item.units}
                                                                                        currentPage={currentPage + 1}
                                                                                        key={item.sku.id}
                                                                                        skuId={item.sku.id}
                                                                                        shipping={item.shippingInfo}
                                                                                        isPlpPage
                                                                                        addToCartMessage={
                                                                                            localization[
                                                                                            GENERIC_L10N
                                                                                                .ADD_TO_CART_MESSAGE
                                                                                            ]
                                                                                        }
                                                                                        isShowFilter={isShowFilter}
                                                                                        isShowPrice={isShowPrice}
                                                                                        isSelectViews={
                                                                                            this.state.selectedView
                                                                                        }
                                                                                        isReturnEmpties={
                                                                                            isReturnEmpties
                                                                                        }
                                                                                        isMarketingMaterials={
                                                                                            isMarketingMaterials
                                                                                        }
                                                                                        selectedMobileView={
                                                                                            this.state.appliedMobileView
                                                                                        }
                                                                                        isFrom='PLP'
                                                                                        plpType={
                                                                                            this.props.routeParams
                                                                                                .plpType
                                                                                        }
                                                                                        addToCartNotifyButton={
                                                                                            localization[
                                                                                            GENERIC_L10N
                                                                                                .STOCK_NOTIFICATION
                                                                                                .NOTIFY_ME
                                                                                            ]
                                                                                        }
                                                                                        addToCartNotifiedButton={
                                                                                            localization[
                                                                                            GENERIC_L10N
                                                                                                .STOCK_NOTIFICATION
                                                                                                .GET_NOTIFIED
                                                                                            ]
                                                                                        }
                                                                                        openStockNotificationModal={e =>
                                                                                            this.openStockNotificationModal(
                                                                                                item.code
                                                                                            )
                                                                                        }
                                                                                        minQty={0}
                                                                                        maxQty={
                                                                                            this.props.stockAllocation?.products.find(
                                                                                                ({ product }) =>
                                                                                                    product.codeShort ===
                                                                                                    item.sku.id.toString()
                                                                                            )?.quantity
                                                                                        }
                                                                                        isInBdaaSlider
                                                                                        blockAddToCartEnabled={
                                                                                            blockAddToCartEnabled
                                                                                        }
                                                                                    />
                                                                                ))}
                                                                            </Slider>
                                                                        </div>
                                                                    )}
                                                                <div
                                                                    className={cx(
                                                                        'plpDetails',
                                                                        isShowFilter
                                                                            ? 'showplpDetails'
                                                                            : 'hideplpDetails',
                                                                        plpListDetail && 'showListDetails'
                                                                    )}
                                                                >
                                                                    {!_isEmpty(productList2) && (
                                                                        <ul
                                                                            className={cx(
                                                                                isGrid ? 'plpGrid' : 'plpList'
                                                                            )}
                                                                        >
                                                                            {productList2 &&
                                                                                productList2.map((item, index) => (
                                                                                    <ProductCard
                                                                                        {...item}
                                                                                        updateFilters={
                                                                                            this.updateFilters
                                                                                        }
                                                                                        selectedView={
                                                                                            this.state.selectedView
                                                                                        }
                                                                                        forwardRef={
                                                                                            index ===
                                                                                                productList2.length - 1
                                                                                                ? this.lastItem
                                                                                                : null
                                                                                        }
                                                                                        isRecommended={
                                                                                            !!this.props
                                                                                                .bdaaSuggestion &&
                                                                                            this.props.bdaaSuggestion.findIndex(
                                                                                                ({ sku: { id } }) =>
                                                                                                    id === item.sku.id
                                                                                            ) !== -1
                                                                                        }
                                                                                        isPromo={item.isPromoProduct}
                                                                                        price={item.listPrice}
                                                                                        unitOptions={item.units}
                                                                                        currentPage={currentPage + 1}
                                                                                        key={item.sku.id}
                                                                                        skuId={item.sku.id}
                                                                                        shipping={item.shippingInfo}
                                                                                        isPlpPage
                                                                                        addToCartMessage={
                                                                                            localization[
                                                                                            GENERIC_L10N
                                                                                                .ADD_TO_CART_MESSAGE
                                                                                            ]
                                                                                        }
                                                                                        isShowFilter={isShowFilter}
                                                                                        isShowPrice={isShowPrice}
                                                                                        isGrid={isGrid}
                                                                                        isReturnEmpties={
                                                                                            isReturnEmpties
                                                                                        }
                                                                                        isMarketingMaterials={
                                                                                            isMarketingMaterials
                                                                                        }
                                                                                        selectedMobileView={
                                                                                            this.state.appliedMobileView
                                                                                        }
                                                                                        isFrom='PLP'
                                                                                        plpType={
                                                                                            this.props.routeParams
                                                                                                .plpType
                                                                                        }
                                                                                        addToCartNotifyButton={
                                                                                            localization[
                                                                                            GENERIC_L10N
                                                                                                .STOCK_NOTIFICATION
                                                                                                .NOTIFY_ME
                                                                                            ]
                                                                                        }
                                                                                        addToCartNotifiedButton={
                                                                                            localization[
                                                                                            GENERIC_L10N
                                                                                                .STOCK_NOTIFICATION
                                                                                                .GET_NOTIFIED
                                                                                            ]
                                                                                        }
                                                                                        openStockNotificationModal={e =>
                                                                                            this.openStockNotificationModal(
                                                                                                item.code
                                                                                            )
                                                                                        }
                                                                                        minQty={0}
                                                                                        maxQty={
                                                                                            this.props.stockAllocation?.products.find(
                                                                                                ({ product }) =>
                                                                                                    product.codeShort ===
                                                                                                    item.sku.id.toString()
                                                                                            )?.quantity
                                                                                        }
                                                                                        blockAddToCartEnabled={
                                                                                            blockAddToCartEnabled
                                                                                        }
                                                                                        depositProduct={item?.depositProduct}
                                                                                        depositProductLogoUrl={item?.depositProductLogoUrl}
                                                                                    />
                                                                                ))}
                                                                        </ul>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </>
                                    )}
                                    {isSearch && _isEmpty(productList.products) && this.renderNoSearchResultsPage()}
                                </>
                            )
                        )}
                    </div>
                )}
            </Layout>
        );
    }
}

function CustomNextArrow(props) {
    const { className, style, onClick, hideComponent } = props;
    const deviceClass =
        getDevice() == DEVICE_TYPES.MOBILE
            ? 'mobileArrowNext'
            : getDevice() == DEVICE_TYPES.TABLET
                ? 'tabletArrow'
                : 'desctopArrow';

    return (
        <div
            className={cx(className, 'arrow', 'nextArrow', className.includes('disabled') ? 'disabled' : '')}
            style={{ ...style, ...(hideComponent ? { display: 'none' } : {}) }}
            onClick={onClick}
        >
            <Icon width='32px' height='32px' viewBox='0 0 24 24' name='chevron-right' />
        </div>
    );
}

function CustomPrevArrow(props) {
    const { className, style, onClick, hideComponent } = props;
    const deviceClass =
        getDevice() == DEVICE_TYPES.MOBILE
            ? 'mobileArrowPrev'
            : getDevice() == DEVICE_TYPES.TABLET
                ? 'tabletArrow'
                : 'desctopArrow';

    return (
        <div
            className={cx(className, 'arrow', 'prevArrow', className.includes('disabled') ? 'disabled' : '')}
            style={{ ...style, ...(hideComponent ? { display: 'none' } : {}) }}
            onClick={onClick}
        >
            <Icon width='32px' height='32px' viewBox='0 0 24 24' name='chevron-left' />
        </div>
    );
}

ProductList.propTypes = {
    productList: PropTypes.objectOf(PropTypes.any),
    deviceType: PropTypes.objectOf(PropTypes.bool),
    localization: PropTypes.objectOf(PropTypes.string),
    isIndirectUser: PropTypes.bool,
    commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    isPickUpAllowed: PropTypes.bool,
    notification: PropTypes.objectOf(PropTypes.any)
};

ProductList.defaultProps = {
    productList: {},
    deviceType: {},
    localization: {},
    isIndirectUser: false,
    commonLocalization: {},
    isPickUpAllowed: false,
    notification: {}
};

const mapStateToProps = state => {
    return {
        orderType: _get(state, 'cartDetails.orderType'),
        productList: _get(state, 'products'),
        deviceType: _get(state, 'context.deviceType'),
        localization: _get(state, 'pageContent.localization'),
        isIndirectUser: _get(state, 'loginPage.isIndirectUser'),
        simpleBanner: _get(state, 'products.plpBannerData', {}),
        commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`),
        isPickUpAllowed: _get(state, 'loginPage.pickUpAllowed'),
        notification: _get(state, 'notification'),
        marketing: _get(state, 'marketing'),
        components: _get(state, 'pageContent.components'),
        isChatPluginLoaded: _get(state, 'pageContent.isChatPluginLoaded'),
        bdaaSuggestion: _get(state, 'products.bdaaSuggestion'),
        stockAllocation: _get(state, 'pageContent.stockAllocation'),
        viewChoice: _get(state, 'products.viewChoice'),
        customerHasSavedFilter: _get(state, 'products.customerHasSavedFilter'),
        isSavedFilterDefault: _get(state, 'products.isSavedFilterDefault'),
        savedFilters: _get(state, 'products.savedFilters')
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getProductList,
            getPageContent,
            setPageTitlePrefix,
            getMarketingConsent,
            hideMarketingConsent,
            resetProductList,
            updateProductsSort,
            getBdaaSuggestion,
            clearMiniCart,
            changeOrderType,
            restoreCart,
            openSubscriptionPopup,
            setPLPViewChoiceFunc
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
