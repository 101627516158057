/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';
import Slider from "react-slick";

import Datepicker from '../../../components/core/Datepicker/Datepicker';
import Tabs from '../../../components/core/Tabs/Tabs';
import Icon from '../../../components/core/Icon/Icon';
import BreadCrumbs from '../../../components/core/BreadCrumbs/BreadCrumbs';
import Button from '../../../components/core/Button/Button';
import TabPanel from '../../../components/core/Tabs/TabPanel';
import CartItemDetails from '../../../components/site/CartItemDetails/CartItemDetails';
import CartSummary from '../../../components/site/CartSummary/CartSummary';
import AddToOrderTemplate from '../../../components/site/AddToOrderTemplate/AddToOrderTemplate';
import Layout from '../../../components/site/Layout/Layout';
import { updateDeliveryDate, clearAll, getMultiCart } from '../../../actions/CartAction';
import { getPageContent } from '../../../actions/PageContentAction';
import { showOrderNotifications, notifySuccess, notifyBlueMessage, notifyWarning, notifyError, showMultipleNotifications } from '../../../actions/NotificationAction';
import { getBdaaSuggestion } from '../../../actions/ProductListAction';
import WholesalerReports from '../../../components/site/WholesalerReports/WholesalerReports';
import ClearAllCartsModal from '../../../components/site/common/ClearAllCartsModal/ClearAllCartsModal';

import {
    getActiveCartId,
    isIndirectUser,
    isReturnEmpties,
    isUseMarketingMaterials,
    isOrderSplitIndirect,
    isBdaaActive,
    mainPartnerId,
    mainPartnerCode,
    getSelfiRedCartMessage,
    getPromoDisclaimerMessage,
    setPromoDisclaimerMessage,
    setSelfiRedCartMessage,
    getLoyaltyCartMessage,
    setLoyaltyCartMessage,
    getMiniCartWorkingWay,
    getUserInfo,
    removingOosProductsEnabled
} from '../../../common/UserManager/UserManager';
import { getSysDate, redirectTo } from '../../../common/Utils/Utils';

import ProductCard from '../../../components/site/ProductCards/ProductCards';

import {
    PAGE_ID,
    URL_PATHS,
    CART_L10N,
    HOME_L10N,
    DELIVERY_TYPES,
    GENERIC_L10N,
    DATA_LAYER_LISTS,
    GA_CHECKOUT_STEPS,
    MINI_CART_WORKING_WAYS,
    UNIQUE_ID, COMMON_COMPONENT, MESSAGE_TYPES, MESSAGE_LEVELS
} from '../../../common/Constants';
import * as styles from './Cart.css';
import { getDevice, DEVICE_TYPES } from '../../../../__runtime/redux/ContextLoader';
import { viewItemListEventGA4, checkoutEventGA4, buttonClickEventConfigs, buttonClickEvent } from '../../../common/GoogleTagManager/GoogleTagManager';
import { productImpressionsBdaaDataLayerFilterGA4, cartProductsCheckoutDataLayerFilterGA4 } from '../../../service/DataLayerFilter';
import { SessionStorage } from '../../../common/Storage/Storage';
import StockAllocation from '../../../components/site/StockAllocation/StockAllocation';
import RemoveOOSModal from './RemoveOOSModal';
import {closeCreditLimitExceededPopup, showCreditLimitExceededPopup} from "../../../actions/CreditInfoAction";
import {CreditLimitExceededPopup} from "../../../components/site/CreditLimit/CreditLimitExceededPopup/CreditLimitExceededPopup";
import _find from "lodash/find";
import SmartEdit from "../../../components/site/SmartEdit/SmartEdit";
import parse from "html-react-parser";
import MessageBox from '../../../components/core/MessageBox/MessageBox';

const cx = classNames.bind(styles);

export class Cart extends Component {
    static propTypes = {
        localization: PropTypes.objectOf(PropTypes.any),
        commonLocalization: PropTypes.objectOf(PropTypes.any),
        cartDetails: PropTypes.objectOf(PropTypes.any),
        shoppingCartClass: PropTypes.bool,
        deliveryType: PropTypes.string,
        isZADOrderType: PropTypes.bool
    };

    static defaultProps = {
        localization: {},
        commonLocalization: {},
        cartDetails: {},
        shoppingCartClass: true,
        deliveryType: '',
        isZADOrderType: false
    };

    constructor(props) {
        super(props);
        this.state = {
            isCalender: false,
            mobileCartSummary: false,
            activeCart: 0,
            showPricingMessage: '',
            isInitialLoad: true,
            filteredBdaa: [],
            isClearAllCartsModal: false,
        };
        this.activeCartId = getActiveCartId();
        this.isReturnEmpty = isReturnEmpties();
        this.isMarketingMaterials = isUseMarketingMaterials();
        this.isBdaaActive = isBdaaActive();

        this.mainPartnerId = mainPartnerId();
        this.mainPartnerCode = mainPartnerCode();

        this.inCartItems = [];
        this.bdaaFiltered = false;
        this.selfiRedDiscountAddedtMessage = getSelfiRedCartMessage();
        this.promoDisclaimerMessage = getPromoDisclaimerMessage();
        this.selfiRedDiscountAddedMessage = getSelfiRedCartMessage();
        this.showStockAllocationModal = SessionStorage.getData(CART_L10N.SHOW_STOCK_ALLOCATION_MODAL, true)
        props.actions.getPageContent({ pageId: PAGE_ID.CART });

        if (this.isBdaaActive) {
            this.props.actions.getBdaaSuggestion({ data: { countryCode: this.mainPartnerCode, customerId: this.mainPartnerId } });
        }

    }

    componentDidMount() {
        const { actions } = this.props;

        const data = {};
        if (!_isEmpty(this.props.cartDetails.validationData)) {
            data.validationData = this.props.cartDetails.validationData;
        }

        if (this.selfiRedDiscountAddedtMessage) {
            data.selfiRedDiscountAddedtMessage = this.selfiRedDiscountAddedtMessage;
            setSelfiRedCartMessage();
        }

        if (this.promoDisclaimerMessage) {
            data.promoDisclaimerMessage = this.promoDisclaimerMessage;
            setPromoDisclaimerMessage();
        }

        if (this.props.cartDetails.loyaltyDiscountAddedMessage) {
            data.loyaltyDiscountAddedMessage = this.props.cartDetails.loyaltyDiscountAddedMessage;
        }
        
        if (this.props.cartDetails.selfREDAppliedDiscountMessage) {
            data.selfREDAppliedDiscountMessage = this.props.cartDetails.selfREDAppliedDiscountMessage;
        }

        if (this.props.cartDetails.productValidationMessage) {
            data.productValidationMessage = this.props.cartDetails.productValidationMessage;
        }

        Object.keys(data).length > 0 && actions.showOrderNotifications(data);
    }

    componentDidUpdate(prevProps) {
        if (!_isEmpty(this.props.cartDetails.showPricingMessage) && this.state.isInitialLoad) {
            this.setState({
                showPricingMessage: this.props.cartDetails.showPricingMessage,
                isInitialLoad: false
            });
        }

        if (!_isEmpty(this.props.cartDetails) && this.props.cartDetails.cartList && (prevProps.cartDetails !== this.props.cartDetails || !this.bdaaFiltered) && !!this.props.bdaaSuggestion) {
            this.filterBdaaProducts();
        }

        if (prevProps.cartItemsCount !== undefined && prevProps.cartItemsCount !== this.props.cartItemsCount && _isEqual(prevProps.cartDetails, this.props.cartDetails)) {
            if (!SessionStorage.getData(CART_L10N.IS_SKIP_LOAD_CART_DATA, true)) {
                this.props.actions.getMultiCart({ pageId: 'cartpage' });
            }
        }


        if (!!prevProps.bdaaSuggestion && prevProps.bdaaSuggestion !== this.props.bdaaSuggestion) {
            this.filterBdaaProducts();
        }

    }

    componentWillUnmount() {
        setLoyaltyCartMessage();
    }

    onCalenderEvent = () => {
        try {
            if (!this.state.isCalender) {
                buttonClickEvent(buttonClickEventConfigs.cart.actions.view, buttonClickEventConfigs.cart.labels.cartViewChangeDeliveryDate, buttonClickEventConfigs.cart.categories.cart);
            }
        } catch (ex) {
            console.error(ex);
        }

        this.setState({
            isCalender: true
        });
    };

    onCloseClick = () => {
        this.setState({
            isCalender: false
        });
    };

    onDateClick = date => {
        this.props.actions.updateDeliveryDate({
            data: {
                deliveryDate: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
                basketId: _get(this.props.cartDetails, `cartList[${this.state.activeCart}].cartName`, '')
            }
        });
        this.setState({
            isCalender: false
        });
    };

    getStartDate = () => {

        const availableDates = _get(this.props.cartDetails, 'availableDeliveryDates', '').split(','); //HYB-462 fix
        const defaultDate = !_isEmpty(availableDates) ? availableDates[0] : ''; //HYB-462 fix

        return getSysDate(defaultDate);
    };

    getSelectedDate = () => {
        const activeCartDetails = _get(this.props.cartDetails, `cartList[${this.state.activeCart}].cartDetails`, {});
        const selectedDate = activeCartDetails.updatedDeliveryDate || activeCartDetails.deliveryDate;
        return getSysDate(selectedDate);
    };

    enableDates = () => {
        const availableDates = _get(this.props.cartDetails, 'availableDeliveryDates', '').split(',');
        return availableDates.map(getSysDate);
    };

    toggleAccordionView = () => {
        const { mobileCartSummary } = this.state;
        this.setState({
            mobileCartSummary: !mobileCartSummary
        });
    };

    handleCheckoutAnalyticsEvent = () => {
        try {
            buttonClickEvent(buttonClickEventConfigs.cart.actions.view, buttonClickEventConfigs.cart.labels.cartViewContinueToCheckout, buttonClickEventConfigs.cart.categories.cart);
        } catch (ex) {
            console.error(ex);
        }

        let productsInCart = [];

        try {
            this.props.cartDetails.cartList.forEach((cartInfo) => {
                productsInCart = [...productsInCart, ...cartInfo.cartDetails.entries.orderEntries]
            });

            checkoutEventGA4(_cloneDeep(productsInCart).map((product, index) => cartProductsCheckoutDataLayerFilterGA4(index, product)));
        } catch (ex) {
            console.error(ex);
        }
    }

    checkCreditLimit = (cartList) => {
        const cartWithNotification = cartList.find(entry => {
            const details = entry.cartDetails;
            if (!details) return false;
            const { creditLimitNotification } = details;
            return !!creditLimitNotification;
        });
        if (!cartWithNotification) return true;
        const alreadyRead = !!this.props.creditLimitNotificationRead;
        const {creditLimitNotification} = cartWithNotification.cartDetails;
        if (!creditLimitNotification.blockOrder && alreadyRead) return true;
        const {notificationCheckoutMessage, messageHeader} = creditLimitNotification;
        this.props.actions.showCreditLimitExceededPopup({
            message: notificationCheckoutMessage,
            header: messageHeader
        });
        return false;
    }

    handleContinueToCheckout = (carts) => {
        const cartList = carts.cartList;
        if (!cartList) return;

        if (!this.checkCreditLimit(cartList)) return;

        this.handleCheckoutAnalyticsEvent();

        if (this.isReturnEmpty || this.isMarketingMaterials) {
            redirectTo(URL_PATHS.CHECKOUT);
        } else {
            this.props.actions.getPageContent({ pageId: PAGE_ID.PROMOTIONS });
        }
    };

    onClearAllCarts = () => {
        this.setState({
            isClearAllCartsModal: true
        });
    }


    onClearAll = () => {
        this.props.actions.clearAll({ data: { list: DATA_LAYER_LISTS.CART_LIST, origin_element: buttonClickEventConfigs.cart.labels.cartViewClearAllCarts } });
        this.closeModal();
    };

    closeModal = () => {
        this.setState({
            isClearAllCartsModal: false,
        });
    };

    onTabChange = index => {
        this.setState({
            activeCart: index
        });
        this.onCloseClick();
    };

    returnToShop = () => {
        redirectTo(URL_PATHS.PLP_ORDER);
    };

    filterBdaaProducts = () => {

        if (this.props.cartDetails.cartList === undefined) {
            this.setState({
                filteredBdaa: []
            });
            this.inCartItems = [];

            return;
        }

        const currInCartItems = [];
        this.props.cartDetails.cartList.forEach(x => {
            const productDetails = _get(x.cartDetails, 'entries.orderEntries', {});
            productDetails.forEach(y => {
                currInCartItems.push(y.product.code);
            });
        });

        if (!_isEqual(this.inCartItems, currInCartItems)) {
            this.setState({
                filteredBdaa: this.props.bdaaSuggestion.filter(x => !currInCartItems.includes(x.code))
            }, () => {
                if (this.state.filteredBdaa && !!this.state.filteredBdaa.length) {
                    try {
                        viewItemListEventGA4(productImpressionsBdaaDataLayerFilterGA4(_cloneDeep(this.state.filteredBdaa)));
                    } catch (ex) {
                        console.error(ex);
                    }
                }
            });

            this.inCartItems = currInCartItems;
        }

        this.bdaaFiltered = true;

    }

    renderStockAllocation = () => {
        const {
            localization,
            commonLocalization,

        } = this.props;
        return (
            <StockAllocation isFrom={PAGE_ID.CART} title={localization?.[CART_L10N.STOCK_ALLOCATION_TITLE]} subTitle={localization?.[CART_L10N.STOCK_ALLOCATION_SUBTITLE]} />
        )
    };

    render() {

        //const inCartProducts = cartDetails.cartList
        const userInfo = getUserInfo();
        const settings = {
            className: "center",
            centerMode: false,
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            autoplay: false,
            adaptiveHeight: false,
            autoplaySpeed: 5000,
            pauseOnHover: true,
            nextArrow: <CustomNextArrow />,
            prevArrow: <CustomPrevArrow />,
            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        nextArrow: <CustomNextArrow />,
                        prevArrow: <CustomPrevArrow />,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        nextArrow: <CustomNextArrow />,
                        prevArrow: <CustomPrevArrow />,
                    }
                }
            ]
        };


        const {
            localization,
            commonLocalization,
            shoppingCartClass,
            cartDetails = {},
            deliveryType,
            isZADOrderType,
            stockAllocation,
            bdaaSuggestion,
            components
        } = this.props;

        //const bdaaSuggestion = this.filteredBdaa;
        const { isCalender, mobileCartSummary, activeCart, filteredBdaa } = this.state;
        const isCartEmpty = !(getActiveCartId() && cartDetails.totalCartCount);
        const isIndirectCustomer = isIndirectUser();
        const orderSplitIndirect = isOrderSplitIndirect();
        let calendarTitle = '';
        const calendarMessage = isIndirectCustomer
            ? localization[CART_L10N.WHOLESALER_DATE_MSG]
            : localization[CART_L10N.DATE_PICK_MSG];
        const isSkipMiniCart = getMiniCartWorkingWay() === MINI_CART_WORKING_WAYS.SKIPMINICART;
        const removingOosProductsisEnabled = removingOosProductsEnabled()

        if (!_isEmpty(localization)) {
            if (deliveryType === DELIVERY_TYPES.ORDER_DRINKS || isIndirectCustomer) {
                calendarTitle = localization[CART_L10N.DELIVERY_DATE];
            } else {
                calendarTitle = localization[CART_L10N.PICKUP_DATE];
            }
        }

        if (!isCartEmpty && activeCart > cartDetails.cartList.length - 1) this[`cartTab-0`].current.props.onSelect(0);

        const cartWarningComponent = _find(components, [UNIQUE_ID, COMMON_COMPONENT.CART_WARNING_COMPONENT]) || { };

        const renderCheckoutBtn = theme => (
            <Button
                type='button'
                buttonType='Primary'
                className={theme}
                automationId='at-continue-button'
                title='checkoutBtn'
                isDisabled={isCartEmpty}
                onClick={() => this.handleContinueToCheckout(cartDetails)}
            >
                {localization[CART_L10N.CONT_TO_CHECKOUT]}
            </Button>
        );

        const activeCartDetails = _get(cartDetails, `cartList[${activeCart}].cartDetails`, {});

        return (
            <Layout showDashboardHeader showOutlet>
                <CreditLimitExceededPopup onClose={this.props.actions.closeCreditLimitExceededPopup} />
                {!_isEmpty(localization) && (
                    <>
                        <BreadCrumbs breadcrumbValues={cartDetails.breadcrumbs} />
                        <div className={cx('addtoCartPageContainer')}>
                            <div className={cx('col8','sm12', 'md12', 'pad0', isCartEmpty && 'emptyPage', !activeCartDetails?.subTotal && 'col12')}>
                                <h1 className={cx('title', 'col6', 'sm12')}>{localization[CART_L10N.CONTENT_TITLE]}</h1>
                                <div className={cx('titleButtons', 'col6', 'sm12')}>
                                    {renderCheckoutBtn(cx( activeCartDetails?.subTotal && 'buttonHiddenLarge', !activeCartDetails?.subTotal && 'half','continueBtn', 'col3', 'sm12'))}  
                                    <Button
                                        type='button'
                                        // buttonType='Ordinary'
                                        className={cx('clearcartBtn', 'col3', 'sm12')}
                                        automationId='at-clear-button'
                                        title='clearCartBtn'
                                        isDisabled={isCartEmpty}
                                        onClick={this.onClearAllCarts}
                                    >
                                        {localization[CART_L10N.CLEAR_ALL_CART]}
                                    </Button>
                                </div>
                            </div>

                            <SmartEdit data={cartWarningComponent}>
                                {cartWarningComponent && cartWarningComponent.content && (
                                    <MessageBox
                                        type={MESSAGE_TYPES.CART_INFO}
                                        level={MESSAGE_LEVELS.SECTION}
                                        closeBtn={false}
                                        infoIcon
                                        iconStyle='cartWarningIcon'
                                        customMsgContent='cartWarningTextContainer'
                                        theme={cx('col8', 'sm12', 'md12')}
                                    >
                                        {parse(cartWarningComponent.content)}
                                    </MessageBox>
                                )}
                            </SmartEdit>

                            <div className={cx('col5', 'sm12', 'deliveryOptionWrapper', 'wholeSalerContainer')}>
                                {isSkipMiniCart &&
                                    isIndirectCustomer &&
                                    !isZADOrderType &&
                                    !this.isMarketingMaterials && <WholesalerReports />}
                            </div>

                            {!isCartEmpty ? (
                                <Tabs
                                    activeTabPanelIndex={0}
                                    themeTabContainerClass={cx('themeTabContainerClass', 'md12' , activeCartDetails?.subTotal && 'col8')}
                                    automationId='cart-tabs'
                                    customTabPanelContentClass={cx('contentWrapper')}
                                    onTabChange={this.onTabChange}
                                >
                                    {(cartDetails.cartList || []).map((cartInfo, index) => {
                                        if (cartInfo.cartItemCount > 0) {
                                            const cartTitle =
                                                (orderSplitIndirect || !isIndirectCustomer) &&
                                                !this.isReturnEmpty &&
                                                cartInfo.cartTranslatedName
                                                    ? `${cartInfo.cartTranslatedName} (${cartInfo.cartItemCount})`
                                                    : '';
                                            const activeCartDetails = cartInfo.cartDetails || {};
                                            const deliveryDate =
                                                activeCartDetails.updatedDeliveryDate || activeCartDetails.deliveryDate;
                                            const cutOffDate =
                                                activeCartDetails.updatedCutoffDateTime ||
                                                activeCartDetails.defaultCutOffDateTime;
                                            const isShowIndirectDate = activeCartDetails.isIndirectZorSplit;

                                            // Passing payload on Add to Template modal.
                                            const productDetails = _get(activeCartDetails, 'entries', {});
                                            const basicProductDetails = (productDetails.orderEntries || []).map(
                                                orderEntry => ({
                                                    productCode: _get(orderEntry, 'product.code'),
                                                    unitCode: orderEntry.qtyTypeName || 'CS',
                                                    quantity: orderEntry.quantity
                                                })
                                            );
                                            let deliveryInfo = '';
                                            if (isIndirectCustomer && !isShowIndirectDate) {
                                                if (
                                                    ((isZADOrderType &&
                                                        (cartDetails.deliverDateInfo || '').indexOf(cutOffDate) !==
                                                            -1) ||
                                                        this.isMarketingMaterials) &&
                                                    cartDetails.showCutOffDate &&
                                                    cutOffDate
                                                ) {
                                                    deliveryInfo = `${
                                                        localization[CART_L10N.ORDER_BEFORE_TXT] || ''
                                                    } ${cutOffDate}`;
                                                } else {
                                                    deliveryInfo = calendarMessage;
                                                }
                                            } else if (cartDetails.showCutOffDate && cutOffDate) {
                                                deliveryInfo = `${
                                                    localization[CART_L10N.ORDER_BEFORE_TXT] || ''
                                                } ${cutOffDate}`;
                                            }

                                            const ref = `cartTab-${index}`;
                                            if (!this[ref]) {
                                                this[ref] = React.createRef();
                                            }

                                            return (
                                                <TabPanel
                                                    key={cartInfo.cartId}
                                                    title={cartTitle}
                                                    index={index}
                                                    ref={this[ref]}
                                                >
                                                    <div>
                                                        <div className={cx('listItems')}>
                                                            <div className={cx('topCalender')}>
                                                                <div className={cx('sm12', 'col8')}>
                                                                    {cartDetails.showRddCalender && (
                                                                        <button
                                                                            onClick={this.onCalenderEvent}
                                                                            type='button'
                                                                            id='calender-close'
                                                                            className={cx('closebtn')}
                                                                            aria-label='close'
                                                                        >
                                                                            <Icon
                                                                                className={cx('calender')}
                                                                                iconType='svg'
                                                                                width='24px'
                                                                                height='24px'
                                                                                viewBox='0 0 24 24'
                                                                                name='calender'
                                                                            />
                                                                            <Icon
                                                                                className={cx('cal-down')}
                                                                                iconType='svg'
                                                                                width='24px'
                                                                                height='24px'
                                                                                viewBox='0 0 24 24'
                                                                                name='caln-down'
                                                                            />
                                                                        </button>
                                                                    )}
                                                                    <p className={cx('deliveryDate')}>
                                                                        {' '}
                                                                        {deliveryDate && (
                                                                            <span className={cx('innerDate')}>
                                                                                {calendarTitle}{' '}
                                                                                <strong>{deliveryDate}</strong>
                                                                            </span>
                                                                        )}
                                                                        <span className={cx('innerDate')}>
                                                                            {deliveryInfo}
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                {cartDetails.showRddCalender && isCalender && (
                                                                    <div className={cx('datePickerInline')}>
                                                                        <Datepicker
                                                                            includeDates={this.enableDates()}
                                                                            calendarTitle={calendarTitle}
                                                                            calendarNote={
                                                                                localization[CART_L10N.PLZ_NOTE_MSG]
                                                                            }
                                                                            calendarMessage={calendarMessage}
                                                                            customCalWrapper={cx('customCalWrapper')}
                                                                            inline
                                                                            footerText
                                                                            onChange={this.onDateClick}
                                                                            minDate={this.getStartDate()}
                                                                            selectedDate={this.getSelectedDate()}
                                                                            dateFormat='DD/MM/YYYY'
                                                                            onCloseClick={this.onCloseClick}
                                                                        />
                                                                    </div>
                                                                )}
                                                                {removingOosProductsisEnabled && <RemoveOOSModal />}
                                                                {!this.isReturnEmpty && !this.isMarketingMaterials && (
                                                                    <div className={cx('addBtnContent')}>
                                                                        <div
                                                                            className={cx(
                                                                                'marr24',
                                                                                'float-left',
                                                                                'addAllBtn'
                                                                            )}
                                                                        >
                                                                            <AddToOrderTemplate
                                                                                showTemplate={false}
                                                                                automationId='at-add-item-to-order-template-icon'
                                                                                basicProductDetails={
                                                                                    basicProductDetails
                                                                                }
                                                                                addToTempBtn={
                                                                                    localization[
                                                                                        CART_L10N.ADD_TO_TEMPLATE
                                                                                    ]
                                                                                }
                                                                                modalTitle={
                                                                                    commonLocalization[
                                                                                        GENERIC_L10N.TEMP_OP_TITLE
                                                                                    ]
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <CartItemDetails
                                                                pageLevelClass={shoppingCartClass}
                                                                isFrom={DATA_LAYER_LISTS.CART_LIST}
                                                                cartInfo={activeCartDetails}
                                                                localization={localization}
                                                                commonLocalization={commonLocalization}
                                                                stockAllocation={stockAllocation}
                                                                hideATP={
                                                                    isIndirectCustomer &&
                                                                    orderSplitIndirect &&
                                                                    !this.isMarketingMaterials &&
                                                                    !activeCartDetails.isZAvailabilityActive
                                                                        ? !activeCartDetails.isIndirectZorSplit
                                                                        : cartDetails.hideATP
                                                                }
                                                            />
                                                        </div>
                                                        <div className={cx('listItems')}>
                                                            {!_isEmpty(activeCartDetails.subTotal) && (
                                                                <CartSummary
                                                                    title={`${
                                                                        (!this.isReturnEmpty &&
                                                                            cartInfo.cartTranslatedName) ||
                                                                        ''
                                                                    } ${localization[CART_L10N.SUMMARY] || ''}`}
                                                                    iconName='financial-info'
                                                                    toggleAccordionView={this.toggleAccordionView}
                                                                    setActive={mobileCartSummary}
                                                                    pricingDataList={cartInfo.cchPricingDataList}
                                                                    cartDetails={activeCartDetails}
                                                                    localization={localization}
                                                                    commonLocalization={commonLocalization}
                                                                    showPricingMessage={this.state.showPricingMessage}
                                                                    theme={cx('cartSummaryTheme')}
                                                                    button={renderCheckoutBtn(cx('buttonHiddenSmall', 'continueBtn', 'col3', 'sm12'))}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                            );
                                        }
                                        return null;
                                    })}
                                </Tabs>
                            ) : (
                                !this.props.cartItemsCount && (
                                    <>
                                        {/* customAddcartContainer={cx('display-none')} If Cart is Empty add this custom Class */}
                                        <p className={cx('emptyCart')}>{localization[CART_L10N.CART_EMPTY_ERROR]}</p>
                                        {/* TO DO Cart Empty Changes Here */}
                                        <div className={cx('returnWrapper')}>
                                            <Button
                                                automation-id='at-return-shopping'
                                                className={cx('returnBtn')}
                                                type='button'
                                                size='Sm'
                                                buttonType='Primary'
                                                onClick={this.returnToShop}
                                            >
                                                {localization[CART_L10N.RETURN_TO_SHOP]}
                                            </Button>
                                        </div>
                                    </>
                                )
                            )}
                        </div>
                        <div>
                            {this.isBdaaActive && !!bdaaSuggestion?.length && filteredBdaa?.length > 0 && (
                                <>
                                    <div className={cx('recommended-header')}>
                                        {localization[CART_L10N.RECOMMENDED_ITEMS]}
                                    </div>
                                    <div className={cx('carousel-container')}>
                                        <Slider {...settings}>
                                            {filteredBdaa &&
                                                filteredBdaa.map((item, key) => (
                                                    <ProductCard
                                                        {...item}
                                                        shipping={item.shippingInfo}
                                                        url={item.productUrl}
                                                        key={key.toString()}
                                                        skuLabel={item.label}
                                                        skuId={item.id}
                                                        code={item.code}
                                                        price={item.listPrice}
                                                        addToCartLabel={localization[HOME_L10N.ADD_BTN]}
                                                        isSelectViews='listView'
                                                        isListGrid={!true}
                                                        isReturnEmpties={false}
                                                        addToCartMessage={
                                                            localization[GENERIC_L10N.ADD_TO_CART_MESSAGE]
                                                        }
                                                        isFrom='CART'
                                                        isUpdateSap
                                                        style={{ margin: '0 20x' }}
                                                        isInBdaaSlider
                                                    />
                                                ))}
                                        </Slider>
                                    </div>
                                </>
                            )}
                        </div>
                        {/* Stock Allocation Popup section */}
                        {this.showStockAllocationModal && stockAllocation?.showPopup && this.renderStockAllocation()}
                    </>
                )}

                {this.state.isClearAllCartsModal && (
                    <ClearAllCartsModal
                        showModal={this.state.isClearAllCartsModal}
                        onOk={this.onClearAll}
                        onCancel={this.closeModal}
                        localization={localization}
                    />
                )}
            </Layout>
        );
    }
}


function CustomNextArrow(props) {
    const { className, style, onClick } = props;
    const deviceClass = getDevice() === DEVICE_TYPES.MOBILE ? 'mobileArrowNext' :
        getDevice() === DEVICE_TYPES.TABLET ? 'tabletArrow' :
            'desctopArrow';

    return (
        <div
            className={cx(className, 'arrow', 'nextArrow', className.includes('disabled') ? 'disabled' : '')}
            style={style}
            onClick={onClick}
        >
            <Icon

                width='32px'
                height='32px'
                viewBox='0 0 24 24'
                name='chevron-right'

            />

        </div>
    );
}

function CustomPrevArrow(props) {
    const { className, style, onClick } = props;

    const deviceClass = getDevice() == DEVICE_TYPES.MOBILE ? 'mobileArrowPrev' :
        getDevice() == DEVICE_TYPES.TABLET ? 'tabletArrow' :
            'desctopArrow';

    return (
        <div
            className={cx(className, 'arrow', 'prevArrow', className.includes('disabled') ? 'disabled' : '')}
            style={style}
            onClick={onClick}
        >
            <Icon
                width='32px'
                height='32px'
                viewBox='0 0 24 24'
                name='chevron-left'
            />
        </div>
    );
}

const mapStateToProps = state => {
    return {
        cartDetails: _get(state, 'cartDetails.cart'),
        localization: _get(state, 'pageContent.localization'),
        stockAllocation: _get(state, 'pageContent.stockAllocation'),
        deliveryType: _get(state, 'cartDetails.deliveryType'),
        isZADOrderType: _get(state, 'loginPage.isZADOrderType'),
        commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`),
        bdaaSuggestion: _get(state, 'products.bdaaSuggestion'),
        cartItemsCount: _get(state, 'cartDetails.miniCart.totalCartCount'),
        creditLimitNotificationRead: _get(state, 'creditInformation.creditLimitNotificationRead'),
        blockOrder: _get(state, 'creditInformation.blockOrder'),
        components: _get(state, 'pageContent.components'),
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getPageContent,
            updateDeliveryDate,
            notifySuccess,
            notifyBlueMessage,
            notifyWarning,
            notifyError,
            showMultipleNotifications,
            getBdaaSuggestion,
            getMultiCart,
            showOrderNotifications,
            clearAll,
            showCreditLimitExceededPopup,
            closeCreditLimitExceededPopup
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
