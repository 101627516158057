import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import _get from 'lodash/get';
import _find from 'lodash/find';
import classNames from 'classnames/bind';
import Image from '../../core/Image/Image';
import NavLink from '../../core/NavLink/NavLink';
import {
    COMMON_COMPONENT,
    UNIQUE_ID,
    ROUND_LOGO_COUNTRIES,
    POP_ORDER_NAVS,
    PLP_TYPES,
    RETURN_ORDER_NAV
} from '../../../common/Constants';
import {
    getSiteId,
    getUserName,
    getUserEmail,
    getUserChatActivated,
    setUserChatActivated,
    setMicrosoftChatBotTokenLS,
    getMicrosoftChatBotTokenLS
} from '../../../common/UserManager/UserManager';
import Modal from '../../core/Modal/Modal';
import ContactMe from '../ContactMe/ContactMe';
import * as styles from './Footer.css';
import { showConatctMe } from '../../../actions/ContactMeAction';
import { changeOrderType, restoreCart } from '../../../actions/CartAction';
import SmartEdit from '../SmartEdit/SmartEdit';
import MicrosoftChatBot from '../MicrosoftChatBot/MicrosoftChatBot';

const cx = classNames.bind(styles);

export function PageFooter(props) {

    const { token: initialToken, directLineURL: initialDirectLineURL, dateCreated: initialTokenDate } = getMicrosoftChatBotTokenLS();

    const [showRequestSupport, setShowRequestSupport] = useState(false);
    const [microsoftChatBotToken, setMicrosoftChatBotToken] = useState(initialToken || '');
    const [microsoftChatBotDirectLineURL, setMicrosoftChatBotDirectLineURL] = useState(initialDirectLineURL || '');

    const { menuList = [], logo = {}, iconList = [], copyRightText, bottomMenuList = [] } = props.footerData;
    const { isShowContactMe, showMicrosoftChatBot, setShowMicrosoftChatBot } = props;
    const isRoundLogo = ROUND_LOGO_COUNTRIES.includes(getSiteId());


    const [isActive, setIsActive] = useState(getUserChatActivated());

    const fetchTokenFunc = async () => {
        if (!(microsoftChatBotToken && microsoftChatBotDirectLineURL) || (new Date().getTime() - initialTokenDate >= 3600)) {
            try {
                const timestamp = new Date(); 
                const dateCreated = timestamp.getTime();

                const tokenEndpointURL = new URL(
                    'https://475d89d5901ae6c2b01435d10ce9e3.47.environment.api.powerplatform.com/powervirtualagents/botsbyschema/cp_cpChatbot/directline/token?api-version=2022-03-01-preview'
                );

                const apiVersion = tokenEndpointURL.searchParams.get('api-version');
                const [directLineResponse, tokenResponse] = await Promise.all([
                    axios.get(
                        new URL(
                            `/powervirtualagents/regionalchannelsettings?api-version=${apiVersion}`,
                            tokenEndpointURL
                        ).href
                    ),
                    axios.get(tokenEndpointURL.href)
                ]);

                const directLineURL = directLineResponse.data.channelUrlsById.directline;
                const token = tokenResponse.data.token;
                // const expiry = tokenResponse.data.expires_in;

                setMicrosoftChatBotDirectLineURL(directLineURL);
                setMicrosoftChatBotToken(token);
                // Set the token in local storage in case we need it when user changes pages or refreshes
                setMicrosoftChatBotTokenLS({
                    dateCreated,
                    token,
                    directLineURL
                });
            } catch (error) {
                console.error(error.message);
            }
        }
        // Close the other chat if needed
        if (window.CXBus) {
            new Promise(resolve => {
                // Attempt to close the WebChat
                window.CXBus.command('WebChat.close');
                resolve();
            }).then(() => {
                const closeButton = document.querySelector('.cx-buttons-binary .cx-btn-primary');
                if (closeButton) {
                    closeButton.click();
                }
                setUserChatActivated(false);
                setIsActive(false);
            });
        }
    };

    useEffect(() => {
        if (showMicrosoftChatBot) {
            fetchTokenFunc();
        }
    }, [showMicrosoftChatBot]);

    document.addEventListener('CHAT_STATE_UPDATED', e => {
        setIsActive(e.value);
    });

    const loadWidgetChat = (isChatPluginLoaded, chatEmail, chatCurrentOutletId, chatCurrentCompanyName) => {
        if (isChatPluginLoaded) {
            const getAdvancedConfig = () => {
                return {
                    userData: {
                        firstName: Object.keys(getUserName()).length === 0 ? '' : getUserName(),
                        OutletNumber: chatCurrentOutletId || '',
                        CompanyName: chatCurrentCompanyName || '',
                        customField1: chatEmail || '',
                        LoggedUserEmailAddress: getUserEmail() || '',
                        Country: props.chatCountry,
                        Language: props.chatLanguage
                    },
                    formJSON: {
                        wrapper: '<table></table>',
                        inputs: [
                            {
                                id: 'cx_webchat_form_subject',
                                name: 'Subject',
                                placeholder: '@i18n:webchat.ChatFormPlaceholderSubject',
                                label: '@i18n:webchat.ChatFormSubject',
                                type: 'select',
                                options: [
                                    {
                                        disabled: 'disabled',
                                        selected: 'selected',
                                        hidden: 'hidden'
                                    },
                                    {
                                        text: '@i18n:webchat.ChatFormSubjectService',
                                        value: 'service',
                                        selected: true
                                    },
                                    {
                                        text: '@i18n:webchat.ChatFormSubjectOrder',
                                        value: 'order'
                                    },
                                    {
                                        text: '@i18n:webchat.ChatFormSubjectFinance',
                                        value: 'finance'
                                    }
                                ],
                                wrapper: '<tr><th>{label}</th><td>{input}</td></tr>'
                            },
                            {
                                id: 'cx_webchat_form_firstname',
                                name: 'firstname',
                                placeholder: 'Required',
                                label: 'First Name',
                                type: 'hidden',
                                maxlength: '100',
                                value: Object.keys(getUserName()).length === 0 ? '' : getUserName()
                            }
                        ]
                    }
                };
            };
            window._genesys.widgets.bus.before('WebChat.open', oData => {
                setUserChatActivated(true);
                // Close the microsoft chat bot when the other one opens
                setShowMicrosoftChatBot(false);
                setMicrosoftChatBotToken('');
                setMicrosoftChatBotDirectLineURL('');
                setMicrosoftChatBotTokenLS({});
                return getAdvancedConfig();
            });
        }

        if (window.CXBus) {
            window.CXBus.subscribe('WebChat.cancelled', e => {
                if (e.data.systemMessages > 3) {
                    window.CXBus.command('WebChat.close').then(() => {
                        setUserChatActivated(false);
                    });
                }
            });
        }
    };

    const commonFooterComp = useMemo(
        () => _find(props.components, [UNIQUE_ID, COMMON_COMPONENT.FOOTER_COMPONENT_UID]),
        [props]
    );

    useEffect(() => {
        if (props.isChatPluginLoaded) {
            loadWidgetChat(
                props.isChatPluginLoaded,
                props.chatEmail,
                props.chatCurrentOutletId,
                props.chatCurrentCompanyName
            );
        }
    }, [props.isChatPluginLoaded, props.chatEmail, props.chatCurrentOutletId, props.chatCurrentCompanyName, isActive]);

    const handleRequestSupportPopup = (menu, e) => {
        const reqSupport = (menu || {})[UNIQUE_ID];

        if (reqSupport === COMMON_COMPONENT.FOOTER_REQUEST_SUPPORT_LINK) {
            e.preventDefault();
            setShowRequestSupport(true);
            if (!isShowContactMe) {
                props.actions.showConatctMe();
            }
        } else if (reqSupport === POP_ORDER_NAVS.FOOTER_CREATE_POP_ORDER) {
            props.actions.changeOrderType({ type: PLP_TYPES.ZPOP });
        } else if (reqSupport === RETURN_ORDER_NAV.FOOTER_CREATE_RETURN_ORDER) {
            props.actions.changeOrderType({ type: PLP_TYPES.RETURN });
        }
    };

    const onCloseRequestModal = () => {
        setShowRequestSupport(false);
    };

    const handleClick = (e, item) => {
        props.handlePopup(e, item);
    };

    const renderSupportModal = () => {
        return (
            <div className={cx('requestModal')}>
                <Modal
                    showModal={showRequestSupport}
                    modalTheme={cx('userThemeModal', props.deviceType.isDevice && 'mobileuserThemeModal')}
                    modalTitleTheme={cx('modalTitleTheme')}
                    themeContent={cx('themeContent')}
                    themeClose={cx('themeClose')}
                    themeModalBlock={cx('themeModalBlock')}
                    defaultCloseBtn={false}
                >
                    <ContactMe onCancelEvent={onCloseRequestModal} />
                </Modal>
            </div>
        );
    };

    const renderMenuList = (menu, index) => {
        if (menu.children) {
            return (
                <div key={`menu-${index}`} className={cx('listItems', 'productLinks')}>
                    <ul className={cx('footerLinks')}>
                        <li>
                            <NavLink automationId={`at-products-navigation-link-${index}`} linkUrl={menu.linkUrl}>
                                {menu.linkName}
                            </NavLink>
                        </li>
                        <ul className={cx('myOrderList')}>
                            {menu.children.map((subMenu, i) => (
                                <li key={`at-submenu-${index}-${i.toString()}`}>
                                    <NavLink
                                        automationId={`at-submenu-${index}-${i}`}
                                        linkUrl={subMenu.linkUrl}
                                        theme={cx('footerMenu')}
                                        onClick={e => {
                                            handleRequestSupportPopup(subMenu, e);
                                        }}
                                    >
                                        {subMenu.linkName}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </ul>
                </div>
            );
        }
        return (
            <div key={`menu-${index}`} className={cx('listItems', 'productLinks')}>
                <ul className={cx('footerLinks')}>
                    <li>
                        <NavLink automationId={`at-products-navigation-link-${index}`} linkUrl={menu.linkUrl}>
                            {menu.linkName}
                        </NavLink>
                    </li>
                </ul>
            </div>
        );
    };

    const renderFooterIcons = () => {
        return (
            <ul className={cx('socialWrapper')} key='socialLink'>
                {(iconList || []).map((item, index) => {
                    const onClick = e => {
                        const isFaq = item.uid === COMMON_COMPONENT.FOOTER_FAQ;
                        if (isFaq) {
                            return;
                        }
                        handleClick(e, item);
                    };
                    return (
                        <li
                            key={`at-faq-${index.toString()}`}
                            automation-id={`at-faq-${index}`}
                            className={cx(`${item.altText}`)}
                        >
                            <NavLink theme={cx('termsLinks')} linkUrl={item.linkUrl} onClick={onClick}>
                                <Image src={item.imageUrl} alt={item.altText} />
                            </NavLink>
                            <span className={cx('links')}>{item.linkName}</span>
                        </li>
                    );
                })}
            </ul>
        );
    };
    
    return (
        <div className={cx('footerWrapper')}>
            <SmartEdit data={commonFooterComp} customClassName={cx('height-auto')}>
                <div className={cx('footerinnerContainer', 'bottom_border')}>
                    <div className={cx('footerContent')}>
                        {logo && logo.imageUrl && (
                            <div className={cx('listItems', 'footerLogo', 'col3', 'md2', 'sm12')}>
                                <Image
                                    automation-id='at-footer-logo'
                                    theme={cx(isRoundLogo ? 'roundFooterLogo' : 'defaultFooterLogo')}
                                    src={logo.imageUrl}
                                    alt='CCHBC Footer Logo'
                                />
                            </div>
                        )}
                        <div
                            className={cx('listItem', 'socialLink', 'col12', 'hiddenMd', 'hiddenLg', 'hiddenXl')}
                            key='renderFooter'
                        >
                            {renderFooterIcons()}
                        </div>
                        <div key='footerContentMenu' className={cx('footerContentMenu', 'col5', 'md10', 'sm12')}>
                            {menuList.map((menu, index) => renderMenuList(menu, index))}
                        </div>
                        <div
                            className={cx('listItem', 'socialLink', 'col4', 'hiddenMd', 'hiddenSm')}
                            key='mobileRenderFooter'
                        >
                            {renderFooterIcons()}
                        </div>
                    </div>
                </div>

                <div className={cx('footerBottom')}>
                    <ul className={cx('list', 'footerTerms')}>
                        {/* <li><a href="" className={cx('termsLinks')}>English <Icon iconType="svg" width="12px" height="12px" viewBox="0 0 12 12" name="globe" /></a></li> */}
                        {(bottomMenuList || []).map(
                            item =>
                                item.url && (
                                    <li key={item.name} id={item.name}>
                                        <NavLink theme={cx('termsLinks')} linkUrl={item.url}>
                                            {item.name}
                                        </NavLink>
                                    </li>
                                )
                        )}
                    </ul>
                    <div className={cx('listItem', 'socialLink', 'col4', 'hiddenXl', 'hiddenSm', 'hiddenLg')}>
                        {renderFooterIcons()}
                    </div>
                    {copyRightText && <p className={cx('copyRight')}>{copyRightText}</p>}
                </div>
                {showRequestSupport && renderSupportModal()}
                {showMicrosoftChatBot && microsoftChatBotToken && microsoftChatBotDirectLineURL && (
                    <MicrosoftChatBot
                        microsoftChatBotDirectLineURL={microsoftChatBotDirectLineURL}
                        microsoftChatBotToken={microsoftChatBotToken}
                        setMicrosoftChatBotToken={setMicrosoftChatBotToken}
                        setMicrosoftChatBotDirectLineURL={setMicrosoftChatBotDirectLineURL}
                        setShowMicrosoftChatBot={setShowMicrosoftChatBot}
                    />
                )}
            </SmartEdit>
        </div>
    );
}

PageFooter.propTypes = {
    deviceType: PropTypes.objectOf(PropTypes.bool),
    footerData: PropTypes.objectOf(PropTypes.any)
};

PageFooter.defaultProps = {
    deviceType: {},
    footerData: {}
};

const mapStateToProps = state => {
    return {
        deviceType: _get(state, 'context.deviceType'),
        footerData: _get(state, 'pageContent.footerData', {}),
        isShowContactMe: _get(state, 'contactInfo.showContactForm'),
        components: _get(state, 'pageContent.components')
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            showConatctMe,
            changeOrderType,
            restoreCart
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(PageFooter);
