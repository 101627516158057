import RestClient from '../RestClient/RestClient';
import RestConfig from '../RestClient/RestConfig';
import { AUTH, OUTLET, PARAMS } from '../common/Api';

export function getSelectedOutlet(payload) {
    const { params = {} } = payload;
    const config = RestConfig.v2ComApiConfig(payload);
    config.url = `/${config.baseInfo[PARAMS.SITE_ID]}${OUTLET.STORE_SELECT}${OUTLET.SELECT_OUTLET}${
        OUTLET.CURRENT_OUTLET
    }`;
    config.params = params;

    return RestClient.get(config).then(json => {
        return json;
    });
}

export function getOutletList(payload) {
    const config = RestConfig.v2ComApiConfig(payload);
    config.url = `/${config.baseInfo[PARAMS.SITE_ID]}${OUTLET.STORE_SELECT}${OUTLET.LIST}`;

    return RestClient.get(config).then(json => {
        return json;
    });
}

export function getStockAllocation(payload) {
    const { baseInfo, params } = payload;
    const config = RestConfig.v2ComApiConfig(payload);
    config.url = `/${config.baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${baseInfo.userId}${OUTLET.STOCK_ALLOCATION}`;
    config.params = params;

    return RestClient.get(config).then(json => {
        return json;
    });
}

export function changeOutlet(payload) {
    const config = RestConfig.v2ComApiConfig(payload);
    config.url = `/${config.baseInfo[PARAMS.SITE_ID]}${OUTLET.STORE_SELECT}${OUTLET.SET_LIST}`;
    config.params = payload.params;

    return RestClient.post(config).then(json => {
        return json;
    });
}

export function loadOutletPrices(payload) {
    const config = RestConfig.v2ComApiConfig(payload);
    config.url = `/${config.baseInfo[PARAMS.SITE_ID]}/products${OUTLET.LOAD_OUTLET_PRICES}`;
    config.params = payload.params;

    return RestClient.post(config).then(json => {
        return json;
    });
}

export function getPricingConditions(payload) {
    const { baseInfo, params } = payload;
    const config = RestConfig.v2ComApiConfig(payload);

    config.url = `/${config.baseInfo[PARAMS.SITE_ID]}${OUTLET.STORE_SELECT}/trigger-pricing-names-api`;
    config.params = params;
    return RestClient.post(config).then(json => {
        return json;
    });
}

export function getDefaultDeliverDate(payload) {
    const config = RestConfig.v2ComApiConfig(payload);
    config.url = `/${config.baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${config.baseInfo[PARAMS.USER_ID]}${
        AUTH.DEFAULT_DELIVERY_DATE
    }`;

    return RestClient.get(config).then(json => {
        return json;
    });
}
