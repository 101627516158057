/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Link from 'react-router/lib/Link';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _get from 'lodash/get';
import classNames from 'classnames/bind';
import Image from '../../core/Image/Image';
import Icon from '../../core/Icon/Icon';
import Button from '../../core/Button/Button';
import ChangeOutlet from '../ChangeOutlet/ChangeOutlet';
import Tooltip from '../../core/Tooltip/Tooltip';
import SearchBox from '../../core/SearchBox/SearchBox';
import Modal from '../../core/Modal/Modal';
import MiniCart from '../MiniCart/MiniCart';
import OptimizedMiniCart from '../OptimizedMiniCart/OptimizedMiniCart';
import ContactMe from '../ContactMe/ContactMe';
import CreditLimit from '../CreditLimit/CreditLimit';
import Notification from '../Notification/Notification';
import { logoutUser } from '../../../actions/LoginAction';
import { changeOrderType, restoreCart } from '../../../actions/CartAction';
import {
    URL_PATHS,
    COMMON_COMPONENT,
    UNIQUE_ID,
    ROUND_LOGO_COUNTRIES,
    LOCALIZATION,
    POP_ORDER_NAVS,
    RETURN_ORDER_NAV,
    PLP_TYPES,
    MINI_CART_WORKING_WAYS,
    PLP_RETURN,
    PLP_ZPOP
} from '../../../common/Constants';
import {
    getDefaultDeliverDate,
    getSiteId,
    isBdaaActive,
    getMiniCartWorkingWay,
    isReturnEmpties,
    isUseMarketingMaterials
} from '../../../common/UserManager/UserManager';
import * as styles from './Header.css';
import SmartEdit from '../SmartEdit/SmartEdit';
import { redirectTo } from '../../../common/Utils/Utils';
import { Cart } from '../MobileTabbar/icons';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../common/GoogleTagManager/GoogleTagManager';

const cx = classNames.bind(styles);

export class Header extends Component {
    static propTypes = {
        showOutlet: PropTypes.bool,
        showDashboardHeader: PropTypes.bool,
        deviceType: PropTypes.objectOf(PropTypes.bool),
        pageTitle: PropTypes.string,
        pageTitlePrefix: PropTypes.string,
        selectedOutlet: PropTypes.objectOf(PropTypes.any),
        headerData: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
        creditInformation: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
        userDetails: PropTypes.objectOf(PropTypes.string),
        miniCart: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        outletList: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    };

    static defaultProps = {
        showOutlet: false,
        showDashboardHeader: false,
        deviceType: {},
        pageTitle: '',
        pageTitlePrefix: '',
        selectedOutlet: {},
        headerData: '',
        creditInformation: {},
        userDetails: {},
        miniCart: {},
        outletList: {}
    };

    constructor(props) {
        super(props);
        this.state = {
            isShowMenu: false,
            isVisible: false,
            selectMenu: '',
            showRequestSupport: false,
            screenWidth: window.innerWidth
        };
        this.notificationRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (this.props.miniCart.totalCartCount !== prevProps.miniCart.totalCartCount) {
            this.startCartIconAnim();
        }
        // React Helemt can be used if need to update more than just a title.
        document.title = `${this.props.pageTitlePrefix} ${this.props.pageTitle}`;
    }

    componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({ screenWidth: window.innerWidth });
        });
    }

    startCartIconAnim = () => {
        this.setState({ startAnim: true }, () => {
            setTimeout(() => {
                this.setState({ startAnim: false });
            }, 900);
        });
    };

    toggleDetails = () => {
        const { isShowMenu } = this.state;
        this.setState({
            isShowMenu: !isShowMenu
        });
    };

    handleMouseEnter = event => {
        const selectMenu = event.target.id;
        this.setState({
            isVisible: true,
            selectMenu
        });
    };

    handleMouseLeave = event => {
        const selectMenu = event.target.id;
        this.setState({
            isVisible: false,
            selectMenu
        });
    };

    renderTooltipData = () => {
        const {
            selectedOutlet: { mainPartnerId },
            outletList: { mainPartners = [] }
        } = this.props;

        return (
            <ChangeOutlet
                id={mainPartnerId}
                customeOutletContainer={cx(
                    mainPartners.length > ChangeOutlet.SCROLL_OUTLET_NO ? 'customeOutletContainer' : ''
                )}
            />
        );
    };

    renderDeliveryData = deliveryDates => {
        return (
            <>
                {deliveryDates?.map((dd, i) => {
                    return (
                        <p key={i}>
                            <span className={cx('deliveryName')}>{dd?.basketName}:</span>{' '}
                            <span className={cx('deliveryItemDate')}>{dd?.deliveryDate}</span>
                        </p>
                    );
                })}
            </>
        );
    };

    logoutEvent = () => {
        if (document.getElementsByClassName('cx-widget')[0]) {
            window._genesys.widgets.bus.command('WebChat.endChat').then(() => {
                window._genesys.widgets.bus.command('WebChat.close');
            });
        }
        this.props.actions.logoutUser();
    };

    renderMyAccount = () => {
        const accountComponent = this.props.headerData[COMMON_COMPONENT.ACCOUNT_MENU] || {};

        const filteredAccountComponent = (accountComponent = []) => {
            if (!isBdaaActive() || !this.props.bdaaSuggestion?.length > 0) {
                return accountComponent.filter(x => x?.uid !== 'MySmartOrdersLink');
            }
            return accountComponent;
        };

        return (
            <div key='myAccountMenu' className={cx('myAccountMenu')}>
                {filteredAccountComponent(accountComponent?.childComponents).map(menu => {
                    if (menu.url === URL_PATHS.LOGOUT) {
                        return (
                            <Button
                                className={cx('accLink')}
                                type='linkbutton'
                                onClick={(event) => {
                                    this.logoutEvent(event);
                                    try {
                                        buttonClickEvent(buttonClickEventConfigs.header.actions.myProfile, buttonClickEventConfigs.header.labels.myProfileLogout, buttonClickEventConfigs.header.categories.header);
                                    } catch (ex) {
                                        console.error(ex);
                                    }
                                }}
                                id='mobileLogout'
                                key={menu.name}
                            >
                                {menu.name}
                            </Button>
                        );
                    }
                    return (
                        menu.name && (
                            <Link className={cx('accLink')} to={menu.url} key={menu.name} onClick={() => {
                                try {
                                    const gaLinks = {
                                        [URL_PATHS.MY_ACCOUNT]: {
                                            action: buttonClickEventConfigs.header.actions.myProfile,
                                            label: buttonClickEventConfigs.header.labels.myProfileMyAccount
                                        }
                                    };
                                    const foundLink = gaLinks[URL_PATHS.MY_ACCOUNT];
                                    if (foundLink) {
                                        buttonClickEvent(foundLink.action, foundLink.label, buttonClickEventConfigs.header.categories.header);
                                    }
                                } catch (ex) {
                                    console.error(ex);
                                }
                            }}>
                                {menu.name}
                            </Link>
                        )
                    );
                })}
            </div>
        );
    };

    //If user is on Cart page and quick minicart is set disable minicart icon
    showMiniCart = (status = true) => {
        const url = window.location.href;
        if (url.includes(URL_PATHS.CART) && getMiniCartWorkingWay() === MINI_CART_WORKING_WAYS.MINICARTNOCRM) {
            this.props.toggleMiniCart(false);
        } else {
            this.props.toggleMiniCart(status);
        }
    };

    handleRequestSupportPopup = (menu, e) => {
        const reqSupport = (menu || {})[UNIQUE_ID];

        if (reqSupport === COMMON_COMPONENT.HEADER_REQUEST_SUPPORT_LINK) {
            e.preventDefault();
            this.props.setShowRequestModal(true);
            this.setState({
                isShowMenu: false
            });
        } else if (reqSupport === POP_ORDER_NAVS.HEADER_CREATE_POP_ORDER) {
            this.props.actions.changeOrderType({ type: PLP_TYPES.ZPOP });
        } else if (reqSupport === RETURN_ORDER_NAV.HEADER_CREATE_RETURN_ORDER) {
            this.props.actions.changeOrderType({ type: PLP_TYPES.RETURN });
        } else if (this.props.deviceType.isMobile) {
            this.toggleDetails(true);
        }
    };

    onCloseRequestModal = () => {
        this.props.setShowRequestModal(false);
    };

    handleCartClick = () => {
        const url = window.location.href;
        if (getMiniCartWorkingWay() === MINI_CART_WORKING_WAYS.SKIPMINICART) {
            this.redirectToCart();
        } else if (
            !(url.includes(URL_PATHS.CART) && getMiniCartWorkingWay() === MINI_CART_WORKING_WAYS.MINICARTNOCRM)
        ) {
            this.props.toggleMiniCart(!this.props.showMiniCart);
        }
    };

    redirectToCart = () => {
        redirectTo('/cart');
    };

    renderSupportModal = () => {
        const { showRequestSupport } = this.props;

        return (
            <div className={cx('requestModal')}>
                <Modal
                    showModal={showRequestSupport}
                    modalTheme={cx('userThemeModal', this.props.deviceType.isDevice && 'mobileuserThemeModal')}
                    modalTitleTheme={cx('modalTitleTheme')}
                    themeContent={cx('themeContent')}
                    themeClose={cx('themeClose')}
                    themeModalBlock={cx('themeModalBlock')}
                    defaultCloseBtn={false}
                >
                    <ContactMe onCancelEvent={this.onCloseRequestModal} />
                </Modal>
            </div>
        );
    };

    renderDesktopMenu = (menu, index) => {
        if (menu.children) {
            const { selectMenu, isVisible } = this.state;
            const menuId = (menu.linkName || '').replace(/\s/g, '');
            const menuActive = selectMenu === menuId && isVisible;
            const downIcon = (
                <Icon
                    className={cx('downIcon', 'mobilesubLevel', menuActive && 'menuUp')}
                    iconType='svg'
                    width='16px'
                    height='16px'
                    viewBox='0 0 16 16'
                    name='menu-down'
                />
            );
            return (
                <li
                    onMouseEnter={e => this.handleMouseEnter(e)}
                    onClick={e => this.handleMouseEnter(e)}
                    onMouseLeave={e => this.handleMouseLeave(e)}
                    id={menuId}
                    key={menuId}
                >
                    <Link
                        automation-id={`at-product-link-${index}`}
                        to={menu.linkUrl}
                        className={cx('subMenu')}
                        id={menuId}
                    >
                        {menu.linkName} {downIcon}
                    </Link>
                    <div
                        onMouseEnter={e => e.stopPropagation()}
                        className={cx(
                            'seconLevelMenu',
                            'myOrderMenu',
                            selectMenu === menuId && isVisible ? 'visible' : 'none'
                        )}
                    >
                        {menu.children.map((item, j) => {
                            const isRequestSupportHeaderLink =
                                (item || {})[UNIQUE_ID] === COMMON_COMPONENT.HEADER_REQUEST_SUPPORT_LINK;
                            return (
                                <div key={item.linkUrl} id='secondLevelMenu' className={cx('seconLevelMenuItem')}>
                                    {isRequestSupportHeaderLink ? (
                                        <a
                                            automation-id={`at-my-orders-and-return-dropdown-${index}-${j}`}
                                            {...{ 'href-data': item.linkUrl }}
                                            className={cx('submenuText')}
                                            onClick={e => this.handleRequestSupportPopup(item, e)}
                                        >
                                            <div className={cx('seconLevelMenuItemIcon')}>
                                                <Image
                                                    automationId='at-cchbc-logo'
                                                    theme={cx('seconLevelMenuItemLogo')}
                                                    src={item.imageUrl}
                                                    alt={item.altText}
                                                />
                                            </div>
                                            <div className={cx('seconLevelMenuItemName')}>
                                                <span className={cx('menuLink')}>{item.linkName}</span>
                                            </div>
                                        </a>
                                    ) : (
                                        <Link
                                            automation-id={`at-my-orders-and-return-dropdown-${index}-${j}`}
                                            to={item.linkUrl}
                                            className={cx('submenuText')}
                                            onClick={e => this.handleRequestSupportPopup(item, e)}
                                        >
                                            <div className={cx('seconLevelMenuItemIcon')}>
                                                <Image
                                                    automationId='at-cchbc-logo'
                                                    theme={cx('seconLevelMenuItemLogo')}
                                                    src={item.imageUrl}
                                                    alt={item.altText}
                                                />
                                            </div>
                                            <div className={cx('seconLevelMenuItemName')}>
                                                <span className={cx('menuLink')}>{item.linkName}</span>
                                            </div>
                                        </Link>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </li>
            );
        }
        return (
            <li key={`${index}-${Math.random()}`}>
                <Link automation-id={`at-product-link-${index}`} to={menu.linkUrl}>
                    {menu.linkName}
                </Link>
            </li>
        );
    };

    toggleMobileMenu = id => {
        const { selectMenu, isVisible } = this.state;
        if (selectMenu === id || (selectMenu === '' && !isVisible)) {
            this.setState({
                isVisible: !isVisible,
                selectMenu: id
            });
        } else {
            this.setState({
                isVisible: true,
                selectMenu: id
            });
        }
    };

    renderOutLet = () => {
        const {
            selectedOutlet: { mainPartnerAddress = {} }
        } = this.props;

        const defaultDeliveryDates = getDefaultDeliverDate();

        return (
            <div className={cx('topHeader', 'col12')}>
                <div className={cx('outletWrapper')}>
                    <ul className={cx('headerWrapper')}>
                        <li>
                            <div className={cx('countrySelect')}>
                                <span className={cx('locationIcon')}>
                                    <Icon
                                        iconType='svg'
                                        width='22px'
                                        height='16px'
                                        viewBox='0 0 22 16'
                                        name='location'
                                    />
                                </span>
                                {!_isEmpty(mainPartnerAddress) && (
                                    <Tooltip
                                        renderTooltipContents={this.renderTooltipData()}
                                        customTooltipContent={cx('topTooltipContent')}
                                        isChangeEvent
                                        customTooltipContainer={cx('customTooltipContainer')}
                                    >
                                        <div className={cx('outletContent')} automation-id='at-outlet-information'>
                                            <div className={cx('outletName')} automation-id='at-outlet-name-dropdown'>
                                                <span className={cx('textEllipsis')}>
                                                    {mainPartnerAddress.companyName}
                                                </span>
                                                <Icon
                                                    className={cx('downArrow')}
                                                    iconType='svg'
                                                    width='16px'
                                                    height='16px'
                                                    viewBox='0 0 16 16'
                                                    name='down'
                                                />
                                            </div>
                                            <p className={cx('outletAddr')} automation-id='at-outlet-address'>
                                                {mainPartnerAddress.district}, {mainPartnerAddress.postalcode}{' '}
                                                {mainPartnerAddress.city} , {mainPartnerAddress.country}{' '}
                                            </p>
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                        </li>
                        <li>
                            {defaultDeliveryDates && (
                                <div className={cx('deliveryWrapper')}>
                                    <Tooltip
                                        renderTooltipContents={this.renderDeliveryData(
                                            defaultDeliveryDates?.deliveryDates
                                        )}
                                        customTooltipContent={cx('tooltipDeliveryContainer')}
                                        isChangeEvent
                                        customTooltipContainer={cx(
                                            'deliveryWrapper',
                                            'delivery-margin-right',
                                            'pointer'
                                        )}
                                        isEmpty={!(defaultDeliveryDates?.deliveryDates?.length > 0)}
                                    >
                                        <span className={cx('deliveryIcon')}>
                                            <Icon
                                                iconType='svg'
                                                width='22px'
                                                height='16px'
                                                viewBox='0 0 22 16'
                                                name='deliveryVan'
                                            />
                                        </span>
                                        <p className={cx('textDelivery', 'underline')}>
                                            {defaultDeliveryDates?.nextDeliveryTxt} :{' '}
                                            <span>{defaultDeliveryDates?.deliveryDate}</span>
                                            <br className={cx('orderDatesSmall')} />
                                            <span className={cx('orderDatesSmall')}>
                                                {defaultDeliveryDates.orderBeforeTxt}{' '}
                                                {defaultDeliveryDates?.cuttOffDate}
                                            </span>
                                            {defaultDeliveryDates?.deliveryDates?.length > 0 && (
                                                <Icon
                                                    className={cx('deliveryDownArrow')}
                                                    iconType='svg'
                                                    width='16px'
                                                    height='16px'
                                                    viewBox='0 0 16 16'
                                                    name='down'
                                                />
                                            )}
                                        </p>
                                    </Tooltip>

                                    <p className={cx('textDelivery', 'hideSmall')}>
                                        {defaultDeliveryDates.orderBeforeTxt} {defaultDeliveryDates.cuttOffDate}
                                    </p>
                                </div>
                            )}
                        </li>
                    </ul>
                </div>
            </div>
        );
    };

    renderMobileMenu = (menu, id) => {
        if (menu.children) {
            const { selectMenu, isVisible } = this.state;
            const menuId = (menu.linkName || '').replace(/\s/g, '');
            const menuActive = selectMenu === menuId && isVisible;
            const downIcon = (
                <Icon
                    className={cx('downIcon', 'mobilesubLevel', menuActive && 'menuUp')}
                    iconType='svg'
                    width='16px'
                    height='16px'
                    viewBox='0 0 16 16'
                    name='menu-down'
                />
            );

            return (
                <li
                    className={cx('mobileMenu')}
                    onClick={() => {
                        this.toggleMobileMenu(menuId);
                    }}
                    automation-id={`at-product-link-${id}`}
                    key={menu.linkName}
                >
                    <p className={cx('subMenu', menuActive && 'menuBorder')}>
                        {menu.linkName} {downIcon}
                    </p>
                    <div
                        className={cx(
                            'mobSeconLevelmenu',
                            `${menuId}`,
                            selectMenu === menuId && isVisible ? 'mobvisible' : 'none'
                        )}
                    >
                        {menu.children.map((item, i) => {
                            return (
                                <div id='mobSecLevItem' key={item.linkUrl} className={cx('mobSeconLevelmenuItem')}>
                                    <Link
                                        automation-id={`at-my-orders-and-return-dropdown-${id}-${i}`}
                                        to={item.linkUrl}
                                        onClick={e => this.handleRequestSupportPopup(item, e)}
                                    >
                                        <div className={cx('mobSeconLevelMenuItemIcon')}>
                                            <Image
                                                theme={cx('mobSeconLevelMenuItemLogo')}
                                                src={item.imageUrl}
                                                alt={item.altText}
                                            />
                                        </div>
                                        <div className={cx('mobSeconLevelMenuItemName')}>
                                            <span className={cx('menuLink')}>{item.linkName}</span>
                                        </div>
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                </li>
            );
        }
        return (
            <li id='submenulink' key={`submenulink-${id}`}>
                <Link
                    automation-id={`at-product-link-${id}`}
                    to={menu.linkUrl}
                    onClick={e => this.handleRequestSupportPopup(menu, e)}
                    className={cx('subMenu')}
                >
                    {menu.linkName}
                </Link>
            </li>
        );
    };

    renderMobileAccount = () => {
        const accountComponent = this.props.headerData[COMMON_COMPONENT.ACCOUNT_MENU] || {};
        const logoutLink = _find(accountComponent.childComponents, [UNIQUE_ID, COMMON_COMPONENT.LOGOUT_LINK]);
        const { selectMenu, isVisible } = this.state;

        return (
            <>
                {(accountComponent.childComponents || []).length > 1 && (
                    <li
                        automation-id='at-my-account-dropdown'
                        onClick={() => {
                            this.toggleMobileMenu('acc');
                        }}
                    >
                        <p className={cx('subMenu', selectMenu === 'acc' && isVisible && 'menuActive' && 'menuBorder')}>
                            {accountComponent.name}
                            <Icon
                                className={cx(
                                    'downIcon',
                                    'mobilesubLevel',
                                    selectMenu === 'acc' && isVisible && 'menuUp'
                                )}
                                iconType='svg'
                                width='16px'
                                height='16px'
                                viewBox='0 0 16 16'
                                name='menu-down'
                            />
                        </p>
                        <div
                            className={cx(
                                'mobSeconLevelmenu',
                                selectMenu === 'acc' && isVisible ? 'mobvisible' : 'none'
                            )}
                        >
                            <div>
                                {(accountComponent.childComponents || []).map(menu => {
                                    if (menu.url !== URL_PATHS.LOGOUT) {
                                        return (
                                            <div key={menu.url} className={cx('mobSeconLevelmenuItem')}>
                                                <Link to={menu.url}>
                                                    <div
                                                        className={cx('mobSeconLevelMenuItemName')}
                                                        role='presentation'
                                                        onClick={this.toggleDetails}
                                                    >
                                                        <span className={cx('menuLink')}>{menu.name}</span>
                                                    </div>
                                                </Link>
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        </div>
                    </li>
                )}
                {logoutLink && (
                    <li automation-id='at-logout-button' id='logout'>
                        <a href='#' className={cx('subMenu')} onClick={this.logoutEvent}>
                            {logoutLink.name}
                        </a>
                    </li>
                )}
            </>
        );
    };

    render() {
        const {
            userDetails,
            showDashboardHeader,
            showOutlet,
            creditInformation,
            miniCart: miniCartProps,
            isSticky,
            components,
            showRequestSupport,
            showMiniCart
        } = this.props;
        const { totalCartCount = 0 } = miniCartProps;
        const { menuList = [], logo = {}, mobilelogo = {}, mobilelogoCut = {} } = this.props.headerData;
        const { isShowMenu, screenWidth } = this.state;
        const logoComp = _find(components, [UNIQUE_ID, LOCALIZATION.LOGO_COMPONENT]) || {};
        const searchBox = _find(components, [UNIQUE_ID, COMMON_COMPONENT.SEARCH_BOX]) || {};
        const miniCart = _find(components, [UNIQUE_ID, COMMON_COMPONENT.MINI_CART]) || {};
        const myAccount = _find(components, [UNIQUE_ID, COMMON_COMPONENT.ACCOUNT_MENU]);
        const headerNav = _find(components, [UNIQUE_ID, COMMON_COMPONENT.HEADER]) || {};
        const searchBoxComp = this.props.headerData[COMMON_COMPONENT.SEARCH_BOX];
        const miniCartComp = this.props.headerData[COMMON_COMPONENT.MINI_CART];
        const creditLimtComp = this.props.headerData[COMMON_COMPONENT.CREDIT_LIMIT] || {};
        const creditEmpty = _isEmpty(_get(creditInformation, 'creditUsed.formattedValue', ''));
        const isRoundLogo = ROUND_LOGO_COUNTRIES.includes(getSiteId());
        const isReturn = window.location.pathname.includes(URL_PATHS.PLP_RETURN) || isReturnEmpties();
        const isZPOP = window.location.pathname.includes(URL_PATHS.PLP_ZPOP) || isUseMarketingMaterials();
        
        return (    
            <div automation-id='at-header-of-the-screen' className={cx('row', 'headerCont')}>
                {showOutlet && this.renderOutLet()}
                <div className={cx(isSticky && 'stickyHeader')} id='stickyHeader'>
                    {showDashboardHeader && (
                        <div className={cx('mainHeaderSection', userDetails && 'userInfo')}>
                            <ul className={cx('headerBlockList')}>
                                <li>
                                    <div className={cx('hamberger-icon')} automation-id='at-hamburger-menu'>
                                        <button
                                            id='menuBtn'
                                            className={cx(isShowMenu ? 'close' : 'menu')}
                                            onClick={this.toggleDetails}
                                            type='button'
                                        >
                                            <div className={cx('navIcon', isShowMenu && 'closeIcon')}>
                                                <div className={cx('hambuger')}>
                                                    {!isShowMenu && (
                                                        <Icon
                                                            iconType='svg'
                                                            width='27px'
                                                            height='18px'
                                                            viewBox='0 0 27 18'
                                                            name='menu-icon'
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </button>
                                    </div>

                                    <div className={cx('menuContainer', 'desktopMenu', isShowMenu && 'menuOpen')}>
                                        <div className={cx('mobileHeader')}>
                                            <ul className={cx('mobileHeaderContainer')}>
                                                <li>
                                                    <button
                                                        automation-id='at-close-hamburger'
                                                        id='mobileBtn'
                                                        onClick={this.toggleDetails}
                                                        type='button'
                                                        aria-label='menuIcon'
                                                    >
                                                        <Icon
                                                            iconType='svg'
                                                            width='27px'
                                                            height='25px'
                                                            viewBox='0 0 24 25'
                                                            name='mobile-close'
                                                        />
                                                    </button>
                                                </li>
                                                <li automation-id='at-cchbc-logo'>
                                                {mobilelogo && mobilelogo.imageUrl && (
                                                    <Image
                                                        theme={cx(
                                                            isRoundLogo ? 'roundMobileLogo' : 'defaultMobileLogo'
                                                        )}
                                                        src={mobilelogo.imageUrl}
                                                        alt={mobilelogo.alt}
                                                    />
                                                    )}
                                                </li>
                                            </ul>
                                            <ul>
                                                <CreditLimit theme='mobileCreditBalance' />
                                            </ul>
                                        </div>
                                        <ul className={cx('sidebarInner')}>
                                            <li>
                                                {searchBoxComp && (
                                                    <div
                                                        className={cx('mobileListheader')}
                                                        automation-id='at-search-bar'
                                                    >
                                                        <div className={cx('mobileSearchBox')}>
                                                            <SearchBox
                                                                customSearch={cx('customSearch')}
                                                                toggleDetails={this.toggleDetails}
                                                                theme={cx('mobsearchboxContainer')}
                                                                searchTerm={this.state.searchTerm}
                                                                id='mobile'
                                                                showSearchIcon
                                                                placeholder={searchBoxComp.name}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                <ul key='at-navigation-link' automation-id='at-navigation-link'>
                                                    {menuList.map((menu, id) => this.renderMobileMenu(menu, id))}
                                                </ul>
                                            </li>
                                            {this.renderMobileAccount()}
                                        </ul>
                                    </div>
                                </li>
                                <SmartEdit data={logoComp}>
                                    {(screenWidth < 768 && (
                                        <li
                                            className={cx('headerList', 'logoSectionBlock', 'logoCut')}
                                            data-automation-id='logoBlock-menu'
                                        >
                                            {mobilelogoCut && mobilelogoCut.imageUrl && (
                                                <Image
                                                    automationId='at-cchbc-logo'
                                                    theme={cx(
                                                        'headerImgContimg',
                                                        isRoundLogo ? 'roundLogo' : 'defaultLogo'
                                                    )}
                                                    src={mobilelogoCut.imageUrl}
                                                    alt={mobilelogoCut.altText}
                                                    linkUrl={URL_PATHS.HOME}
                                                />
                                            )}
                                        </li>
                                    )) || (
                                        <li
                                            className={cx('headerList', 'logoSectionBlock')}
                                            data-automation-id='logoBlock-menu'
                                        >
                                            {logo && logo.imageUrl && (
                                                <Image
                                                    automationId='at-cchbc-logo'
                                                    theme={cx(
                                                        'headerImgContimg',
                                                        isRoundLogo ? 'roundLogo' : 'defaultLogo'
                                                    )}
                                                    src={logo.imageUrl}
                                                    alt={logo.altText}
                                                    linkUrl={URL_PATHS.HOME}
                                                />
                                            )}
                                        </li>
                                    )}
                                </SmartEdit>
                                <SmartEdit data={searchBox}>
                                    <li
                                        className={cx('headerList', 'searchSectionBlock')}
                                        automation-id='at-search-bar'
                                    >
                                        {searchBoxComp && (
                                            <div className={cx('searchBox')}>
                                                <SearchBox
                                                    id='desktop'
                                                    searchTerm={this.state.searchTerm}
                                                    placeholder={searchBoxComp.name}
                                                />
                                                <span className={cx('srchProduct')}>
                                                    <Icon
                                                        iconType='svg'
                                                        width='22px'
                                                        height='16px'
                                                        viewBox='0 0 22 16'
                                                        name='magnifier'
                                                    />
                                                </span>
                                            </div>
                                        )}
                                    </li>
                                </SmartEdit>
                                <li onClick={this.handleCartClick} className={cx('cartIconMobile')}>
                                    <div className={cx('cartIconContainer')}>
                                        <div className={cx('badge')}>{totalCartCount || 0}</div>
                                        <Cart color='#000000' width='24px' />
                                    </div>
                                </li>
                                <li
                                    className={cx(
                                        'headerList',
                                        'ctaSectionBlock',
                                        'right',
                                        creditEmpty && 'ctaSectionInput'
                                    )}
                                >
                                    <div className={cx('bagBlock', 'cartBag')}>
                                        <ul className={cx(creditEmpty && 'creditAvail')}>
                                            <CreditLimit />
                                            <Notification />
                                            <li automation-id='at-outlet-dropdown' className={cx('userContent')}>
                                                <SmartEdit data={myAccount}>
                                                    <Tooltip
                                                        renderTooltipContents={this.renderMyAccount()}
                                                        CustomClassName={cx('customClassName')}
                                                        customTooltipContent={cx('customTooltipContent')}
                                                        customInnerStyle={cx('customInnerStyle')}
                                                    >
                                                        <span
                                                            className={cx(
                                                                'userIcon',
                                                                _isEmpty(creditLimtComp.name) && 'userEmpty'
                                                            )}
                                                            automation-id='at-my-account-link'
                                                        >
                                                            <Icon
                                                                iconType='svg'
                                                                width='24px'
                                                                height='24px'
                                                                viewBox='0 0 24 24'
                                                                name='account'
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                </SmartEdit>
                                            </li>
                                            {miniCartComp && (
                                                <li
                                                    automation-id='at-cart-button'
                                                    className={cx('mobileIcons', isRoundLogo && 'roundMobileIcons')}
                                                >
                                                    <SmartEdit data={miniCart}>
                                                        <Link
                                                            onClick={(event) => {
                                                                if (getMiniCartWorkingWay() === MINI_CART_WORKING_WAYS.SKIPMINICART) {
                                                                    this.redirectToCart(event);
                                                                } else {
                                                                    this.showMiniCart(event);
                                                                }
                                                                
                                                                try {
                                                                    buttonClickEvent(buttonClickEventConfigs.header.actions.cartView, buttonClickEventConfigs.header.labels.cartViewMinicart, buttonClickEventConfigs.header.categories.header);
                                                                } catch (ex) {
                                                                    console.error(ex);
                                                                }
                                                            }}
                                                        >
                                                            <span className={cx('cartTxt')}>{miniCartComp.name}</span>
                                                            <span className={cx('cartIcon')} automation-id='at-cart'>
                                                                <Icon
                                                                    iconType='svg'
                                                                    width='20px'
                                                                    height='20px'
                                                                    viewBox='0 0 34 34'
                                                                    name='cart'
                                                                />
                                                            </span>
                                                            <span className={cx('cartCount')}>{totalCartCount}</span>
                                                        </Link>
                                                    </SmartEdit>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    )}

                    {/* It's more readable for me */}
                    {/* This is detailed Minicart which will be loaded if users seleced Full minicart */}
                    {showMiniCart && getMiniCartWorkingWay() === MINI_CART_WORKING_WAYS.CRMLOAD && (
                        <MiniCart onClose={this.showMiniCart} />
                    )}

                    {/* This is detailed Minicart which will be loaded if users seleced Quick minicart but are orderin Empties or Marketing Materials */}
                    {showMiniCart &&
                        getMiniCartWorkingWay() === MINI_CART_WORKING_WAYS.MINICARTNOCRM &&
                        (isReturn || isZPOP) && <MiniCart onClose={this.showMiniCart} />}

                    {/* This is quick Minicart which will be loaded if users seleced Quick minicart*/}
                    {showMiniCart &&
                        getMiniCartWorkingWay() === MINI_CART_WORKING_WAYS.MINICARTNOCRM &&
                        !isReturn &&
                        !isZPOP && <OptimizedMiniCart onClose={this.showMiniCart} />}

                    {/* Need to load only for desktop */}

                    <div
                        className={cx('header-nav-bar-sec', 'hiddenSm', 'hiddenMd', isSticky && 'stickyNav')}
                        automation-id='at-navigation-link'
                    >
                        <SmartEdit data={headerNav}>
                            <ul className={cx('mainNav', 'defaultNav')}>
                                {menuList.map((menu, index) => this.renderDesktopMenu(menu, index))}
                            </ul>
                        </SmartEdit>
                    </div>
                </div>

                {showRequestSupport && this.renderSupportModal()}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    deviceType: _get(state, 'context.deviceType'),
    pageTitle: _get(state, 'pageContent.pageTitle'),
    pageTitlePrefix: _get(state, 'pageContent.pageTitlePrefix'),
    selectedOutlet: _get(state, 'pageContent.selectedOutlet'),
    outletList: _get(state, 'pageContent.outletList'),
    headerData: _get(state, 'pageContent.headerData'),
    creditInformation: _get(state, 'creditInformation', {}),
    userDetails: _get(state, 'userDetails'),
    miniCart: _get(state, 'cartDetails.miniCart'),
    components: _get(state, 'pageContent.components'),
    bdaaSuggestion: _get(state, 'products.bdaaSuggestion')
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            restoreCart,
            changeOrderType,
            logoutUser
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
