import { takeLatest, put } from "redux-saga/effects";
import { showMultipleNotifications } from "../actions/NotificationAction";
import { SHOW_ORDER_NOTIFICATIONS } from "../actionTypes/NotificationActionTypes";
import { MESSAGE_TYPES, URL_PATHS } from "../common/Constants";
import {  setPromoDisclaimerMessage, setSelfiRedCartMessage } from "../common/UserManager/UserManager";


export function* showOrderNotificationsSaga(action) {
    const isCartPage = window.location.pathname === URL_PATHS.CART;
    const { data } = action;

    let {
        promoDisclaimerMessage,
        selfiRedDiscountAddedMessage,
        selfREDAppliedDiscountMessage,
        selfiRedOrderConfirmationMsg,
        multiCartSummary = {},
        message,
        saveLocal,
        checkPage,
        validationData,
        loyaltyDiscountAddedMessage,
        productValidationMessage
    } = data;

    if (!validationData) {
        validationData = multiCartSummary.validationData
    }

    let messages = [];

    if (validationData) {
        const validationDataMessages = [
            ...(Array.isArray(validationData) ? validationData : [validationData])
        ];

        validationDataMessages.map(content => {
            messages.push({
                type: MESSAGE_TYPES.WARN,
                content
            })
        })
    }

    if (message) {
        messages.push({
            type: MESSAGE_TYPES.WARN,
            content: message
        })
    }

    if (data.selfiRedDiscountAddedMessage || selfiRedDiscountAddedMessage || selfiRedOrderConfirmationMsg) {
        saveLocal && setSelfiRedCartMessage(data.selfiRedDiscountAddedMessage || selfiRedDiscountAddedMessage || selfiRedOrderConfirmationMsg)
        if (checkPage) {
            isCartPage && messages.push({
                type: MESSAGE_TYPES.BLUE_NOTIFICATION,
                content: selfiRedOrderConfirmationMsg || selfiRedDiscountAddedMessage || data.selfiRedDiscountAddedMessage
            }) 
        } else {
            messages.push({
                type: MESSAGE_TYPES.BLUE_NOTIFICATION,
                content: selfiRedOrderConfirmationMsg || selfiRedDiscountAddedMessage || data.selfiRedDiscountAddedMessage
            })    
        }
    }

    if (selfREDAppliedDiscountMessage) {
        if (isCartPage) {
            messages.push({
                type: MESSAGE_TYPES.SUCCESS,
                content: selfREDAppliedDiscountMessage
            })
        }
    }

    if (multiCartSummary.promoDisclaimerMessage || promoDisclaimerMessage) {
        saveLocal && setPromoDisclaimerMessage(multiCartSummary.promoDisclaimerMessage || promoDisclaimerMessage)
        if (checkPage) {
            isCartPage && messages.push({
                type: MESSAGE_TYPES.WARN,
                content: promoDisclaimerMessage || multiCartSummary.promoDisclaimerMessage
            })
        } else {
            messages.push({
                type: MESSAGE_TYPES.WARN,
                content: promoDisclaimerMessage || multiCartSummary.promoDisclaimerMessage
            })
        }
    }

    if (loyaltyDiscountAddedMessage) {
        if (isCartPage) {
            messages.push({
                type: MESSAGE_TYPES.BLUE_NOTIFICATION,
                content: loyaltyDiscountAddedMessage
            })
        }
    }

    if (productValidationMessage) {
        if (isCartPage) {
            messages.push({
                type: MESSAGE_TYPES.WARN,
                content: productValidationMessage
            })
        }
    }

    
    if (messages.length > 0) {
        yield put(showMultipleNotifications({ messages }));
    }
}

export default function* watchMessageRequest() {
    yield takeLatest(SHOW_ORDER_NOTIFICATIONS, showOrderNotificationsSaga)
}