import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import * as styles from './ProductCardListView.css';
import classNames from 'classnames/bind';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router';
import SelectBox from '../../core/SelectBox/SelectBox';
import promoTag from '../../../assets/images/Promo-icon2.svg'
import AddToCart from '../AddToCart/AddToCart';
import {
    PLP_L10N,
    HOME_L10N,
    GENERIC_L10N,
    IMAGE_COMING_SOON,
    STOCK_LEVELS
} from '../../../common/Constants';
import { ProductCardsWater } from './ProductCardsWater';
import Image from '../../core/Image/Image';
import Icon from '../../core/Icon/Icon';
import Countdown from 'react-countdown';
import moment from 'moment-timezone';
import { mainPartnerCode } from '../../../common/UserManager/UserManager';


const cx = classNames.bind(styles);

export const ProductCardListView = (props) => {
    const [isToggledPromoDescription, setIsToggledPromoDescription] = useState(false)
    const mainPartnerCountryCode = mainPartnerCode();
    const assetBaseUrl = useSelector(state => state.context.environment.assetBaseUrl);

    const {
        quantity,
        isQtyError,
        handleProductClickEvent,
        goToProductPage,
        productUrl,
        renderDescription,
        updateUnits,
        renderFavTermIcons,
        stateUnits,
        gtmList,
        depositProduct,
        depositProductLogoUrl
    } = props
    const {
        name,
        sku,
        skuId,
        price, // productObj.price
        brand, // productObj.brand
        category, //productObj.cat
        listPrice,
        isShowPrice,
        units,
        imageUrl,
        deviceType,
        isShowFilter,
        isReturnEmpties,
        isMarketingMaterials,
        isFrom,
        url,
        code,
        conversionFactors,
        addToCartMessage,
        localization,
        commonLocalization,
        isPromo,
        isUpdateSap,
        selectedView,
        baseUnit,
        blockAddToCartEnabled,
        addToCartNotifiedButton,

        stockFlag,
        stockNotified,
        waterCategory,
        openStockNotificationModal,
        addToCartNotifyButton,
        promotion,
        isInBdaaSlider,
        updateFilters,
        maxQty

    } = props.parentProps;

    const timeZone =
        Intl.DateTimeFormat()?.resolvedOptions()?.timeZone ||
        countriesWithTimeZones[mainPartnerCountryCode]?.TimeZones[0];

    const endDate = moment.tz(promotion?.endDate, timeZone);

    return (
        <li
            role='presentation'
            onClick={handleProductClickEvent}
            className={cx('ProductItem', 'ListItem', 'Row', isPromo && 'promoItem')}
        >
            <ProductCardsWater deviceType={deviceType} mode="list" show={waterCategory} tooltipMessage={commonLocalization[GENERIC_L10N.WATER_CATEGORY_MESSAGE]} />
            <div className={cx('ProductDataContainerList')}>
                {selectedView !== localization[PLP_L10N.COMPACT_VIEW] && (
                    <div
                        role='presentation'
                        className={cx('ProductImageContainer', 'ProductImageContainerList', 'pointer')}
                        onClick={() => goToProductPage(url, skuId || sku.id)}
                    >
                        {_isEmpty(imageUrl) ? (
                            <img src={IMAGE_COMING_SOON} alt='product coming soon' />
                        ) : (
                            <Image automationId='at-product-image' src={imageUrl} alt='product name' />
                        )}
                         {(depositProduct && depositProductLogoUrl) && (
                            <img
                                src={`${assetBaseUrl}${depositProductLogoUrl}`}
                                style={{position: "absolute", bottom: 0, right: 0, width: '35px', height: '35px', objectFit: "contain"}}
                                alt='return_fee_logo'
                            />
                         )} 
                    </div>
                )}

                <div className={cx('ProductControlsList')}>
                    <div className={cx('ProductDataContainer')}>
                        <Link to={productUrl()} className={cx('ProdTitle', 'ProdTitleList')}>
                            <span translate='no'>{name}</span>
                        </Link>
                        <p className={cx('skuId', isPromo && 'skuIdPromo')}>
                            <span automation-id='at-product-id'>{commonLocalization[GENERIC_L10N.SKU]}</span>{' '}
                            {skuId || sku.id}
                        </p>
                        <RenderStockQuantity stockFlag={stockFlag} commonLocalization={commonLocalization} isList={true} />
                        {isPromo && deviceType.isDesktop && <div className={cx('promoProductToggleContainer')} onClick={() => setIsToggledPromoDescription((prev) => !prev)}>
                            <img src={promoTag} />
                            <p className={cx('promoProductToggle')}>{localization[PLP_L10N.PROMO_PRODUCT_TILE]}</p>
                            <Icon
                                className={cx('svgClass', 'promoProductToggleArrow', !isToggledPromoDescription && 'rotateDown')}
                                iconType='svg'
                                width='24px'
                                height='24px'
                                viewBox='0 0 24 24'
                                name='up'
                            />

                        </div>}
                    </div>
                    <div className={cx('PriceAndTypeContainer', 'PriceAndTypeContainerList', !listPrice && 'flex1')}>
                        <p automation-id='at-prices-for-each-item' className={cx('text-right', 'pad5', 'noWrp', isPromo && 'pricePromo')}>
                            {(isShowPrice && listPrice) || ''}
                        </p>
                        <div className={cx('mobhid', 'visibleList')}>
                            <SelectBox
                                arrowTheme={cx('selectOpt')}
                                automationId='at-uom-selection-dropdown'
                                deaultIcon
                                id={skuId || sku.id}
                                onChange={val => {
                                    const item = units.find(({ value }) => value === val);
                                    updateUnits(item);
                                }}
                                options={units}
                                defaultValue={stateUnits.value}
                                disabled={isMarketingMaterials}
                                themeSelectBox={cx('select')}
                            />
                        </div>
                    </div>
                </div>
                <div className={cx('AddToCartControlsContainer')}>
                    <div>
                        <AddToCart
                            isFromProductCardList
                            ignoreSavedCart={isReturnEmpties || isMarketingMaterials}
                            baseUnit={baseUnit}
                            isUpdateSap={isUpdateSap}
                            isQtyError={isQtyError}
                            skuId={skuId || sku.id}
                            automationId='at-add-to-card-button'
                            autoHeight
                            customAddtoCartStyle={cx(
                                'btn-addtocart',
                                isShowFilter ? 'hidenAddBtn' : 'filterAddBtn'
                            )}
                            iconName='addCart-red'
                            buttonValue={
                                isFrom === 'HOME' && deviceType.isDesktop
                                    ? localization[HOME_L10N.ADD_BTN]
                                    : commonLocalization[GENERIC_L10N.ADD_TO_CART]
                            }
                            productInfo={[
                                {
                                    code,
                                    quantityType: stateUnits.id,
                                    quantity,
                                    conversionFactors
                                }
                            ]}
                            analyticsProductInfo={[
                                {
                                    name,
                                    // id: code.substring(22),
                                    id: skuId || sku.id,
                                    uom: stateUnits.id,
                                    quantity,
                                    list: gtmList,
                                    price: price || listPrice, // productObj.price
                                    brand, // productObj.brand
                                    category //productObj.cat
                                }
                            ]}
                            stockFlag={stockFlag}
                            stockNotified={stockNotified}
                            openStockNotificationModal={e => openStockNotificationModal()}
                            addToCartMessage={addToCartMessage}
                            addToCartNotifyButton={addToCartNotifyButton}
                            addToCartNotifiedButton={addToCartNotifiedButton}
                            blockAddToCartEnabled={blockAddToCartEnabled}
                        />
                    </div>
                    {!isReturnEmpties && !isMarketingMaterials && renderFavTermIcons(stateUnits)}
                </div>
            </div>
            {quantity === maxQty && <div className={cx("maxQtyWarn")}>
                <Icon
                    className={cx("maxQtyWarn-icon")}
                    iconType='svg'
                    width='15px'
                    height='16px'
                    viewBox='0 0 15 16'
                    name='warning-list'
                /><p>
                    {commonLocalization[GENERIC_L10N.STOCK_ALLOCATION_LIMIT_MESSAGE_LONG]}
                </p></div>}
            <div className={cx('promoToggledDescription', isToggledPromoDescription && 'openPromoToggledDescription')}>
                {promotion?.description && (
                    <div>
                        <p className={cx('promoTitle')} >
                            {promotion.description}
                        </p>
                    </div>
                )}

                <div className={cx('promoCountdownContainer')}>
                    <Countdown
                        date={endDate}
                        renderer={({ days, hours, minutes }) => {
                            return (
                                <div className={cx('promoCountdown')}>
                                    {days}
                                    {commonLocalization?.day} : {hours > 9 ? hours : `0${hours} `}
                                    {commonLocalization?.hour} : {minutes > 9 ? minutes : `0${minutes} `}
                                    {commonLocalization?.minute}
                                </div>
                            );
                        }}
                    />
                    <p className={cx('promoValidFrom')}>
                        <span className={cx('promoValidFromLabel')}>{localization[PLP_L10N.VALID_FROM]}</span> {promotion?.formattedStartDate}{' '}
                        <span className={cx('promoValidFromLabel')}>{localization[PLP_L10N.VALID_UNTIL]}</span> {promotion?.formattedEndDate}
                    </p>
                </div>
                <div className={cx('promoCTA')} onClick={e => {
                    updateFilters('promotion', promotion.description, null, true)

                }}>
                    <p>{localization[PLP_L10N.PROMO_VIEW_ALL_PRODUCTS]}</p>

                    <Icon
                        className={cx('svgClass', 'promoProductToggleArrow', 'rotateRight')}
                        iconType='svg'
                        width='24px'
                        height='24px'
                        viewBox='0 0 24 24'
                        name='up'
                    />
                </div>
            </div>

        </li>
    );
}




const RenderStockQuantity = (props) => {
    const { stockFlag, commonLocalization } = props;

    if (stockFlag) {
        const isOutOfStock = stockFlag === 'OUT_OF_STOCK';
        return (
            <div
                className={cx(
                    'StockContainer',
                    STOCK_LEVELS[stockFlag].class,
                    'StockContainerList',
                    isOutOfStock && 'OutOfStockList'
                )}
            >
                {isOutOfStock
                    ? commonLocalization[GENERIC_L10N.OUT_OF_STOCK]
                    : commonLocalization[GENERIC_L10N.IN_STOCK]}
            </div>
        );
    }
    return <div />;
};
