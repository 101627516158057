import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { PRE_LOGIN_UID } from '../../../common/Constants';
import { findComponent } from '../../../common/Utils/Utils';
import { getCountry, getLocalization } from '../../../common/UserManager/UserManager';


import * as styles from './WhyCCHBC.css';

const cx = classNames.bind(styles);

const WhyCCHBC = ({ className, cmsComponents, handlePopup, callUsBtn }) => {

    const selectedCountry = getCountry();
    const localization = getLocalization();
    const irishCountries = ["ni", "roi"];

    if (!cmsComponents || selectedCountry === 'Russia') {
        return null;
    }

    const assetBaseUrl = useSelector(state => state.context.environment.assetBaseUrl);
    const bannerComponent = findComponent(cmsComponents, PRE_LOGIN_UID.WHYCCHBC_COMPONENTS.BANNER_UID);
    const titleComponent = findComponent(cmsComponents, PRE_LOGIN_UID.WHYCCHBC_COMPONENTS.TITLE_UID);
    const paragraph1Component = findComponent(cmsComponents, PRE_LOGIN_UID.WHYCCHBC_COMPONENTS.PARAGRAPH1_UID);
    const paragraph2Component = findComponent(cmsComponents, PRE_LOGIN_UID.WHYCCHBC_COMPONENTS.PARAGRAPH2_UID);
    const testComponent = findComponent(cmsComponents, PRE_LOGIN_UID.WHYCCHBC_COMPONENTS.TEXT_UID);
    const countriesComponent = findComponent(cmsComponents, PRE_LOGIN_UID.WHYCCHBC_COMPONENTS.COUNTRIES_UID);
    const customersComponent = findComponent(cmsComponents, PRE_LOGIN_UID.WHYCCHBC_COMPONENTS.CUSTOMERS_UID);
    const brandsComponent = findComponent(cmsComponents, PRE_LOGIN_UID.WHYCCHBC_COMPONENTS.BRANDS_UID);
    const getStartedComponent = findComponent(cmsComponents, PRE_LOGIN_UID.WHYCCHBC_COMPONENTS.CALLUS_UID)

    return (
        <section className={cx(className)}>
            <div className={cx("whycchbc")}>
                <div className={cx("whycchbc-info")}>
                    <h2>{titleComponent?.content}</h2>
                    <p>{paragraph1Component?.content} {paragraph2Component?.content}</p>
                    <h4>{testComponent?.content}</h4>
                    {irishCountries.includes(localization?.siteId) ? (
                        <button type="button" className={cx('call-us')} onClick={() => {
                            window.open(getStartedComponent?.url, '_blank');
                        }}>
                            {getStartedComponent?.name}
                        </button>
                    ) : (
                        <button
                            type="button"
                            className={cx("call-us")}
                            onClick={(e) => handlePopup(e)}
                        >
                            {callUsBtn?.content}
                        </button>
                    )}
                </div>
                <div className={cx("whycchbc-img-container")}>
                    {bannerComponent && <img src={`${assetBaseUrl}/${bannerComponent.url}`} alt={bannerComponent.altText} />}
                </div>
            </div>
            <div className={cx("whycchbc-statistics")}>
                <p>
                    <span>{countriesComponent?.content?.split('<br>').filter(Boolean)[0]}</span>
                    {countriesComponent?.content?.split('<br>').filter(Boolean)[1]}
                </p>
                <p>
                    <span>{customersComponent?.content?.split('<br>').filter(Boolean)[0]}</span>
                    {customersComponent?.content?.split('<br>').filter(Boolean)[1]}
                </p>
                <p>
                    <span>{brandsComponent?.content?.split('<br>').filter(Boolean)[0]}</span>
                    {brandsComponent?.content?.split('<br>').filter(Boolean)[1]}
                </p>
            </div>
        </section >
    )
};

export default WhyCCHBC;