/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { PRE_LOGIN_UID, URL_PATHS } from '../../../common/Constants';
import { findComponent } from '../../../common/Utils/Utils';
import { getLocalization } from '../../../common/UserManager/UserManager';
import Icon from '../../../components/core/Icon/Icon';

import * as styles from './Banner.css';

const cx = classNames.bind(styles);

const Banner = ({ cmsComponents, callUsBtn, handlePopup, handleCustomSignUpPopup, signupLink }) => {
    const localization = getLocalization();

    if (!cmsComponents) {
        return null;
    }

    const assetBaseUrl = useSelector(state => state.context.environment.assetBaseUrl);

    const backGroundImgComp = findComponent(
        cmsComponents,
        PRE_LOGIN_UID.BACKGROUND_BANNER_COMPONENTS.BACKGROUND_IMG_UID
    );
    const titleComponent = findComponent(cmsComponents, PRE_LOGIN_UID.BACKGROUND_BANNER_COMPONENTS.TITLE_UID);
    const paragraphComponent = findComponent(cmsComponents, PRE_LOGIN_UID.BACKGROUND_BANNER_COMPONENTS.PARAGRAPH_UID);
    const signUpComponent = findComponent(cmsComponents, PRE_LOGIN_UID.BACKGROUND_BANNER_COMPONENTS.SIGNUP_BTN_UID);
    const loginComponent = findComponent(cmsComponents, PRE_LOGIN_UID.BACKGROUND_BANNER_COMPONENTS.LOGIN_LINK_UID);
    const ignoreSelfRegistrationCountries = ["austria"];
    const irishCountries = ["ni", "roi"];

    return (
        <section
            className={cx('banner')}
            style={
                backGroundImgComp && {
                    backgroundImage: `url(${assetBaseUrl}${backGroundImgComp.url})`
                }
            }
        >
            <div className={cx('banner-icon-wrapper')}>
                <Icon
                    className={cx('icon')}
                    iconType='svg'
                    width='auto'
                    height='25px'
                    viewBox='0 0 20 32'
                    name='mouse'
                />
            </div>
            <div className={cx('banner-content')}>
                <h1 className={cx('banner-title')}>{titleComponent?.content?.split('<br>').filter(Boolean)[0]}</h1>
                <h3 className={cx('banner-subtitle')}>{titleComponent?.content?.split('<br>').filter(Boolean)[1]}</h3>
                <p className={cx('banner-paragraphcontent')}>{paragraphComponent?.content}</p>
                <div className={cx('banner-login-links')}>
                    {ignoreSelfRegistrationCountries.includes(localization?.siteId) ? (
                        <a role="presentation" className={cx('signup-btn')} onClick={e => handlePopup(e)}>
                            {callUsBtn?.content}
                        </a>
                    ) : irishCountries.includes(localization?.siteId) ? (
                        <a role="presentation" onKeyDown={e => handleCustomSignUpPopup(e)} className={cx('signup-btn')} onClick={e => handleCustomSignUpPopup(e)}>
                            {signupLink?.content}
                        </a>
                    ) : (
                        <Link to={URL_PATHS.REGISTER} className={cx('signup-btn')}>
                            {signUpComponent?.content}
                        </Link>
                    )}
                    <span>
                        {loginComponent?.content?.split('<a>')[0]}
                        <Link to={URL_PATHS.LOGIN}>{loginComponent?.content?.split('<a>')[1].split('</a>')[0]}</Link>
                    </span>
                </div>
            </div>
        </section>
    );
};

export default Banner;
