export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';

export const AUTH = {
    LOGIN: '/login',
    LOGOUT: '/logout',
    TOKEN: '/token',
    IS_VALID: '/is-valid',
    REGISTER_USER: '/register',
    USER: '/user',
    USERS: '/users',
    CURRENT: '/current',
    CUSTOMER_TYPE: '/customertype',
    CREDIT_LIMIT: '/creditlimit',
    UPDATE_CONSENT: '/update-consent',
    DEFAULT_DELIVERY_DATE: '/getdefaultdeliverydate',
    LOCALE: '/account/locale'
};

export const CONTENT = {
    PAGES: '/cms/pages/',
    QUERY: '?fields=DEFAULT',
    COMPONENTS: '/cms/components?fields=DEFAULT&componentIds='
};

export const HEADERS = {
    AUTH: 'Authorization',
    CONTENT_TYPE: 'Content-type',
    ACCEPT: 'Accept'
};

export const LOCALIZATION = {
    COUNTRY_SELECT: '/countryselect',
    PRE_LOGIN: '/prelogin'
};

export const PARAMS = {
    CURRENCY: 'curr',
    LANG: 'lang',
    SITE_ID: 'siteId',
    USER_ID: 'userId',
    CART_ID: 'cartId',
    IS_RETURN_EMPTIES: 'isReturnEmptiesMode',
    IS_MARKETING_MATRIALS: 'isMarketingMaterialsMode',
    IS_PICKUP_ORDER: 'isPickUpMode',
    PLANT_CODE: 'plantCode',
    WHOLE_SALER_ID: 'wholeSalerId',
    IS_OPEN_MINI_CART: 'isOpenMiniCart',
    IS_REMOVE_OOS_FLOW: 'isRemoveOosFlow',
    FIELDS: 'fields',
    FULL: 'FULL',
    MESSAGE_ID: 'messageId',
    FROM: 'from',
    TO: 'to',
    VIEW_CHOICE: 'viewchoice'
};

export const CART_API = {
    MINI_CART: '/minicart',
    ADD_TO_CART: '/addToCart',
    CART: '/cart',
    GET_SAVED_CART: '/getSavedCart',
    MULTI_CART: '/multicart',
    CLEAR_ALL: '/clearall',
    CLEAR_SAVED_CART: '/clearSavedCart',
    DELETE_SAVED_CART_ENTRY: '/deleteSavedCartEntry',
    REMOVE_PRODUCT_SAVED_CART: '/deleteSavedCartEntry',
    UPDATED_DELIVERY_DATE: '/updatedeliverydate',
    RESET_DELIVERY_DATE: '/resetdeliverydate',
    RE_ORDER: '/reorder',
    PROMO_TO_CART: '/promotion',
    CONV_FACTOR: '/convfactor',
    SUBSCRIBE_TO_PRODUCT: '/stocknotification/save'
};

export const COOLER_API = {
    COOLER_DASHBOARD: '/coolers'
};

export const CHECKOUT_API = {
    CHECKOUT: '/checkout'
};

export const DELIVERY_API = {
    ACTIVATE_DELIVERY: '/activate',
    GET_PLANTS: '/getplants',
    CHANGE_PLANT: '/changeplant',
    WHOLE_SALER: '/wholesaler',
    CHANGE_WHOLE_SALER: '/changewholesaler'
};

export const TEMPLATE_API = {
    SAVED_CART: '/saved-cart',
    MY_SAVED_CARDS: '/my-account/saved-carts'
};

export const OUTLET = {
    STORE_SELECT: '/storeselection',
    SELECT_OUTLET: '/b2bunit',
    LIST: '/availableb2bunits',
    SET_LIST: '/partnerchange',
    CURRENT_OUTLET: '/currentoutlet',
    LOAD_OUTLET_PRICES: '/loadoutletprices',
    STOCK_ALLOCATION: '/stockallocation'
};
