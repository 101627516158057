import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames/bind';
import { PAGE_ID } from '../../../common/Constants';
import Layout from '../../../components/site/Layout/Layout';
import styles from './FoodServiceAggregator.css';
import StatsComponent from './components/StatsComponent';
import NotSellingOnlineComponent from './components/NotSellingOnlineComponent';
import CardsComponent from './components/CardsComponent';
import DosAndDontsComponent from './components/DosAndDontsComponent';
import BestSellingComponent from './components/BestSellingComponent';
import DigitalMenusComponent from './components/DigitalMenusComponent';
import ReadyToStartComponent from './components/ReadyToStartComponent';
import PopUpModal from './components/PopUpModal';
import BannerComponent from './components/BannerComponent';
import * as pageActions from '../../../actions/PageContentAction';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../common/GoogleTagManager/GoogleTagManager';

const cx = classNames.bind(styles);

const FoodServiceAggregator = props => {
    const tipsRef = useRef(null);
    const getStartedRef = useRef(null);
    const dispatch = useDispatch();
    const pageId = PAGE_ID.TIPS;

    useEffect(() => {
        dispatch(pageActions.getPageContent({ pageId }));
    }, [dispatch, pageId]);

    const [getStartedModalOpen, setGetStartedModalOpen] = useState(false);
    const [learnMoreModalOpen, setLearnMoreModalOpen] = useState(false);

    const scrollTo = (ref, block = 'start') => {
        if (ref && ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block });
        }
    };

    return (
        <Layout showDashboardHeader showOutlet theme={cx('fsaContainer')}>
            <PopUpModal
                isOpen={getStartedModalOpen}
                closeModal={() => {
                    setGetStartedModalOpen(false);
                    try {
                        buttonClickEvent(
                            buttonClickEventConfigs.fsa.actions.getStarted,
                            buttonClickEventConfigs.fsa.labels.getStartedClosePopUp,
                            buttonClickEventConfigs.fsa.categories.tipsNTricks
                        );
                    } catch (ex) {
                        console.error(ex);
                    }
                }}
                onGetStartedClicked={() => scrollTo(getStartedRef)}
                isLearnMore={false}
            />
            <PopUpModal
                isOpen={learnMoreModalOpen}
                closeModal={() => {
                    setLearnMoreModalOpen(false);
                    try {
                        buttonClickEvent(
                            buttonClickEventConfigs.fsa.actions.learnMore,
                            buttonClickEventConfigs.fsa.labels.learnMoreClosePopup,
                            buttonClickEventConfigs.fsa.categories.tipsNTricks
                        );
                    } catch (ex) {
                        console.error(ex);
                    }
                }}
                onGetStartedClicked={() => scrollTo(tipsRef)}
                isLearnMore
            />
            <BannerComponent />
            <StatsComponent />
            <NotSellingOnlineComponent
                getStartedRef={getStartedRef}
                onGetStartedClicked={() => {
                    setGetStartedModalOpen(true);
                    try {
                        buttonClickEvent(
                            buttonClickEventConfigs.fsa.actions.getStarted,
                            buttonClickEventConfigs.fsa.labels.getStartedOpenPopUp,
                            buttonClickEventConfigs.fsa.categories.tipsNTricks
                        );
                    } catch (ex) {
                        console.error(ex);
                    }
                }}
                onLearnMoreClicked={() => {
                    setLearnMoreModalOpen(true);
                    try {
                        buttonClickEvent(
                            buttonClickEventConfigs.fsa.actions.learnMore,
                            buttonClickEventConfigs.fsa.labels.learnMoreOpenPopup,
                            buttonClickEventConfigs.fsa.categories.tipsNTricks
                        );
                    } catch (ex) {
                        console.error(ex);
                    }
                }}
            />
            <CardsComponent tipsRef={tipsRef} />
            <DosAndDontsComponent />
            <BestSellingComponent />
            <DigitalMenusComponent />
            <ReadyToStartComponent
                onGetStartedClicked={() => {
                    scrollTo(getStartedRef);
                    try {
                        buttonClickEvent(
                            buttonClickEventConfigs.fsa.actions.readyToStart,
                            buttonClickEventConfigs.fsa.labels.readyToStartGetStarted,
                            buttonClickEventConfigs.fsa.categories.tipsNTricks
                        );
                    } catch (ex) {
                        console.error(ex);
                    }
                }}
                onTipsClicked={() => {
                    scrollTo(tipsRef);
                    try {
                        buttonClickEvent(
                            buttonClickEventConfigs.fsa.actions.readyToStart,
                            buttonClickEventConfigs.fsa.labels.readyToStartCheckOurTips,
                            buttonClickEventConfigs.fsa.categories.tipsNTricks
                        );
                    } catch (ex) {
                        console.error(ex);
                    }
                }}
            />
        </Layout>
    );
};

export default FoodServiceAggregator;
