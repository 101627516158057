/**
 * Holds all the available host names that is configured in the environments of settings files.
 */
const apiHosts = {
    cchcms: 'cchcms',
    cchcommerce: 'cchcommerce',
    swagger: 'swagger'
};

const BLOB_URLS = ['invoices/download', 'catalogue/excel'];

export const RESPONSE_TYPES = {
    BLOB: 'blob',
    JSON: 'json'
};
export const REQUEST_METHODS = {
    DELETE: 'delete',
    GET: 'get',
    PATCH: 'patch',
    POST: 'post',
    PUT: 'put'
};

/**
 * To get the required request configuration for all the requests.
 *
 * @param {object} config
 * @param {string} method
 * @param {string} url
 */
export const getRequestConfig = (config, method, url) => {
    const { baseURL, headers, params, data, responseType } = config;
    const requestConfig = {
        // `url` is the server URL that will be used for the request
        url,

        // `method` is the request method to be used when making the request
        method: method || REQUEST_METHODS.GET, // default

        // `baseURL` will be prepended to `url` unless `url` is absolute.
        baseURL,

        // `headers` are custom headers to be sent
        headers,

        // `params` are the URL parameters to be sent with the request
        // Must be a plain object or a URLSearchParams object
        params,

        // `data` is the data to be sent as the request body
        // Only applicable for request methods 'PUT', 'POST', and 'PATCH'
        // When no `transformRequest` is set, must be of one of the following types:
        // - string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams
        // - Browser only: FormData, File, Blob
        // - Node only: Stream, Buffer
        data,
        ...(BLOB_URLS.some(bUrl => url.includes(bUrl)) && {
            responseType: RESPONSE_TYPES.BLOB
        }),
        ...(responseType && { responseType }),
        withCredentials: true
    };

    return requestConfig;
};

/**
 * For each of the different combination of apiHosts and any other config attributes are defined as config methods here.
 */
class RestConfig {
    static v2CmsApiConfig = ({ baseInfo } = {}) => ({
        baseInfo,
        baseURL: '/cchcmswebservices',
        isAuthRequired: true,
        isL10NRequired: true,
        contentType: 'application/json',
        apiHost: apiHosts.cchcms
    });

    static v2NoAuthComApiConfig = ({ baseInfo } = {}) => ({
        baseInfo,
        baseURL: '/cchcommercewebservices',
        version: '/v2',
        isAuthRequired: false,
        isL10NRequired: true,
        contentType: 'application/json',
        apiHost: apiHosts.cchcommerce
    });

    static v2ComApiConfig = ({ baseInfo }) => ({
        baseInfo,
        baseURL: '/cchcommercewebservices',
        version: '/v2',
        isAuthRequired: true,
        isL10NRequired: true,
        contentType: 'application/json',
        apiHost: apiHosts.cchcommerce
    });

    static v2ComApiConfigPDF = ({ baseInfo }) => ({
        baseInfo,
        baseURL: '/cchcommercewebservices',
        version: '/v2',
        isAuthRequired: true,
        isL10NRequired: true,
        contentType: 'application/json',
        apiHost: apiHosts.cchcommerce,
        responseType: 'arraybuffer'
    });

    static v2CmsSwaggerApiConfig = ({ baseInfo } = {}) => ({
        baseInfo,
        baseURL: '/cchcmswebservices',
        isAuthRequired: false,
        isL10NRequired: true,
        contentType: 'application/json',
        apiHost: apiHosts.swagger
    });

    static v2ComSwaggerApiConfig = ({ baseInfo } = {}) => ({
        baseInfo,
        baseURL: '/cchcommercewebservices',
        version: '/v2',
        isAuthRequired: false,
        isL10NRequired: true,
        contentType: 'application/json',
        apiHost: apiHosts.swagger
    });

    static authorizationApiConfig = ({ baseInfo }) => ({
        baseInfo,
        baseURL: '/authorizationserver/oauth',
        contentType: 'application/json',
        apiHost: apiHosts.cchcommerce
    });

    static v2NoAuthComApiConfigNoL10N = ({ baseInfo } = {}) => ({
        baseInfo,
        baseURL: '/cchcommercewebservices',
        version: '/v2',
        isAuthRequired: false,
        isL10NRequired: false,
        contentType: 'application/json',
        apiHost: apiHosts.cchcommerce
    });
}

export default RestConfig;
