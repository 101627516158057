import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import * as styles from './CartSummary.css';
import Icon from '../../core/Icon/Icon';
import { CART_L10N, CART_PRICING_TYPES, GENERIC_L10N } from '../../../common/Constants';
import { formatHungaryTousends, trimString } from '../../../common/Utils/Utils';

const cx = classNames.bind(styles);

const CartSummary = props => {
    const {
        theme,
        title,
        iconName,
        toggleAccordionView,
        setActive,
        pricingDataList = [],
        cartDetails = {},
        localization,
        commonLocalization,
        showPricingMessage,
        button
    } = props;
    const iconArrowName = setActive ? 'acc-up' : 'acc-down';
    const totalTax = (
        _find(cartDetails.pricingBreakDown, pricingData => !_isEmpty(pricingData.totalPriceBeforeTax)) || {}
    ).totalPriceBeforeTax;

    let pricingDetails = {};
    if (!_isEmpty(pricingDataList)) {
        pricingDetails = pricingDataList[0] || {};
    }
    const { pricingInfoStyle, pricingConditions, yourSavings } = pricingDetails;
    const logistic = _find(pricingConditions, logisticdata => /^(LF_)/.test(logisticdata.key));

    const iconViewList = (
        <Icon
            className={cx('accordionArrow')}
            iconType='svg'
            width='24px'
            height='24px'
            viewBox='0 0 16 16'
            name={iconArrowName}
        />
    );

    return (
        <div className={cx('cartSummaryWrapper', theme)} automation-id='cart-summary'>
            <button
                type='button'
                className={cx('mobileViewBtn', setActive && 'visibleClick')}
                onClick={toggleAccordionView}
            >
                <Icon className={cx('leftIcon')} width='24px' height='24px' viewBox='0 0 24 24' name={iconName} />
                <h1 className={cx('title')}>{title}</h1>
                {iconViewList}
            </button>
            <ul className={cx('summaryDetails', setActive ? 'visibleSummary' : 'hiddenSummary')}>
                <li key='totalItems' className={cx('totalSplit')}>
                    <span className={cx('totVal')}>{localization[CART_L10N.TOTAL_ITEMS]}</span>
                    <span className={cx('totPrice')}>{cartDetails.totalUnitCount}</span>
                </li>
                {!_isEmpty(cartDetails.subTotal) && (
                    <li key='subTotal' className={cx('totalSplit')}>
                        <span className={cx('totVal')}>{localization[CART_L10N.SUB_TOTAL]} </span>
                        <span className={cx('totPrice')}>{formatHungaryTousends(cartDetails.subTotal.formattedValue)}</span>
                    </li>
                )}
                {!_isEmpty(cartDetails.selfREDDiscount) && (
                   <li key='selfRedDiscount' className={cx('selfRedDiscount')}>
                        <span className={cx('totVal')}>
                            {commonLocalization?.[GENERIC_L10N.SELF_RED.SELF_RED_DISCOUNT_LABEL]}
                        </span>
                        <span className={cx('totPrice')}>
                            {formatHungaryTousends(cartDetails.selfREDDiscount.formattedValue)}
                        </span>
                    </li>
                )}
                {!_isEmpty(cartDetails.loyaltyDiscount) && (
                   <li key='loyaltyDiscount' className={cx('loyaltyDiscount')}>
                        <span className={cx('totVal')}>
                            {commonLocalization[GENERIC_L10N.LOYALTY_DISCOUNT]}
                        </span>
                        <span className={cx('totPrice')}>
                            {formatHungaryTousends(cartDetails.loyaltyDiscount.formattedValue)}
                        </span>
                    </li>
                )}
                {totalTax && (
                    <li key='totalTax' className={cx('totalSplit')}>
                        <span className={cx('totVal')}>{localization[CART_L10N.TAX]} </span>
                        <span className={cx('totPrice')}>{formatHungaryTousends(totalTax.formattedValue)}</span>
                    </li>
                )}
                {!_isEmpty(cartDetails.depositFee) && (
                    <li key='depositFee' className={cx('totalSplit')}>
                        <span className={cx('totVal')}>{commonLocalization[GENERIC_L10N.DEPOSIT_FEE]} </span>
                        <span className={cx('totPrice')}>{formatHungaryTousends(cartDetails.depositFee.formattedValue)}</span>
                    </li>
                )}
                {cartDetails.ecoTax && !_isEmpty(cartDetails.totalPriceWithTax) && (
                    <li key='ecoTax' className={cx('totalSplit')}>
                        <span className={cx('totVal')}>{commonLocalization[GENERIC_L10N.ECO_TAX]} </span>
                        <span className={cx('totPrice')}>
                            {cartDetails.totalPriceWithTax.currencyIso} {cartDetails.ecoTax}
                        </span>
                    </li>
                )}
                {!_isEmpty(cartDetails.totalPriceWithTax) && (
                    <li key='orderTotal' className={cx('clearfix', 'orderTotal')}>
                        <span className={cx('totVal')}>{localization[CART_L10N.ORDER_TOTAL]} </span>
                        <span className={cx('totPrice')}>{formatHungaryTousends(cartDetails.totalPriceWithTax.formattedValue)}</span>
                        {showPricingMessage && (
                            <div className={cx('totPriceMsg')}>
                                *<span>{showPricingMessage}</span>
                            </div>
                        )}
                    </li>
                )}

                {pricingInfoStyle === CART_PRICING_TYPES.PRICING &&
                    (pricingConditions || []).map(({ key, value }) => {
                        return (
                            <li key={key}>
                                <span className={cx('totVal')}>{trimString(key)}</span>
                                <span className={cx('totPrice')}>{formatHungaryTousends(value.formattedValue)}</span>
                            </li>
                        );
                    })}
                {pricingInfoStyle === CART_PRICING_TYPES.SAVINGS && (
                    <>
                        {!_isEmpty(yourSavings) && (
                            <li key='yourSavings'>
                                <span className={cx('totVal')}>{commonLocalization[GENERIC_L10N.YOUR_SAVINGS]} </span>
                                <span className={cx('totPrice')}>{formatHungaryTousends(yourSavings.formattedValue)}</span>
                            </li>
                        )}
                        {!_isEmpty(logistic) && (
                            <li key='logistic'>
                                <span className={cx('totVal')}>{trimString(logistic.key)} </span>
                                <span className={cx('totPrice')}>{formatHungaryTousends((logistic.value || {}).formattedValue)}</span>
                            </li>
                        )}
                    </>
                )}
                {!_isEmpty(pricingDetails.orderEmpties) && (
                    <li key='orderEmpties'>
                        <span className={cx('totVal')}>{localization[CART_L10N.ORDER_EMPTIES_TOTAL]} </span>
                        <span className={cx('totPrice')}>{formatHungaryTousends(pricingDetails.orderEmpties.formattedValue)}</span>
                    </li>
                )}
                {cartDetails.palletRequired && (
                    <li key='noOfPallet'>
                        <span className={cx('totVal')}>{localization[CART_L10N.NO_PALLETS]} </span>
                        <span className={cx('totPrice')}>{cartDetails.palletRequired}</span>
                    </li>
                )}
                {cartDetails.weight && (
                    <li key='weight'>
                        <span className={cx('totVal')}>{localization[CART_L10N.WEIGHT]} </span>
                        <span className={cx('totPrice')}>
                            {cartDetails.weight} {localization[CART_L10N.WEIGHT_UNIT]}
                        </span>
                    </li>
                )}
            </ul>
            <div>
                {button && (button)}
            </div>
        </div>
    );
};

CartSummary.propTypes = {
    theme: PropTypes.string,
    title: PropTypes.string,
    iconName: PropTypes.string,
    toggleAccordionView: PropTypes.func,
    setActive: PropTypes.bool,
    showPricingMessage: PropTypes.string,
    pricingDataList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    cartDetails: PropTypes.objectOf(PropTypes.any),
    localization: PropTypes.objectOf(PropTypes.any),
    commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

CartSummary.defaultProps = {
    theme: '',
    title: '',
    iconName: '',
    toggleAccordionView: undefined,
    setActive: false,
    pricingDataList: [],
    cartDetails: {},
    localization: {},
    commonLocalization: {},
    showPricingMessage: ''
};

export default CartSummary;
