// Common Constants for Application.

export const UNIQUE_ID = 'uid';

export const LOGOUNT_INFO = {
    LOGOUT_REASON: 'logountReason',
    SMART_ORDER_MSG_HOME: 'smartOrderMsgHome',
    SMART_ORDER_MSG_PLP: 'smartOrderMsgPLP'
};

export const LOGIN_INFO = {
    DEVICE_UUID: 'device_uuid',
    RANDOM_UUID: 'random_uuid',
    ACCESS_TOKEN: 'access_token',
    REFRESH_TOKEN: 'refresh_token',
    EXPIRES_IN: 'expires_in',
    L10N: 'l10n',
    CART_ID: 'cartId',
    IS_RETURN_EMPTIES: 'isReturnEmptiesMode',
    IS_MARKETING_MATERIALS: 'isMarketingMaterialsMode',
    IS_PICKUP_ORDER: 'isPickUpMode',
    CURRENCY: 'curr',
    ISO_CODE: 'isocode',
    CURRENCY_SYMBOL: 'symbol',
    COUNTRY: 'country',
    SITE_ID: 'siteId',
    LANGUAGE: 'language',
    LANG: 'lang',
    ONE_TRUST_ISO_CODE: 'oneTrustIsoCode',
    REDIRECT_FROM: 'redirectFrom',
    TOKEN_USER_ID: 'principal',
    DELIVERY_TYPE: 'deliveryType',
    LOADED_PRICES: 'loadedPrices',
    ACTIVE_WHOLE_SALER: 'activeWholeSaler',
    IS_INDIRECT_USER: 'isIndirectUser',
    IS_PICKUP_ALLOWED: 'isPickUpAllowed',
    MINI_CART_WORKING_WAY: 'miniCartWorkingWay',
    IS_ZAD_ORDER_TYPE: 'isZADOrderType',
    CUST_GROUP_CODE: 'assignedCustGroupCode',
    USER_INFO: 'userInfo',
    USER_ID: 'userId',
    COOKIE_CONSENT_STATUS: 'cookieConsentStatus',
    IS_ORDER_SPLIT_INDIRECT: 'isOrderSplitIndirect',
    IS_BDAA_ACTIVE: 'bdaaActive',
    REMOVING_OOS_PRODUCTS_ENABLED: 'removingOosProductsEnabled',
    MAIN_PARTNER_ID: 'mainPartnerId',
    MAIN_PARTNER_COUNTRY: 'mainPartnerCountry',
    EXCEL_ORDER_ULOAD: 'excelOrderUpload',
    SERBIAN_LANG: 'sh',
    ONETRUST_SERBIAN_LANG: 'sr',
    DEFAULT_DELIVERY_DATE: 'defaultDeliveryDate',
    IS_STOCK_ALLOWED: 'stockAllowed',
    LOYALTY_ENABLED: 'loyaltyEnabled',
    CHAT_BOT_ENABLED: 'chatBotEnabled',
};

export const URL_PATHS = {
    LOCALIZATION: '/selectcountry',
    STORE_FRONT: '/cchstorefront/*',
    LOGIN: '/login',
    PRE_LOGIN: '/prelogin',
    FORGOT_PWD: '/login/forgot',
    RESET_PWD: '/login/reset',
    VERIFY: '/login/verify',
    REGISTER: '/login/register',
    LOGOUT: '/logout',
    HOME: '/home',
    PLP: '/plp',
    PLP_ORDER: '/plp/order',
    PLP_SEARCH: '/plp/search',
    PLP_RETURN: '/plp/return',
    PLP_ZPOP: 'plp/zpop',
    PLP_ORDER_FAV: '/plp/order/favorite/true',
    PLP_ORDER_RECOMMENDED: '/plp/order/bdaa,page/true,1',
    PDP: '/pdp',
    CART: '/cart',
    CHECKOUT: '/checkout',
    ORDER_LIST: '/account/order-information/orderHistory',
    ORDER_DETAILS: '/order/details',
    ORDER_TEMPLATE: '/order/template',
    ORDER_TEMPLATE_DETAILS: '/order/template',
    INVOICES: '/invoices',
    MY_ACCOUNT: '/account',
    MY_ACCOUNT_INVOICES: '/account/order-information/invoices',
    UPDATE_PASSWORD: '/account/updatepassword',
    USER_MAINTENANCE: '/account/usermaintenance',
    MOBILE_ACCOUNT_PAGE: '/account/mobileAccountPage',
    ACCOUNT_MY_SERVICES: '/account/myServices',
    ACCOUNT_SHOPPING_LIST: '/account/shoppingList',
    ORDER_CONFIRMATION: '/checkout/order',
    PROMOTIONS: '/promotions',
    CHECKOUT_PROMOTIONS: '/checkout/promotions',
    FAQ: '/faq',
    IMPRINT: '/imprint',
    CLAIMS_HISTORY: '/claim/history',
    PROMOTIONS_CALENDAR: '/promotions',
    ACCOUNT_DATA: '/account',
    PLP_REFERRER_URL: 'plpReferrerUrl',
    CX_PREVIEW: '/cx-preview',
    COOKIE_LIST: '/cookiepolicy',
    COOLER_DASHBOARD: '/coolerDashboard',
    CAPMPAIGNS_VALENTINE_URL: '/campaign/valentine-day',
    CAPMPAIGNS_EASTER_URL: '/campaign/easter',
    CAPMPAIGNS_SUMMER_URL: '/campaign/summer',
    CAPMPAIGNS_BACKTOSCHOOL_URL: '/campaign/back-to-school',
    CAPMPAIGNS_BLACKFRIDAY_URL: '/campaign/black-Friday',
    CAPMPAIGNS_CHRISTMAS_URL: '/campaign/christmas',
    CAPMPAIGNS_WOMENDAY_URL: '/campaign/women-day',
    CAPMPAIGNS_HALLOWEEN_URL: '/campaign/halloween',
    CAPMPAIGNS_SAINTPATRICKDAY_URL: '/campaign/saint-patrick-day',
    CAPMPAIGNS_RAMADAN_URL: '/campaign/ramadan',
    CAPMPAIGNS_CONSTITUTIONDAY_URL: '/campaign/constitution-day',
    CAPMPAIGNS_INDEPENDANCEDAY_URL: '/campaign/independance-day',
    CAPMPAIGNS_LABOURDAY_URL: '/campaign/labour-day',
    CAPMPAIGNS_RUSSIADAY_URL: '/campaign/russia-day',
    CAPMPAIGNS_URL: '/campaign',
    ORDER_INFORMATION: 'account/order-information',
    ACCOUNT_SETTINGS: '/account/settings',
    ACCOUNT_MY_REWARDS: '/account/myrewards',
    ACCOUNT_MY_SERVICES_FSA: '/account/myServices/fsa',
    MY_SALES: '/account/myreports/mysales',
    REPORT_POWER_BI: 'power-bi-report'
};

export const PAGE_ID = {
    SELECTLANGUAGE: 'countryselect',
    LOGIN: 'loginpage',
    PRE_LOGIN: 'prelogin',
    LOGIN_FORGOT: 'forgotpassword',
    LOGIN_RESET: 'resetpassword',
    LOGIN_IMPRINT: 'loginimprint',
    LOGIN_REGISTER_USER: 'loginregister',
    ERROR_404: '404',
    GENERIC: 'cchgeneric',
    HOME: 'home',
    PLP: 'productlist',
    PDP: 'productdetails',
    TEMPLATE_LIST: 'templatelist',
    TEMPLATE_DETAILS: 'templatedetails',
    ORDER_LIST: 'orderhistory',
    ORDER_DETAILS: 'orderdetails',
    ORDERS_INFORMATION: 'ordersinformation',
    ORDER_CONFIRMATION: 'checkoutconfirmation',
    INVOICES: 'invoices',
    MINI_CART: 'minicart',
    CART: 'cartpage',
    FAQ: 'faqpage',
    IMPRINT: 'imprintpage',
    CHECKOUT: 'checkout',
    PROMOTIONS_CALENDAR: 'promotioncalendar',
    CAMPAIGNS: 'campaign',
    PROMOTIONS: 'promotion',
    CLAIMS_HISTORY: 'claims',
    UPDATE_PASSWORD: 'changepassword',
    USER_MAINTENANCE: 'usermaintenance',
    PERSONAL: 'personaldata',
    COMPANY: 'companydata',
    CUSTOMER_FACT_SHEET: 'factsheet',
    COOKIE_NOTICE: 'cookienotice',
    ORDER_INFORMATION: 'orderinformation',
    MY_ACCOUNT: 'myaccount',
    SHOPPING_LIST: 'shoppinglist',
    MY_SERVICES: 'myservices',
    MY_SETTINGS: 'accountsettings',
    MY_REWARDS: 'myrewards',
    LOYALTY_ORDERS: 'loyaltyorders',
    ONBOARDING: 'onboarding',
    SELF_RED: 'selfred',
    TIPS: 'tips',
    MY_SALES: "mysales"
};

export const NO_COMMON_SAGA = [
    PAGE_ID.SELECTLANGUAGE,
    PAGE_ID.LOGIN,
    PAGE_ID.COOKIE_NOTICE,
    PAGE_ID.PRE_LOGIN,
    PAGE_ID.LOGIN_FORGOT,
    PAGE_ID.LOGIN_RESET,
    PAGE_ID.LOGIN_REGISTER_USER,
    PAGE_ID.LOGIN_IMPRINT
];

export const LOCALIZATION = {
    BANNER_COMPONENT: 'BackgroundBannerComponent-React',
    LOGO_COMPONENT: 'SiteLogoComponent-React'
};

export const MESSAGE_TYPES = {
    SUCCESS: 'success',
    WARN: 'warning',
    ERROR: 'error',
    INFO: 'information',
    BLUE_NOTIFICATION: 'blueNotification',
    CART_INFO: 'cartInfo'
};

export const MESSAGE_LEVELS = {
    PAGE: 'page',
    SECTION: 'section',
    INLINE: 'inline'
};

export const USER_PREFERENCES = {
    PLP_VIEW: 'plpView'
};

export const PRE_LOGIN_UID = {
    LOGO_COMPONENTS: {
        UID: 'SiteLogoPreloginPageComponent-React',
        MOBILE_UID: 'SiteLogoPreloginPageComponentCut-React'
    },
    FOOTER_COMPONENTS: {
        UID: 'footerComponent',
        CALL_US: 'FooterCallUsText-React',
        PHONE: 'FooterPhoneNumber-React',
        CANCEL_BTN: 'FooterCancelBtnText-React'
    },
    HEADER_LINKS: {
        BENEFITS_UID: 'BenefitsHeaderNameComponent-React',
        WORKFLOW_UID: 'HowitWorksHeaderNameComponent-React',
        ABOUTUS_UID: 'AboutUsHeaderNameComponent-React',
        WHYCCHBC_UID: 'WhyCCHBCHeaderNameComponent-React',
        OURCOMMITMENTS_UID: 'OurCommitmentHeaderNameComponent-React',
        SIGNUP_CUSTOM_LINK: 'SignUpHeaderNameComponent-React',
        SIGNUP_UID: 'btnSignUp-React',
        LOGIN_UID: 'btnLogin-React',
        YES_BTN: 'btnYes-React',
        NO_BTN: 'btnNo-React',
        CUSTOM_MODAL_TEXT: 'BackgroundBannerLoginPreloginPageComponent-React'
    },
    BACKGROUND_BANNER_COMPONENTS: {
        UID_CONTAINS: 'Background',
        BACKGROUND_IMG_UID: 'BackgroundBannerPreloginPageComponent-React',
        TITLE_UID: 'BackgroundBannerTitlePreloginPageComponent-React',
        PARAGRAPH_UID: 'BackgroundBannerContentPreloginPageComponent-React',
        SIGNUP_BTN_UID: 'BackgroundBannerSignUpPreloginPageComponent-React',
        LOGIN_LINK_UID: 'BackgroundBannerLoginPreloginPageComponent-React'
    },
    BENEFITS_COMPONENTS: {
        UID_CONTAINS: 'Benefits',
        BANNER_UID: 'BenefitsBannerPreloginPageComponent-React',
        TITLE_UID: 'BenefitsTitlePreloginPageComponent-React',
        TEXT1_UID: 'BenefitsText1LoginPreloginPageComponent-React',
        TEXT2_UID: 'BenefitsText2LoginPreloginPageComponent-React',
        TEXT3_UID: 'BenefitsText3LoginPreloginPageComponent-React',
        TEXT4_UID: 'BenefitsText4LoginPreloginPageComponent-React',
        TEXT5_UID: 'BenefitsText5LoginPreloginPageComponent-React',
        TEXT6_UID: 'BenefitsText6LoginPreloginPageComponent-React'
    },
    HOWITWORKS_COMPONENTS: {
        UID_CONTAINS: 'HowitWorks',
        BANNER_UID: 'HowitWorksBannerPreloginPageComponent-React',
        TITLE_UID: 'HowitWorksTitlePreloginPageComponent-React',
        PARAGRAPH_UID: 'HowitWorksContentPreloginPageComponent-React',
        REGISTER_UID: 'HowitWorksRegisterPreloginPageComponent-React',
        LOGIN_UID: 'HowitWorksLoginPreloginPageComponent-React',
        ORDER_UID: 'HowitWorksPlaceAnOrderPreloginPageComponent-React'
    },
    WHYCCHBC_COMPONENTS: {
        UID_CONTAINS: 'WhyCCHBC',
        BANNER_UID: 'WhyCCHBCBannerImagePreloginPageComponent-React',
        TITLE_UID: 'WhyCCHBCTitlePreloginPageComponent-React',
        PARAGRAPH1_UID: 'WhyCCHBCContent1PreloginPageComponent-React',
        PARAGRAPH2_UID: 'WhyCCHBCContent2PreloginPageComponent-React',
        TEXT_UID: 'WhyCCHBCTextPreloginPageComponent-React',
        CALLUS_UID: 'WhyCCHBCCallUsPreloginPageComponent-React',
        COUNTRIES_UID: 'WhyCCHBCNumberOfCountriesPreloginPageComponent-React',
        CUSTOMERS_UID: 'WhyCCHBCPotentialConsumersPreloginPageComponent-React',
        BRANDS_UID: 'WhyCCHBCBrandsInPortfolioPreloginPageComponent-React',
        CALL_US_BTN_UID: 'btnCallus-React'
    },
    OURCOMMITMENT_COMPONENTS: {
        UID_CONTAINS: 'OurCommitment',
        BANNER_UID: 'OurCommitmentBannerPreloginPageComponent-React',
        TITLE_UID: 'OurCommitmentTitlePreloginPageComponent-React',
        PARAGRAPH1_UID: 'OurCommitmentContent1PreloginPageComponent-React',
        PARAGRAPH2_UID: 'OurCommitmentContent2PreloginPageComponent-React',
        READ_MORE_LINK_UID: 'OurCommitmentReadMorePreloginPageComponent-React',
        READY_TO_ORDER_UID: 'OurCommitmentReadyToOrderPreloginPageComponent-React',
        SIGNUP_TEXT_UID: 'OurCommitmentSignUpTextPreloginPageComponent-React',
        LOGIN_TEXT_UID: 'OurCommitmentLoginTextPreloginPageComponent-React',
        SEPARATOR_TEXT_UID: 'OurCommitmentSeparatorTextPreloginPageComponent-React',
        READ_MORE_BTN_UID: 'btnReadMore-React'
    },
    FAQ_COMPONENTS: {
        TYPE: 'FAQParagraphComponent',
        TITLE_UID: 'FaqTitlePreloginPageComponent-React'
    }
};

export const LOGIN_UID = {
    SITE_LOGO: 'SiteLogoComponent-React',
    BG_BANNER: 'RightSideBannerComponent',
    BG_BANNER_UID: {
        [PAGE_ID.LOGIN]: 'RightSideLoginBannerComponent',
        [PAGE_ID.LOGIN_FORGOT]: 'RightSideForgotPwdBannerComponent',
        [PAGE_ID.LOGIN_RESET]: 'RightSideLoginResetBannerComponent',
        [PAGE_ID.LOGIN_IMPRINT]: 'RightSideLoginImprintBannerComponent',
        [PAGE_ID.LOGIN_REGISTER_USER]: 'RightSideRegWebUserBannerComponent'
    },
    IMPRINT_PARA: 'imprintLoginParaComp-React',
    FOOTER: 'footerComponent',
    IMPRINT: 'imprintLoginLinkComponent',
    BACK_TO_SITE: 'BackToSite_ReactNavNode',
    OUR_COMPANY: 'OurCompany_ReactNavNode',
    OUR_COMPANY_URL: 'OurCompanyLink',
    CONTACT_US: 'contactUsLinkComponent',
    TERMS: 'Terms&Conditions_ReactNavNode',
    PRIVACY: 'Privacy&CookiePolicy_ReactNavNode',
    SELF_REGISTER: 'HideRegisterWebUserComponent',
    PARA_COMP: 'loginParaComp-React',
    COPY_RIGHT: 'notice',
    PRIVACY_LINK_COMPONENT: 'privacyLinkComponent',
    NOT_YET_A_CUSTOMER: 'NotYetACustomerLogin-React',
    GET_STARTED: 'GetStartedLogin-React',
    CONTENT: {
        WELCOME: 'welcomeText',
        COUNTRY_CHANGE: 'countryChange',
        USER_NAME: 'userName',
        PASSWORD: 'password',
        LOGIN_BTN: 'loginBtn',
        FORGOT_PWD: 'forgotPassword',
        RESET_PWD: 'passwordReset',
        ALREADY_CUST: 'alreadyCustomer',
        REGISTER_CUST: 'registerUser',
        ACC_CALL_US: 'accountCallUs',
        CALL_US: 'callUs',
        PHONE_NO: 'phoneNo',
        COOKIE_CONFIRM: 'cookieConfirm',
        OKAY_BTN: 'okayBtn',
        CANCEL_BTN: 'btnCancel',
        EMAIL_US: 'accountEmailUs',
        EMAIL_ID: 'emailId'
    }
};

export const LOGIN_FORGOT_UID = {
    CONTENT: {
        WELCOME: 'welcomeText',
        PAGE_DESC: 'pageDescription',
        REMEMBER_PWD: 'rememberPassword',
        LOGIN_LINK: 'loginLink',
        USER_NAME: 'userName',
        USERID: 'userId',
        COMPANY_ID: 'companyId',
        RESET_BTN: 'resetBtn',
        RESET_HELP: 'resetHelp',
        CALL_US: 'callUs',
        PHONE_NO: 'phoneNo',
        COOKIE_CONFIRM: 'cookieConfirm',
        OKAY_BTN: 'okayBtn',
        CLOSE_BTN: 'closeBtn',
        EMAIL_US: 'accountEmailUs',
        EMAIL_ID: 'emailId'
    }
};

export const LOGIN_REGISTER_USER_UID = {
    CONTENT: {
        WELCOME: 'welcomeText',
        PAGE_DESC: 'pageDescription',
        USER_ACCOUNT_DESC: 'userAccountDesc',
        USER_NAME: 'userName',
        CUSTOMER_NO: 'customerNoPlaceholder',
        EMAIL: 'emailPlaceholder',
        LOGIN_LINK: 'loginLink',
        MANDATORY_INFO: 'mandatoryInfo',
        ERROR_INFO: 'errorInfo',
        VERIFY_BTN: 'verifyBtn',
        REGISTRATION_DESC: 'registrationDesc',
        NAME: 'namePlaceholder',
        SURNAME: 'surnamePlaceholder',
        MOBILE: 'mobilePlaceholder',
        REGISTER_BTN: 'registerBtn',
        REGISTER_ACCOUNT: 'regiterAccount',
        REGISTER_MODAL_DESC: 'registerModalContent',
        USER_ACTIVATE_DESC: 'userActivateDesc',
        CLOSE_BTN: 'closeBtn'
    }
};

export const LOGIN_RESET_UID = {
    CONTENT: {
        WELCOME: 'welcomeText',
        PAGE_DESC: 'pageDescription',
        NO_OF_VALIDATION: 'noOfPassValidation',
        NO_OF_CHAR_VALID: 'noOfCharValidation',
        LOWER_CASE_VALID: 'lowerCaseValidation',
        UPPER_CASE_VALID: 'upperCaseValidation',
        DIGIT_VALID: 'digitValidation',
        MATCH_PWD_VALID: 'matchPasswordValidation',
        USER_NAME: 'userName',
        CURRENT_PWD: 'currentPwd',
        NEW_PWD: 'newPwd',
        CONFIRM_PWD: 'confirmPwd',
        CHANGE_BTN: 'changeBtn',
        PRIVACY_TEXT: 'privacyPolicyAck',
        COOKIE_CONFIRM: 'cookieConfirm',
        OKAY_BTN: 'okayBtn',
        CANCEL_BTN: 'cancelBtn'
    }
};

export const MOBILE_NAV = {
    MOBILE_BAR_MENU: 'CommonMenuBarMobileNavigationComponent-React',
    MOBILE_ACCOUNT_NAV: 'AccountNavLink',
    ACCOUNT_ORDERS: 'MyOrders&ReturnsHeader-ReactNavNode',
    ACCOUNT_SERVICES: 'MyServicesHeader-ReactNavNode',
    MOBILE_NAVIGATION: 'MobileNavigationComponent',
    MY_ACCOUNT_MENU_LINK: 'MobileAccountNavNode'
};

export const NAV_COMPONENTS = {
    PERSONAL_DATA: 'PersonalDataLink-React'
};

export const COMMON_COMPONENT = {
    HEADER: 'HeaderNavBar',
    CART_WARNING_COMPONENT: 'cartWarningComponent',
    HEADER_OLD: 'HeaderNavBarComponent',
    HEADER_ORDERS_OLD: 'MyOrders&ReturnsHeader-ReactNavNode',
    HEADER_NAVIGATION_LINK_PROMOTION: 'PromotionsLink',
    CREDIT_LIMIT: 'CreditLimitInfoComponent-React',
    MINI_CART: 'CchMiniCartComponent-React',
    SEARCH_BOX: 'SearchBox-React',
    ACCOUNT_MENU: 'MenuNavBar',
    SUBMENU_IMAGE: 'SimpleBannerComponent',
    SIMPLE_BANNER: 'SimpleBannerComponent',
    SUNMENU_NAME: 'CMSLinkComponent',
    LOGO_COMPONENT: 'SiteLogoComponent-React',
    MOBILE_LOGO_COMPONENT: 'MobileSiteLogoComponent-React',
    MOBILE_LOGO_CUT_COMPONENT: 'MobileSiteLogoCutComponent-React',
    LOGOUT_LINK: 'LogoutLink-React',
    FOOTER_LOGO_COMPONENT: 'FooterSiteLogoNavNode',
    FOOTER_COMPONENT: 'FooterNavigationComponent',
    FOOTER_COMPONENT_UID: 'FooterNavigationComponent',
    FOOTER_MENU: 'FooterMainLinksNavNode',
    FOOTER_ICON_COMPONENT: 'FooterIconNavNode',
    FOOTER_CALL_US_LINK: 'FooterContactUsNavNode',
    FOOTER_SALES_LINK: 'FooterSalesRepNavNode',
    FOOTER_LIVE_CHAT: 'LiveChatFooter-ReactNavNode',
    FOOTER_FAQ: 'FooterFAQNavNode',
    HEADER_REQUEST_SUPPORT_LINK: 'ContactUsHeader-ReactNavNode',
    FOOTER_REQUEST_SUPPORT_LINK: 'ContactUsLinkComponent',
    FOOTER_TERMS_AND_CONDITIONS: 'TermsAndConditionsLink',
    FOOTER_BOTTOM_LINKS: 'FooterBottomLinksNavNode',
    FOOTER_MY_ACCOUNT_LINKS: 'FooterMyAccountLinksNavNode',
    FOOTER_MY_ACCOUNT_LINK: 'MyAccountLink',
    FOOTER_MY_SERVICES_LINKS: 'FooterMyServicesLinksNavNode',
    FOOTER_MY_SERVICES_SUPPORT_LINK: 'FooterRequestSupportLink',
    ...MOBILE_NAV
};

export const POP_ORDER_NAVS = {
    HEADER_CREATE_POP_ORDER: 'CreatePOPOrderHeader-ReactNavNode',
    FOOTER_CREATE_POP_ORDER: 'CreatePOPOrderLinkComponent'
};

export const RETURN_ORDER_NAV = {
    HEADER_CREATE_RETURN_ORDER: 'CreateReturnOrderHeader-ReactNavNode',
    FOOTER_CREATE_RETURN_ORDER: 'CreateReturnOrderLinkComponent'
};

export const GENERIC_L10N = {
    ADD_TO_CART: 'btnAddToCart',
    NEXT_PROMO: 'updCartBtn',
    REMOVE_PROMOTION: 'removePromoBtn',
    CANCEL_BTN: 'btnCancel',
    CLEAR_BTN: 'btnClear',
    CREATE_NEW_BTN: 'btnCreateNew',
    SEARCH_BTN: 'btnSearch',
    SUBMIT_BTN: 'btnSubmit',
    SAVE_BTN: 'btnSave',
    CREATE_BTN: 'btnCreate',
    REMOVE_BTN: 'removeBtn',
    CALL_US: 'callUs',
    DESC_CONCERN: 'descConcern',
    ITEMS: 'items',
    MAND_INFO: 'manInfo',
    NO_RESULTS_MSG: 'noResultsMsg',
    PAGE: 'page',
    SKU: 'sku',
    TEMP_DESC: 'templateDesc',
    TEMP_TITLE: 'templateMyTitle',
    TEMP_NEW_DESC: 'templateNewDesc',
    TEMP_NEW_FIELD: 'templateNewField',
    TEMP_NEW_TITLE: 'templateNewTitle',
    TEMP_OP_TITLE: 'templatePopUpTitle',
    TEMP_SELECT: 'templateSelect',
    TEMP_MY_DESC: 'templatesMyDesc',
    YOUR_CONCERN: 'yourConcern',
    YOUR_SAVINGS: 'yourSavingTxt',
    CHOOSE_SUPPORT: 'chooseSubject',
    REQUEST_SUPPORT: 'requestSupport',
    ERROR_MESSAGE: 'reqsSupportError',
    WEIGHT_SYMBOL: 'weightSymbol',
    PLEASE_TEXT: 'pleaseTxt',
    PHONE_NO: 'phoneNo',
    MORE: 'more',
    SEARCH_RESULTS: 'searchResults',
    FREE: 'free',
    DETAILS: 'details',
    ECO_TAX: 'ecoTaxLabel',
    DEPOSIT_FEE: 'depositFeeLabel',
    TOTAL_TAX: 'totalTaxLabel',
    LOYALTY_DISCOUNT: 'loyaltyDiscountLabel',
    SEARCH_PLACE_HOLDER: 'searchPlaceholder',
    BD_Name: 'BDName',
    BD_Phone: 'BDPhone',
    ADD_TO_CART_MESSAGE: 'addToCartMessage',
    CATALOGUE: 'btnCatalogue',
    DRAG_DROP_TITLE: 'dragDropContainerContent',
    DRAG_DROP_SUB_TITLE: 'dragDropContainerSubTitle',
    BROWSE: 'dragDropContainerBtnBrowse',
    SUPPORT_FILES: 'dragDropContainerSuppotedFiles',
    ERROR_UPLOAD_SUBTITLE: 'errUploadSubTitle',
    ERROR_UPLOAD_TITLE: 'errUploadTitle',
    MY_ACCOUNT: 'myAccount',
    OUT_OF_STOCK: 'outStockLabel',
    IN_STOCK: 'inStockLabel',
    IN_STOCK_LABEL: 'inStockLabel',
    OUT_STOCK_LABEL: 'outStockLabel',
    PROMOTION_LABEL: 'promotionLabel',
    STOCK_NOTIFICATION: {
        TITLE: 'stkNtfTitle',
        DESCRIPTION: 'stkNtfDescription',
        NOTIFY_ME: 'stkNtfNotifyMe',
        CANCEL: 'stkNtfCancel',
        GET_NOTIFIED: 'stkNtfGetNotified',
        EMAIL: 'stkNtfEmail'
    },
    LOYALTY: {
        TITLE: 'notificationsTitle',
        MUTE_TITLE: 'muteTitle',
        MUTE_SUBTITLE: 'muteSubTitle',
        LOYALTY_TITLE: 'loyaltyTitle',
        ALL_MESSAGES: 'allMessages',
        UNREAD_MESSAGES: 'unreadMessages',
        BACK: 'back',
        DELETE_MESSAGES: 'deleteMessages',
        GREETINGS: 'greetings'
    },
    SELF_RED: {
        ONBOARDING_POPUP_PAGE4_TITLE :'onboardingPopupPage4Title',
        ONBOARDING_POPUP_PAGE4_DESCRIPTION :'onboardingPopupPage4Description',
        SELF_RED_DISCOUNT_LABEL: 'selfREDDiscountLabel',
    },
    WATER_CATEGORY_MESSAGE: 'waterCategoryMessage',
    STOCK_ALLOCATION_LIMIT_MESSAGE_LONG: 'stockAllocationLimitMessage',
    CREDIT_ISSUE: 'creditIssue',
    CHECK_INVOICES: 'checkInvoices',
    LIMITED_QUANTITY: 'limitedQuantity',
    CHATBOT_POPUP_TEXT_1: 'chatbotPopupText1',
    CHATBOT_POPUP_TEXT_2: 'chatbotPopupText2',
    CHATBOT_POPUP_TEXT_3: 'chatbotPopupText3',
};

export const ORIGIN_LINKS = [URL_PATHS.IMPRINT, URL_PATHS.FAQ];

export const DEBOUNCE_DELAY = 1000;
export const MAX_PRODUCT_QUANTITY = 99999;
export const PROMO_NOTE_LENGTH = 190;
export const CART_PRODUCT_LENGTH = 60;
export const MIN_PRODUCT_QUANTITY = 1;
export const PAGE_ITEM_SIZE = 25;
export const ROUND_LOGO_COUNTRIES = ['nm'];

export const PLP_FILTERS = {
    PROMOTION: 'promotion',
    FAVORITE: 'favorite',
    PAGE: 'page',
    PAGE_ITEM_SIZE: 'pageItemSize',
    SHOW_PRICE: 'showPrice',
    RECOMMENDATIONS: 'bdaa',
    CAPACITY: 'capacity',
    BRAND: 'brand',
    IN_STOCK: 'inStock',
    PRICE: 'price',
    MIN_PRICE: 'minPriceFilterValue',
    MAX_PRICE: 'maxPriceFilterValue',
    PRICE_FILTER_FROM: 'priceFilterFrom',
    PRICE_FILTER_TO: 'priceFilterTo',
    IS_PRICE_PER_PIECE: 'isPricePerPieceFilterActive',
    SAVED_FILTERS: 'saveUpdateSavedFilter',
    MAKE_DEFAULT_SAVED_FILTERS: 'isSavedFilterDefault',
    REMOVE_SAVED_FILTERS: 'removeSavedFilter'
};

export const PLP_MOBILE_FILTERS_VIEWS = {
    MAIN: 'MAIN',
    BRAND: 'BRAND',
    CAPACITY: 'CAPACITY',
    PACKAGING: 'PACKAGING',
    PROMOTION: 'PROMOTION'
};

export const PLP_SORTS_VALUES = {
    DEFAULT: 'default',
    NAME_ASC: 'name-asc',
    NAME_DESC: 'name-desc',
    PRICE_ASC: 'price-asc',
    PRICE_DESC: 'price-desc',
    NEW: 'new',
    BDAA: 'bdaa'
};

export const PLP_TYPES = {
    ORDER: 'order',
    SEARCH: 'search',
    RETURN: 'return',
    ZPOP: 'zpop'
};

export const PLP_VIEW_TYPES = {
    VIEW_TYPE: 'View Type',
    GRID_VIEW: 'GRID',
    LIST_VIEW: 'LIST',
    COMPACT_VIEW: 'COMPACT'
};

export const CART_PRICING_TYPES = {
    PRICING: 'PRICING',
    SAVINGS: 'SAVINGS'
};

export const HOME_BANNER = {
    BANNER_UID: 'homeTopBannerCarouselImages',
    AD_IMAGES_UID: 'homeMiddleBannerImages'
};

export const CAMPAIGN_BANNER = {
    AD_IMAGES_UID: 'campaignMiddleBannerImages'
};

export const CAMPAIGN_TEXT = {
    CAMPAIGN_TEXT_CONTENT: 'CampaignPageText',
    CAMPAIGN_TITLE_TEXT_CONTENT: 'CampaignTitlePageText'
};

export const STOCK_LEVELS = {
    IN_STOCK: {
        class: 'InStock',
        label: 'inStockLabel'
    },
    OUT_OF_STOCK: {
        class: 'OutOfStock',
        label: 'outStockLabel'
    }
};

export const COOKIE_NOTICE_L10N = {
    BACK_BUTTON: 'backBtn'
};

export const PLP_L10N = {
    ALL_PRODUCTS: 'title',
    RESULTS: 'results',
    HIDE_FILTERS: 'hideFilters',
    SHOW_FILTERS: 'showFilters',
    SHOW_PRICE: 'showPrice',
    HIDE_PRICE: 'hidePrice',
    FILTER_BY: 'filterBy',
    FILTER_APPLIED: 'filterApplied',
    CLEAR_ALL: 'clearAll',
    FILTERS: 'filters',
    FILTERS_CAPITALIZED: 'filtersCapitalized',
    FLTR_VIEW_RESULTS: 'fltrViewResults',
    FLTR_RESET_ALL: 'fltrResetAll',
    FLTR_FILTER: 'fltrFilter',
    SORT_SORT: 'srtSort',
    SORT_DEFAULT: 'srtDefault',
    SORT_NAME_ASC: 'srtNameAsc',
    SORT_NAME_DESC: 'srtNameDesc',
    SORT_PRICE_ASC: 'srtPriceAsc',
    SORT_PRICE_DESC: 'srtPriceDesc',
    SORT_NEW: 'srtNew',
    SORT_BDAA: 'srtBdaa',
    RECOMMENDATIONS: 'recommendations',
    FAVORITES: 'favorites',
    PROMOTIONS: 'promotions',
    GRID_VIEW: 'gridView',
    LIST_VIEW: 'listView',
    COMPACT_VIEW: 'compactView',
    SHOW: 'show',
    VIEW_TYPE: 'viewType',
    VIEW_TYPES: 'viewTypes',
    APPLY_FILTERS: 'applyFilters',
    PRICE_TEXT: 'priceText',
    DELIVERY: 'delivery',
    PICKUP: 'pickUp',
    RETURN_TITLE: 'returnTitle',
    MARKETING_MATERIALS_TITLE: 'marketingMaterialsTitle',
    SEARCH_TITLE: 'searchResults',
    NO_RESULTS_FOUND: 'noResultsFound',
    PLP_BANNER_IMAGE_UID: 'PLPTopBannerComponent-React',
    ADD_ALL_CART_BTN: 'btnAddAllToCart',
    BACK_TO_PRODUCTS: 'btnBackToProducts',
    QTY_LABEL: 'enterQty',
    CONTINUE_SHIPPING: 'continueShopping',
    SEARCHED_FOR: 'searchedFor',
    NO_RESULTS_DESC: 'noResultsDesc',
    SUGGESTION_TXT: 'suggestionTxt',
    SUGGESTION_POINTONE: 'suggestion1',
    SUGGESTION_POINTTWO: 'suggestion2',
    SUGGESTION_POINTTHREE: 'suggestion3',
    NO_PRODUCTS: 'noProducts',
    PROMOTION: 'promotion',
    VALID_FROM: 'valid_from',
    VALID_UNTIL: 'valid_until',
    UNTIL: 'until',
    VALIDFROM: 'validFrom',
    PROMO_SELECT_ALL: 'promoFacetsSelectAll',
    GROUP: 'group',
    FREE_GROUP: 'free_group',
    FAVORITE_PRODUCTS: 'favorite_products',
    PROMOTION_PRODUCTS: 'promotion_products',
    CONSENT_ALLOW: 'consentAllowSubscription',
    CONSENT_DENY: 'consentCancelSubscription',
    CONSENT_DESCRIPTION: 'consentEmailUnsubscribe',
    CONSENT_NEWSLETTER: 'consentNewsletter',
    CONSENT_TRADE_OFFERS: 'consentPromotionalOffers',
    CONSENT_SAVE_CLOSE: 'consentSaveAndClose',
    CONSENT_TITLE: 'consentStayUpdated',
    CONSENT_NEWSLETTER_DESCRIPTION: 'consentUpdatesNewProducts',
    CONSENT_TRADE_OFFERS_DESCRIPTION: 'consentUpdatesUpcomingPromotions',
    SMART_ORDERS: 'bdaaSmartOrders',
    BDAA_SMART_RECOMMENDATIONS: 'bdaaCrslTitle',
    BDAA_VIEW_ALL_PRODUCTS: 'bdaaCrslViewAll',
    BDAA_VIEW_ALL: 'bdaaViewAll',
    BDAA_MY_SMART_ORDERS: 'bdaaMenu',
    BDAA_RECOMMENDED: 'bdaaRecommendedText',
    BDAA_MSG_BOLD_TEXT: 'bdaaMsgBoldTextPlp',
    BDAA_MSG_REGULAR_TEXT: 'bdaaMsgregularTextPlp',
    PROMO_PRODUCT_TILE: 'promoProductTile',
    PROMO_VIEW_ALL_PRODUCTS: 'promoViewAllProducts',
    ENDS_IN: 'promoEndsIn',
    STOCK_ALLOCATION_LIMIT_MESSAGE: 'stockAllocationLimitMessageShort',
    IN_STOCK: 'inStock',
    VIEW_MORE: 'viewMore',
    VIEW_LESS: 'viewLess',
    LABEL_MIN_PRICE: 'minProductPrice',
    LABEL_MAX_PRICE: 'maxProductPrice',
    CURRENCY_SYMBOL: 'currencySymbol',
    OVERALL_PRICE: 'overallPrice',
    PRICE_PER_PIECE: 'pricePerPiece',
    PRICE_FILTER_VIEW_RESULT: 'priceFilterViewResult',
    PRICE_FILTER_NAME: 'priceFilterName',
    SAVE_FILTERS: 'saveFilters',
    SAVED_FILTERS: 'savedFilters',
    THREE_DOTS_MESSAGE: 'threeDotsMessage',
    MAKE_DEFAULT: 'makeDefault',
    UPDATE_SAVED: 'updateSaved',
    DELETE_SAVED: 'deleteSaved',
    SAVE_ACTION_MESSAGE: 'saveActionMessage',
    MAKE_DEFAULT_ACTION_MESSAGE: 'makeDefaultActionMessage',
    UPDATED_ACTION_MESSAGE: 'updatedActionMessage',
    DELETED_ACTION_MESSAGE: 'deletedActionMessage',
    SAVED_FILTER_NO_PRODUCTS: 'savedFilterNoProducts'
};

export const PLP_SORTS = [
    {
        value: PLP_SORTS_VALUES.DEFAULT,
        lolcalizationProp: PLP_L10N.SORT_DEFAULT,
        fallbackLabel: 'Default'
    },
    {
        value: PLP_SORTS_VALUES.NAME_ASC,
        lolcalizationProp: PLP_L10N.SORT_NAME_ASC,
        fallbackLabel: 'A-Z'
    },
    {
        value: PLP_SORTS_VALUES.NAME_DESC,
        lolcalizationProp: PLP_L10N.SORT_NAME_DESC,
        fallbackLabel: 'Z-A'
    },
    {
        value: PLP_SORTS_VALUES.PRICE_ASC,
        lolcalizationProp: PLP_L10N.SORT_PRICE_ASC,
        fallbackLabel: 'Price low to high'
    },
    {
        value: PLP_SORTS_VALUES.PRICE_DESC,
        lolcalizationProp: PLP_L10N.SORT_PRICE_DESC,
        fallbackLabel: 'Price high to low'
    },
    {
        value: PLP_SORTS_VALUES.NEW,
        lolcalizationProp: PLP_L10N.SORT_NEW,
        fallbackLabel: 'Newest'
    },
    {
        value: PLP_SORTS_VALUES.BDAA,
        lolcalizationProp: PLP_L10N.SORT_BDAA,
        fallbackLabel: 'Recommendations'
    }
];

export const HOME_L10N = {
    FAV_PRODUCTS: 'favProdTitle',
    VIEW_ALL: 'viewAll',
    VIEW_ORDER_HISTORY: 'viewOrderHistory',
    REORDER_BTN: 'reOrder',
    REORDER_POPUP_CANCEL_BUTTON: 'reorderPopupCancelButton',
    REORDER_POPUP_CHECKOUT_BUTTON: 'reorderPopupCheckoutButton',
    REORDER_POPUP_HEADER_TEXT: 'reorderPopupHeaderText',
    REORDER_POPUP_SUBTEXT_WARNING: 'reorderPopupSubtextStrongWarning',
    REORDER_POPUP_SUBTEXT: 'reorderPopupSubtext',
    MY_RECENT_ORDER: 'myRecentOrder',
    VIEW_ORDER_HINT: 'viewOrderHint',
    VIEW_FINANCE_INFO: 'viewFinanceInfo',
    REPORTS: 'reports',
    ADD_BTN: 'addBtn',
    CUST_REPORT_COMP: 'ReportsComponent-React',
    BDAA_MSG_BOLD_TEXT: 'bdaaMsgBoldText',
    BDAA_MSG_REGULAR_TEXT: 'bdaaMsgRegularText',
    BDAA_MSG_LINK_TEXT: 'bdaaMsgLinkText',
    BDAA_SMART_RECOMMENDATIONS: 'bdaaCrslTitle',
    BDAA_VIEW_ALL_PRODUCTS: 'bdaaCrslViewAll',
    EMAIL_LABEL: 'emailLabel',
    SMS_LABEL: 'smsLabel',
    MANAGE_PREFERENCES_BUTTON: 'managePreferencesButton',
    AGREE_BUTTON: 'agreeButton',
    SOFT_CONSENT_POPUP_TITLE: 'SoftConsentPopupTitle-React',
    SOFT_CONSENT_POPUP_SUBTITLE: 'SoftConsentPopupSubTitle-React',
    SOFT_CONSENT_POPUP_POLICY_CONTENT: 'SoftConsentPopupPolicyContent-React',
    SOFT_CONSENT_POPUP_POLICY_TITLE: 'SoftConsentPopupPolicyTitle-React',
    MARKETING_CONSENT_BANNER_TITLE: 'MarketingConsentBanner_HeaderTextParagraphComponent',
    MARKETING_CONSENT_BANNER_CONTENT: 'MarketingConsentBanner_TextParagraphComponent',
    MARKETING_CONSENT_BANNER_EMAIL: 'MarketingConsentBanner_EmailParagraphComponent',
    MARKETING_CONSENT_BANNER_SMS: 'MarketingConsentBanner_SmsParagraphComponent',
    MARKETING_CONSENT_BANNER_SUBSCRIBE_BTN: 'MarketingConsentBanner_SubscribeBtnParagraphComponent',
    MARKETING_CONSENT_BANNER_REJECT_BTN: 'MarketingConsentBanner_RejectBtnParagraphComponent',
    MARKETING_CONSENT_POPUP_TITLE: 'ExplicitConsentPopupTitle-React',
    MARKETING_CONSENT_POPUP_SUBTITLE: 'ExplicitConsentPopupSubTitle-React',
    MARKETING_CONSENT_POPUP_SUPPORTTEXT: 'ExplicitConsentPopupCustomerSupportText-React',
    MARKETING_CONSENT_POPUP_SUPPORTNUMBER: 'ExplicitConsentPopupCustomerSupportNumber-React',
    MINICART_ONBOARDING_POPUP_CONFIGURE_BUTTON: 'minicartOnboardingPopupConfigureButton',
    MINICART_ONBOARDING_POPUP_FIRST_ICON_FIRST_TEXT_LINE: 'minicartOnboardingPopupFirstIconFirstTextLine',
    MINICART_ONBOARDING_POPUP_FIRST_ICON_SECOND_TEXT_LINE: 'minicartOnboardingPopupFirstIconSecondTextLine',
    MINICART_ONBOARDING_POPUP_FIRST_ICON_THIRD_TEXT_LINE: 'minicartOnboardingPopupFirstIconThirdTextLine',
    MINICART_ONBOARDING_POPUP_FIRST_TITLE: 'minicartOnboardingPopupFirstTitle',
    MINICART_ONBOARDING_POPUP_LABEL: 'minicartOnboardingPopupLabel',
    MINICART_ONBOARDING_POPUP_MAYBE_LATER_BUTTON: 'minicartOnboardingPopupMaybeLaterButton',
    MINICART_ONBOARDING_POPUP_SECOND_ICON_FIRST_TEXT_LINE: 'minicartOnboardingPopupSecondIconFirstTextLine',
    MINICART_ONBOARDING_POPUP_SECOND_ICON_SECOND_TEXT_LINE: 'minicartOnboardingPopupSecondIconSecondTextLine',
    MINICART_ONBOARDING_POPUP_SECOND_ICON_THIRD_TEXT_LINE: 'minicartOnboardingPopupSecondIconThirdTextLine',
    MINICART_ONBOARDING_POPUP_SECOND_TITLE: 'minicartOnboardingPopupSecondTitle',
    STOCK_ALLOCATION_TITLE: 'stockAllocationTitle',
    STOCK_ALLOCATION_SUBTITLE: 'stockAllocationSubTitle',
    STOCK_ALLOCATION_CONTINUE_SHOPPING: 'stockAllocationContinueShopping',
    STOCK_ALLOCATION_CONTINUE_TO_CART: 'stockAllocationContinueToCart'
};

export const ACCOUNT_L10N = {
    PERSONAL_DATA: 'personalData',
    COMPANY_DATA: 'companyData',
    CUSTOMER_FACT_SHEET: 'customerFactSheet',
    FACTSHEET: 'factsheet',
    CONTENT_TITLE: 'contentTitle',
    SUB_TEXT: 'subText'
};
export const MY_ACCOUNT_L10N = {
    CONTENT_TITLE: 'contentTitle',
    PAGE_DESC: 'pageDescription',
    SHOPPING_LIST_TITLE: 'shoppingListPageTitle',
    SHOPPING_LIST_SUBTITLE: 'shoppingListPageSubTitle',
    HOME_TITLE: 'homeTitle',
    SHOPPING_LIST_TAB_FAVORITES: 'shoppingListFavouritesTab',
    SHOPPING_LIST_TAB_RECOMMENDATIONS: 'shoppingListRecommendationsTab',
    SHOPPING_LIST_SAVED_CARTS: 'shoppingListSavedCartsTab',
    SHOPPING_LIST_QUICK_ORDER: 'shoppingListQuickOrderTab',
    MY_ACCOUNT: 'myAccount',
    ACCOUNT_SETTINGS: 'accountSettings',
    CLAIMS: 'claims',
    COMPANY_DATA: 'companyData',
    CUSTOMER_FACT_SHEET: 'customerFactSheet',
    EARN_REWARDS: 'earnRewards',
    EDIT_PASSWORD: 'editPassword',
    INVOICES: 'invoices',
    MY_EQUIPMENT: 'myEquipment',
    MY_FAVORITES: 'myFavorites',
    MY_RECOMMENDATIONS: 'myRecommendations',
    MY_REWARDS: 'myRewards',
    MY_SAVED_CARTS: 'mySavedCarts',
    MY_SERVICES: 'myServices',
    ORDER_HISTORY: 'orderHistory',
    ORDER_INFORMATION: 'orderInformation',
    PERSONAL_DATA_TITLE: 'personalDataTitle',
    QUICK_ORDER: 'quickorder',
    SHOPPING_LIST: 'shoppingList',
    SUPPORT: 'support',
    USER_MANAGEMENTE: 'userManagement',
    MY_ACCOUNT_TITLE: 'myAccountTitle',
    MY_ACCOUNT_SUBTITLE: 'myAccountSubTitle',
    CONNECTED_COOLERS: 'connectedCoolers',
    TIPS: 'tips',
    MY_REPORTS: "myReports",
    MY_SALES: "mySales"
};

export const TEMP_POPUP_L10N = {
    ADD_TO_TEMP_TEXT: 'addToTemplateBtnText',
    RESTORE_TEMP_TITLE: 'restoreTitle',
    RESTORE_TEMP_DESC: 'restoreDescription',
    CART_NAME_TITLE: 'cartName',
    NO_OF_PRODS: 'numberOfProducts',
    RESTORE_BTN: 'restoreBtn',
    DEL_TEMP_TITLE: 'deleteTitle',
    DEL_TEMP_DESC: 'deleteDescription',
    DELETE_BTN: 'deleteBtn'
};

export const PDP_L10N = {
    ITEM_DETAILS: 'itemDetails',
    FAVORITE: 'favorite',
    PROMOTIONS: 'promotions',
    PROMOTION: 'promotion',
    VALID_FROM: 'valid_from',
    VALID_UNTIL: 'valid_until',
    GROUP: 'group',
    FREE_GROUP: 'free_group',
    BDAA_RECOMMENDED_TEXT: 'bdaaRecommendedTextPdp'
};

export const IMAGE_FORMAT_TYPES = {
    ZOOM: 'zoom',
    PRODUCT: 'product',
    THUMBNAIL: 'thumbnail'
};

export const DELIVERY_TYPES = {
    ORDER_DRINKS: 'drinks',
    PICKUP: 'pickup'
};

export const ZORB_ORDER = {
    PLANT_ID: 'P2560'
};

export const ORDER_INFORMATION_L10N = {
    TITLE: 'title',
    SUB_TITLE: 'subTitle',
    ORDER_HISTORY_TAB: 'ordersHistoryTab',
    INVOICES_TAB: 'invoicesTab'
};

export const ORDERS_L10N = {
    ORDER_HISTORY_TITLE: 'orderHistoryTitle',
    SEARCH_ORDER_HISTORY: 'searchOrderHistory',
    ORDER_ID: 'orderId',
    DELIVERY_DATE: 'deliveryDate',
    ORDER_NUMER: 'orderNumber',
    TOTAL_ITEMS: 'totalItems',
    ORDER_TOTAL: 'orderTotal',
    DESCRIPTION_TEXT: 'description',
    REORDER_BTN: 'reorder',
    REORDER_POPUP_CANCEL_BUTTON: 'reorderPopupCancelButton',
    REORDER_POPUP_CHECKOUT_BUTTON: 'reorderPopupCheckoutButton',
    REORDER_POPUP_HEADER_TEXT: 'reorderPopupHeaderText',
    REORDER_POPUP_SUBTEXT_WARNING: 'reorderPopupSubtextStrongWarning',
    REORDER_POPUP_SUBTEXT: 'reorderPopupSubtext',
    CANCEL_ORDER_POPUP_TITLE: 'cancelOrderTitle',
    CANCEL_ORDER_POPUP_DESCRIPTION_TEXT: 'cancelOrderDescription',
    REJECT_BTN: 'reject',
    SEARCH_ORDERS_TEXT: 'searchOrders',
    RESULTS_TEXT: 'results',
    QUICKVIEW_LINK: 'quickView',
    ORDER_DETAILS_TITLE: 'orderDetailTitle',
    PLACED_BY: 'placedBy',
    SHIP_TO: 'shipTo',
    BILLING_ADDRESS: 'billingAddress',
    TRACK_ORDER_BTN: 'trackOrder',
    ORDER_STATUS: 'orderStatus',
    DATE_PLACED: 'datePlaced',
    SHIPPING_STATUS: 'shippingStatus',
    BILLING_STATUS: 'billingStatus',
    SUCCESSOR_DOCUMENTS: 'successorDocuments',
    PAYMENT_TERMS: 'paymentTerms',
    NO_OF_PALLETS_REQUIRED: 'ofPalletsRequired',
    TOTAL_WEIGHT: 'totalWeight',
    ITEM_TEXT: 'item',
    QUANTITY: 'quantity',
    PRICE_TEXT: 'price',
    PRICE_BREAKDOWN: 'priceBreakdown',
    SUB_TOTAL: 'subTotal',
    TOTAL: 'total',
    NO_OF_ORDERS_TEXT: 'noOfOrders',
    ORDER_STATUS_TITLE: 'orderStatusTitle',
    ORDER_CREATED_STATUS: 'orderCreated',
    ORDER_INPROGRESS_STATUS: 'orderInprogress',
    ORDER_SHIPPED_STATUS: 'orderShipped',
    ORDER_DELIVERED_STATUS: 'orderDelivered',
    ORDER_CANCELED_STATUS: 'orderCanceled',
    ORDER_STATUS_LIST: ['OPEN', 'PARTIALLY_PROCESSED', 'SHIPPED', 'COMPLETED', 'CANCELLED'],
    CANCEL_CONFIRM_BTN: 'cancelOrderTitle',
    CANCEL_BTN: 'btnCancel',
    ORDER_INFORMATION_TITLE: 'orderInformationTitle'
};

export const TEMP_L10N = {
    TITLE: 'templateTitle',
    TOTAL_TEMP: 'totalTemplate',
    NAME: 'name',
    DATE_SAVED: 'dateSaved',
    QTY: 'quantity',
    DESC: 'description',
    WEIGHT: 'weight',
    ID: 'id',
    QUICK_VIEW: 'quickView',
    NO_OF_ORDERS_TEXT: 'noOfOrders',
    CREATE_NEW_TEMP_DESC: 'createNewTemplateDesc',
    OF: 'of',
    RESTORE_BTN: 'restoreBtn'
};

export const TEMP_DETAILS_L10N = {
    TITLE: 'templateTitle',
    NAME: 'templateName',
    DATE_SAVED: 'dateSaved',
    NO_OF_UNIQUE_SKUS: 'sku',
    DESC: 'description',
    DONE_TEMP_BTN: 'doneTemplateBtn',
    EDIT_TEMP_BTN: 'editTemplateBtn',
    ITEMS: 'items',
    TOTAL_ITEMS: 'totalItems',
    CASES: 'cases',
    REMOVE_BTN: 'removeBtn',
    REMOVE_ALL_BTN: 'removeAllBtn',
    UPDATE_BTN: 'updateBtn',
    ADD_ALL_CART_BTN: 'btnAddAllToCart',
    DELETE_BTN: 'deleteBtn'
};

export const PAGINATION_L10N = {
    OF: 'of'
};

export const INVOICES_L10N = {
    TITLE: 'invoicesTitle',
    CREATE_CLAIM_BUTTON: 'createClaim',
    STATUS: 'status',
    TIME_PERIOD: 'timePeriod',
    INVOICES_TOTAL: 'invoicesTotal',
    DOCUMENT_TYPE: 'documentType',
    DUE_DATE: 'dueDate',
    BILL_NO: 'billNo',
    INVOICE_DATE: 'invoiceDate',
    CUSTOMER: 'customer',
    AMOUNT: 'amount',
    DETAILS: 'details',
    CREATE_CLAIM_TITLE: 'createClaimTitle',
    TOTAL_SUM_AMOUNT: 'totalSumAmount',
    SUBJECT: 'subject',
    SHORT_DESC: 'shortDescription',
    DETAILED_DESC: 'detailedDescription',
    MANDATORY_INFO: 'mandatoryInformation',
    FILTERS: 'filters',
    DOWNLOAD_BTN: 'download',
    ERROR_MESSAGE: 'errorMessage',
    EMAIL_ADDRESS: 'emailAddress',
    INVOICES_PAGE_TITLE: 'invoicesPageTitle',
    FILTER_STATUS_OPEN: 'filterStatusOpen',
    FILTER_STATUS_OVERDUE: 'filterStatusOverdue',
    FILTER_STATUS_PAID: 'filterStatusPaid',
    FILTER_STATUS_SELECT_ALL: 'filterStatusSelectAll',
    VIEW_RESULTS: 'buttonViewResults',
    FILTER_PERIOD_LAST_MONTH: 'filterPeriodLastMonth',
    FILTER_PERIOD_LAST_3_MONTHS: 'filterPeriodLast3Months',
    FILTER_PERIOD_LAST_6_MONTHS: 'filterPeriodLast6Months',
    FILTER_PERIOD_LAST_12_MONTHS: 'filterPeriodLast12Months',
    FILTER_PERIOD_CUSTOM: 'filterPeriodCustom',
    CUSTOM_POPUP_TITLE: 'customPopupTitle',
    CUSTOM_POPUP_DESCRIPTION: 'customPopupDescription',
    CUSTOM_POPUP_START_DATE: 'customPopupStartDate',
    CUSTOM_POPUP_END_DATE: 'customPopupEndDate',
    CUSTOM_POPUP_CANCEL_BUTTON: 'customPopupCancelButton',
    CUSTOM_POPUP_CONFIRM_BUTTON: 'customPopupConfirmButton',
    TIME_PERIOD_TITLE: 'timePeriodTitle',
    FILTER_SELECTED: 'filterSelected',
    FILTER_CUSTOMER: 'filterCustomer',
    FILTER_CUSTOMER_ALL: 'filterCustomerAll',
    FILTER_DOCUMENT_TYPE_INVOICE: 'filterDocumentTypeInvoice',
    FILTER_DOCUMENT_TYPE_CREDIT_MEMO: 'filterDocumentTypeCreditMemo',
    FILTER_DOCUMENT_TYPE_OTHER: 'filterDocumentTypeOther',
    FILTER_DOCUMENT_TYPE_PAYMENT: 'filterDocumentTypePayment',
    FILTER_DOCUMENT_TYPE_SELECT_ALL: 'filterDocumentTypeSelectAll',
    FILTER_PRICE_FROM: 'filterPriceFrom',
    FILTER_PRICE_TO: 'filterPriceTo',
    BUTTON_RESET: 'buttonReset',
    FILTER_SELECT: 'filterSelect',
    ADVANCED_FILTERS: 'advancedFilters',
    TABLE_TITLE: 'tableTitle',
    BUTTON_CREATE_CLAIM_DESCRIPTION: 'buttonCreateClaimDescription',
    BUTTON_DOWNLOAD_DESCRIPTION: 'buttonDownloadDescription',
    TABLE_COLUMN_ALL: 'tableColumnAll',
    SELECT_FOR_DOWNLOADING_OR_CLAIMING: 'selectForDownloadingOrClaiming',
    SEARCH_INVOICES: 'searchInvoices',
    FILTER_PRICE_ERROR_MESSAGE: 'filterPriceErrorMessage',
    SUCCESSFUL_DOWNLOAD_TEXT: 'successfulDownloadText',
    ONE_INVOICE_SUCCESSFUL_DOWNLOAD_TEXT: 'oneInvoiceSuccessfulDownloadText'
};

export const ACCOUNT_STATEMENTS_L10N = {
    ACCOUNT_STATEMENT_ERROR_MESSAGE: 'accountStatementErrorMessage',
    GENERATE_STATEMENT_BUTTON: 'generateStatementButton',
    GENERATE_STATEMENT_POPUP_TITLE: 'generateStatementPopupTitle',
    GENERATE_STATEMENT_POPUP_DESCRIPTION: 'generateStatementPopupDescription',
    TIME_PERIOD_TITLE: 'timePeriodTitle',
    TIME_PERIOD_LAST_WEEK: 'timePeriodLastWeek',
    TIME_PERIOD_TWO_WEEKS_AGO: 'timePeriod2WeeksAgo',
    TIME_PERIOD_THREE_WEEKS_AGO: 'timePeriod3WeeksAgo',
    TIME_PERIOD_FOUR_WEEKS_AGO: 'timePeriod4WeeksAgo',
    TIME_PERIOD_CUSTOM_PERIOD: 'timePeriodCustomPeriod',
    TIME_PERIOD_CUSTOM_PERIOD_ERROR: 'incorrectCustomTimePeriod',
    TIME_PERIOD_CUSTOM_PERIOD_NOTE: 'timePeriodCustomPeriodNote',
    TIME_PERIOD_FROM: 'timePeriodFrom',
    TIME_PERIOD_TO: 'timePeriodTo',
    TIME_PERIOD_ERROR_MESSAGE: 'timePeriodErrorMessage',
    STATEMENT_BUTTON: 'statementButton'
};

export const CART_L10N = {
    MINI_CART_TITLE: 'miniCartTitle',
    CONT_TO_CART: 'continueToCart',
    CLEAR_ALL_CART: 'clearAllCart',
    CLEAR_SAVED_CART: 'CLEAR_SAVED_CART',
    RETURN_TO_SHOP: 'returnToShop',
    RECOMMENDED_ITEMS: 'recommendedItems',
    DELIVERY_TYPE: 'deliveryType',
    PICK_UP: 'pickUpType',
    TOTAL_WITHOUT_TAX: 'totalWithoutTax',
    TOTAL_WITH_TAX: 'totalWithTax',
    DELETE_BTN: 'deleteBtn',
    CART_EMPTY_ERROR: 'cartEmptyError',
    DELIVERY_TYPE_ERROR: 'deliveryTypeError',
    CHANGE_TXT: 'changeTxt',
    PICKUP_DATE: 'pickupDate',
    CLEAR_ALL_CONFIRM: 'clearAllConfirm',
    CANCEL: 'cancel',
    CLEAR_ALL: 'clearAllBtn',
    CONTENT_TITLE: 'contentTitle',
    CONT_TO_CHECKOUT: 'continueToCheckout',
    ADD_TO_TEMPLATE: 'addToTemplateBtn',
    DELIVERY_DATE: 'deliveryDate',
    PLZ_NOTE_MSG: 'pleaseNoteMsg',
    DATE_PICK_MSG: 'datePickerMsg',
    WHOLESALER_DATE_MSG: 'wholeSalerDateMsg',
    ORDER_BEFORE_TXT: 'orderBeforeTxt',
    PRICE_IN_TAX: 'priceInTax',
    PRICE_EX_TAX: 'priceExTax',
    SUMMARY: 'summary',
    TOTAL_ITEMS: 'totalItems',
    SUB_TOTAL: 'subtotal',
    SHOW_GROSS_PRICE: 'showGrossPrice',
    TAX: 'tax',
    ORDER_TOTAL: 'orderTotal',
    ORDER_EMPTIES_TOTAL: 'orderEmptiesTotal',
    NO_PALLETS: 'palletsRequired',
    WEIGHT: 'weight',
    WEIGHT_UNIT: 'weightUnit',
    ADD_TO_TEMPLATE_TITLE: 'templatePopUpTitle',
    MY_TEMPLATE: 'myTemplates',
    MY_TEMPLATE_DESC: 'myTemplatesDesc',
    SELECT_TEMPLATE: 'selectTemplate',
    CREATE_TEMPLATE: 'createNewTemplate',
    CREATE_NEW_TEMPLATE_DESC: 'createNewTemplateDesc',
    NEW_TEMPLATE: 'newTemplateName',
    TENPLATE_DESC: 'templateDescription',
    CREATE_NEW_TEMPLATE: 'createNewTemplateBtn',
    IS_SKIP_GET_CART: 'isSkipGetCart',
    FROM_STOCK_ALLOCATION_POPUP: 'isFromStockAllocationPopup',
    IS_SKIP_LOAD_CART_DATA: 'isSkipLoadCartData',
    MINI_CART_COUNT: 'miniCartCount',
    MINI_CART_LIST: 'miniCartList',
    SELFIRED_CART_MESSAGE: 'selfiRedCartMessage',
    PROMO_DISCLAIMER_MESSAGE: 'promoDisclaimerMessage',
    LOYALTY_CART_MESSAGE: 'loyaltyCartMessage',
    STOCK_ALLOCATION_LIMIT_MESSAGE: 'stockAllocationLimitMessage',
    SHOW_STOCK_ALLOCATION_MODAL: 'showStockAllocationModal',
    STOCK_ALLOCATION_TITLE: 'cartStockAllocationTitle',
    STOCK_ALLOCATION_SUBTITLE: 'cartStockAllocationSubTitle',
    STOCK_ALLOCATION_BUTTON_OK: 'cartStockAllocationBtnOk',
    REMOVE_OOS_ITEMS_BUTTON: 'removeOosItemsButton',
    REMOVE_OOS_ITEMS_TEXT: 'removeOosItemsText',
    REMOVE_ITEMS: 'removeItems',
    KEEP_ITEMS: 'keepItems'
};

export const CHECKOUT_L10N = {
    TITLE: 'checkout',
    PLACE_ORDER_BUTTON: 'placeOrder',
    DELIVERY_ADDRESS: 'deliveryAddress',
    BILLING_ADDRESS: 'billingAddress',
    DELIVERY_DATE: 'deliveryDate',
    WHOLESALER_ADDRESS: 'wholesalerAddress',
    REFERENCE_DATA: 'referenceData',
    REVIEW_PRODUCTS: 'reviewProducts',
    YOUR_REFERENCE: 'yourReference',
    YOUR_DESCRIPTION: 'yourDescription',
    DRIVER_MESSAGE: 'driverMessage',
    DISPATCHER_MESSAGE: 'dispatcherMessage',
    TERMS_CONDITION: 'termsCondition',
    SPECIAL_CLAUSES: 'specialClauses',
    ID: 'id',
    PRODUCT: 'product',
    QUANTITY: 'quantity',
    PRICE_EXL_TAX: 'priceExlTax',
    PRICE_INCL_TAX: 'priceInclTax',
    CONTINUE_TO_CHECKOUT: 'continueToCheckout',
    PRODUCT_DETAILS: 'productDetails',
    TOTAL_ITEMS: 'totalItems',
    SUB_TOTAL: 'subTotal',
    TOTAL_BEFORE_TAXES: 'totalBeforeTaxes',
    TAX: 'tax',
    ORDER_EMPTIES: 'orderEmpties',
    ORDER_TOTAL: 'orderTotal',
    DELIVERY: 'delivery',
    PERSONAL_DATA: 'personalData',
    PAYMENT_TERMS: 'paymentTerms',
    PALLET: 'pallet',
    WEIGHT: 'weight',
    WEIGHT_UNIT: 'weightUnit',
    SUMMARY: 'summary',
    SPECIAL_CLAUSES_LINK_COMPONENT: 'SpecialClauseLinkComponent_react',
    EMAIL_LABEL: 'emailLabel',
    SMS_LABEL: 'smsLabel',
    MARKETING_COM_DESC: 'marketingCommunicationDesc',
    MARKETING_COM_LINK: 'marketingCommunication',
    SAVE_NOTE: 'saveReferenceNote',
    BTN_CANCEL: 'btnCancel'
};

export const ORDER_CONFIRM_L10N = {
    TITLE: 'orderConfirmation',
    TITLE_TEXT: 'titletext',
    ORDER_TOTAL: 'orderTotal',
    ORDER_NUMBER: 'orderNumber',
    PLACED_BY: 'placedBy',
    SHIP_TO: 'shipTo',
    BILLING_ADDRESS: 'billingAddress',
    ORDER_STATUS: 'orderStatus',
    DATE_PLACED: 'datePlaced',
    DELIVERY_DATE: 'deliveryDate',
    PAYMENT_TERMS: 'paymentTerms',
    PALLET: 'pallet',
    WEIGHT: 'weight',
    ITEM: 'item',
    PRICE: 'price',
    PRODUCT_DETAILS: 'productDetails',
    ID: 'id',
    QUANTITY: 'quantity',
    PRICE_BREAKDOWN: 'priceBreakdown',
    PRODUCTS: 'products',
    TOTAL_BEFORE_TAXES: 'totalBeforeTaxes',
    DATA_SHARING: 'dataSharing',
    LOG_SHARING: 'logSharing',
    VOLUME: 'volume',
    ORDER_EMPTIES: 'orderEmpties',
    MICROSOFTLINK_TEXT: 'microsoftFormsLink'
};

export const SEARCHBOX_L10N = {
    SEARCH_RESULTS: 'Search Results',
    THUMBNAIL: 'thumbnail',
    PRIMARY: 'PRIMARY'
};

export const PROMO_L10N = {
    TITLE: 'title',
    SUB_TITLE: 'subTitle',
    PROMO_TAB: 'promotionTab',
    STEP1: 'step1',
    STEP2: 'step2',
    STEP3: 'step3',
    STEP1_TEXT: 'step1Text',
    STEP2_TEXT: 'step2Text',
    BUY: 'buy',
    PROD_TO: 'productTo',
    GET: 'get',
    PROD_FOR: 'productFor',
    FREE: 'free',
    SELECT: 'select',
    PROD_BELOW: 'productBelow',
    ADD_YOUR: 'addYour',
    PROD_TO_YOUR_CART: 'productToYourCart',
    GROUP: 'group',
    RESET_BTN: 'reset',
    CTC: 'continueToCheckout',
    CTC_WARNING_TITLE: 'checkoutWarningTitle',
    CTC_WARNING_SUBTITLE: 'checkoutWarningSubTitle',
    CT: 'continueTo',
    SHOPPING_CART: 'shoppingCart',
    TOTAL_EX_TAX: 'totalExTax',
    SKU: 'sku',
    IN_CART: 'inCart',
    PROMO_DISCOUNT_TEXT: 'getPromoDiscount'
};

export const USER_MAIN_L10N = {
    TITLE: 'contentTitle',
    NAME: 'name',
    USER_NAME: 'userName',
    ROLE: 'role',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    PHONE: 'phone',
    MOBILE: 'mobile',
    EMAIL: 'email',
    COMPANY_NAME: 'companyName',
    USER_ROLE: 'userRole',
    DETAILS: 'details',
    ERROR: 'errorMessage',
    NO_RESULTS_FOUND: 'noResultsFound',
    EDIT: 'edit',
    DELETE: 'delete',
    ADD_NEW_USER: 'addNewUser',
    EDIT_USER: 'editUser',
    DELETE_CONTENT: 'deleteMsg',
    REGISTER: 'register',
    EMAIL_ERROR_MSG: 'errorEmailMsg',
    MANDATORY_INFO: 'mandatoryInfo',
    PLACE_HOLDER_USER_NAME: 'placeHolderUserName',
    PLACE_HOLDER_FIRST_NAME: 'placeHolderFirstName',
    PLACE_HOLDER_LAST_NAME: 'placeHolderLastName',
    PLACE_HOLDER_PHONE: 'placeHolderPhone',
    PLACE_HOLDER_MOBILE: 'placeHolderMobile',
    PLACE_HOLDER_EMAIL: 'placeHolderEmail',
    OWNER: 'owner',
    PAYER: 'payer',
    ADMIN: 'admin',
    BUYER: 'buyer',
    USER_INFORMATION: 'userInformation',
    DELETE_USER: 'deleteUser'
};
export const IMPRINT_L10N = {
    IMPRINT_COMP: 'imprintParaComponent-React',
    RETURN_LOGIN: 'returnlogin'
};

export const FAQ_L10N = {
    FAQ_COMP: 'faqParagraphList'
};

export const IMAGE_COMING_SOON = '/assets/images/imgComingSoon.jpg';

export const CLAIMS_HISTORY_L10N = {
    CLAIM_HISTORY_TITLE: 'claimHistoryTitle',
    SEARCH_CLAIM_HISTORY: 'searchClaimHistory',
    TICKET_ID: 'ticketId',
    STATUS: 'status',
    CREATION_DATE: 'creationDate',
    TICKET_TYPE: 'ticketType',
    SHORT_DESCRIPTION: 'shortDescription',
    REASON_CODE: 'reasonCode',
    CREATION_PERIOD: 'creationPeriod',
    DETAILS: 'details',
    FILTER: 'filter'
};

export const PROMO_CALENDAR_L10N = {
    TOP_BANNER: 'PromotionCalendarBanner-React',
    TOP_BANNER_RESTRICTED: 'PromotionCalendarBannerRestricted-React',
    PROMO_BANNER: 'PromotionCalendarPromoImageBanner-React',
    VALID_FROM: 'validFrom',
    UNTIL: 'until',
    FREE: 'free',
    GROUP: 'group',
    PRODUCTS: 'products',
    NOPROMOTIONS: 'noPromotions',
    DESCRIPTION: 'description',
    ENDS_IN: 'endsIn',
    NO_PROMOTIONS: 'noPromotions',
    SHOP_PRODUCTS: 'shopProducts',
    SUBTITLE: 'subtitle'
};

export const CAMPAIGN_L10N = {
    TOP_BANNER: 'CampaignBanner-React'
};

export const COMPANY_DATA_L10N = {
    COMPANY_ID: 'companyId',
    COMPANY_NAME: 'companyName',
    PHONE: 'phone',
    FAX: 'fax',
    EMAIL: 'email',
    COUNTRY: 'countryName',
    STATE_OR_REGION: 'regionText',
    STREET_OR_HOUSE: 'street',
    POSTAL_CODE_OR_CITY: 'postalcode',
    SDO_PORTAL: 'sdoLink',
    CITY: 'city',
    PRICE_PREF: 'pricePreference',
    GROSS_PRICE_PREF: 'showGrossPrice',
    NET_PRICE_PREF: 'showNetPrice'
};

const PERSONAL_DATA_FOOTER_ITEMS_L10N = {
    emailNotification: {
        RECEIVE_OCEMAILS: 'orderSaveEmailActive',
        RECEIVE_ODEMAILS: 'orderReleaseEmailActive'
    },
    marketingConsentAgreementData: {
        RECEIVE_NEWSLETTER: 'newsletterSubscription',
        RECEIVE_TRADING_OFFERES: 'tradingOffersSubscription'
    },
    marketingCommunicationChannels: {
        SMS: 'smsLabel',
        EMAIL: 'emailLabel'
    },
    orderNotifications: {
        n0: 'orderConfirmation',
        n1: 'orderDispatching',
        n2: 'deliveryPlanning',
        n3: 'loadingActivities',
        n4: 'shipmentStart',
        n5: 'orderFinalized'
    },
    miniCartPreferences: {
        CRMLOAD: 'fullMiniCartPref',
        MINICARTNOCRM: 'quickMiniCartPref',
        SKIPMINICART: 'noMiniCartPref',
        CRMLOAD_DESCRIPTION: 'fullMiniCartDescription',
        MINICARTNOCRM_DESCRIPTION: 'quickMiniCartDescription',
        SKIPMINICART_DESCRIPTION: 'noMiniCartDescription'
    }
};

export const PERSONAL_DATA_L10N = {
    USER_NAME: 'userId',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    COMPANY_NAME: 'companyName',
    PHONE: 'phone',
    MOBILE: 'mobile',
    EMAIL: 'email',
    SMS: 'sms',
    COMM_PREF: 'commPreferences',
    ADV_PREF: 'advPreferences',
    MARKETING_COMM: 'marketingCommunication',
    MARKETING_COMM_DESC: 'marketingCommunicationDesc',
    ORDER_NOTIFICATION_TITLE: 'orderNotifyTitle',
    ORDER_NOTIFICATION_DESCRIPTION: 'orderNotifyDesc',
    MINICART_TITLE_PREFERENCE: 'miniCartPrefTitle',
    MINICART_DESCRIPTION_PREFERENCE: 'miniCartPrefDescription',
    PREFERENCES_CENTER: 'preferencesCenter',
    MANAGE_COMMUNICATION_CONSENTS: 'manageCommunicationConsents',
    CONFIGURATION_SETTINGS: 'configurationSettings',
    MANAGE_CONFIGURATION_SETTINGS: 'manageConfigurationSettings',
    ...PERSONAL_DATA_FOOTER_ITEMS_L10N
};

export const FACT_SHEET_L10N = {
    OUTLETID: 'companyId',
    NAME1: 'companyName',
    NAME2: 'name2',
    WEB_SITE: 'website',
    PHONE: 'phone',
    FAX: 'fax',
    EMAIL: 'email',
    CORRESPONDANCE_ADDRESS: 'corrAddress',
    BILLING_ADDRESS: 'billingAddress',
    LEAD_TIME: 'leadTime',
    PAYMENTS_TERM: 'paymentTerms',
    BD_NAME: 'BDName',
    BD_TEL_NUMBER: 'BDPhone',
    CREDIT_DATA: 'creditData',
    WHOLESALER: 'wholesaler',
    CREDIT: 'credit',
    CREDIT_DAY: 'creditDay',
    CREDIT_LIMIT: 'creditLimit',
    CREDIT_OVERDUE: 'creditOverdue',
    QUICK_VIEW: 'quickView'
};

export const CLASIFICATION_BRAND = 'CchClassification/1.0/default.brand';

export const SMART_EDIT = {
    CMS_TICKET_ID: 'cmsTicketId'
};

export const DATA_LAYER_LISTS = {
    FAVORITE_LIST: 'favorites-list',
    HOMEPAGE_FAVORITE_LIST: 'homepage-favorites-list',
    PRODUCT_LIST: 'product-list',
    SEARCH_LIST: 'search-list',
    TEMPLATE_LIST: 'template-list',
    REORDER_LIST: 'reorder-list',
    CART_LIST: 'shopping-cart-list',
    MINI_CART_LIST: 'mini-cart-list',
    ORDER_CONFIRM_LIST: 'order-confirm-list',
    CHECKOUT_CONFIRM_LIST: 'checkout-confirm-list',
    BDAA_LIST: 'bdaa-list'
};

export const GA_CHECKOUT_STEPS = {
    DELIVERY_ADDRESS: 1,
    BILLING_ADDRESS: 2
};

export const GA_BANNERS_PROPS = {
    STANDARD: {
        id: 'altText',
        position: 'id'
    },
    PROMOTIONS_TOP_BANNER: {
        id: 'uid',
        name: 'name',
        creative: 'urlLink'
    },
    PROMOTIONS_AD_MIDDLE: {
        id: 'uid',
        creative: 'urlLink'
    }
};

export const PAGE_TYPES = {
    HOME: 'HOME',
    PLP: 'PLP'
};

export const MARKETING_TYPES = {
    SMS: 'SMS',
    EMAIL: 'EMAIL'
};

export const CONSENT_STATUS = {
    ACTIVE: 'ACTIVE'
};

export const CART_ORDER_STATUSES = {
    SHOW_TO_ORDER: 'SHOW_TO_ORDER',
    HIDE: 'HIDE',
    GET_NOTIFIED: 'GET_NOTIFIED',
    NOTIFY_ME: 'NOTIFY_ME'
};

export const ACCOUNT_MY_SERVICES_L10N = {
    TITLE: 'myServicesTitle',
    SUBTITLE: 'myServicesSubTitle',
    CLAIM_HISTORY_TITLE: 'claimHistoryTitle',
    REQUEST_SUPPORT_TAB: 'requestSupport',
    ORDER_RETURN_TAB: 'orderReturn'
};

export const MY_ACCOUNT_SERVICES_TABS = {
    claimsTickets: {
        uid: 'ClaimsAndTicketsTab',
        name: 'claimsTickets'
    },
    requestSupport: {
        uid: 'RequestSupportTab',
        name: 'requestSupport'
    }
};
export const ORDERS_INFORMATION_TABS = {
    orderHistory: 'orderHistory',
    invoices: 'invoices'
};

export const MY_ACCOUNT_SETTINGS_TABS = {
    personalData: {
        name: 'personalData',
        uid: 'PersonalDataTab'
    },
    updatePassword: {
        name: 'updatePassword',
        uid: 'EditPasswordTab'
    },
    customerFactSheet: {
        name: 'customerFactSheet',
        uid: 'CustomerFactSheetTab'
    },
    companyData: {
        name: 'companyData',
        uid: 'CompanyDataTab'
    },
    userManagement: {
        name: 'userManagement',
        uid: 'UserMaintenanceTab'
    }
};

export const MY_ACCOUNT_ORDER_INFORMATION_TABS = {
    orderHistory: 'orderHistory',
    invoices: 'invoices'
};

export const ACCOUNT_SETTINGS_L10N = {
    TITLE: 'accountSettingsTitle',
    SUBTITLE: 'accountSettingsSubTitle',
    PERSONAL_DATA_TAB: 'accountSettingsTabPersonalData',
    EDIT_PASSWORD_TAB: 'accountSettingsTabEditPassword',
    CUSTOMER_FACT_SHEET_TAB: 'accountSettingsTabCustomerFactSheet',
    COMPANY_DATA_TAB: 'accountSettingsTabCompanyData',
    USER_MANAGEMENT_TAB: 'accountSettingsTabUserManagement'
};

export const ACCOUNT_TABS_RESTRICTIVE = {
    ORDER_HISTORY_TAB: 'OrderHistoryTab',
    INVOICES_TAB: 'InvoicesTab',
    MY_FAVOURITES_TAB: 'MyFavouritesTab',
    MY_RECOMMENDATIONS_TAB: 'MyRecommendationsTab',
    MY_SAVED_CARTS_TAB: 'MySavedCartsTab',
    QUICK_ORDER_TAB: 'QuckOrderTab',
    CLAIMS_AND_TICKETS_TAB: 'ClaimsAndTicketsTab',
    REQUEST_SUPPORT_TAB: 'RequestSupportTab',
    PERSONAL_DATA_TAB: 'PersonalDataTab',
    EDIT_PASSWORD_TAB: 'EditPasswordTab',
    CUSTOMER_FACT_SHEET_TAB: 'CustomerFactSheetTab',
    COMPANY_DATA_TAB: 'CompanyDataTab',
    USER_MAINTENANCE_TAB: 'UserMaintenanceTab',
    COOLER_DASHBOARD_TAB: 'CoolersDashboardTab',
    MY_REWARDS_TAB: 'RewardsTab',
    TIPS_TAB: 'TipsTab',
    MY_SALES_TAB: 'MySalesTab'
};
export const SHOPPING_LIST_SECTION_TABS = {
    favorites: 'favorites',
    smartOrders: 'smartOrders',
    savedCarts: 'savedCarts',
    quickOrder: 'quickOrder'
};
export const MINI_CART_WORKING_WAYS = {
    CRMLOAD: 'CRMLOAD',
    SKIPMINICART: 'SKIPMINICART',
    MINICARTNOCRM: 'MINICARTNOCRM'
};

export const PERSONAL_DATA_CONSENTS = {
    MARKETING_COMMUNICATION: 'marketingCommunicationChannels',
    ORDER_NOTIFICATION: 'orderNotifications',
    EMAIL_NOTIFICATION: 'emailNotification',
    MARKETING_AGREEMENT: 'marketingConsentAgreementData',
    MINI_CART: 'miniCartPreferences'
};

export const BREADCRUMBS_URLS = {
    ACCOUNT: '/account',
    HOME: '/home'
};

export const CDC_CONTAINER_IDS = {
    PASWORDLESS_LOGIN: 'CCH-PasswordlessLogin',
    REGISTRATION_LOGIN: 'CCH-RegistrationLogin',
    PROFILE_UPDATE: 'CCH-ProfileUpdate'
};

export const CDC_SCREEN_SET_IDS = {
    FORGOT_PASSWORD: 'cch-forgot-password-screen',
    LOGIN: 'cch-login-screen',
    RESET_PASSWORD: 'cch-reset-password-screen',
    PASWORDLESS_LOGIN: 'cch-passwordless-login-screen',
    FORGOT_PW_SUCCESS: 'cch-forgot-password-success-screen',
    RESET_PW_SUCCESS: 'cch-reset-password-success-screen',
    CHANGE_PW: 'cch-change-password-screen'
};

export const CDC_INFO = {
    client_id: 'cdc',
    client_secret: ''
};

export const HOWITWORKS_QUICK_ORDER = {
    SHOPPING_LIST_HOW_IT_WORKS: 'shoppingListHowItWorks',
    SHOPPING_LIST_ORDER_QUICKLY: 'shoppingListOrderQuickly',
    SHOPPING_LIST_STEP_1: 'shoppingListStep1',
    SHOPPING_LIST_STEP_2: 'shoppingListStep2',
    SHOPPING_LIST_STEP_3: 'shoppingListStep3',
    SHOPPING_LIST_STEP_4: 'shoppingListStep4'
};

export const MY_REWARDS = {
    LOYALTY_MAX_CONSECUTIVE_ORDERS_STEPS: 3,
    ONBOARDING_MAX_ORDERS_STEPS: 7,
    PAGE_TITLE: 'myRewardsPageTitle',
    LOYALTY_CARD_TITLE: 'program123CardTitle',
    LOYALTY_CARD_COMING_SOON: 'program123CardComingSoon',
    LOYALTY_CARD_CONSECUTIVE_ORDERS: 'program123CardConsecutiveOrders',
    LOYALTY_CARD_ORDERS_UNTIL_DISCOUNT: 'program123CardOrdersUntilDiscount',
    LOYALTY_CARD_DISCOUNT_RECEIVED: 'program123CardDiscountReceived',
    ONBOARDING_CARD_TITLE: 'program246CardTitle',
    ONBOARDING_CARD_COMPLETED: 'program246CardCompleted',
    ONBOARDING_CARD_ORDERS_COMPLETED: 'program246CardOrdersCompleted',
    ONBOARDING_CARD_FOOTNOTE: 'program246CardFootnote',
    ONBOARDING_CARD_SAVINGS: 'program246CardSavings',
    OUR_LOYALTY_TITLE: 'programmersComponentTitle',
    OUR_LOYALTY_SUBTITLE: 'programmersComponentSubtitle',
    BOTTOM_COMPONENT_TITLE: 'bottomMarketingComponentTitle',
    BOTTOM_COMPONENT_TEXT: 'bottomMarketingComponentDescription',
    ONBOARDING_COMPLETED_STATUS: 'COMPLETED',
    LOYALTY_PROGRAM_NOT_STARTED: 'COMING SOON',
    SUBCARD_LOYALTY_ORDERS: 'subcardLoyaltyOrders',
    SUBCARD_LOYALTY_ORDERS_ICON: 'loyalty-orders',
    LOYALTY_BUTTON_LEARN: 'loyaltyButtonLearn',
    SUBCARD_ONBOARDING: 'subcardOnboarding',
    SUBCARD_ONBOARDING_ICON: 'loyalty-onboarding',
    SUBCARD_REFERRAL: 'subcardReferral',
    SUBCARD_REFERRAL_ICON: 'referral',
    SUBCARD_REVENUE_INCENTIVE: 'subcardRevenueIncentive',
    SUBCARD_REVENUE_INCENTIVE_ICON: 'revenue-incentive',
    SUBCARD_SELF_RED: 'subcardSelfRed',
    SUBCARD_SELF_RED_ICON: 'self-red',
    SUBCARD_SKU_ASSORTMENT: 'subcardSkuAssortment',
    SUBCARD_SKU_ASSORTMENT_ICON: 'sku-assortment',
    MY_REWARDS_PAGE_MY_ACCOUNT: 'myRewardsPageMyAccount',
    LOYALTY_HOW_IT_WORKS: 'loyaltyHowItWorks',
    LOYALTY_COMPLETE_STEPS: 'loyaltyCompleteSteps',
    LOYALTY_ORDERS_TITLE: 'loyaltyOrdersPageMyRewards',
    LOYALTY_ORDERS_COMPLETE_ONBOARDING: 'loyaltyOrdersPageCompleteOnboarding',
    LOYALTY_ORDERS_RECEIVE_DISCOUNT: 'loyaltyOrdersPageReceiveDiscount',
    LOYALTY_ORDERS_TRACK_REWARDS: 'loyaltyOrdersPageTrackDiscount',
    ONBOARDING_COMPLETE_ONBOARDING: 'onboardingPageCompleteOnboarding',
    ONBOARDING_RECEIVE_DISCOUNT: 'onboardingPageReceiveDiscount',
    ONBOARDING_TRACK_DISCOUNT: 'onboardingPageTrackDiscount',
    ONBOARDING_PAGE_TITLE: 'onboardingPageTitle',
    ONBOARDING_PAGE_MY_REWARDS: 'onboardingPageMyRewards',
    ONBOARDING_HOW_IT_WORKS: 'onboardingHowItWorks',
    ONBOARDING_COMPLETE_STEPS: 'onboardingCompleteSteps',
    ONBOARDING_COMPLETED: 'onboarding246CardCompleted',
    ONBOARDING_SAVINGS: 'onboarding246CardSavings',
    ONBOARDING_ORDERS_COMPLETED: 'onboarding246CardOrdersCompleted',
    ONBOARDING_FOOTNOTE: 'onboarding246CardFootnote',
    ONBOARDING_TITLE: 'onboarding246CardTitle',
    LOYALTY_COMING_SOON: 'loyalty123CardComingSoon',
    LOYALTY_CONSECUTIVE_ORDERS: 'loyalty123CardConsecutiveOrders',
    LOYALTY_ORDERS_UNTIL_DISCOUNT: 'loyalty123CardOrdersUntilDiscount',
    LOYALTY_ORDERS_DISCOUNT_RECEIVED: 'loyalty123CardDiscountReceived',
    LOYALTY_TITLE: 'loyalty123CardTitle',
    LOYALTY_MY_REWARDS: 'loyaltyOrdersPageMyRewards',
    ONBOARDING_MY_REWARDS: 'onboardingPageMyRewards',
    ONBOARDING_POPUP_PAGE1_TITLE :'onboardingPopupPage1Title',
    ONBOARDING_POPUP_PAGE1_DESCRIPTION :'onboardingPopupPage1Description',
    ONBOARDING_POPUP_PAGE2_TITLE :'onboardingPopupPage2Title',
    ONBOARDING_POPUP_PAGE2_DESCRIPTION :'onboardingPopupPage2Description',
    ONBOARDING_POPUP_PAGE3_TITLE :'onboardingPopupPage3Title',
    ONBOARDING_POPUP_PAGE3_DESCRIPTION :'onboardingPopupPage3Description',
    MY_REWARDS_SELF_RED_TITLE: "myRewardsSelfREDTitle",
    MY_REWARDS_SELF_RED_MESSAGE_UNDER_TITLE :"myRewardsSelfREDMessageUnderTitle",
    MY_REWARDS_SELF_RED_CURRENT_DISCOUNT :"myRewardsSelfREDCurrentDiscount",
    MY_REWARDS_SELF_RED_CURRENT_DISCOUNT_PERIOD: "myRewardsSelfREDCurrentDiscountPeriod",
    MY_REWARDS_SELF_RED_NEXT_DISCOUNT: "myRewardsSelfREDNextDiscount",
    MY_REWARDS_SELF_RED_NEXT_DISCOUNT_PERIOD: "myRewardsSelfREDNextDiscountPeriod",
};

export const SELF_RED = {
    SELF_RED_TITLE: "selfREDTitle",
    SELF_RED_MESSAGE_UNDER_TITLE: "selfREDMessageUnderTitle",
    SELF_RED_TOTAL_REWARDS: "selfREDTotalRewards",
    SELF_RED_CAPTURE_NOW: "selfREDCaptureNow",
    SELF_RED_CAPTURE_NOW_MOBILE: "selfREDCaptureNowMobile",
    SELF_RED_CURRENT_DISCOUNT: "selfREDCurrentDiscount",
    SELF_RED_CURRENT_DISCOUNT_PERIOD: "selfREDCurrentDiscountPeriod",
    SELF_RED_GET_YOUR_REWARD: "selfREDGetYourReward",
    SELF_RED_GET_YOUR_REWARD_MOBILE: "selfREDGetYourRewardMobile",
    SELF_RED_GET_YOUR_REWARD_PERIOD: "selfREDNextDiscountPeriod",
    SELF_RED_NEXT_DISCOUNT: "selfREDNextDiscount",
    SELF_RED_NEXT_DISCOUNT_PERIOD: "selfREDNextDiscountPeriod",
    SELF_RED_HOME: "selfREDPageHome",
    SELF_RED_MY_ACCOUNT: "selfREDPageMyAccount",
    SELF_RED_MY_REWARDS: "selfREDPageMyRewards",
    SELF_RED_HOW_IT_WORKS: "selfREDHowItWorks",
    SELF_RED_EARN_SPEND_REWARDS: "selfREDEarnSpendRewards",
    SELF_RED_DOWNLOAD: "selfREDDownload",
    SELF_RED_CLICK_TO_WIN_APP: "selfREDClickToWinApp",
    SELF_RED_ENSURE_MERCHANDISING: "selfREDEnsureMerchandising",
    SELF_RED_FIND_OUT_HOW: "selfREDFindOutHow",
    SELF_RED_TAKE_PICTURE: "selfREDTakePicture",
    SELF_RED_OF_YOUR_COOLER: "selfREDOfYourCooler",
    SELF_RED_GET_REWARD: "selfREDGetReward",
    SELF_RED_READ_NOW: "selfREDReadNow",
    SELF_RED_LEARN_MORE: "selfREDLearnMore",
    SELF_RED_STILL_HAVE_QUESTIONS_TITLE: "selfREDStillHaveQuestionsTitle",
    SELF_RED_STILL_HAVE_QUESTIONS_UNDER_TITLE: "selfREDStillHaveQuestionsUnderTitle",
    SELF_RED_TERMS_AND_CONDITIONS_TITLE: "selfREDTermsAndConditionsTitle",
    SELF_RED_TERMS_AND_CONDITIONS_UNDER_TITLE: "selfREDTermsAndConditionsUnderTitle"
};

export const SELF_RED_COMPONENTS = {
    SELF_RED_FAQ_TITLE: 'FaqTitleSelfREDPageComponent-React',
    SELF_RED_FAQ_TYPE: 'FAQParagraphComponent',
    SELF_RED_BANNER_COMPONENT: 'SelfREDBannerComponent',
    MOBILE_SELF_RED_BANNER_COMPONENT: 'MobileSelfREDBannerComponent'
}

export const DEVICE_TYPES = {
    DESKTOP: 'desktop',
    MOBILE: 'mobile',
    TABLET: 'tablet'
};

export const FSA = {
    SECTION_ONE_TEXT_1: 'tipsSection1Text1',
    SECTION_ONE_TEXT_2: 'tipsSection1Text2',
    SECTION_ONE_TEXT_3: 'tipsSection1Text3',
    SECTION_ONE_TEXT_4: 'tipsSection1Text4',
    SECTION_ONE_TEXT_5: 'tipsSection1Text5',
    SECTION_ONE_TEXT_6: 'tipsSection1Text6',
    SECTION_ONE_TEXT_7: 'tipsSection1Text7',
    SECTION_ONE_TEXT_8: 'tipsSection1Text8',
    SECTION_ONE_TEXT_9: 'tipsSection1Text9',
    SECTION_TWO_POPUP_ONE_TEXT_1: 'tipsSection2Popup1Text1',
    SECTION_TWO_POPUP_ONE_TEXT_10: 'tipsSection2Popup1Text10',
    SECTION_TWO_POPUP_ONE_TEXT_11: 'tipsSection2Popup1Text11',
    SECTION_TWO_POPUP_ONE_TEXT_12: 'tipsSection2Popup1Text12',
    SECTION_TWO_POPUP_ONE_TEXT_2: 'tipsSection2Popup1Text2',
    SECTION_TWO_POPUP_ONE_TEXT_3: 'tipsSection2Popup1Text3',
    SECTION_TWO_POPUP_ONE_TEXT_4: 'tipsSection2Popup1Text4',
    SECTION_TWO_POPUP_ONE_TEXT_5: 'tipsSection2Popup1Text5',
    SECTION_TWO_POPUP_ONE_TEXT_6: 'tipsSection2Popup1Text6',
    SECTION_TWO_POPUP_ONE_TEXT_7: 'tipsSection2Popup1Text7',
    SECTION_TWO_POPUP_ONE_TEXT_8: 'tipsSection2Popup1Text8',
    SECTION_TWO_POPUP_ONE_TEXT_9: 'tipsSection2Popup1Text9',
    SECTION_TWO_POPUP_TWO_TEXT_1: 'tipsSection2Popup2Text1',
    SECTION_TWO_POPUP_TWO_TEXT_10: 'tipsSection2Popup2Text10',
    SECTION_TWO_POPUP_TWO_TEXT_11: 'tipsSection2Popup2Text11',
    SECTION_TWO_POPUP_TWO_TEXT_12: 'tipsSection2Popup2Text12',
    SECTION_TWO_POPUP_TWO_TEXT_13: 'tipsSection2Popup2Text13',
    SECTION_TWO_POPUP_TWO_TEXT_2: 'tipsSection2Popup2Text2',
    SECTION_TWO_POPUP_TWO_TEXT_3: 'tipsSection2Popup2Text3',
    SECTION_TWO_POPUP_TWO_TEXT_4: 'tipsSection2Popup2Text4',
    SECTION_TWO_POPUP_TWO_TEXT_5: 'tipsSection2Popup2Text5',
    SECTION_TWO_POPUP_TWO_TEXT_6: 'tipsSection2Popup2Text6',
    SECTION_TWO_POPUP_TWO_TEXT_7: 'tipsSection2Popup2Text7',
    SECTION_TWO_POPUP_TWO_TEXT_8: 'tipsSection2Popup2Text8',
    SECTION_TWO_POPUP_TWO_TEXT_9: 'tipsSection2Popup2Text9',
    SECTION_TWO_TEXT_1: 'tipsSection2Text1',
    SECTION_TWO_TEXT_10: 'tipsSection2Text10',
    SECTION_TWO_TEXT_11: 'tipsSection2Text11',
    SECTION_TWO_TEXT_12: 'tipsSection2Text12',
    SECTION_TWO_TEXT_13: 'tipsSection2Text13',
    SECTION_TWO_TEXT_14: 'tipsSection2Text14',
    SECTION_TWO_TEXT_15: 'tipsSection2Text15',
    SECTION_TWO_TEXT_16: 'tipsSection2Text16',
    SECTION_TWO_TEXT_17: 'tipsSection2Text17',
    SECTION_TWO_TEXT_18: 'tipsSection2Text18',
    SECTION_TWO_TEXT_19: 'tipsSection2Text19',
    SECTION_TWO_TEXT_2: 'tipsSection2Text2',
    SECTION_TWO_TEXT_20: 'tipsSection2Text20',
    SECTION_TWO_TEXT_21: 'tipsSection2Text21',
    SECTION_TWO_TEXT_22: 'tipsSection2Text22',
    SECTION_TWO_TEXT_23: 'tipsSection2Text23',
    SECTION_TWO_TEXT_3: 'tipsSection2Text3',
    SECTION_TWO_TEXT_4: 'tipsSection2Text4',
    SECTION_TWO_TEXT_5: 'tipsSection2Text5',
    SECTION_TWO_TEXT_6: 'tipsSection2Text6',
    SECTION_TWO_TEXT_7: 'tipsSection2Text7',
    SECTION_TWO_TEXT_8: 'tipsSection2Text8',
    SECTION_TWO_TEXT_9: 'tipsSection2Text9',
    SECTION_THREE_TEXT_1: 'tipsSection3Text1',
    SECTION_THREE_TEXT_10: 'tipsSection3Text10',
    SECTION_THREE_TEXT_11: 'tipsSection3Text11',
    SECTION_THREE_TEXT_12: 'tipsSection3Text12',
    SECTION_THREE_TEXT_13: 'tipsSection3Text13',
    SECTION_THREE_TEXT_14: 'tipsSection3Text14',
    SECTION_THREE_TEXT_15: 'tipsSection3Text15',
    SECTION_THREE_TEXT_16: 'tipsSection3Text16',
    SECTION_THREE_TEXT_17: 'tipsSection3Text17',
    SECTION_THREE_TEXT_18: 'tipsSection3Text18',
    SECTION_THREE_TEXT_19: 'tipsSection3Text19',
    SECTION_THREE_TEXT_2: 'tipsSection3Text2',
    SECTION_THREE_TEXT_20: 'tipsSection3Text20',
    SECTION_THREE_TEXT_21: 'tipsSection3Text21',
    SECTION_THREE_TEXT_22: 'tipsSection3Text22',
    SECTION_THREE_TEXT_23: 'tipsSection3Text23',
    SECTION_THREE_TEXT_24: 'tipsSection3Text24',
    SECTION_THREE_TEXT_3: 'tipsSection3Text3',
    SECTION_THREE_TEXT_4: 'tipsSection3Text4',
    SECTION_THREE_TEXT_5: 'tipsSection3Text5',
    SECTION_THREE_TEXT_6: 'tipsSection3Text6',
    SECTION_THREE_TEXT_7: 'tipsSection3Text7',
    SECTION_THREE_TEXT_8: 'tipsSection3Text8',
    SECTION_THREE_TEXT_9: 'tipsSection3Text9',
    SECTION_FOUR_TEXT_1: 'tipsSection4Text1',
    SECTION_FOUR_TEXT_2: 'tipsSection4Text2',
    SECTION_FOUR_TEXT_3: 'tipsSection4Text3',
    SECTION_FOUR_TEXT_4: 'tipsSection4Text4',
    SECTION_FOUR_TEXT_5: 'tipsSection4Text5',
    SECTION_FOUR_TEXT_6: 'tipsSection4Text6',
    SECTION_FOUR_TEXT_7: 'tipsSection4Text7',
    SECTION_FOUR_TEXT_8: 'tipsSection4Text8',
    SECTION_FOUR_TEXT_9: 'tipsSection4Text9',
    SECTION_FOUR_TEXT_10: 'tipsSection4Text10',
    SECTION_FIVE_TEXT_1: 'tipsSection5Text1',
    SECTION_FIVE_TEXT_2: 'tipsSection5Text2',
    SECTION_FIVE_TEXT_3: 'tipsSection5Text3',
    SECTION_SIX_TEXT_1: 'tipsSection6Text1',
    SECTION_SIX_TEXT_2: 'tipsSection6Text2',
    SECTION_SEVEN_TEXT_1: 'tipsSection7Text1',
    SECTION_SEVEN_TEXT_2: 'tipsSection7Text2',
    SECTION_SEVEN_TEXT_3: 'tipsSection7Text3',
    SECTION_SEVEN_TEXT_4: 'tipsSection7Text4',
    SECTION_SEVEN_TEXT_5: 'tipsSection7Text5',
    SECTION_SEVEN_TEXT_6: 'tipsSection7Text6'
};

export const FSA_IMAGES = {
    BANNER_IMAGE: 'TipsPageHeroBannerComponent',
    BANNER_IMAGE_MOBILE: 'TipsPageHeroMobileBannerComponent',
    BANNER_IMAGE_TABLET: 'TipsPageHeroTabletBannerComponent',
    BEST_SELLING: 'TipsPageHeroBestSellingComponent',
    LEARN_MORE: 'TipsPageHeroLearnMoreComponent',
    DOS_IMAGE: 'TipsPageHeroDosComponent',
    DONT_IMAGE: 'TipsPageHeroDontsComponent',
    DELIVERY_PARTNERS_IMAGE: 'TipsPageDeliveryPartnersComponent',
    GET_STARTED: 'TipsPageGetStrted',
    BRAND_LOGOS: 'TipsPageBrandLogosComponent'
};