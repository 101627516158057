/* eslint-disable no-nested-ternary */
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import Modal from '../../core/Modal/Modal';
import step1 from '../../../assets/images/Pay4PerformanceImage1.webp';
import step2 from '../../../assets/images/Pay4PerformanceImage2.webp';
import step3 from '../../../assets/images/Pay4PerformanceImage3.webp';
import step4Desktop from '../../../assets/images/Pay4PerformanceImage4Desktop.webp';
import step4Mobile from '../../../assets/images/Pay4PerformanceImage4Mobile.webp';
import googlePlayStore from '../../../assets/images/GooglePlayStore.webp';
import appStore from '../../../assets/images/AppStore.webp';
import * as styles from './Pay4Performance.css';
import Button from '../../core/Button/Button';
import Icon from '../../core/Icon/Icon';

const cx = classNames.bind(styles);

const Pay4Performance = ({
    showOnboardingSelfREDPopUp,
    setShowOnboardingSelfREDPopUp,
    onboardingPopupPage1Title,
    onboardingPopupPage1Description,
    onboardingPopupPage2Title,
    onboardingPopupPage2Description,
    onboardingPopupPage3Title,
    onboardingPopupPage3Description,
    onboardingPopupPage4Title,
    onboardingPopupPage4Description,
    currentStepStart
}) => {
    const deviceType = useSelector(state => state.context.deviceType);

    const [currentStep, setCurrentStep] = useState(currentStepStart);
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    useMemo(() => setIsPopupVisible(showOnboardingSelfREDPopUp), [showOnboardingSelfREDPopUp]);

    const handleNextButtonClick = () => {
        setCurrentStep(currentStep + 1);
    };

    const handleXButtonClick = () => {
        setIsPopupVisible(false);
        setShowOnboardingSelfREDPopUp();
    };

    const screensData = [
        {
            title: onboardingPopupPage1Title,
            description: onboardingPopupPage1Description,
            image: step1
        },
        {
            title: onboardingPopupPage2Title,
            description: onboardingPopupPage2Description,
            image: step2
        },
        {
            title: onboardingPopupPage3Title,
            description: onboardingPopupPage3Description,
            image: step3
        },
        {
            title: onboardingPopupPage4Title,
            description: deviceType.isMobile ? "" : onboardingPopupPage4Description,
            image: deviceType.isMobile ? step4Mobile : step4Desktop
        }
    ];

    return (
        <Modal
            themeModalBlock={cx('p4pModalBlock')}
            themeClose={cx('p4pCloseButton')}
            modalTheme={cx('p4pModalTheme')}
            showModal={isPopupVisible}
            onClose={handleXButtonClick}
            className={cx('p4pModalWrapper')}
        >
            <div className={cx('p4pPopUpModal')}>
                <h1 className={cx('p4pTitle')}>{screensData[currentStep]?.title}</h1>
                <div className={cx('p4pImage')}>
                    <img src={screensData[currentStep]?.image} alt={`step-${currentStep}`} />
                </div>
                <p className={cx('p4pText')}>{screensData[currentStep]?.description}</p>
                <div className={cx('p4pButtonGroup')}>
                    {currentStep !== 3 ? (
                        <Button type='linkbutton' onClick={handleNextButtonClick}>
                            <Icon
                                iconType='svg'
                                width='35'
                                height='35'
                                viewBox='0 0 35 35'
                                name='loyalty-arrow-right'
                            />
                        </Button>
                    ) : deviceType.isMobile ? (
                        <div className={cx('mobile-button-col')}>
                            <div
                                tabIndex='0'
                                role='button'
                                className={cx('mobile-button-container')}
                                onKeyDown={() => {
                                    window.open(
                                        'https://play.google.com/store/apps/details?id=apps.maxerience.clicktowin.and.generic&hl=es_419&gl=US',
                                        '_blank'
                                    );
                                }}
                                onClick={() => {
                                    window.open(
                                        'https://play.google.com/store/apps/details?id=apps.maxerience.clicktowin.and.generic&hl=es_419&gl=US',
                                        '_blank'
                                    );
                                }}
                            >
                                <img className={cx('mobile-img')} src={googlePlayStore} alt='play-store-btn' />
                            </div>
                            <div
                                tabIndex='0'
                                role='button'
                                className={cx('mobile-button-container')}
                                onKeyDown={() => {
                                    window.open('https://apps.apple.com/us/app/selfred/id1631778487', '_blank');
                                }}
                                onClick={() => {
                                    window.open('https://apps.apple.com/us/app/selfred/id1631778487', '_blank');
                                }}
                            >
                                <img className={cx('mobile-img')} src={appStore} alt='app-store-btn' />
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className={cx('p4pDots')} style={{display: currentStepStart === 3 ? 'none' : 'flex'}}>
                    <span className={cx('dot', { active: currentStep === 0 })} />
                    <span className={cx('dot', { active: currentStep === 1 })} />
                    <span className={cx('dot', { active: currentStep === 2 })} />
                    <span className={cx('dot', { active: currentStep === 3 })} />
                </div>
            </div>
        </Modal>
    );
};

export default Pay4Performance;